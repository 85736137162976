import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.isOpenDialog),callback:function ($$v) {_vm.isOpenDialog=$$v},expression:"isOpenDialog"}},[_c(VCard,[_c(VCardText,{staticClass:"pt-5"},[_c('div',{class:_vm.isDarkTheme ? 'card-dark' : 'card-light'},[_c('p',{staticClass:"d-flex justify-center title pt-2"},[_vm._v("Please, Select any one!")]),_c(VLayout,{staticClass:"ma-auto",attrs:{"row":""}},_vm._l((_vm.clients),function(activeClient,index){return _c(VFlex,{key:activeClient.id,staticClass:"register-page_person mr-0",attrs:{"col-sm-4":"","col-md-4":"","col-12":""}},[_c(VBtn,{staticClass:"client-card-button",on:{"click":function($event){return _vm.onSelectActiveClient(activeClient)}}},[_c('div',{staticClass:"text-wrap",staticStyle:{"width":"min-content"}},[_vm._v(_vm._s(activeClient && activeClient.assoc_abbrev))])])],1)}),1)],1)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.onCloseClickHandler}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }