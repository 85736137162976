import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({attrs:{"small":"","label":"","text-color":"white","color":_vm.legislatorCommittee.committee.color
          ? _vm.legislatorCommittee.committee.color
          : _vm.getColorFromText(_vm.legislatorCommittee.committee.shortname)},on:{"click":function($event){return _vm.$emit('click', _vm.legislatorCommittee, _vm.client)}}},on),[_vm._v(" "+_vm._s(_vm.chipName)+" "),(
          !_vm.isSubcommittee &&
          _vm.legislatorCommittee.membership_states.some(
            (membershipState) => membershipState.pivot.subcommittee === 1
          ) &&
          _vm.client &&
          _vm.client.state &&
          _vm.client.state.has_subcommittees
        )?_c(VAvatar,{staticClass:"darken-4 ml-1",attrs:{"color":_vm.legislatorCommittee.committee.subcommittee_color
            ? _vm.legislatorCommittee.committee.subcommittee_color
            : _vm.getColorFromText(_vm.legislatorCommittee.committee.shortname)}},[_vm._v(" sub ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.fullTooltipText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }