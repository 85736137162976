<template>
  <v-dialog max-width="700" v-model="isModalOpen" v-if="isModalOpen">
    <v-form ref="textForm" @submit.prevent="onSend()">
      <v-card v-if="member">
        <v-card-title>
          <span class="title">Send {{ isEmail ? 'an Email' : 'a Text' }} to {{ member.nickname }} {{ member.lastname }}</span>
          <v-spacer></v-spacer>
          <!-- import and load text/email button component  -->
          <message-send-as
            @changeMessageType="onEmailOrTextToggle"
            class="mr-2"
            :disabled="isBusy"
            :messageLength="getMessageLength(message)"
            :textDisabled="!member.cell || member.bad_cell"
            :emailDisabled="!member.email || invalidEmail(member.email)"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert class="mt-2" type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 id="editorContainer" class="px-2 tiny_editor min-height-125">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <!-- When message will send using by editor   -->
              <div class="mt-4">
                <editor
                    v-if="isRenderEditor"
                    v-model="message"
                    :api-key="TINY_MCE_API_KEY"
                    :plugins="isEmail ? tinyPlugins : ''"
                    :toolbar="isEmail ? tinyToolbars : []"
                    :init="tinyInit"
                    @click="onChangeText"
                    @input="onChangeText"
                  ></editor>
                  <div v-if="!isEmail" class="d-flex justify-end mt-2">
                    <span class="text-counter" :class="getMessageLength(message) > 320 ? 'error--text' : ''">
                      {{ getMessageLength(message) }}/320
                    </span>
                  </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy"
                 :disabled="isEmptyMessage < 1 || message.length > 320 && !isEmail">
            {{ isApproval ? 'Queue for Approval' : 'Send' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import he from "he";

import AppService from '@/services/AppService'
import ClientService from '@/services/ClientService'
import SystemMessageService from '@/services/SystemMessageService'
import ShorterUrl from "@/mixins/shorterUrl";
import swal from "sweetalert2";
import MessageSendAs from "@/components/global/message-send-as"
import {mapGetters} from "vuex";

export default {
  mixins: [AppService, ClientService, ShorterUrl, TinyConfig, SystemMessageService],
  components: {
    'editor': Editor,
    'message-send-as': MessageSendAs,
  },
  data() {
    return {
      isModalOpen: false,
      member: null,
      message: '',
      error: '',
      isBusy: false,
      isEmail: false,
      isRenderEditor: true,
      compOptions: {} 
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isApproval() {
      return this.client && this.client.uses_approval_coordinator_m && this.isCoordinator
    },
    isEmptyMessage() {
      if (!this.message) return 0
      return this.message.split(' ').join('').length
    },
    messageRules() {
      return [
        v => !!(v !== null ? v.split(' ').join('') : v) || 'Message is required.',
        v => (v && this.message && !this.isEmail <= 320) || 'Message should be less than 320 characters.',
      ]
    }
  },
  methods: {
    toggle(member, isEmail, options = {}) {
      this.compOptions = options;
      this.isModalOpen = false
      if(this.invalidEmail(member.email) && !member.bad_cell) {
        this.isEmail = !isEmail
      } else {
        this.isEmail = isEmail
      }
      this.message = ''
      this.error = ''
      if (this.$refs.textForm) this.$refs.textForm.reset()
      this.member = member

      this.$nextTick().then(() => {
        this.text = {
          client_id: this.client.id,
          phone: member.cell,
          member_id: member.id
        }

        this.email = {
          client_id: this.client.id,
          sender_email: this.userData && this.userData.email || '',
          sender_name: this.userName,
          subject: `KP Dashboard - ${this.userName}`,
          email: member.email,
          member_id: member.id
        }

        //Set default message
        this.message = `Hi ${member.nickname},  <br />Thanks! -${this.userData.nickname}`
        this.isModalOpen = true
        this.onEditorRender();
      })
    },
    getMessageLength(message){
      return message && message.length || 0;
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
          re = new RegExp(find, 'g');
      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    //It's email and text toggle button handler
    onEmailOrTextToggle(value){
      this.isEmail = value;
      this.onEditorRender();
    },
    onEditorRender(){
      this.isRenderEditor = false;
      setTimeout(()=>{ 
        this.isRenderEditor = true;
      }, 500)
    },
    async onChangeText() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.message)
        if (newContent !== this.message) {
          document.activeElement.blur()
          this.message = newContent
          this.disabledEditor = false
        }
      })
    },
    async send() {
      if (!this.$refs.textForm.validate()) return
      this.isBusy = true
      if (!this.isEmail) {
        try {
          this.text.message = this.html2Text(this.message)
          // await this.sendMessage(this.text)

          await this.saveClientScheduleMessage({
            clientId: this.client.id,
            is_sms: true,
            members: [this.member.id],
            message: this.text.message,
            is_kps_send: false,
            is_admin_send: false,
          });
          this.onCallToastr('success', 'Text has been sent.', 'Success!')
        } catch (err) {
          this.isBusy = false
          this.error = 'Something went wrong!'
          return
        }
      } else {
        try {
          this.email.message = this.message
          // await this.sendMessage(this.email)
          await this.saveClientScheduleMessage({
            clientId: this.client.id,
            is_sms: false,
            members: [this.member.id],
            message: this.email.message,
            from: this.email.sender_name,
            from_members_page: true,
            subject: this.email.subject,
            show_legislators: false,
            is_kps_send: false,
            is_admin_send: false,
          })

          this.onCallToastr('success', 'Email has been sent.', 'Success!')
        } catch (err) {
          this.isBusy = false
          this.error = 'Something went wrong!'
          return
        }
      }
      this.isBusy = false
      this.isModalOpen = false
    },
    onSend() {
      if (!this.$refs.textForm.validate()) return

      if (this.isApproval) {
        swal({
          text: 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Queue for Approval',
          cancelButtonText: 'Go Back',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.send()
          }
        })
      } else {
        this.send()
      }
    },
    invalidEmail(email) {
      if (this.isBad(email) || !this.validateEmail(email)) {
        this.isEmail = false
      }
      return this.isBad(email) || !!!this.validateEmail(email);
    },
    // invalidPhone(phone) {      // not implying here
    //   return !!!this.validateCell(phone);
    // },
  },
}
</script>
