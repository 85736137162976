<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer fixed temporary right touchless width="600" v-model="isModalOpen">
    <v-form ref="clientForm" @submit.prevent="onUpdateClient(false)">
      <v-card style="box-shadow: none !important;">
        <v-card-title>
          <span class="title">Edit Client</span>
          <v-spacer />
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="mb-2 px-2">
              <div class="d-inline-block">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar size="80" class="cursor-pointer" @click="$refs.avatarUploader.click()" v-on="on">
                      <img :src="client.avatar_url || '/images/client-avatar.png'" />
                    </v-avatar>
                  </template>
                  <span>Click above to upload this client's association avatar.</span>
                </v-tooltip>
              </div>
              <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Active"
                v-model="client.active"
              ></v-checkbox>
            </v-flex>
            <v-flex xs8 class="px-2">
              <v-select
                v-if="isSuperAdmin"
                label="Plan"
                item-text="name"
                item-value="id"
                :items="tariffList"
                :rules="tariffRules"
                v-model="client.tariff_id"
              ></v-select>
            </v-flex>
            <v-flex xs8 class="px-2">
              <v-text-field label="Association*" :rules="associationRules" v-model="client.association" @input="error = ''"></v-text-field>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-text-field label="Association Abbr*" :rules="associationAbbrevRules" v-model="client.assoc_abbrev"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Profession" v-model="client.profession"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Use KP Dashboard Emails in Messages?"
                v-model="client.is_forward_email_required"
                @change="client.reply_to_email = '';client.mailgun_email = '';client.forward_email = '';client.mailgun_domain = '';"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="!client.is_forward_email_required">
              <v-text-field label="Reply To Email*" v-model="client.reply_to_email"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="client.is_forward_email_required">
              <v-text-field label="Mailgun Email*" v-model="client.mailgun_email"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="client.is_forward_email_required">
              <v-text-field label="Forward Email" v-model="client.forward_email"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="client.is_forward_email_required">
              <v-text-field label="Mailgun Domain" v-model="client.mailgun_domain"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Forward reply texts to personal phone number?"
                v-model="client.is_forward_text_required"
                @change="client.forward_number = ''"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 md12 class="px-2">
              <v-select label="Time zone" :items="getTimeZonesList()"
                        v-model="client.timezone"></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Twilio SID" v-model="client.twilio_sid"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-text-field label="Twilio Number" v-mask="'(###) ###-####'" :rules="phoneNumberRules" v-model="client.twilio_number"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-text-field label="Forward Number*" v-mask="'(###) ###-####'" :rules="phoneNumberRules" v-model="client.forward_number" v-if="client.is_forward_text_required"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-select label="Country/State*" disabled item-text="state" item-value="id" :items="stateList" :rules="stateRules" v-model="client.state_id" @change="onSelectState"></v-select>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-select label="How many KPs?" :items="kpsList" v-model="client.uses_kps"></v-select>
            </v-flex>
            <v-flex xs12 md6 class="px-2" v-if="client.uses_regions">
              <v-switch
                  color="primary"
                  v-model="client.uses_approval_coordinator_m"
                  :label="`Queue all Coordinator messages for Admin approval?`"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-switch
                  color="primary"
                  v-model="client.uses_approval_kp_m"
                  :label="`Queue all KP messages for Admin approval?`"
              ></v-switch>
            </v-flex>
            <v-flex v-if="client.uses_regions" xs12 md6 class="px-2">
              <v-switch
                  class="px-2"
                  color="primary"
                  v-model="client.uses_assignment_kp_c"
                  :label="`Allow Coordinators to search for, assign, and remove KPs`"
              ></v-switch>
            </v-flex>
            <v-flex v-if="isSuperAdmin" xs4 class="px-2">
              <v-checkbox
                color="primary"
                :disabled="!(client.tariff && client.tariff.uses_regions_option)"
                label="Uses regions?"
                v-model="client.uses_regions"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 :md6="isSuperAdmin"  class="px-2" v-if="client.uses_regions">
              <v-checkbox
                color="primary"
                label="Uses coordinators?"
                v-model="client.uses_coordinators"
                @change="client.show_all = false"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Show All Task Rows for Coordinators"
                v-model="client.show_all"
                v-if="client.uses_coordinators"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses DOB?"
                v-model="client.uses_dob"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses grad year?"
                v-model="client.uses_grad_year"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 2nd work?"
                v-model="client.uses_2nd_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 3rd work?"
                v-model="client.uses_3rd_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 4th work?"
                v-model="client.uses_4th_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-switch
                color="primary"
                v-model="client.profile_update_notifications"
                label="Profile Update Notifications"
                @change="client.embed_personal_data = false"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md6 class="px-2" v-if="client.profile_update_notifications">
              <div class="d-flex align-right">
                <v-text-field label="Notification Email Address" v-model="client.notification_email"></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="px-2" v-if="client.profile_update_notifications">
              <div class="d-flex align-center">
                <v-checkbox
                  color="primary"
                  v-model="client.embed_personal_data"
                  label="Embed Personal Data"
                ></v-checkbox>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon size="20" v-on="on">help</v-icon>
                    </template>
                    <span>If you have security concerns about personal information in an email, then check this box to hide personal data in the notification email</span>
                  </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <div class="d-flex align-center">
                <v-text-field label="Formal House" v-model="client.formal_house"></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" v-on="on">help</v-icon>
                  </template>
                  <span>How you would like to address House legislators, placeholder = “example, Delegate/Representative/Del./Rep.</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <div class="d-flex align-center">
                <v-text-field label="Formal Senate" v-model="client.formal_senate"></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" v-on="on">help</v-icon>
                  </template>
                  <span>How you would like to address Senators, placeholder = “example, Senator/Sen.</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-alert type="info" :value="true" outlined class="mb-2">
                Please select the Committee(s) that are important to your cause.
                These will be used throughout your Dashboard - all others will be hidden, but available if you change your mind later.
              </v-alert>
              <v-autocomplete
                multiple
                chips
                label="House Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="houseCommitteeList"
                v-model="client.house_committees"
                v-if="selectedState && selectedState.house_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                          v-on="on"
                          label
                          dark
                          close
                          :input-value="props.selected"
                          :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                          class="chip--select-multi"
                          @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Senate Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="senateCommitteeList"
                v-model="client.senate_committees"
                v-if="selectedState && selectedState.sen_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Joint Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="jointCommitteeList"
                v-model="client.joint_committees"
                v-if="selectedState && selectedState.has_joint_committees">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'

import swal from 'sweetalert2'

export default {
  name: 'EditClientModal',
  props: ['stateList', 'refresh', 'tariffList'],
  mixins: [ClientService, CloudinaryService, StateService],
  data () {
    return {
      isModalOpen: false,
      committeeList: [],
      client: {},
      error: '',
      isBusy: false,
      activeKpsPosition: null,
      kpsPositionList: [0, 1, 2, 3, 4, 5]
    }
  },
  computed: {
    associationRules () {
      return [
        v => !!v || 'Association is required.',
      ]
    },
    associationAbbrevRules () {
      return [
        v => !!v || 'Association abbr is required.',
      ]
    },
    stateRules () {
      return [
        v => !!v || 'State is required.',
      ]
    },
    tariffRules () {
      return [
        v => !!v || 'Tariff is required.',
      ]
    },
    kpsList () {
      return this.kpsPositionList.map((kp) => ({
        text: kp.toString(),
        value: kp,
        disabled: kp < this.activeKpsPosition,
      }));
    },
    houseCommitteeList () {
      const houseCommitteeList = this.committeeList.filter(committee => committee.type === 0)

      if(this.client.house_committees.length > 0){
        this.client.house_committees.forEach(houseCommittee => {
          const houseCommitteeIndex = houseCommitteeList.findIndex((item) => item.id === houseCommittee);
          if(houseCommitteeIndex !== -1){
            const selectedHouseCommittee = houseCommitteeList[houseCommitteeIndex];
            if (selectedHouseCommittee.subcommittees && selectedHouseCommittee.subcommittees.length > 0) {
              // Add additional items after the selected item
              const additionalItemsNotInList = selectedHouseCommittee.subcommittees.filter(
                (item) => !houseCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                houseCommitteeList.splice(houseCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }

      return houseCommitteeList
    },
    senateCommitteeList () {
      const senateCommitteeList = this.committeeList.filter(committee => committee.type === 1)
      if(this.client.senate_committees.length > 0){
        this.client.senate_committees.forEach(senateCommittee => {
          const senateCommitteeIndex = senateCommitteeList.findIndex((item) => item.id === senateCommittee);
          if(senateCommitteeIndex !== -1){
            const selectedSenateCommittee = senateCommitteeList[senateCommitteeIndex];
            if (selectedSenateCommittee.subcommittees && selectedSenateCommittee.subcommittees.length > 0) {
              const additionalItemsNotInList = selectedSenateCommittee.subcommittees.filter(
                (item) => !senateCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                senateCommitteeList.splice(senateCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }
      return senateCommitteeList
    },
    jointCommitteeList () {
      const jointCommitteeList = this.committeeList.filter(committee => committee.type === 2)
      if(this.client.joint_committees.length > 0){
        this.client.joint_committees.forEach(jointCommittee => {
          const jointCommitteeIndex = jointCommitteeList.findIndex((item) => item.id === jointCommittee);
          if(jointCommitteeIndex !== -1){
            const selectedJointCommittee = jointCommitteeList[jointCommitteeIndex];
            if (selectedJointCommittee.subcommittees && selectedJointCommittee.subcommittees.length > 0) {
              const additionalItemsNotInList = selectedJointCommittee.subcommittees.filter(
                (item) => !jointCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                jointCommitteeList.splice(jointCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }
      return jointCommitteeList
    },
    selectedState () {
      return this.stateList.find(state => state.id === this.client.state_id)
    },
  },
  methods: {
    onSelectHouseCommitteeHandler(selectedIds){
      selectedIds.forEach(selectedValue => {
        const selectedIndex = this.houseCommitteeList.findIndex((item) => item.id === selectedValue);
        if(selectedIndex !== -1){
        const selectedHouseCommittee = this.houseCommitteeList[selectedIndex];
        console.log(selectedHouseCommittee)

        if (selectedHouseCommittee.subcommittees &&selectedHouseCommittee.subcommittees.length > 0) {
          // Add additional items after the selected item
          const additionalItemsNotInList = selectedHouseCommittee.subcommittees.filter(
            (item) => !this.houseCommitteeList.find((existing) => existing.id === item.id)
          );
          if (additionalItemsNotInList.length > 0) {
            this.houseCommitteeList.splice(selectedIndex + 1, 0, ...additionalItemsNotInList);
          }
        } else {
          // Remove additional items if "Option 2" is unchecked
          this.houseCommitteeList = this.houseCommitteeList.filter(
            (item) =>
              !this.houseCommitteeList.find((additionalItem) => additionalItem.id === item.id)
          );
        }
      }
      })
    },
    async toggle (client) {
      this.isModalOpen = true
      this.error = ''
      this.client = (await this.showClient(client.id)).data
      this.client.state_id = client.state ? client.state.id : null
      delete this.client.state
      if (!this.client.house_committees) this.client.house_committees = []
      if (!this.client.senate_committees) this.client.senate_committees = []
      if (!this.client.joint_committees) this.client.joint_committees = []
      this.client = _.cloneDeep(this.client)
      this.committeeList = (await this.getStateCommittees(this.client.state_id, {
        only_parent_committees: true,
        with_subcommittees: true,
      })).data
      
      //Getting client's active kps position from BE
      this.getClientActiveKpsPostion();
    },
    async getClientActiveKpsPostion(){
      if(this.client && this.client.id) {
        const data = (await this.getActiveKpsPostion(this.client.id)).data;
        this.activeKpsPosition = data || 0;
      }
    },
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.client.avatar_url = res.secure_url
          this.client = {...this.client}
          this.onUpdateClient(true)
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    async onSelectState () {
      if (!this.client.state_id) return
      this.committeeList = (await this.getStateCommittees(this.client.state_id)).data
      this.client.house_committees = []
      this.client.senate_committees = []
      this.client.joint_committees = []
      this.client = { ...this.client }
    },
    onUpdateClient (isModalOpen) {
      if (!this.$refs.clientForm.validate()) return
      this.isBusy = true
      this.updateClient(this.client)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Client info has been updated.', 'Success!')
          this.$store.dispatch('app/setSuperAdminSelectedClient', this.client)    
          this.refresh()
          this.isModalOpen = isModalOpen
        })
        .catch((err) => {
          this.isBusy = false
          if (err && err.response && err.response.data && err.response.data.message) {
            // Display the error message using your custom function (e.g., onCallToastr)
            this.onCallToastr('error', err.response.data.message, 'Failed!');
          } else {
            // Handle other types of errors or show a generic error message
            this.error = 'Something went wrong!'
          }
        })
    },
  }
}
</script>
