<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card class="mx-auto" max-width="600">
      <v-toolbar color="primary">
        <v-toolbar-title class="d-flex flex-nowrap justify-space-between white--text full-width">
          <div>{{ fullCommitteeName }}</div>
          <v-spacer/>
          <v-icon class="ml-3 cursor-pointer" @click.native="closeModal">close</v-icon>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list subheader two-line>
          <v-list-item v-for="chair in chairs" :key="chair.id">
            <v-list-item-avatar>
              <v-img
                :src="chair.official_image ? getThumbImage(chair.official_image) : '/images/member-avatar.png'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" v-text="chair.fullname"></v-list-item-title>
              <v-list-item-subtitle class="font-weight-light" v-text="chair.name"></v-list-item-subtitle>
            </v-list-item-content>

            <v-chip small color="#EECB23" v-if="chair.vip">VIP</v-chip>
            <v-list-item-action>
              <v-icon color="#EECB23" class="legislators_icon--faded" v-if="chair.lame_duck">mdi-duck</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader inset>Members</v-subheader>
          <v-list-item v-for="member in members" :key="member.id">
            <v-list-item-avatar>
              <v-img
                :src="member.official_image ? getThumbImage(member.official_image) : '/images/member-avatar.png'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" v-text="member.fullname"></v-list-item-title>
              <v-list-item-subtitle class="font-weight-light" v-text="member.name"></v-list-item-subtitle>
            </v-list-item-content>

            <v-chip small color="#EECB23" v-if="member.vip_for_client">VIP</v-chip>
            <v-list-item-action>
              <v-icon color="#EECB23" class="legislators_icon--faded" v-if="member.lame_duck">mdi-duck</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LegislatorService from "@/services/LegislatorService";

export default {
  name: "CommitteeMembersModal",
  mixins: [LegislatorService],
  data: () => ({
    isModalOpen: false,
    isLoading: false,
    committeeId: 0,
    stateId: 0,
    clientId: 0,
    subcommittee: 0,
    fullCommitteeName: '',
    chairs: [],
    members: [],
  }),
  methods: {
    async toggle(committee, client) {
      this.isModalOpen = true;
      this.isLoading = true;
      this.members = [];
      this.chairs = [];
      this.stateId = committee.committee.state_id;
      this.committeeId = committee.committee.id;
      this.fullCommitteeName = committee.committee.fullname;
      this.clientId = client.id
      this.subcommittee = +committee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1)
        && client
        && client.state
        && client.state.has_subcommittees
      await this.getResults();
      this.isLoading = false;
    },
    getResults() {
      this.getLegislatorsByStateAndCommittee(this.stateId, this.committeeId, {
        subcommittee: this.subcommittee,
        client_id: this.clientId
      })
        .then((data) => {
          //Here member POSITION relational memberships.Id is 7 (although id =4). All else go above the v-divider (chairs, ranking, etc)
          for (let legislator of data.data) {
            legislator.fullname = legislator.nickname + " " + legislator.lastname
            if (legislator.position === 7) {
              this.members.push(legislator)
            } else {
              this.chairs.push(legislator)
            }
          }
          this.chairs.sort((a, b) => a.position - b.position)
        })
        .catch((err) => {
          this.isLoading = false
          this.handleError(err, true)
        })
    },
    closeModal() {
      this.isModalOpen = false
    }
  }
}
</script>
