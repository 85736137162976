<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-dialog persistent max-width="900" :fullscreen="isMobile" v-model="isModalOpen">
      <v-card>
        <v-card-title>
          <span class="title">Map for {{ legislator.fullname }}</span>
          <v-spacer/>
          <v-icon class="ml-3" @click="closeModal">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap class="card-content mt-3">
            <div class="map-container">
              <div class="map-el" :id="id+'_'+legislator.id" :ref="id" :style="`width: 880px; height: 400px;`"></div>
            </div>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-card-subtitle class="pa-0 py-0" >Total Advocates: 
            <v-tooltip bottom v-if="members.length" max-width="250">
              <template v-slot:activator="{ on }">
                <a v-on="on">
                  <strong>{{ members.length }}</strong>
                </a>
              </template>
              <span>
                <div v-for="(member, index) in members" k:ey="index" v-if="member && member.fullname"> {{member.fullname}} </div>
              </span>
            </v-tooltip>
            <strong v-else="">{{ members.length }}</strong>
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click="closeModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Conditionally render member-detail-modal -->
    <member-detail-modal
      v-if="isMemberDetailModalLoaded"
      ref="memberDetailModal"
      :selected-client="client"
      @openDistrictModal="openDistrictModal"
    ></member-detail-modal>
  </div>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import DistrictService from '@/services/DistrictService'
  import { partyKmlColorList } from '@/entities/party-color-list'
export default {
  name: "MapOpenlayerModal",
  components: {},
  mixins: [ DistrictService, ClientService ],
  props: {
    id: {
      type: String,
      default: 'large_map'
    },
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    legislator: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isMemberDetailModalLoaded: false,
      clientId: null,
      districtId: null,
      partyKmlColorList: partyKmlColorList(),
      isModalOpen: false,
      members: [],
      geoMembersJsonData: {
        "type": "FeatureCollection",
        "totalFeatures": 0,
        "features": [],
        "crs": {
            "type": "name",
            "properties": {
                "name": "urn:ogc:def:crs:EPSG::4326"
            }
        }
      },
    }
  },
  beforeCreate(){
    // Dynamically import MemberDetailModal component
    import('@/components/members/member-detail-modal.vue')
      .then((module) => {
        // Register the component locally
        this.$options.components['member-detail-modal'] = module.default || module;
        // Now, 'member-detail-modal' is available for use in the template
        this.isMemberDetailModalLoaded = true;
      })
      .catch((error) => {
        console.error('Error loading MemberDetailModal component:', error);
      });
  },
  created(){
    this.clientId = this.client && this.client.id? this.client.id : '';
    this.districtId = this.legislator && this.legislator.district_id? this.legislator.district_id : '';
  },
  mounted(){
    this.getMembers();
    setTimeout(()=>{
      this.initLargeMap();
    }, 200);
  },
  methods: {
    toggle(memberId) {
      this.isModalOpen = true;
      if(memberId) {
        this.$refs.memberDetailModal.toggle({id: memberId});
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async getMembers () {
      this.isLoading = true;
      try {
        const { data } = await (this.getClientMembersByDistrict(this.client.id, this.districtId));
        this.members = data || {};
        if(data && data.length) {
          const geoFeatures = [];
          data.forEach((member)=>{
            geoFeatures.push({
                      "type": "Feature",
                      "id": member.id,
                      "geometry": {
                          "type": "Point",
                          "coordinates": [
                            member.home_lng || member.work_lng,
                            member.home_lat || member.work_lat
                          ]
                      },
                      "geometry_name": "SHAPE",
                      "properties": {
                        "avatar_url": member.avatar_url,
                        "advocate_id": member.id,
                        "fullname": member.fullname
                      }
                  });
          });
          this.geoMembersJsonData.totalFeatures = data.length;
          this.geoMembersJsonData.features = geoFeatures;
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Error reading features from KML:', error);
        this.isLoading = false;
      } 
    },
    async initLargeMap(){
      try {
        const legislatorParty = this.legislator && this.legislator.party? this.legislator.party : '';
      
        //KML api data
        const { data } = (await this.getKmlFileByDistrict(this.districtId)).data;
        //Replace outline color with purpile color hex code
        const searchOutlineString = "<LineStyle><color>ff0000ff</color>";
        const replacementOutlineString = "<LineStyle><color>#88801788</color>";
        let modifiedString = data.replace(searchOutlineString, replacementOutlineString);
        const partyColor = `${this.partyKmlColorList[legislatorParty]}`;
        const searchPolyStyleString = "<PolyStyle><color>7fff8080</color></PolyStyle>";
        const replacementPolyStyleString = `<PolyStyle><color>${partyColor}</color></PolyStyle>`;
        modifiedString = modifiedString.replace(searchPolyStyleString, replacementPolyStyleString);
        
        // Set up the geographic projection
        // ol.proj.useGeographic();

        const kmlFormat = new ol.format.KML({
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });

        const kmlFeatures = kmlFormat.readFeatures(modifiedString);
          
        const kmlVectorSource = new ol.source.Vector({
          features: kmlFeatures,
        });

        const kmlVectorLayer = new ol.layer.Vector({
          source: kmlVectorSource,
        });
      
        // Add this script after including the OL library via CDN
        const circleDistanceMultiplier = 1;
        const circleFootSeparation = 28;
        const circleStartAngle = Math.PI / 2;

        const convexHullFill = new ol.style.Fill({
          color: 'rgba(255, 153, 0, 0.4)',
        });
        const convexHullStroke = new ol.style.Stroke({
          color: 'rgba(204, 85, 0, 1)',
          width: 1.5,
        });
        const outerCircleFill = new ol.style.Fill({
          color: 'rgba(255, 153, 102, 0.3)',
        });
        const innerCircleFill = new ol.style.Fill({
          color: 'rgba(255, 165, 0, 0.7)',
        });
        const textFill = new ol.style.Fill({
          color: '#fff',
        });
        const textStroke = new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 0.6)',
          width: 3,
        });
        const innerCircle = new ol.style.Circle({
          radius: 14,
          fill: innerCircleFill,
        });
        const outerCircle = new ol.style.Circle({
          radius: 20,
          fill: outerCircleFill,
        });

        const getImage = (clusterMember) =>{
          const imgUrl = clusterMember.get('avatar_url');
          const imageSize = 20;
          let smallImageUrl = imgUrl ||  '/images/member-avatar-map.png';
          if(imgUrl) {
            smallImageUrl = imgUrl.replace('/image/upload/', '/image/upload/c_thumb,g_face,r_max,h_20,w_20/');
          }
              
          return new ol.style.Icon({
            src: smallImageUrl,
            imgSize: [imageSize, imageSize], // Set the width and height of the image
            radius: imageSize / 2, // Set the radius of the circle based on half of the image size
          });
        };

        // Continue with the rest of your script...

        /**
        * Single feature style, users for clusters with 1 feature and cluster circles.
        * @param {Feature} clusterMember A feature from a cluster.
        * @return {Style} An icon style for the cluster member's location.
        */
        const clusterMemberStyle = (clusterMember) => {
          const fullname = clusterMember.get('fullname') || ''; // Get label from feature properties

          const textStyle = new ol.style.Text({
            text: fullname,
            textAlign: 'center',
            offsetY: -15, // Adjust the offset based on your preference
            font: '12px Arial', // Adjust the font based on your preference
            fill: new ol.style.Fill({
              color: '#9155fd', // Adjust the color based on your preference
            }),
          });

          const imageStyle = getImage(clusterMember); // Assuming getImage returns the image style

          return new ol.style.Style({
            geometry: clusterMember.getGeometry(),
            image: imageStyle,
            text: textStyle, // Add the text style for the label
          });
        }

        let clickFeature, clickResolution;
        /**
        * Style for clusters with features that are too close to each other, activated on click.
        * @param {Feature} cluster A cluster with overlapping members.
        * @param {number} resolution The current view resolution.
        * @return {Style|null} A style to render an expanded view of the cluster members.
        */
        const clusterCircleStyle = (cluster, resolution) => {
          if (cluster !== clickFeature || resolution !== clickResolution) {
            return null;
          }
          const clusterMembers = cluster.get('features');
          const centerCoordinates = cluster.getGeometry().getCoordinates();
          return generatePointsCircle(
            clusterMembers.length,
            cluster.getGeometry().getCoordinates(),
            resolution
          ).reduce((styles, coordinates, i) => {
            const point = new ol.geom.Point(coordinates);
            const line = new ol.geom.LineString([centerCoordinates, coordinates]);
            styles.unshift(
              new ol.style.Style({
                geometry: line,
                stroke: convexHullStroke,
              })
            );

            styles.push(
              clusterMemberStyle(
                new ol.Feature({
                  ...clusterMembers[i].getProperties(),
                  geometry: point,
                })
              )
            );
            return styles;
          }, []);
        }

        /**
        * From
        * https://github.com/Leaflet/Leaflet.markercluster/blob/31360f2/src/MarkerCluster.Spiderfier.js#L55-L72
        * Arranges points in a circle around the cluster center, with a line pointing from the center to
        * each point.
        * @param {number} count Number of cluster members.
        * @param {Array<number>} clusterCenter Center coordinate of the cluster.
        * @param {number} resolution Current view resolution.
        * @return {Array<Array<number>>} An array of coordinates representing the cluster members.
        */
        const generatePointsCircle = (count, clusterCenter, resolution) => {
          const circumference =
            circleDistanceMultiplier * circleFootSeparation * (2 + count);
          let legLength = circumference / (Math.PI * 2); //radius from circumference
          const angleStep = (Math.PI * 2) / count;
          const res = [];
          let angle;

          legLength = Math.max(legLength, 35) * resolution; // Minimum distance to get outside the cluster icon.

          for (let i = 0; i < count; ++i) {
            // Clockwise, like spiral.
            angle = circleStartAngle + i * angleStep;
            res.push([
              clusterCenter[0] + legLength * Math.cos(angle),
              clusterCenter[1] + legLength * Math.sin(angle),
            ]);
          }
          return res;
        }

        const monotoneChainConvexHull = (points) => {
          // Ensure at least 3 points to form a convex hull
          if (points.length < 3) {
            return points;
          }

          // Sort points lexicographically (first by x, then by y)
          points.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

          // Build lower hull
          const lowerHull = [];
          for (const point of points) {
            while (lowerHull.length >= 2 &&
                  orientation(lowerHull[lowerHull.length - 2], lowerHull[lowerHull.length - 1], point) !== 2) {
              lowerHull.pop();
            }
            lowerHull.push(point);
          }

          // Build upper hull
          const upperHull = [];
          for (let i = points.length - 1; i >= 0; i--) {
            const point = points[i];
            while (upperHull.length >= 2 &&
                  orientation(upperHull[upperHull.length - 2], upperHull[upperHull.length - 1], point) !== 2) {
              upperHull.pop();
            }
            upperHull.push(point);
          }

          // Concatenate lower and upper hulls to form the convex hull
          return lowerHull.slice(0, lowerHull.length - 1).concat(upperHull.slice(0, upperHull.length - 1));
        }

        // Helper function to determine the orientation of three points
        const orientation = (p, q, r) => {
          const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);
          if (val === 0) {
            return 0; // Collinear
          }
          return val > 0 ? 1 : 2; // Clockwise or counterclockwise
        }

        let hoverFeature;
        /**
        * Style for convex hulls of clusters, activated on hover.
        * @param {Feature} cluster The cluster feature.
        * @return {Style|null} Polygon style for the convex hull of the cluster.
        */
        const clusterHullStyle = (cluster) => {
          if (cluster !== hoverFeature) {
            return null;
          }
          const originalFeatures = cluster.get('features');
          const points = originalFeatures.map((feature) =>
            feature.getGeometry().getCoordinates()
          );
          return new ol.style.Style({
            geometry: new ol.geom.Polygon([monotoneChainConvexHull(points)]),
            fill: convexHullFill,
            stroke: convexHullStroke,
          });
        }

        const clusterStyle = (feature) => {
          const size = feature.get('features').length;
          if (size > 1) {
            return [
              new ol.style.Style({
                image: outerCircle,
              }),
              new ol.style.Style({
                image: innerCircle,
                text: new ol.style.Text({
                  text: size.toString(),
                  fill: textFill,
                  stroke: textStroke,
                }),
              }),
            ];
          }
          const originalFeature = feature.get('features')[0];
          return clusterMemberStyle(originalFeature);
        }

        const vectorSource = new ol.source.Vector({
          features: new ol.format.GeoJSON().readFeatures(this.geoMembersJsonData, {
            featureProjection: 'EPSG:3857', // Adjust the projection as needed
          }),
        });

        const clusterSource = new ol.source.Cluster({
          attributions:
            'Data: <a href="https://www.data.gv.at/auftritte/?organisation=stadt-wien">Stadt Wien</a>',
          distance: 35,
          source: vectorSource,
        });

        // Layer displaying the convex hull of the hovered cluster.
        const clusterHulls = new ol.layer.Vector({
          source: clusterSource,
          style: clusterHullStyle,
        });

        // Layer displaying the clusters and individual features.
        const clusters = new ol.layer.Vector({
          source: clusterSource,
          style: clusterStyle,
        });

        // Layer displaying the expanded view of overlapping cluster members.
        const clusterCircles = new ol.layer.Vector({
          source: clusterSource,
          style: clusterCircleStyle,
        });

        const simpleRaster = new ol.layer.Tile({
          source: new ol.source.OSM(),
        });

        const map = new ol.Map({
          layers: [simpleRaster, kmlVectorLayer, clusterHulls, clusters, clusterCircles],
          target: `${this.id}_${this.legislator.id}`,
          view: new ol.View({
            center: ol.extent.getCenter(vectorSource.getExtent()),
            zoom: 7,
            maxZoom: 50,
            showFullExtent: true,
          }),
          controls: [
            new ol.control.Zoom(),
            new ol.control.ZoomSlider(),
            new ol.control.ZoomToExtent(),
            new ol.control.FullScreen(),
            new ol.control.Rotate(),
            new ol.control.ScaleLine(),
            // new ol.control.MousePosition(),
          ],
        });

        map.on('pointermove', (event) => {
          clusters.getFeatures(event.pixel).then((features) => {
            if (features[0] !== hoverFeature) {
              // Display the convex hull on hover.
              hoverFeature = features[0];
              clusterHulls.setStyle(clusterHullStyle);
              // Change the cursor style to indicate that the cluster is clickable.
              map.getTargetElement().style.cursor =
                hoverFeature && hoverFeature.get('features').length > 1
                  ? 'pointer'
                  : '';
            }
          });

          // Showing cursor pointer when hover avatar icon
          const hit = map.forEachFeatureAtPixel(event.pixel, function(feature, layer) { return true; });   
          map.getTargetElement().style.cursor = hit? 'pointer' : '';
        
        });

        map.on('click', (event) => {
          clusters.getFeatures(event.pixel).then((features) => {
            if (features.length > 0) {
              const clusterMembers = features[0].get('features');
              if (clusterMembers.length > 1) {
                // Calculate the extent of the cluster members.
                const extent = ol.extent.createEmpty();
                clusterMembers.forEach((feature) =>
                  ol.extent.extend(extent, feature.getGeometry().getExtent())
                );
                const view = map.getView();
                const resolution = map.getView().getResolution();
                if (
                  view.getZoom() === view.getMaxZoom() ||
                  (ol.extent.getWidth(extent) < resolution &&
                    ol.extent.getHeight(extent) < resolution)
                ) {
                  // Show an expanded view of the cluster members.
                  clickFeature = features[0];
                  clickResolution = resolution;
                  clusterCircles.setStyle(clusterCircleStyle);
                } else {
                  // Zoom to the extent of the cluster members.
                  view.fit(extent, { duration: 500, padding: [50, 50, 50, 50] });
                }
              } else {

                // Get the feature at the clicked on the advocate avatar
                const feature = map.forEachFeatureAtPixel(event.pixel, function (feature) { return feature; });

                if(feature){
                  // Assuming 'feature' is your OpenLayers feature object
                  const firstFeature = feature.get('features')[0];
                  if (firstFeature) {
                    const advocateId = firstFeature.get('advocate_id');
                    this.toggle(advocateId);
                  }
                }
              }
            }
          });
        });

      } catch (error) {
        console.log('.....error:', error); 
      }

    }

  }
}
</script>

<style>
  .ol-attribution {
    display: none !important;
  }
</style>
