<template>
    <!-- Start active clients popup  -->
    <div class="text-center">
            <v-dialog
              v-model="isOpenDialog"
              width="600"
            >
              <v-card>
                <v-card-text class="pt-5">
                  <div class=""
                    :class="isDarkTheme ? 'card-dark' : 'card-light'"
                  >
                    <p class="d-flex justify-center title pt-2">Please, Select any one!</p>
                    <v-layout row class="ma-auto" >
                      <v-flex 
                        class="register-page_person mr-0"
                        col-sm-4 col-md-4 col-12
                        v-for="(activeClient, index) in clients" :key="activeClient.id" 
                        >
                        <v-btn @click="onSelectActiveClient(activeClient)" class="client-card-button" >
                          <div class="text-wrap" style="width: min-content;">{{ activeClient && activeClient.assoc_abbrev }}</div>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="onCloseClickHandler"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
      <!-- End active clients popup  -->
  </template>
  
  <script>
    export default {
      data() {
        return {
          isOpenDialog: false,
          clients: [],
          selectedActiveClient: null,
        }
      },
      methods:{
        onCloseClickHandler(){
          this.isOpenDialog = false;
          this.$emit('onCloseClick');
        },
        async toggle(clients, isModalOpen = true) {
            this.isOpenDialog = isModalOpen;
            this.clients = clients;
        },
        onSelectActiveClient(client) {
           this.$emit('onSelectClient', client);
           this.isOpenDialog = false;
        },
      }
    }
  </script>
  