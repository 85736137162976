<template>
  <v-navigation-drawer
    :expand-on-hover="!isPinnedSidebar"
    app
    fixed
    touchless
    width="250"
    class="app-sidebar"
    v-model="isModalOpen"
    mini-variant-width="100"
    :mini-variant.sync="isMini"
    @update:mini-variant="setListeners"
    :clipped="$vuetify.breakpoint.mdAndUp"
    :key="isPinnedSidebar"
  >
    <perfect-scrollbar class="pr-4">
    <div
        class="client-avatar-container mt-2 d-flex justify-center"
        :class="isSuperAdmin ? 'pt-8' : ''"
    >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
      <v-btn icon width="auto" height="auto"  class="pa-1"  @click="goTo('home-page')">
      <v-avatar v-on="on" v-if="client" size="70" style="border: 2px solid #3d4977;">
        <img :src="client.avatar_url || '/images/client-avatar.png'" />
      </v-avatar>
      </v-btn>
    </template>
      <span>Home</span>
    </v-tooltip>

      <div class="toggle-sidebar-btn">
      <v-tooltip v-if="isPinnedSidebar && !isMobile" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-2"
            v-on="on"
            small
            icon
            @click="toggleSidebar(false)"
          >
            <v-icon icon>mdi-adjust</v-icon>
          </v-btn>
        </template>
        <span>Hide Sidebar</span>
      </v-tooltip>
      <v-tooltip v-else-if="!isPinnedSidebar && !isMobile" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-2 toggle-sidebar"
            v-on="on"
            small
            icon
            @click="toggleSidebar(true)"
          >
            <v-icon icon color="secondary">mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Pin Sidebar</span>
      </v-tooltip>
      </div>
    </div>
    <div class="pb-3 d-flex flex-column align-center" v-if="client && client.tariff && client.tariff.name">
      <v-tooltip bottom max-width="400">
        <template v-slot:activator="{ on }">
          <v-chip
            class="mt-2 px-1"
            text-color="primary"
            color="primary"
            outlined
            x-small
            pill
            v-on="on"
          >
            {{ client.tariff.name }} {{isPinnedSidebar ? 'Plan' : ''}}
          </v-chip>
        </template>
        <span>You are currently enrolled in the {{client.tariff.name}} Plan.<br><i>Please contact Sales if you would like to change your plan.</i></span>
      </v-tooltip>

      <!--
      <h2>{{ userClient.assoc_abbrev }}</h2>
      -->
    </div>
      <v-divider v-if="!isSuperAdmin" class="py-1"></v-divider>
    <!-- Top menu -->
    <v-list dense nav shaped class="pa-0">
      <template v-for="(menuItem, index) in menuItems">
        <!-- Items without children -->
        <v-list-item
          v-if="menuItem.show && !menuItem.items && (menuItem.link !== 'messages-page') && menuItem.link !== 'status-page' && (menuItem.link !== 'advocacy-page')"
          :key="index"
          :ripple="false"
          color="primary"
          :class="{ 'active': menuItem.link === $route.name }"
          class="py-2"
          @click="onClickMainMenuItem($event, menuItem)"
        >
          <v-list-item-icon :class="{'sidebar-icon': !isMini}">
            <v-icon size="24" v-text="menuItem.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            <div v-if="menuItem.link === 'articles-page'">
              <span v-text="menuItem.title"></span>
              <v-badge
                v-if="latestArticle && getBadge(latestArticle) == 'New'"
                inline
                color="success"
                class="mt-0"
                style="flex:unset;"
              >
                <template v-slot:badge>
                  <span>New</span>
                </template>
              </v-badge>
              <v-badge
                v-if="latestArticle && getBadge(latestArticle) == 'Updated'"
                inline
                color="primary"
                class="mt-0"
                style="flex:unset;"
              >
                <template v-slot:badge>
                  <span>Updated</span>
                </template>
              </v-badge>
            </div>

              <span v-else-if="menuItem.link != 'messages-page' || menuItem.link != 'articles-page'" v-text="menuItem.title"></span>
            </v-list-item-title>
          </v-list-item>

        <!-- setup-page -->
        <v-list-item
          v-if="menuItem.show && !menuItem.items && (menuItem.link === 'status-page') && isSetupErrorsCount > 0"
          :key="index"
          :ripple="false"
          color="primary"
          :class="{ 'active': menuItem.link === $route.name }"
          class="py-2"
          @click="$refs.setupModal.open()"
        >
          <v-list-item-icon class="d-flex" :class="{'sidebar-icon': !isMini}">
            <v-icon size="24" v-text="menuItem.icon"></v-icon>
            <v-badge v-if="isMini" color="red" class="mt-0" v-model="isSetupErrorsCount">
              <template v-slot:badge>
                <span>{{ $store.state.app.setupErrorsCount > 9 ? '9+' : $store.state.app.setupErrorsCount }}</span>
              </template>
            </v-badge>
          </v-list-item-icon>

          <v-list-item-title>
            <v-badge v-if="!isMini" color="red" class="mt-0" v-model="isSetupErrorsCount">
              <template v-slot:badge>
                <span>{{ $store.state.app.setupErrorsCount > 9 ? '9+' : $store.state.app.setupErrorsCount }}</span>
              </template>
              <span v-text="menuItem.title"></span>
            </v-badge>
            <span v-else>{{menuItem.title}}</span>
          </v-list-item-title>
        </v-list-item>

        <!-- messages-page -->
        <v-list-item
          v-if="menuItem.show && !menuItem.items && (menuItem.link === 'messages-page') && (isSuperAdmin || client && client.tariff && client.tariff.messages)"
          :key="index"
          :ripple="false"
          color="primary"
          :class="{ 'active': menuItem.link === $route.name }"
          class="py-2"
          @click="onClickMainMenuItem($event, menuItem)"
        >
          <v-list-item-icon class="d-flex" :class="{'sidebar-icon': !isMini}">
            <v-icon size="24" v-text="menuItem.icon"></v-icon>
            <v-badge v-if="isMini" color="red" class="mt-0" v-model="isUnseenMessages">
              <template v-slot:badge>
                <span>{{ $store.state.app.unseenMessageCount > 9 ? '9+' : $store.state.app.unseenMessageCount }}</span>
              </template>
            </v-badge>
          </v-list-item-icon>

          <v-list-item-title>
            <v-badge v-if="!isMini" color="red" class="mt-0" v-model="isUnseenMessages">
              <template v-slot:badge>
                <span>{{ $store.state.app.unseenMessageCount > 9 ? '9+' : $store.state.app.unseenMessageCount }}</span>
              </template>
              <span v-text="menuItem.title"></span>
            </v-badge>
            <span v-else>{{menuItem.title}}</span>
          </v-list-item-title>
        </v-list-item>

        <v-tooltip bottom v-if="menuItem.show && !menuItem.items && (menuItem.link === 'messages-page') && !(!isSuperAdmin && client && client.tariff &&  client.tariff.messages)">
          <template v-slot:activator="{ on }">
            <v-list-item
              :key="index"
              :ripple="false"
              color="secondary"
              :class="{ 'active': menuItem.link === $route.name }"
              class="py-2"
              v-on="on"
            >
              <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                <v-icon class="disabled-color" size="24" v-text="menuItem.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                <span v-text="menuItem.title" class="disabled-color"></span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>Upgrade for this functionality</span>
        </v-tooltip>

        <!-- AM-page -->
        <v-list-item
          v-if="menuItem.show && !menuItem.items && (menuItem.link === 'advocacy-page') && (isSuperAdmin || client && client.tariff && client.tariff[`advocacy_messages`])"
          :key="index"
          :ripple="false"
          color="primary"
          :class="{ 'active': menuItem.link === $route.name }"
          class="py-2"
          @click="onClickMainMenuItem($event, menuItem)"
        >
          <v-list-item-icon :class="{'sidebar-icon': !isMini}">
            <v-icon size="24" v-text="menuItem.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-title>
              <span v-text="menuItem.title"></span>
          </v-list-item-title>
        </v-list-item>

        <v-tooltip
          bottom
          v-if="(menuItem.show && !menuItem.items && (menuItem.link === 'advocacy-page')) && (!isSuperAdmin && !(client && client.tariff &&  client.tariff.advocacy_messages))"
        >
          <template v-slot:activator="{ on }">
            <v-list-item
              :key="index"
              :ripple="false"
              color="secondary"
              :class="{ 'active': menuItem.link === $route.name }"
              class="py-2"
              v-on="on"
            >
              <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                <v-icon class="disabled-color" size="24" v-text="menuItem.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                <span v-text="menuItem.title" class="disabled-color"></span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>Upgrade for this functionality</span>
        </v-tooltip>


        <!-- Items with children -->
        <v-expansion-panels v-if="menuItem.show && menuItem.items" flat :key="index">
          <v-expansion-panel>
            <v-expansion-panel-header
              :ripple="false"
              :class="{ 'active': menuItem.link === $route.name || menuItem.items.some(linkItem => linkItem.link === $route.name) }"
              class="menu_item--has-child py-2 mb-1"
              :hide-actions="isMini"
            >
              <template>
                <v-list dense nav shaped class="pa-0">
                  <v-list-item>
                    <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                      <v-icon size="24" v-text="menuItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </template>
              <template slot="actions">
                <v-icon size="16" class="mr-2">expand_more</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="!isMini">
              <v-list dense nav shaped class="pa-0">
                <v-list-item
                  :ripple="false"
                  @click="onClickSubMenuItem(subMenuItem)"
                  v-for="subMenuItem in menuItem.items"
                  :key="subMenuItem.title"
                  v-if="subMenuItem.show"
                  :class="{'active': subMenuItem.link === $route.name }"
                >
                  <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                    <v-icon v-text="subMenuItem.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-list>
    <v-divider light class="mt-1 pb-1"></v-divider>

      <!-- Bottom menu -->
      <v-list dense nav shaped class="pa-0">
        <template v-for="(item, key) in bottomMenuItems">
          <!-- Items without children -->
          <v-list-item
              v-if="item.show && !item.items && item.title !== 'Notifications'"
              :key="key"
              :ripple="false"
              :class="{ 'active': item.link === $route.name }"
              class="py-2"
              @click="onClickMainMenuItem($event, item)"
          >
            <v-list-item-icon :class="{'sidebar-icon': !isMini}">
              <v-icon size="24" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item>

          <!-- Items with children -->
          <v-expansion-panels v-if="item.show && item.items" :key="key" flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                :ripple="false"
                :class="{'active': item.link === $route.name || item.items.some(linkItem => linkItem.link === $route.name) }"
                class="menu_item--has-child py-2 mb-1"
                :hide-actions="isMini"
              >
                <template>
                  <v-list dense nav shaped class="pa-0">
                    <v-list-item>
                      <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                        <v-icon size="24" v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ item.title }}
                        <span v-if="item.title === 'Settings' && hasActiveSession()">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                              class="ma-2"
                              color="error"
                              v-on="on"
                              outlined
                              >
                              info
                              </v-icon>
                            </template>
                            <span>No active Session!</span>
                          </v-tooltip>
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </template>
                <template slot="actions">
                  <v-icon size="16" class="mr-2">expand_more</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="!isMini">
                <v-list class="pa-0" dense nav shaped>
                  <v-list-item
                      :ripple="false"
                      @click="onClickSubMenuItem(subMenuItem)"
                      v-for="subMenuItem in item.items"
                      :key="subMenuItem.title"
                      v-if="subMenuItem.show"
                      :class="{'active': subMenuItem.link === $route.name }"
                  >
                    <v-list-item-icon :class="{'sidebar-icon': !isMini}">
                      <v-icon v-text="subMenuItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ subMenuItem.title }}
                      <span v-if="subMenuItem.title === 'Sessions/Tasks' && hasActiveSession()">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                              class="ma-2"
                              color="error"
                              v-on="on"
                              outlined
                              >
                              info
                              </v-icon>
                            </template>
                            <span>No active Session!</span>
                          </v-tooltip>
                        </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-list>
    </perfect-scrollbar>
    <setup-modal ref="setupModal"></setup-modal>
  </v-navigation-drawer>
</template>

<script>
import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import ResourcesService from '@/services/ResourcesService'
import {mapGetters} from "vuex";
import logo from '@/components/common/app-logo'
import vuetify from '@/plugins/vuetify'
import sidebar from "../../utils/storage/sidebar";
import Echo from "laravel-echo";
import token from "@/utils/storage/token";
import SetupModal from "@/components/global/setup-modal"

export default {
  mixins: [ClientService, MemberService, ResourcesService, vuetify, sidebar],
  data() {
    return {
      unseenMessagesTimerId: null,
      notificationsTimerId: null,
      isCompleted: false,
      articlesList: [],
      isSidebarExpanded: false,
      noHovered: true,
      isPinnedSidebar: true,
      mini: false,
      $echo: {},
    }
  },
  components: {
    'app-logo': logo,
    'setup-modal': SetupModal,
  },
  created() {
    const socketUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local:6001';
    this.$echo = new Echo({
      broadcaster: "socket.io",
      host: socketUrl,
      auth: {
        headers: {
          Authorization: 'Bearer ' + token.get(),
        },
      },
    })
  },
  computed: {
    ...mapGetters('notifications', ['notifications']),
    ...mapGetters('app', ['sidebarState']),
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isModalOpen: {
      get() {
        const localSidebar = sidebar.get()
        if(localSidebar && !this.isMobile) {
          return localSidebar
        }
        return this.sidebarState
      },
      set(newValue) {
        this.$store.dispatch('app/setMenuSidebarOpen', newValue)
      }
    },
    isUnseenMessages() {
      return this.$store.state.app.unseenMessageCount > 0
    },
    isSetupErrorsCount() {
      return this.$store.state.app.setupErrorsCount > 0
    },
    isClientUseRegions() {
      return this.client && this.client.uses_regions
    },
    menuItems() {
      return [
        {
          icon: 'fas fa-list-ol',
          title: 'Setup',
          link: 'status-page',
          show: !this.isCompleted && this.isVIP,
        },
        {
          icon: 'dashboard',
          title: 'Command Center',
          show: this.isSuperAdmin,
          items: [
            {
              icon: 'dashboard',
              title: 'General',
              link: 'general-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fas fa-user',
              title: 'Helpers',
              link: 'helpers-page',
              show: this.isSuperAdmin
            },
          ]
        },/* moved home link to client avatar
        {
          icon: 'fa-light fa-house',
          title: 'Home',
          link: 'home-page',
          show: this.isSuperAdmin || this.isVIP,
        },*/
        {
          icon: 'fa-light fa-list-check',
          title: 'Dashboard',
          link: 'kp-tasks-page',
          show: this.isSuperAdmin || this.isVIP,
        },
        {
          icon: 'fa-light fa-users',
          title: 'Advocates',
          //title: this.isSuperAdmin ? 'Advocates' : `${this.userClient ? this.userClient.assoc_abbrev : ''} Advocates`,
          //link: 'members-page',
          show: this.isSuperAdmin || this.isVIP,
          items: [
            {
              icon: 'fa-light fa-users',
              title: this.isSuperAdmin ? 'Advocates' : `${this.client ? this.client.assoc_abbrev : ''} Advocates`,
              link: 'members-page',
              show: this.isSuperAdmin || this.isVIP,
            },
            {
              icon: 'fa-light fa-medal',
              title: 'KP Leaderboard',
              link: 'kp-leaderboard-page',
              show: this.isSuperAdmin || this.isVIP
            },
            {
              icon: 'fa-light fa-user-edit',
              title: 'Profile Updates',
              link: 'profile-updates-page',
              show: this.isSuperAdmin || this.isVIP
            },
          ]
        },
          {
            icon: 'fa-light fa-user-tie',
            title: 'Legislators',
            //link: 'legislators-page',
            show: this.isSuperAdmin || this.isVIP || this.isLegSupport,
            items: [
                {
                icon: 'fa-light fa-user-tie',
                title: 'Legislator Page',
                link: 'legislators-page',
                show: this.isSuperAdmin || this.isVIP || this.isLegSupport || this.isCoordinator,
                },
              {
                icon: 'fa-light fa-magnifying-glass-location',
                title: 'District Lookup',
                link: 'district-lookup-page',
                show: this.isSuperAdmin || this.isVIP
              },
            {
              icon: 'fa-light fa-message-arrow-up-right',
              title: 'Advocacy Messaging',
              link: 'advocacy-page',
              show: this.isSuperAdmin || (this.isVIP && (this.client && this.client.tariff && this.client.tariff.advocacy_messages)),
            },
          ]
        },
        {
          icon: 'fa-light fa-messages',
          title: 'Messages',
          link: this.isSuperAdmin ? 'scheduled-messages-page' : 'messages-page',
          show: this.isVIP || this.isCoordinator || this.isSuperAdmin,
        },
        {
          icon: 'fa-light fa-folder-open',
          title: 'Resources',
          link: 'articles-page',
          show: this.isSuperAdmin || ((this.isCoordinator || this.isKP || this.isVIP) && this.client && this.client.resources_count)
        },
      ]
    },
    bottomMenuItems() {
      return [
        {
          icon: 'fa-light fa-book',
          title: 'Documentation',
          show: this.isSuperAdmin || this.isVIP,
        },
        {
          icon: 'fa-light fa-square-question',
          title: 'KP Help Page',
          link: 'kp-help-page',
          show: this.isKP,
        },
        {
          icon: 'fa-light fa-life-ring',
          title: 'Support',
          show: this.isVIP || this.isCoordinator,
        },
        {
          icon: 'fa-light fa-message-arrow-up-right',
          title: 'Advocacy Messages',
          link: 'assigned-tasks-page',
          show: this.isKP && this.hasTasks(),
        },
        {
          icon: 'fa-light fa-list-check',
          title: 'My Tasks',
          link: 'my-tasks-page',
          show: this.isKP,
        },
        {
          icon: 'fa-light fa-folder-gear',
          title: 'Admin',
          show: this.isSuperAdmin,
          items: [
            {
              icon: 'fa-light fa-id-card',
              title: 'Clients',
              link: 'clients-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-messages',
              title: 'Messages',
              link: 'admin-messages-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-screen-users',
              title: 'Committees',
              link: 'committees-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-person-booth',
              title: 'Political Leanings',
              link: 'political-leanings-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-chess-clock',
              title: 'User Sessions',
              link: 'user-sessions-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-magnifying-glass-plus',
              title: 'Find Legislators',
              link: 'find-legislators-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-magnifying-glass-plus',
              title: 'Find Fed Legislators',
              link: 'find-federal-legislators-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-grid-dividers',
              title: 'Districts',
              link: 'districts-page',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-rss',
              title: 'API Sources',
              link: 'api-sources',
              show: this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-ticket',
              title: 'Level Admin',
              link: 'level-admin-page',
              show: this.isSuperAdmin
            },
          ],
        },
        {
          icon: 'fa-light fa-gear',
          title: 'Settings',
          show: this.isSuperAdmin || this.isVIP,
          items: [
            {
              icon: 'fa-light fa-user-shield',
              title: this.isSuperAdmin ? 'Team' : `${this.client ? this.client.assoc_abbrev : ''} Team`,
              link: 'users-page',
              show: this.isSuperAdmin || this.isVIP
            },
            {
              icon: 'fa-light fa-list-check',
              title: 'Sessions/Tasks',
              link: 'sessions-page',
              show: this.isSuperAdmin || this.isVIP
            },
            {
              icon: 'fa-light fa-memo-pad',
              title: 'System Actions',
              link: 'system-actions-page',
              show: this.isSuperAdmin || this.isCoordinator || this.isVIP
            },
            {
              icon: 'fa-light fa-ellipsis-stroke',
              title: 'Relationships',
              link: 'relationships-page',
              show: this.isSuperAdmin || this.isVIP || this.isCoordinator
            },
            {
              icon: 'fa-light fa-users-gear',
              title: 'Advocate Fields',
              link: 'custom-fields-page',
              show: this.isSuperAdmin || this.isVIP || this.isCoordinator
            },
            {
              icon: 'fa-light fa-message-lines',
              title: 'Custom Messages',
              link: 'custom-messages-page',
              show: this.isSuperAdmin || this.isVIP
            },
            {
              icon: 'fa-light fa-grid-dividers',
              title: 'Regions/Districts',
              link: 'regions-page',
              show: this.isSuperAdmin && !this.client || this.isSuperAdmin && this.isClientUseRegions || this.isVIP && this.isClientUseRegions || this.isCoordinator && this.isClientUseRegions
            },
            {
              icon: 'fa-light fa-screen-users',
              title: 'Committees',
              link: 'client-committees-page',
              show: this.isVIP || this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-folder-open',
              title: 'Resources',
              link: 'resources-page',
              show: this.isVIP || this.isSuperAdmin
            },
            {
              icon: 'fa-light fa-sliders',
              title: 'Options',
              link: 'client-options-page',
              show: this.isSuperAdmin || this.isVIP
            },
          ],
        },
      ]
    },
    isMini: {
      get() {
        return !this.isSidebarExpanded && !this.isPinnedSidebar && this.noHovered
      },
      set(newValue) {
        this.mini = newValue
      }
    }
  },
  beforeMount () {
    this.isMobile && this.$store.dispatch('app/setMenuSidebarOpen', false)
  },
  mounted () {
    this.setListeners()
    if(sidebar.get() === null) {
      this.isPinnedSidebar = true
    } else {
      this.isPinnedSidebar = sidebar.get()
    }
    if (!this.isSuperAdmin) {
      this.getArticles()
    }
    if (!this.isSuperAdmin && !this.isKP && this.client && this.client.id) {
      this.getStatus()
    }

    if (this.isVIP || this.isCoordinator) {
      this.getUnseenMessageCount()
      this.getSetupErrors()
      this.$echo.private(`unseenMessageCount`)
        .listen('UnseenMessageCountEvent', (event) => {
          this.getUnseenMessageCount()
        });
    }

  },
  beforeDestroy() {
    this.unseenMessagesTimerId = null
    this.removeListeners()
  },
  methods: {
    toggleNotifications() {
      this.$modals.get('notifications').openAndLoad()
    },
    goTo (route) {
      this.$router.push({ name: route }).catch(err => {})
    },
    async getUnseenMessageCount () {
      if (this.client && this.client.id) {
        const messageCount = (await this.getClientUnseenMessageCount(this.client.id)).data
        this.$store.dispatch('app/setUnseenMessageCount', messageCount)
      }
    },
    async getSetupErrors() {
      if (this.client.id) {
        const status = (await this.getClientStatus(this.client.id)).data

        let countErrors = 0
        for (let item in status) {
          if(item == 'settings' ) {
            for (let setting in status[item]) {
              if(!status[item][setting]) countErrors++
            }
          } else {
            if(!status[item]) countErrors++
          }
        }
        this.$store.dispatch('app/setSetupErrorsCount', countErrors)
      }
    },
    onClickMainMenuItem (event, mainItem) {
      if (mainItem.title === 'Support') {
        if (this.$intercom) {
          this.$intercom.show()
        }
      }
      if (mainItem.title === 'Documentation') {
        window.open('https://help.kpdashboard.com/en/','_blank')
      }
      if (mainItem.title === 'Messages') {
        this.$router.push({ name: mainItem.link }).catch(err => {})
        return this.$store.dispatch('app/setMenuSidebarOpen', true)
      }

      this.$router.push({ name: mainItem.link }).catch(err => {})
    },
    onClickSubMenuItem (subItem) {
      if(subItem.title === 'Logout') {
        this.onLogout()
      }
      if(subItem.title === 'Profile') {
        return this.$store.dispatch('app/setProfileSidebarOpen', true)
      }

      this.$router.push({ name: subItem.link }).catch(err => {})
    },
    async hasTasks () {
      let result = (await this.getMemberAdvocacyMessages(this.userId)).data
      return result && result.length
    },
    getStatus() {
      return this.getClientStatus(this.client.id).then(response => {
        this.$store.dispatch('app/setClientStatus', response.data)
        let check = true
        _.forEach(response.data, (value) => {
          check &= value
        })
        this.isCompleted = check
      })
    },
    toggleSidebar(bool) {
      sidebar.set(bool)
      this.isPinnedSidebar = sidebar.get()

      if (!this.isPinnedSidebar) {
        this.isSidebarExpanded = false

        this.$nextTick(() => {
          this.setListeners()
        })
      }
    },
    mouseLeaveHandler() {
      this.noHovered = true
      this.isSidebarExpanded = false
    },
    mouseEnterHandler() {
      this.isSidebarExpanded = true
      this.noHovered = false
    },
    setListeners() {
      const el = document.querySelector(".app-sidebar");
      if(el) {
        el.addEventListener("mouseenter", this.mouseEnterHandler, true);
        el.addEventListener("mouseleave", this.mouseLeaveHandler, false);
      }
    },
    removeListeners() {
      const el = document.querySelector(".app-sidebar");
      if(el) {
        el.removeEventListener("mouseenter", this.mouseEnterHandler, true);
        el.removeEventListener("mouseleave", this.mouseLeaveHandler, false);
      }
    },
    hasActiveSession() {
      return this.client &&  !(this.client.active_session && this.client.active_session.id)
    },
  },
  watch: {
    '$intercom.visible'(value) {
      let launcherEl = document.querySelector('.intercom-launcher-frame') ? document.querySelector('.intercom-launcher-frame') : false
      if (value && launcherEl) {
        launcherEl.classList.add('visible')
        launcherEl.classList.remove('intercom-hide')
      }
      if (!value && launcherEl) {
        launcherEl.classList.remove('visible')
        launcherEl.classList.add('intercom-hide')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-navigation-drawer--is-mouseover {
  .toggle-sidebar {
    display: block;
  }
  .sidebar-icon {
    padding-left: 20px;

    i {
      min-width: 30px;
    }
  }
}
.v-navigation-drawer--clipped {
  .sidebar-icon {
    i {
      min-width: 30px;
    }
  }
}

.toggle-sidebar {
  display: none;
}
.ps__rail-y {
  z-index: 1;
}

.toggle-sidebar-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.client-avatar-container {
  position: relative;
}
.sidebar-icon {
  padding-left: 20px;
}

</style>

