import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","max-height":500},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"District","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":_vm.clearSelected},model:{value:(_vm.selectedDistrictNames),callback:function ($$v) {_vm.selectedDistrictNames=$$v},expression:"selectedDistrictNames"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VList,[_c(VTextField,{staticClass:"px-4",attrs:{"label":"Search Districts","prepend-icon":"mdi-magnify","dense":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_vm._l((_vm.filteredDistrictList),function(group,index){return _c(VListGroup,{key:index,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VCheckbox,{attrs:{"input-value":_vm.isGroupSelected(group.children),"label":`${group.group.toUpperCase()}`},on:{"change":function($event){return _vm.toggleGroup(group.children)}}})]},proxy:true}],null,true),model:{value:(group.open),callback:function ($$v) {_vm.$set(group, "open", $$v)},expression:"group.open"}},_vm._l((group.children),function(item,childIndex){return _c(VListItem,{key:childIndex},[_c(VCheckbox,{attrs:{"label":item.district,"input-value":_vm.selectedDistrict.includes(item)},on:{"change":function($event){return _vm.toggleItem(item)}}})],1)}),1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }