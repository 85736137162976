<template>
  <div class="login-form-container">
    <div class="register-page_form my-auto elevation-24" :class="isDarkTheme ? 'card-dark' : 'card-light'">
      <auth-logo></auth-logo>
        <v-alert color="error" :value="true" class="mb-3 mt-5" outlined type="error" v-if="error && !errors">
          {{ error }}
        </v-alert>
        <div class="d-flex align-center justify-center register-page_form__inner mt-8">
          <transition :name="position">
            <div
              v-if="isKeyPerson === 0"
              class="register-page_form__inner_major"
              :class="isDarkTheme ? 'card-dark' : 'card-light'"
            >
              <p class="d-flex justify-center title my-4">Select your login type</p>
              <v-layout row class="ma-auto">
                <v-flex class="register-page_person mr-0" col-sm-6 col-md-6 col-12>
                <v-btn @click="toKeyPerson">
                  <div class="text-wrap" style="width: min-content;">I'm a Key Person</div>
                </v-btn>
              </v-flex>
              <v-flex class="register-page_person ml-0" col-sm-6 col-md-6 col-12>
                <v-btn @click="toAdmin">
                <div class="text-wrap" style="width: min-content;">I'm an Admin or Coordinator</div>
                </v-btn>
              </v-flex>
              </v-layout>
            </div>
          </transition>

          <transition :name="'left'">
            <div
              v-if="isKeyPerson === 1"
              class="register-page_form__inner_admin"
              :class="isDarkTheme ? 'card-dark' : 'card-light'"
            >
              <div class="d-flex align-center justify-space-between my-4">
                <v-btn
  small
  outlined
  color="primary"
  @click="backToMenu"
  :class="isMobile ? 'mr-1' : ''"
  class="back-btn"
>
  <v-icon class="mr-1">mdi-chevron-left</v-icon>
  Back
</v-btn>
                <p class="card-title mb-0" :class="!isMobile ? 'mr-2' : ''">Admin/Coordinator Login</p>
              </div>
              <v-card-text class="pa-0">
                <v-form ref="loginForm" @submit.prevent="handleSubmit" v-if="!twoFactorCodeSent">
                  <v-text-field
                    label="Email Address*"
                    prepend-inner-icon="email"
                    type="email"
                    outlined
                    v-model="user.email"
                    :rules="emailRules"
                    @input="error = ''"
                  ></v-text-field>
                  <v-text-field
                    :rules="passwordRules"
                    @input="error = ''"
                    outlined
                    label="Password*"
                    prepend-inner-icon="lock"
                    type="password"
                    v-model="user.password"
                  >
                  </v-text-field>
                  <v-snackbar
                    v-model="showSnackbar"
                    :top="true"
                    :timeout="2000"
                  >
                    {{ error ? error : message }}
                  </v-snackbar>
                  <div class="text-right mb-4">
                    <router-link :to="{name: 'forgot-password-page'}">Forgot Password?</router-link>
                  </div>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn type="submit" block large color="primary" :loading="isBusy">Sign in</v-btn>
                  </div>
                </v-form>
                <v-form @submit.prevent="handleTwoFactorAuthorize" ref="twoFaForm"
                        v-if="twoFactorCodeSent && !isDeactivateTwoFactor">
                  <v-card flat color="rgba(0,0,0,0)">
                    <v-card-title class="pl-0  mb-0 justify-center">Two factor authorization</v-card-title>
                    <v-alert :value="true" class="mb-3" color="primary" outlined type="success" v-if="message">
                      {{ message }}
                    </v-alert>
                    <v-alert :value="true" class="mb-3" color="primary" outlined type="error" v-else-if="error">
                      {{ error }}
                    </v-alert>
                    <v-text-field
                      :rules="TwoFactorCode"
                      @input="error = ''"
                      background-color="rgba(255, 255, 255, 0.2)"
                      filled
                      color="white"
                      label="Verification code"
                      prepend-inner-icon="lock"
                      :error-messages="error?['Verification code is invalid']: []"
                      type="text"
                      v-model="user.two_factor_code"
                    ></v-text-field>
                    <div class="text-xs-left mb-5">
                      <a @click="sendDeactivateTwoFactorCode" class="lost-device justify-center d-flex"> Lost the device? Use an
                        email</a>
                    </div>
                    <br/>
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <v-btn :loading="isBusy" block large color="primary" type="submit">
                        {{ 'Verify' }}
                      </v-btn>
                    </div>
                  </v-card>
                </v-form>
              </v-card-text>
            </div>
          </transition>
          <transition :name="'right'">
            <div
              v-if="isKeyPerson === 2"
              class="register-page_form__inner_major"
              :class="isDarkTheme ? 'card-dark' : 'card-light'"
            >
              <div v-if="!isSentOtp" class="d-flex align-center justify-space-between my-4 pb-4">
                <div >
                  <v-tooltip bottom max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="primary"
                        v-on="on"
                        class="ml-3 mb-2"
                      >
                        fa-circle-question
                      </v-icon>
                    </template>
                    <span>Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
                      They can also send you a link directly.
                    </span>
                  </v-tooltip>
                </div>
                <v-btn
  small
  outlined
  color="primary"
  @click="backToMenu"
  :class="isMobile ? 'mr-1' : ''"
  class="back-btn"
>
Back
  <v-icon class="mr-1">mdi-chevron-right</v-icon>
</v-btn>
              </div>
              <!-- removed to make room on mobile
              <v-card-subtitle>
                Contact your Admin if you are unsure of the email or cell phone # we have on file for you.
                They can also send you a link directly.
              </v-card-subtitle>-->
              <v-card-text class="pa-0" v-if="!isSentOtp" >
              <v-form ref="loginForm" @submit.prevent="handleSubmit" v-if="!twoFactorCodeSent">
                  <v-text-field
                    label="Email or cell number"
                    outlined
                    :prepend-inner-icon="isPhone? `phone_iphone`:`email`"
                    v-model="smartInput"
                    clearable
                    :rules="smartInputRules"
                    @input="onSmartInput"
                    @focus="handleFocus"
                    :error-messages="smartInputErrorMessages('smartInput')"
                  ></v-text-field>
                  <v-tooltip bottom max-width="250px">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-checkbox
                        v-if="isPhone && smartInput.length > 0"
                        v-model="acceptCellOTPCheckbox"
                        color="primary" 
                        class="py-0"
                        disabled
                        >
                        <template v-slot:label>
                          <div class="text-caption">
                            I consent to receiving relevant text messages from KP Dashboard
                          </div>
                        </template>  
                      </v-checkbox>
                    </div>
                  </template>
                    <span>KP Dashboard only sends text messages initiatied by you or your Association and will never send spam or unwanted messages.</span>
                </v-tooltip>
                  <v-btn
                    type="submit"
                    :dark="!isValidInput"
                    :disabled="!isValidInput"
                    class="mt-2"
                    block
                    large
                    color="primary"
                    :loading="isBusy"
                  >
                    Submit
                  </v-btn>
                </v-form>
              </v-card-text>
            
              <!--Start Here OTP form after OTP sent  -->
              <div v-if="isSentOtp" class="otp-form-top">
                <template v-if="isPhone">
                  <h1 class="mb-3">Check your texts</h1>
                  <p class="otp-form-description">We have sent a verification code to {{ smartInput }}. Please enter the code below</p>
                </template>
                <template v-if="!isPhone">
                  <h1 class="mb-3">Check your email</h1>
                  <p class="otp-form-description">We have sent a verification code to {{ smartInput }}. Please enter the code below</p>
                </template>
              </div>
              <div v-if="isSentOtp" class="d-flex justify-center">
                <div class="otp-form">
                  <div class="text-left">
                    <small v-if="otpTimer > 0">
                      Verification Code (Wait for <strong>{{ otpTimer }}s</strong> to resend)
                    </small>
                    <small v-else>
                      Verification Code (
                        <a v-if="otpSending" href="javascript:void(0)">Sending..</a>
                        <a v-else="" @click="sendKPOTPCode(1)" href="javascript:void(0)">Resend</a>
                      )
                    </small>
                  </div>
                  <v-otp-input
                    v-model="otpCode"
                    length="6"
                    type="number"
                    class="py-0 otp-fields mb-3"
                  ></v-otp-input>
                  <v-btn
                    block
                    color="primary"
                    elevation="1"
                    class="otp-continue-btn mb-2"
                    :disabled="!isValidOTPForm()"
                    @click="sendEnterOTPCode"
                  >Continue</v-btn>
                  <p>
                    <a v-if="!isPhone" @click="onReturnToForm(1)" href="javascript:void(0)">Text</a>
                    <a v-if="isPhone" @click="onReturnToForm(0)" href="javascript:void(0)">Email</a>
                  me the code instead
                  </p>
                </div>
              </div>
              <!--End Here OTP form after OTP sent  -->
            </div>
          </transition>
          <!-- alert was here -->
        </div>
        <system-message-alert :list="systemMessages" class="mt-10"></system-message-alert>
    </div>
    <clients-select-modal
      ref="clientSelectModal"
      @onSelectClient="onSelectClient"
      @onCloseClick="onClientSelectModalCloseClickHandler"
     ></clients-select-modal>
  </div>
</template>

<script>
  import swal from 'sweetalert2'

  import AuthService from '@/services/AuthService'
  import UserService from "@/services/UserService"
  import ClientService from '@/services/ClientService'
  import SystemMessageService from '@/services/SystemMessageService'

  import defaultRoutes from '@/router/default-routes'

  import systemMessageAlert from '@/components/auth/system-message-alert'
  import logo from '../../components/common/auth-logo'
  import ClientsSelectModal from '../../components/auth/clients-select-modal.vue'

  export default {
    mixins: [AuthService, UserService, ClientService, SystemMessageService],
    components: {
      'system-message-alert': systemMessageAlert,
      'auth-logo': logo,
      'clients-select-modal': ClientsSelectModal,
    },
    data() {
      return {
        user: {},
        error: '',
        errors: null,
        isBusy: false,
        message: null,
        position: null,
        isKeyPerson: 0,
        cellPhone: null,
        fastEmail: null,
        showSnackbar: false,
        show2FAfield: false,
        twoFactorCodeSent: false,
        isDeactivateTwoFactor: false,
        redirectTo: null,
        userLoginData: {},
        defaultRoutes: defaultRoutes,
        systemMessages: [],
        //It's OTP system
        phoneRegex: /^(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/, // Matches phone number formats
        emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Matches email format
        smartInput: "",
        isPhone: false, // Tracks if the input is a phone number
        isValidInput: false, // Tracks overall validity
        inputTouched: false, // Tracks whether the user has interacted with the field
        otpCode: null,
        otpSending: false,
        isSentOtp: false,
        otpTimer: 60, // Initial countdown time
        otpInterval: null,
        activeClients: [],
        selectedActiveClient: null,
        acceptCellOTPCheckbox: true,
      }
    },
    computed: {
      emailRules() {
        return [
          v => !!v || 'Email is required.',
        ]
      },
      restoreEmailRules() {
        return [
          v => (!v || !!v) || 'Email is required.',
        ]
      },
      TwoFactorCode() {
        return [
          v => !!v || 'Verification code is required.',
        ]
      },
      passwordRules() {
        return [
          v => !!v || 'Password is required.',
        ]
      },
      smartInputRules() {
        return [
          (value) => !this.inputTouched || !!value || "This field is required.",
          (value) => {
            if (!this.inputTouched) return true; // Skip other validations if input is untouched
            if (this.phoneRegex.test(value) || this.emailRegex.test(value)) {
              return true;
            }
            return "Please enter a valid email or 10-digit cell number.";
          },
        ];
      },
    },
    mounted() {
      if(this.$route.query.redirectTo) this.redirectTo = this.$route.query.redirectTo
      let script = document.createElement('script')
      script.setAttribute('src', 'https://r8pt9rywm60j.statuspage.io/embed/script.js')
      script.setAttribute('id', 'embed_script')
      document.head.appendChild(script)
      this.loadSystemMessages()
    },
    beforeDestroy() {
      if (this.otpInterval) {
        clearInterval(this.otpInterval);
      }
      let script = document.querySelector('#embed_script')
      script.remove()
    },
    watch: {
      'otpTimer': (newValue, oldValue) => {
        if(newValue > 60 ) {
          clearInterval(this.otpInterval);
        }
      },
      'smartInput': function (newValue, oldValue) {
        if(newValue !== oldValue) {
          this.clearError();
        }
      },
      'otpCode': function (newValue, oldValue) {
        if(newValue !== oldValue) {
          this.clearError();

           // Auto-submit when OTP is fully entered
          if (newValue.length === 6) {
            this.sendEnterOTPCode();
          }
        }
      },
    },
    methods: {
      onClientSelectModalCloseClickHandler(){
        this.selectedActiveClient = null;
        this.isBusy = false;
      },
      onSmartInput(value) {
        this.inputTouched = true; // Mark input as touched when user types

        if (this.phoneRegex.test(value)) {
          this.isPhone = true;
          this.isValidInput = true;
        } else if (this.emailRegex.test(value)) {
          this.isPhone = false;
          this.isValidInput = true;
        } else {
          this.isValidInput = false; // Invalid input
        }
      },
      handleFocus() {
        this.inputTouched = true; // Mark input as touched when it gains focus
      },
      async handleBlur() {
        if(this.isValidInput && this.smartInput) {
          await this.getMemberActiveClient();
          this.handleSubmit();
        }
      },
      onSelectClient(selectedClient) {
        if(selectedClient) {
          this.selectedActiveClient = selectedClient;
          if(this.isKeyPerson === 1){
            this.onLogin();
          } else if(this.isKeyPerson === 2){
            this.sendKPOTPCode();
          }
        }
      },
      smartInputErrorMessages(field) {
        if (field === "smartInput" && this.inputTouched && !this.isValidInput) {
          return "Please enter a valid email or 10-digit cell number.";
        }
        return "";
      },
      
      isValidOTPForm(){
        return (this.otpCode && this.otpCode.length === 6 );
      },
      loadSystemMessages() {
        this.getSystemMessages({active: 1}).then(response => {
          this.systemMessages = response.data.data
        })
      },
      backToMenu() {
        this.position = 'right'
        setTimeout(()=> {
          this.isKeyPerson = 0
        }, 0)

      },
      backToMenuFromKeyPerson() {
        this.position = 'left'
        this.clearError();
        setTimeout(()=> {
          this.isKeyPerson = 0
        }, 0)
      },
      toAdmin() {
        this.position = 'right'
        this.clearError();
        setTimeout(()=> {
          this.isKeyPerson = 1
        }, 0)
      },
      toKeyPerson() {
        this.position = 'left'
        this.clearError();
        setTimeout(()=> {
          this.isKeyPerson = 2
        }, 0)
      },
      clearError() {
        this.errors = null
        this.error = ''
      },
      disabledField(field) {
        return field && field.length && field !== null
      },
      async getActiveClients(){
        this.isBusy = true
        try{
          const response = await this.getActiveClientsForLogin(this.user)
          console.log('response', response.data)
          this.activeClients = response.data;
          
          if(!!(this.activeClients && this.activeClients.length > 1)){
            this.$refs.clientSelectModal.toggle(this.activeClients);
          } else {
            this.selectedActiveClient = this.activeClients[0];
            this.onLogin();
          }
        }catch(err){
          if (err.response) {
            this.errors = err.response.data.errors
            this.handleError(err)
          } else {
            this.errors = err
          }
          this.isBusy = false
        }
      },
      onLogin() {
        if (!this.$refs.loginForm.validate()) return
        this.isBusy = true;
        this.login({
          ...this.user,
          client_id: this.selectedActiveClient ? this.selectedActiveClient.id : null
        })
          .then((res) => {
            this.isBusy = false
            this.$store.dispatch('auth/setUserData', res.data)
            this.$store.dispatch('auth/refreshSession')
            if (res.data.user.two_factor_verified) {
              this.user.cell_phone = res.data.user.cell_phone;
              this.userLoginData = res.data.user
              this.send2FACode();
            } else {
              this.setDefaultRoute()
            }
          })
          .catch((err) => {
            if (err.response) {
              this.errors = err.response.data.errors
              this.handleError(err)
            } else {
              this.errors = err
            }
            this.isBusy = false
          })
      },
      async setDefaultRoute() {
        let userRole = this.$store.state.auth.userData ? this.$store.state.auth.userData.role : false
        let serviceType = this.$store.state.auth.userData ? this.$store.state.auth.userData.serviceType : false

        if (userRole == 'KP') {
          let route = serviceType == 'adv' ? '/assigned-tasks' : '/my-tasks'
          return this.$router.push(route)
        }

        if (userRole != 'Super Admin' && userRole != 'Leg Support') {
          if (!this.userClientId) {
            this.logout()
            return;
          }
            const result = (await this.getClientStatus(this.userClientId)).data
            this.$store.dispatch('app/setClientStatus', result)
            let isCompleted = true
            _.forEach(result, (value) => {
              isCompleted &= value
            })


          if (userRole == 'VIP' && this.redirectTo){
              this.$router.push({name: this.redirectTo})
              return;
          }
        }

        this.$router.push(this.defaultRoutes[userRole])

      },
      async sendEnterOTPCode() {
        if (!this.isValidOTPForm()) return
        this.isBusy = true;

        let payload = {
          code: this.otpCode,
          sendTo: this.isPhone? 'phone':'email',
          type: 'login',
          client_id: this.selectedActiveClient && this.selectedActiveClient.id
        };

        if(this.isPhone) {
            payload.phone = this.getFormattedCellNumber(this.smartInput);
        } else {
          payload.email = this.smartInput;
        }

        try {
          const response = await this.sendVerifyOTPCode(payload);
          if (response.status === 200 && response.data.message !== undefined) {
            this.message = response.data.message
            this.showSnackbar = true;
            this.$router.push(`/${response.data.link}`);
          }

          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          const responseData = e && e.response && e.response.data ? e.response.data:'';
          this.error = responseData && responseData.message || '';
          this.errors =  responseData && responseData.errors || '';
        }
      },
      async sendKPOTPCode(isResend=0) {
        
        if(!isResend) {
          const phoneRegex = /^(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/; // Matches 10-digit US phone formats
          if ( this.isPhone && !phoneRegex.test(this.smartInput)) {
            this.error = "Please, enter valid 10-digit cell number.";
            return;
          }

          if (!this.isValidInput) return
          this.isBusy = true;
        };

        this.otpSending = true;
        this.otpCode = null;
        this.clearError();

        let formData = {
          type: 'login',
          client_id: this.selectedActiveClient && this.selectedActiveClient.id
        };

        if(this.isPhone) { 
          formData.phone = this.getFormattedCellNumber(this.smartInput);
        } else {
          formData.email = this.smartInput;
        }

        try {
          const response = await this.sendOTPKPLogin(formData);
          if (response.status === 200 && response.data.message !== undefined) {
            this.message = response.data.message
            this.showSnackbar = true
            this.isSentOtp = true;
            this.otpSending = false;
            this.startOtpTimer();
          }

          if (response.status !== 200 && response.data.message !== undefined) {
            this.error = response.data.message
            this.showSnackbar = true
            this.otpSending = false;
          }
          this.onCallToastr('success', `Code sent...`, 'Success!')

          this.isBusy = false
        } catch (e) {
          this.isBusy = false;
          this.otpSending = false;
          const responseData = e && e.response && e.response.data ? e.response.data:'';
          this.error = responseData && responseData.message || '';
          this.errors =  responseData && responseData.errors || '';
        }
      },
      async getMemberActiveClient() {
        this.isBusy = true;
        let payload = {};

        if(this.isPhone) {
            payload.phone = this.getFormattedCellNumber(this.smartInput);
        } else {
          payload.email = this.smartInput;
        }

        try {
          const response = await this.getMemberActiveClients(payload);
          this.activeClients = response.data;
          
          if(!!(this.activeClients && this.activeClients.length > 1)){
            this.$refs.clientSelectModal.toggle(this.activeClients);
          } else {
            this.selectedActiveClient = this.activeClients[0];
          }

          this.isBusy = false;

        } catch (e) {
          this.isBusy = false;
        }
      },
      async handleSubmit() {
        if (this.isKeyPerson === 1) {
          this.getActiveClients()
          // this.onLogin();
        } else if (this.isKeyPerson === 2){
          if(this.isValidInput && this.smartInput) {
            await this.getMemberActiveClient();
          }
          if(this.activeClients.length == 0){
            this.error = "No active clients found for this email or phone number.";
            return;
          }
          if(!!(this.selectedActiveClient && this.selectedActiveClient.id)) {
            this.sendKPOTPCode(0);
          } else {
            this.$refs.clientSelectModal.toggle(this.activeClients);
          }
        }
      },
      send2FACode() {
        this.isBusy = true;
        this.verifyPhone({phone: this.user.cell_phone})
          .then(() => {
            this.isBusy = false;
            this.twoFactorCodeSent = true;
            this.message = 'The verification code was sent to your cell ' + this.user.cell_phone + ', please check';
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      sendDeactivateTwoFactorCode() {
        if (!this.user.email)
        {
          return false;
        }
        this.isBusy = true;
        this.sendDeactivateCode()
          .then(() => {
            this.isBusy = false;
            this.isDeactivateTwoFactor = true;
            this.message = 'The verification code was sent to your email ' + this.user.email + ', please check';
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      verifyTwoFactorCode() {
        if (!this.$refs.twoFaForm.validate()) return
        this.isBusy = true;
        this.verifyUserTwoFactorCode({verification_code: this.user.two_factor_code})
          .then((res) => {
            this.isBusy = false;
            if (res.data.verified) {
              this.$store.dispatch('auth/setUserData', this.userLoginData)
              this.$store.dispatch('auth/refreshSession')
              this.setDefaultRoute()
            } else {
              this.error = "This verification code is invalid."
            }
          })
          .catch(() => {
            this.error = 'Something went wrong, please try again later';
            this.isBusy = false
          })
      },
      handleTwoFactorAuthorize() {
        if (this.twoFactorCodeSent) {
          this.verifyTwoFactorCode()
        }
      },
      handleDeactivateTwoFactor() {
        if (!this.user.deactivate_token)
        {
          this.error = true;
          this.message = 'Verification code is required';
          return
        }
        this.isBusy = true;
        this.error = false
        this.deactivateTwoFactorCode({deactivate_token: this.user.deactivate_token})
        .then((res) => {
          this.isBusy = false;
          if (res.data.success) {
            this.userLoginData.two_factor_verified = false
            this.$store.dispatch('auth/setUserData', this.userLoginData)
            this.$store.dispatch('auth/refreshSession')
            this.setDefaultRoute()
          } else {
            this.error = true;
            this.message = "This verification code is invalid.";
          }
        })
        .catch(() => {
          this.error = 'Something went wrong, please try again later';
          this.isBusy = false
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      onReturnToForm(type) {
        this.isSentOtp = false;
        this.otpCode = null;
        this.smartInput = '';
        this.isValidInput = false;
      },
      startOtpTimer() {
        this.otpTimer = 60; // Reset timer
        this.otpInterval = setInterval(() => {
          if (this.otpTimer > 0) {
            this.otpTimer--;
          } else {
            clearInterval(this.otpInterval);
          }
        }, 1000);
      },
      // Convert Cell/phone US format
      getFormattedCellNumber(cell){
        let phone = null;
        // Format the phone number
        const phoneNumber = cell.replace(/\D/g, ''); // Remove all non-digit characters
        if (phoneNumber.length === 10) {
          phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
        } else {
          phone = phoneNumber; // Fallback if the input is not exactly 10 digits
        }
        
        return phone;
      }
    }
  }
</script>
<style lang="css">

  .otp-form { 
    max-width: 300px;
    text-align: center;
  }

  .otp-form-top {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .otp-form-description {
    text-align: center;
    margin-bottom: 40px;
    max-width: 400px;
  }

  .otp-continue-btn {
    opacity: 0.7;
  }

  .otp-fields .v-input { 
    padding: 0 !important;
  }

  .otp-fields .v-input:not(:last-child) fieldset {
    border-right: 0;
   }

  .otp-fields .v-input:not(:first-child) fieldset {
    border-left-width: 1px;
   }

</style>

<style lang="scss" scoped>
.card-light {
  background-color: #f4f5fa!important;
  .register-page_person button {
    box-shadow: 0 2px 6px -2px rgb(49, 45, 75) !important;
    color: rgba(94, 86, 105, 0.87);
  }
}
.card-dark {
  background-color: var(--v-primary-lighten1);
  .register-page_person button {
    box-shadow: 0 2px 6px -2px #f4f5fa !important;
  }
}
  .lost-device {
    font-size: 18px;
    font-weight: 400;
  }
  .v-card__title {
    word-break: break-word;
  }

  .card-title {
    font-size: 16px!important;
    font-weight: 500;
    line-height: 1.2;
    @media (min-width: 380px) {
      font-size: 20px!important;
      font-weight: 500;
    }
  }
  .back-btn {
    @media (max-width: 380px) {
      height: 24px;
      width: 24px;
    }
  }

  .left-leave-active {
    transition: 0.5s;
  }
  .left-enter-active {
    transition: 0.3s;
  }

  .left-enter {
    transform: translate(120%, 0);
  }

  .left-leave-to {
    transform: translate(120%, 0);
  }

  .right-leave-active {
    transition: 0.5s;
  }
  .right-enter-active {
    transition: 0.3s;
  }

  .right-enter {
    transform: translate(-120%, 0);
  }

  .right-leave-to {
    transform: translate(-120%, 0);
  }

  .client-card-button {
    width: 100%;
  }
</style>