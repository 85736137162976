<template>
	<div class="d-flex align-center flex-column mt-0">
		  <!-- adam removed mt-2 padding from text/email button -->
			<v-btn-toggle
                  class="full-width"
                  rounded
                >
				<v-tooltip bottom v-if="disabledText()">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						small
						value="left"
						class="disabled-text"
						v-bind="attrs"
						v-on="on"
						>
						<span small>Text</span>
						<v-icon end small :color="'error'"> {{textDisabled ? 'mdi-cellphone-off' : 'mdi-cellphone'}} </v-icon>
						</v-btn>
					</template>
					<span v-if="!textDisabled">Message has exceeded 320-character texting max</span>
					<span v-else>This Advocate does not have a valid cell number</span>
				</v-tooltip>
				<v-btn
						v-else
						small
						value="left"
						:class="!isEmail || emailDisabled ? 'primary' : ''"
						:style="!isEmail || emailDisabled ? 'color: white' : ''"
						@click="isEmail = false"
						>
						<span small>Text</span>
						<v-icon end small :style="(!isEmail && !textDisabled) || emailDisabled? 'color: white' : textDisabled ? 'color: red' : ''"> {{textDisabled ? 'mdi-cellphone-off' : 'mdi-cellphone'}} </v-icon>
				</v-btn>
				<v-tooltip bottom v-if="emailDisabled">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							small
							value="center"
							class="disabled-text"
							v-bind="attrs"
							v-on="on"
						>
							<span small>Email</span>
							<v-icon right small :color="'error'"> {{emailDisabled ? 'mdi-email-off-outline' : 'mdi-email-outline'}} </v-icon>
						</v-btn>
					</template>
					<span v-if="emailDisabled">This Advocate does not have a valid email</span>
				</v-tooltip>
				<v-btn
					v-else
					small
					value="center"
					:class="isEmail ? 'primary' : ''"
					:style="isEmail ? 'color: white' : ''"
					@click="isEmail = true"
				>
					<span small>Email</span>
					<v-icon
						end
						small
						:style="isEmail ? 'color: white' : emailDisabled ? 'color: red' : ''"
					>
						{{emailDisabled ? 'mdi-email-off-outline' : 'mdi-email-outline'}}
					</v-icon>
				</v-btn>
            </v-btn-toggle>
    </div>
</template>

<script>
export default {
	name: "MessageSendAs",
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		messageLength: {
			type: Number,
			default: 0
		},
		textDisabled: {
			type: Boolean,
			default: false
		},
		emailDisabled: {
			type: Boolean,
			default: false
		},
    value: {
      type: Boolean,
      default: true
    }
	},
	data() {
		return {
			isEmail: true,
		}
	},
	methods: {
		disabledText() {
			return (this.messageLength > 320 && this.isEmail) || this.textDisabled
		}
	},
	watch: {
		isEmail: function (val) {
			this.$emit('changeMessageType', val)
		},
    value: {
      handler() {
        this.isEmail = this.value
      },
      immediate: true
    }
	},
}
</script>
<style>
 .disabled-text {
	background-color: rgba(255, 255, 255, 0.12);
	color: #ff4c51 !important;
	cursor: not-allowed;
  }

</style>