import stateService from "@/services/StateService";
export default {
  fetchParentCommittees: async ({ commit }, stateId) => {
    commit("setParentCommitteeIsLoading");
    try {
      const response = await stateService.methods.getStateCommittees(stateId, {
        only_parent_committees: true,
      });
      commit("setParentCommittees", response.data);
    } catch (error) {
      commit("setParentCommittees", []);
      console.error(error);
    } finally {
      commit("setParentCommitteeIsNotLoading");
    }
  },
};
