<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>

    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Clients</h1>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-btn color="warning" class="my-1" @click="$refs.newClientModal.toggle()">New Client</v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn color="warning" small @click="$refs.newClientModal.toggle()">New Client</v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap>
        <v-flex class="d-flex align-center flex-wrap mb-4">
          <v-text-field
            prepend-inner-icon="search"
            label="Search Clients"
            clearable
            outlined
            hide-details
            dense
            class="search-input search-input_members"
            v-model="search"
          ></v-text-field>
        </v-flex>
    </v-layout>
    <v-data-table
      :loading="isLoading"
      class="elevation-1"
      :options.sync="pagination"
      :headers="clientTableHeader"
      :items="clientList"
    >
      <template v-slot:item.action="{ item }">
        <v-btn icon class="mx-0" @click="onEditClient(item)">
          <v-icon size="18" color="primary">edit</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="onDisableClient(item.id)" v-if="item.active">
          <v-icon size="18" color="error">block</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" @click="onEnableClient(item.id)" v-else>
          <v-icon size="18" color="success">check_circle</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.active="{ item }">
        <div>
          <v-icon color="success" v-if="item.active">check_circle</v-icon>
          <v-icon color="error" v-else>cancel</v-icon>
        </div>
      </template>

      <template v-slot:item.association="{ item }">
        <div>{{ item.association }}</div>
      </template>

      <template v-slot:item.assoc_abbrev="{ item }">
        <div>{{ item.assoc_abbrev }}</div>
      </template>

      <template v-slot:item.state="{ item }">
        <div>{{ item.state ? item.state.state : '' }}</div>
      </template>

      <template v-slot:item.uses_regions="{ item }">
        <div>
          <v-icon color="success" v-if="item.uses_regions">check_circle</v-icon>
          <v-icon color="error" v-else>cancel</v-icon>
        </div>
      </template>

      <template v-slot:item.uses_kps="{ item }">
        <div>{{ item.uses_kps }}</div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No client registered yet.</p>
      </template>
    </v-data-table>

    <edit-client-modal ref="editClientModal" :state-list="stateList" :tariffList="tariffList" :refresh="refresh"></edit-client-modal>
    <new-client-modal ref="newClientModal" :state-list="stateList" :tariffList="tariffList" :refresh="refreshAfterCreate"></new-client-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import StateService from '@/services/StateService'

import editClientModal from '@/components/clients/edit-client-modal'
import newClientModal from '@/components/clients/new-client-modal'

export default {
  name: 'ClientsPage',
  mixins: [ClientService, StateService],
  components: {
    'edit-client-modal': editClientModal,
    'new-client-modal': newClientModal,
  },
  data () {
    return {
      isLoading: true,
      stateList: [],
      tariffList: [],
      clientList: [],
      search: '',
      pagination: {
        itemsPerPage: 10
      }
    }
  },
  computed: {
    clientTableHeader () {
      return [
        { text: '', value: 'action', align: 'center', sortable: false },
        { text: 'Active', value: 'active', align: 'center' },
        { text: 'Association', value: 'association', align: 'center' },
        { text: 'Association Abbr', value: 'assoc_abbrev', align: 'center' },
        { text: 'State', value: 'state.state', align: 'center' },
        { text: 'Uses regions?', value: 'uses_regions', align: 'center' },
        { text: 'How many KPs?', value: 'uses_kps', align: 'center' },
      ]
    }
  },
  async mounted () {
    this.stateList = (await this.getStates()).data
    this.tariffList = (await this.getTariffs()).data
    this.refresh()
  },
  methods: {
    async refresh () {
      this.isLoading = true
      let params = {
        search: this.search
      }
      this.clientList = (await this.getClients(params)).data
      this.isLoading = false
    },
    onDisableClient (clientId) {
      swal({
        title: 'Are you sure to disable this client?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.disableClient(clientId)
            .then(() => {
              this.onCallToastr('success', 'Client has been disabled.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onEnableClient (clientId) {
      swal({
        title: 'Are you sure to enable this client?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.enableClient(clientId)
            .then(() => {
              this.onCallToastr('success', 'Client has been enabled.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    onEditClient (client) {
      this.$refs.editClientModal.toggle(client)
    },
    async refreshAfterCreate () {
      await this.refresh()
      this.$store.dispatch('app/setClientList', this.clientList)
      
    },
  },
  watch: {
    'search': function(newVal, oldVal) {
      if (newVal === "") {
        this.refresh()
      }
      else if (newVal && oldVal && newVal !== oldVal && newVal.length === 3) {
        this.refresh()
      }
    },
  }
}
</script>
