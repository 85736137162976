import axios from 'axios'

export default {
  methods: {
    getLegislators () {
      return axios.get('/legislators')
    },
    createLegislator (payload) {
      return axios.post('/legislators', payload)
    },
    updateLegislator (payload) {
      return axios.put(`/legislators/${payload.id}`, payload)
    },
    getConnections(clientId, leg_id) {
      return axios.get(`/clients/${clientId}/legislators/${leg_id}/connections`)
    },
    geKML(url) {
      return axios.get(url)
    },
    deleteLegislator (legislatorId) {
      return axios.delete(`/legislators/${legislatorId}`)
    },
    getLegislatorVoteHistory (params) {
      return axios.get(`/legislators/${params.id}/vote-history`, { params })
    },
    getLegislatorKPHistory (params) {
      return axios.get(`/legislators/${params.id}/kp-history`, { params })
    },
    getLegislatorDonationHistory (params) {
      return axios.get(`/legislators/${params.id}/donation-history`, { params })
    },
    addLegData (payload) {
      return axios.post(`/legislators/${payload.leg_id}/data`, payload)
    },
    updateLegData (payload) {
      return axios.put(`/legislators/${payload.leg_id}/data`, payload)
    },
    addLegislatorKPs (legislatorId, payload) {
      return axios.post(`/legislators/${legislatorId}/save-kps`, payload)
    },
    deleteLegislatorKPs (legislatorId, payload) {
      return axios.post(`/legislators/${legislatorId}/delete-kps`, payload)
    },
    importLegislatorsFromGoogle () {
      return axios.post('/legislators/import-from-google')
    },
    replaceWithNewLegislators (payload) {
      return axios.post('/legislators/replace-with-new', payload)
    },
    ignoreLegislators (payload) {
      return axios.post('/legislators/ignore', payload)
    },
    getLegislatorNews(legislatorId) {
      return axios.get(`/legislators/${legislatorId}/news`)
    },
    getLegislatorDonationsList(clientId, legislatorId, params) {
      return axios.get(`/clients/${clientId}/legislators/${legislatorId}/donations`, { params })
    },
    addLegislatorDonation (clientId, legislatorId, payload) {
      return axios.post(`/clients/${clientId}/legislators/${legislatorId}/donations/store`, payload)
    },
    removeLegislatorDonation (clientId, legislatorId, donationId) {
      return axios.post(`/clients/${clientId}/legislators/${legislatorId}/donations/${donationId}/delete`)
    },
    updateMemberLegislatorNote (memberLegislatorId, payload) {
      return axios.post(`/member-legislator/${memberLegislatorId}/update`, payload)
    },
    updateMemberLegislatorNotes (payload) {
      return axios.post(`/member-legislator/update-notes`, payload)
    },
    getLegislatorTaskResults (legislatorId, params) {
      return axios.get(`/legislators/${legislatorId}/get-legislator-tasks`, {params})
    },
    getLegislatorsByStateAndCommittee (stateId, committeeId, params) {
      return axios.get(`/states/${stateId}/committe-legislators/${committeeId}`, {params})
    },
    getStaffByLegislator(params) {
      return axios.get(`/staff/`, {params})
    },
    getStaffIssuesByClient(params) {
      return axios.get(`/staff/issues`, {params})
    },    
    getStaffRolesByClient(params) {
      return axios.get(`/staff/roles`, {params})
    },
    getImportedFederalLegislators(params) {
      return axios.get(`/federal-legislators/get-imported-list`, {params})
    },
    createMultipleFederalLegislators (payload) {
      return axios.post('/federal-legislators/create-multiple-federal-legislators', payload)
    },
    createFederalLegislators (importedId) {
      return axios.post(`/federal-legislators/create-federal-legislators/${importedId}`)
    },
    updateFederalLegislators (payload) {
      return axios.post('/federal-legislators/update-federal-legislators', payload)
    },
    replaceFederalLegislators (payload) {
      return axios.post('/federal-legislators/replace-federal-legislators', payload)
    },
    fetchNewFederalLegislators () {
      return axios.post('/federal-legislators/fetch-new-federal-legislators')
    },
  }
}
