<template>
  <v-dialog fullscreen persistent v-model="isModalOpen" tile>
    <v-toolbar :loading="isLoading && !refreshKPList">
      <v-toolbar-title class="title">KP Finder Tool - Assign KPs to {{ legislator.fullname }}</v-toolbar-title>
      <v-spacer/>
      <v-icon class="ml-3" @click.native="closeModal">close</v-icon>
    </v-toolbar>
    <v-card>
      <v-card-text class="d-flex assignment-modal__text-container">
        <legislator-data
          ref="legislatorData"
          :legislator="legislator"
          :selectedClient="client"
          @updateLegislators="$emit('updateLegislators')"
        ></legislator-data>
        <v-flex md12 lg9 class="px-2 mt-5">
          <v-layout xs12 wrap :class="!isMobile ? 'row px-5' : ''">
            <div v-if="renderKPS" class="d-flex flex-wrap full-width" :class="!isMobile ? 'row px-5' : ''">
              <div
                :class="!isMobile ? 'col' : 'full-width py-1'"
                class="pt-5 pb-0"
                :style="!isMobile ? 'max-width:' + draganWidth + '% !important' : ''"
                v-for="(kp, index) in kps"
                :key="index"
                v-if="index < 3"
              >
                <v-flex class="d-flex justify-center" v-if="kp.kp_position  || index === autocompleteIndex">
                  <h4 class="text-center my-1">KP{{ index + 1 }}</h4>
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                            v-on="on"
                        >
                          <v-switch
                            v-if="index > 0"
                            class="my-1 ml-3 py-0"
                            color="primary"
                            hide-details
                            label="CC all messaging"
                            v-model="sendCopy[index + 1]"
                            @change="changeSendCopy(kp)"
                          ></v-switch>
                        </div>
                      </template>
                        <div>
                          By design, only KP1 can log in or receive KP messaging.
                          By enabling this feature for any KP2+, each will also receive messages and also be able to log in.
                          It is recommended to only enable this feature for those KP2+ who truly work as a team with the KP1.
                          If your KP2-5 are only used as backups (and are not deeply involved), then do not enable this feature.
                        </div>
                      </v-tooltip>
                </v-flex>
              </div>
              <draggable
                :class="isMobile ? 'flex-wrap' : 'flex-nowrap'"
                class="d-flex full-width"
                v-model="kps"
                v-bind="getKPsOptions"
                :move="handleMove"
                @end="endDrag"
              >
                <v-col
                  class="assignment-kps"
                  v-for="(kp, index) in kps"
                  :key="index"
                  :style="!isMobile ? 'max-width:' + draganWidth + '% !important' : ''"
                  v-if="index < 3"
                >
                  <v-card v-if="kp.kp_position">
                    <member-card
                      :member="kp"
                      :legislator="legislator"
                      :isNotKp="false"
                      @updateKps="reload"
                      @removeKp="deleteKp"
                      @updateLegislators="$emit('updateLegislators')"
                    ></member-card>
                  </v-card>

                  <v-card v-else class="assignment-kps_empty ignore-elements" width="100%" outlined>
                    <v-card-title v-show="index !== autocompleteIndex"
                                  class="assignment-kps_empty_title justify-center pb-0 pt-2">
                      {{ `KP${index + 1}` }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </draggable>
            </div>
            <div v-if="kps.length > 3 && renderKPS" class="d-flex flex-wrap full-width" :class="!isMobile ? 'row px-5' : ''">
              <div
                :class="!isMobile ? 'col' : 'full-width py-1'"
                class="pt-5 pb-0"
                :style="!isMobile ? 'max-width:' + draganWidth + '% !important' : ''"
                v-for="(kp, index) in kps"
                :key="index"
                v-if="index > 2"
              >
                <v-flex class="d-flex justify-center" v-if="kp.kp_position  || index === autocompleteIndex">
                  <h4 class="text-center my-1">KP{{ index + 1 }}</h4>
                  <v-switch
                    v-if="index > 0"
                    class="my-1 ml-3 py-0"
                    color="primary"
                    hide-details
                    label="CC all messaging"
                    v-model="sendCopy[index + 1]"
                    @change="changeSendCopy(kp)"
                  ></v-switch>
                </v-flex>
              </div>
              <draggable
                :class="isMobile ? 'flex-wrap' : 'flex-nowrap'"
                class="d-flex full-width"
                v-model="kps"
                v-bind="getKPsOptions"
                :move="handleMove"
                @end="endDrag"
              >
                <v-col
                  class="assignment-kps"
                  v-for="(kp, index) in kps"
                  :key="index"
                  :style="!isMobile ? 'max-width:' + draganWidth + '% !important' : ''"
                  v-if="index > 2"
                >
                  <v-card v-if="kp.kp_position">
                    <member-card
                      :member="kp"
                      :legislator="legislator"
                      :isNotKp="false"
                      @updateKps="reload"
                      @removeKp="deleteKp"
                      @updateLegislators="$emit('updateLegislators')"
                    ></member-card>
                  </v-card>

                  <v-card v-else class="assignment-kps_empty ignore-elements" width="100%" outlined>
                    <v-card-title v-show="index !== autocompleteIndex"
                                  class="assignment-kps_empty_title justify-center pb-0 pt-2">
                      {{ `KP${index + 1}` }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </draggable>
            </div>
          </v-layout>

          <v-layout xs12 wrap class="ma-5">
            <div class="full-width">
              <h2>Eventual KP?</h2>
            </div>
            <v-flex class="d-flex">
              <v-text-field
                prepend-inner-icon="search"
                label="Search Advocates"
                clearable
                class="search-input search-input_members"
                v-model="filter.search"
                persistent-hint
                :hint="`${active_members_count} Active`"
              >
              </v-text-field>
              <v-switch
                class="ml-3"
                color="primary"
                hide-details
                label="Constituents Only"
                v-model="constituentsOnly"
                @change="onChangeConstituentsOnly"
              ></v-switch>
              <v-spacer></v-spacer>
              <div class="legislators_filter__additional--filter" :class="{ 'w-100': isMobile }">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  left
                  offset-y
                  :close-on-click="false"
                  min-width="600"
                  max-width="300"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                      <span>Filters</span>
                      <v-icon>filter_list</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="members_filter-overflow">
                      <v-layout wrap>
                        <v-flex xs6 sm6>
                          <div class="mt-4 mb-1">
                            <h4>KP Preference</h4>
                          </div>
                          <div v-for="(item, index) in kpPreference" :key="index">
                            <v-checkbox
                              color="primary"
                              hide-details
                              class="ma-0"
                              :label="item.title"
                              :value="filter.kpPreferenceResults.indexOf(item.value) >= 0"
                              @change="editKpPreferenceResults($event, item.value)"
                            ></v-checkbox>
                          </div>
                        </v-flex>

                        <v-flex xs6 sm6>
                          <div class="mt-4 mb-1">
                            <h4>Political Leanings</h4>
                          </div>
                          <div v-for="(item, index) in politicalLeanings" :key="index">
                            <v-checkbox
                              color="primary"
                              hide-details
                              class="ma-0"
                              :label="item.name"
                              :value="filter.politicalLeanings.indexOf(item.name) >= 0"
                              @change="editKpPoliticalLeanings($event, item.name)"
                            ></v-checkbox>
                          </div>
                        </v-flex>

                        <v-flex xs12 sm12 v-if="legislator.universities && legislator.universities.length > 0">
                          <div class="mt-4 mb-1">
                            <h4>Match {{ legislator.nickname }}'s School/University</h4>
                          </div>
                          <div
                            v-for="school in legislator.universities"
                            :key="school.id"
                          >
                            <v-checkbox
                              color="primary"
                              :label="school.name"
                              class="mt-0"
                              :input-value="isEmptySchool(school)"
                              @change="editSchools($event, school)"
                            ></v-checkbox>
                          </div>
                        </v-flex>

                        <v-flex xs12 sm12 v-if="hobbies">
                          <div class="mb-1">
                            <h4>Match {{ legislator.nickname }}'s Hobbies & Interests</h4>
                          </div>
                          <div
                            v-for="interest in hobbies"
                            :key="interest.id"
                          >
                            <v-checkbox
                              v-if="interest.parent_id === null"
                              color="primary"
                              :label="interest.name"
                              class="mt-0"
                              :input-value="isEmptyInterest(interest)"
                              @change="editHobbies($event, interest)"
                            ></v-checkbox>
                            <div
                              class="ml-4"
                              v-for="childInterest in hobbies"
                              :key="childInterest.id"
                            >
                              <v-checkbox
                                v-if="interest.id === childInterest.parent_id
                            && isEmptyInterest(interest)
                            && childInterest.type !== 'favorite_team'
                            && childInterest.type !== 'note_hobby'"
                                color="primary"
                                :label="childInterest.name"
                                class="mt-0"
                                :input-value="isEmptyInterest(childInterest)"
                                @change="editHobbies($event, childInterest)"
                              ></v-checkbox>
                              <div
                                v-if="interest.id === childInterest.parent_id
                          && childInterest.type === 'watching_sports'
                          && isEmptyInterest(childInterest)
                          && isEmptyInterest(interest)"
                              >
                                <favorite-teams-autocomplete
                                  :clientId="client.id"
                                  :memberFavoriteTeams="filterFavoriteTeams"
                                  @response="changeFavoriteTeams"
                                  @remove="removeFavoriteTeam"
                                ></favorite-teams-autocomplete>
                              </div>
                              <div
                                v-if="childInterest.type === 'other'
                                  && interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && isEmptyInterest(childInterest)"
                              >
                              </div>
                            </div>
                          </div>
                        </v-flex>
                        
                        <v-flex xs12>
                          <v-layout
                            v-if="filter.customFields && filter.customFields.length"
                            class="legislators_filter__additional--item_wrap"
                            v-for="(item, index) in filter.customFields"
                            :key="index"
                          >
                            <div v-if="item.type == 'text' && item.kp_assignment_window" class="mb-4">
                              <h4>{{ item.name }}</h4>
                              <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                            </div>
                            <div
                              v-if="item.type == 'checkbox' && item.metadata && item.metadata.length && item.kp_assignment_window">
                              <h4>{{ item.name }}</h4>
                              <v-radio-group
                                v-for="(data, j) in item.metadata"
                                :key="j"
                                row
                                v-model="data.type"
                                :label="data.name"
                              >
                                <v-radio
                                  color="primary"
                                  v-for="(label, key) in checkboxLabels"
                                  :key="key"
                                  :label="label.name"
                                  :value="label.value"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                            <div v-if="item.type == 'toggle' && item.kp_assignment_window">
                              <h4>{{ item.name }}</h4>
                              <v-checkbox :label="item.name" class="mt-0" v-model="item.value"></v-checkbox>
                            </div>
                            <div
                              v-if="item.type == 'dropdown' && item.metadata && item.metadata.length && item.kp_assignment_window">
                              <h4 class="my-2">{{ item.name }}</h4>
                              <v-checkbox
                                color="primary"
                                v-for="(data, j) in item.metadata"
                                :key="j"
                                :label="data.name"
                                class="mt-0"
                                v-model="data.checked"
                              >
                              </v-checkbox>
                            </div>
                          </v-layout>
                        </v-flex>

                      </v-layout>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                      <v-btn color="primary" text @click="getMembersList">Apply Filter</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-flex>
            <v-data-table
              :loading="refreshKPList"
              class="elevation-1 full-width"
              :headers="membersHeader"
              :items="membersList"
              v-if="renderKPS"
              :server-items-length="pagination.totalItems"
              :footer-props="tableFooter"
              @update:options="handlePaginationChange"
            >
              <template v-slot:item.fullname="{ item }">
                <member-card
                  ref="MemberCard"
                  :member="item"
                  :legislator="legislator"
                ></member-card>
              </template>

              <template v-slot:item.interests[0]="{ item }">
                <p>{{ memberHobbies(item) }}</p>
              </template>

              <template v-slot:item.notes="{ item }">
                <v-tooltip bottom v-if="!!item.notes" max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0"
                      small
                      @click="openEditNotesModal(item)"
                      v-on="on"
                    >
                      <v-icon size="28" color="primary">mdi-file</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.notes }}</span>
                </v-tooltip>
                <v-btn
                  v-else
                  icon
                  class="mx-0"
                  small
                  @click="openEditNotesModal(item)"
                >
                  <v-icon size="21" color="primary">fa-light fa-file-circle-plus</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.possible_kp="{ item }">
                <v-btn-toggle
                  mandatory
                  :value="item.possible_kp"
                  @change="changePossibleKp($event, item)"
                  rounded
                >
                  <v-btn
                    active-class="color-white"
                    small
                    :color="item.possible_kp === 0 ? 'green' : ''"
                    :disabled="refreshKPList"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    active-class="color-white"
                    :color="item.possible_kp === 1 ? 'orange' : ''"
                    small
                    :disabled="refreshKPList"
                  >
                    Maybe
                  </v-btn>
                  <v-btn
                    active-class="color-white"
                    :color="item.possible_kp === 2 ? 'red' : ''"
                    small
                    :disabled="refreshKPList"
                  >
                    No
                  </v-btn>
                </v-btn-toggle>
              </template>

              <template v-slot:item.promote="{ item }">
                <div class="d-flex wrap">
                  <v-btn
                    v-for="(kp, index) in kps"
                    :key="index"
                    v-if="!(kp && kp.id)"
                    active-class="color-white"
                    class="mr-1"
                    small
                    color="primary"
                    :disabled="isLoading || refreshKPList || item.possible_kp === 2"
                    @click="changePromoteKp(index + 1, item)"
                  >
                    KP{{ index + 1 }}
                  </v-btn>
                </div>
              </template>

            </v-data-table>
          </v-layout>
        </v-flex>
      </v-card-text>
    </v-card>

    <kp-notes-modal
      ref="KpNotesModal"
      :legislator="legislator"
      @updateKps="reload"
      @updateLegislators="$emit('updateLegislators')"
    ></kp-notes-modal>
    <members-note-modal
      ref="MembersNoteModal"
      @updateKps="reload"
    >
    </members-note-modal>
    <member-detail-modal ref="MemberDetailModal" :selectedClient="client"/>
  </v-dialog>
</template>

<script>
import LegislatorData from '@/components/legislators/assignment/legislator-data';
import MemberCard from '@/components/legislators/assignment/member-card';
import KpNotesModal from '@/components/legislators/assignment/kp-notes-modal';
import MembersNoteModal from '@/components/legislators/assignment/member-notes-modal';

import ClientService from "@/services/ClientService";
import LegislatorService from "@/services/LegislatorService";
import MemberService from "@/services/MemberService";
import {mdiNotePlus, mdiNote } from '@mdi/js';

import swal from 'sweetalert2'
import CustomFieldsFilter from '@/mixins/custom-fields-filter'
import _ from "lodash";
import InterestService from "@/services/InterestService";
import FavoriteTeamsAutocomplete from "@/components/auth/favorite-teams-autocomplete";
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'

export default {
  name: "AssignmentModal",
  mixins: [ClientService, LegislatorService, MemberService, InterestService, CustomFieldsFilter],
  components: {
    MemberDetailModal: () => import('@/components/members/member-detail-modal'),
    LegislatorData,
    MemberCard,
    KpNotesModal,
    MembersNoteModal,
    draggable,
    "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      refreshKPList: false,
      legislator: {},
      kps: [],
      newKp: null,
      allActiveMembers: [],
      filterFavoriteTeams: [],
      filter: {
        search: '',
        kpPreferenceResults: [],
        politicalLeanings: [],
        hobbies: [],
        schools: [],
        customFields: [],
      },
      pagination: {
        current_page: 1
      },
      tableFooter: {
        'items-per-page-options': [ 10, 20, 40, -1]
      },
      active_members_count: 0,
      movingIndex: null,
      membersList: [],
      menu: false,
      constituentsOnly: true,
      shortList: [],
      ruledOut: [],
      hobbies: [],
      politicalLeanings: [],
      openKpModal: false,
      futureIndex: null,
      toDrag: null,
      sendCopy: {},
      shortListPosition: 'short list',
      autocompleteIndex: null,
      notePlus: mdiNotePlus,
      note: mdiNote,
      renderKPS: true,
      checkboxLabels: [
          { value: 'default', name: 'All' },
          { value: 1, name: 'Yes' },
          { value: 0, name: 'No' },
        ],
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    getOptions() {
      return {group: 'people'}
    },
    getKPsOptions() {
      return {group: 'people', pull: true, filter: ".ignore-elements"}
    },
    membersHeader() {
      return [
        {text: 'Advocates', value: 'fullname'},
        {text: 'Hobbies', value: 'interests[0]'},
        {text: 'Notes', value: 'notes'},
        {text: 'Possible KP', value: 'possible_kp'},
        {text: 'Promote', value: 'promote'},
      ]
    },
    draganWidth() {
      return this.kps.length > 3 ? 100/3 : 100/this.kps.length
    }
  },
  async mounted() {
    this.politicalLeanings = (await this.getPoliticalLeanings()).data
    this.loadCustomFields(this.client.id)
  },
  methods: {
    onChangeConstituentsOnly(){
      this.pagination.page = 1;
      if(!this.constituentsOnly) {
        this.tableFooter['items-per-page-options'] = [10, 20, 40];
      } else {
        this.tableFooter['items-per-page-options'] = [10, 20, 40, -1];
      }
      this.getMembersList();
    },
    toggle(legislator) {
      if (!legislator || !legislator.id) {
        return;
      }
      this.sendCopy = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      }
      this.kps = []
      this.isModalOpen = true
      this.legislator = _.cloneDeep(legislator);
      this.hobbies = this.legislator.interests
      this.reload(false)
    },
    async reload(isNotFromToggle = true) {
      this.isLoading = true
      await this.getKp()
      if (isNotFromToggle) {
        await this.getMembersList()
      }
    },
    getKp() {
      this.isLoading = true
      this.renderKPS = false
      this.kps = []
      for (let i = 1; i <= this.client.uses_kps; i++) {
        this.kps.push({})
      }
      this.getKpsByLegislator(this.client.id, this.legislator.id).then((response) => {
        const arrayKp = [1, 2, 3, 4, 5]
        response.data.map((kp) => {
          if (kp.kp_position && kp.kp_position === this.shortListPosition) {
            this.shortList.push(kp)
          }

          if (kp.kp_position && arrayKp.indexOf(kp.kp_position)) {
            this.kps[kp.kp_position - 1] = kp
            this.sendCopy[kp.kp_position] = kp.send_copy
          }
        })

        if (this.openKpModal) {
          this.$refs.KpNotesModal.toggle(this.kps[this.futureIndex])
        }
        this.renderKPS = true
        this.openKpModal = false
        this.isLoading = false
      }, (err) => {
        this.handleError(err)
        this.isLoading = false
      })
    },
    editKpPreferenceResults(event, value) {
      if (event) {
        this.filter.kpPreferenceResults.push(value)
      } else {
        const index = this.filter.kpPreferenceResults.indexOf(value)
        if (index >= 0) {
          this.filter.kpPreferenceResults.splice(index,1)
        }
      }
    },
    editHobbies(event, value) {
      if (event) {
        this.filter.hobbies.push(value)
      } else {
        this.removeInterest(value)
      }
    },
    editSchools(event, value) {
      if (event) {
        this.filter.schools.push(value)
      } else {
        this.removeSchools(value)
      }
    },
    removeInterest(value) {
      const index = this.filter.hobbies.findIndex(object => object.id === value.id);
      if (index >= 0) {
        const childInterests = this.filter.hobbies.filter(object => object.parent_id === value.id)
        childInterests.forEach(object => this.removeInterest(object))
        this.filter.hobbies.splice(index, 1)
      }
      if (value.type === 'watching_sports') {
        this.filterFavoriteTeams = [];
      }
    },
    removeSchools(value) {
      const index = this.filter.schools.findIndex(object => object.id === value.id);
      if (index >= 0) {
        this.filter.schools.splice(index, 1)
      }
    },
    changeFavoriteTeams(value) {
      this.filterFavoriteTeams = value
    },
    removeFavoriteTeam(value) {
      this.filterFavoriteTeams.splice(value, 1)
    },
    isEmptyInterest(interest) {
      const memberInterest = this.filter.hobbies.findIndex(item => item.id === interest.id)
      return memberInterest >= 0
    },
    isEmptySchool(school) {
      const memberSchool = this.filter.schools.findIndex(item => item.id === school.id)
      return memberSchool >= 0
    },
    editKpPoliticalLeanings(event, value) {
      if (event) {
        this.filter.politicalLeanings.push(value)
      } else {
        const index = this.filter.politicalLeanings.indexOf(value)
        if (index >= 0) {
          this.filter.politicalLeanings.splice(index,1)
        }
      }
    },
    clearFilter() {
      this.filter = {
        search: '',
        kpPreferenceResults: [],
        politicalLeanings: [],
        hobbies: [],
        schools: []
      }
      this.getMembersList()
    },
    getMembersList() {
      this.isLoading = true
      this.menu = false
      // const reg = /\w\d+\w/g;
      // let district = this.legislator.district && this.legislator.district.district.match(reg) ? this.legislator.district.district.match(reg)[0] : null;
      let districtName = ''
      if(this.legislator && this.legislator.district && this.legislator.district.district) {
        districtName = this.legislator.district.district
        const splitDistrict = districtName.split('-')
        districtName = splitDistrict[0]
      }
      let district = districtName ? districtName : null;

      let hobbies = this.filter.hobbies.concat(this.filterFavoriteTeams);
      let kps = [];
      if(this.kps.length) {
          kps = this.kps.map(({ id, nickname, lastname }) => ({ id, fullname: `${nickname} ${lastname}` }));
      }
      
      let pageLimit = this.pagination && this.pagination.per_page ? this.pagination.per_page: 10;
      if(!this.constituentsOnly && this.pagination.per_page === -1) {
        pageLimit = 10;
      }

      //Set district to district id if client is not using state
      if(this.client.is_use_state === 0) {
        district = this.legislator && this.legislator.district && this.legislator.district.id || 0;
      }

      let params = {
        page: this.pagination && this.pagination.page ? this.pagination.page: 1,
        limit: pageLimit,
        sort: this.pagination && this.pagination.sortBy && this.pagination.sortBy[0]? this.pagination.sortBy[0]:'possible_kp',
        direction: this.pagination && this.pagination.sortDesc && this.pagination.sortDesc[0] ? 'desc' : 'asc',
        search: this.filter.search,
        all_kps: 1,
        showInactive: 0,
        isStringDistrict: 1,
        kp_preference: this.filter.kpPreferenceResults,
        political_leanings: this.filter.politicalLeanings,
        hobbies: hobbies.map(hobby => hobby.name),
        schools: this.filter.schools.map(school => school.id),
        district: this.constituentsOnly ? district : 0,
        kps,
        request_from:'assignment',
        legislator_id: this.legislator.id,
        custom_fields: this.collectCustomFieldsFilter(),
      }

      const clientId = this.client && this.client.id || '';
      this.getClientMembersWithPagination(clientId, params).then((response) => {
        this.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.pagination.current_page = response.data.current_page
        this.pagination.per_page = response.data.per_page * 1
        this.pagination.last_page = response.data.last_page
        let members = response.data.data ? response.data.data : response.data
        this.active_members_count = response.data.active_members_count
        this.membersList  = members || [];
        this.isLoading = false
      })
      .catch(() => {
        this.onCallToastr('error', 'Something went wrong!', 'Error!')
        this.isLoading = false
      })
    },
    openEditNotesModal(member) {
      this.$refs.MembersNoteModal.toggle(member)
    },
    changePromoteKp(kpPosition, member) {
      swal({
        title: `Assign this member as a KP${kpPosition}?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true
          this.openKpModal = true
          this.futureIndex = kpPosition - 1
          await this.addMemberToKP(member, kpPosition)
        }
      })
    },
    async changePossibleKp($event, member) {
      this.refreshKPList = true
      if ($event === 0) {
        member.possible_kp = 0
        this.addMemberToKP(member, 'short list')
      } else if ($event === 1) {
        member.possible_kp = 1
        this.removeMemberLegislator(member)
      } else {
        member.possible_kp = 2
        await this.removeMemberLegislator(member)
        this.refreshKPList = true
        await this.addMemberToKP(member,  'rules out')
      }
    },
    async endDrag() {
      if (this.kps[this.futureIndex] && this.kps[this.futureIndex].id) {
        swal({
          title: 'KP should be dragged only to an open spot!',
          type: 'info',
          confirmButtonColor: '#3085d6',
        })
      } else {
        swal({
          title: `Move ${this.kps[this.movingIndex].nickname} ${this.kps[this.movingIndex].lastname} to KP${this.futureIndex + 1}?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true
            const payload = [
              {
                client_id: this.client.id,
                member_id: this.kps[this.movingIndex].id,
                kp_position: this.futureIndex + 1,
                start_date: moment().format('YYYY-MM-DD'),
                send_copy: 0
              }
            ]
            this.openKpModal = true
            this.addLegislatorKPs(this.legislator.id, payload)
              .then(() => {
                this.isLoading = false
                this.reload()
                this.$emit('updateKps')
                this.onCallToastr('success', `KP has been reassigned`, 'Success!')
              })
              .catch(() => {
                this.newKp = null
                this.isLoading = false
                this.onCallToastr('error', 'Something went wrong!', 'Error')
              })
          }
        })
      }
    },
    handleMove(event) {
      this.movingIndex = event.draggedContext.index
      this.futureIndex = event.relatedContext.index
      return false
    },
    deleteKp(member) {
      swal({
        title: 'Are you sure you want to unassign this KP?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          await this.removeMemberLegislator(member)
          this.$refs.KpNotesModal.toggle(member)

        }
      })
    },
    async addMemberToKP(member, kpPosition) {
      this.isLoading = true
      let payload = [{
        client_id: this.client.id,
        member_id: member.id,
        kp_position: kpPosition,
        start_date: moment().format('YYYY-MM-DD'),
        send_copy: 0
      }]

      await this.addLegislatorKPs(this.legislator.id, payload)
        .then(async () => {
          await this.reload()
          if (!this.refreshKPList) {
            this.onCallToastr('success', `KP has been reassigned`, 'Success!')
          }
          this.refreshKPList = false
          this.isLoading = false
        })
        .catch(() => {
          this.newKp = null
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
          this.refreshKPList = false
          this.isLoading = false
        })
    },
    async removeMemberLegislator(member) {
      this.isLoading = true
      let payload = [{
        client_id: this.client.id,
        member_id: member.id,
        start_date: moment().format('YYYY-MM-DD'),
      }]

      await this.deleteLegislatorKPs(this.legislator.id, payload)
        .then(async () => {
          await this.reload()
          if (!this.refreshKPList) {
            this.onCallToastr('success', `KP has been unassigned`, 'Success!')
          }
          this.refreshKPList = false
          this.isLoading = false
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
          this.refreshKPList = false
          this.isLoading = false
        })
    },
    memberHobbies(member) {
      let hobbies = ''
      if (member.interests && member.interests.length) {
        member.interests.forEach((hobby, index) => {
          if(index === (member.interests.length - 1)) {
            hobbies = `${hobbies} ${hobby.name}`
          } else {
            hobbies = `${hobbies} ${hobby.name},`
          }
        })
      }
      return hobbies
    },
    changeSendCopy(kp) {
      this.isLoading = true
      if (kp && kp.id) {
        let payload = {
          send_copy: this.sendCopy[kp.kp_position]
        }
        this.updateMemberLegislator(kp.id, this.legislator.id, payload)
          .then(() => {
            if (!this.refreshKPList) {
              this.onCallToastr('success', `KP has been updated`, 'Success!')
            }
            this.isLoading = false          
          })
          .catch(() => {
            this.reload()
            this.onCallToastr('error', 'Something went wrong!', 'Error!')
            this.isLoading = false
          })
      }
    },
    closeModal() {
      this.autocompleteIndex = null
      this.newKp = null
      this.isModalOpen = false;
      this.$refs.legislatorData.isMapLoad = false;
      this.kps = []
    },
    handlePaginationChange(options) {
      this.pagination.page = options.page;
      this.pagination.per_page = options.itemsPerPage;
      this.getMembersList();
    },
  },
  watch: {
    'filter.search'() {
      clearTimeout(this.timer)
      this.timer = setTimeout(()=> {
        this.memberList = []
        this.pagination.page = 1
        this.getMembersList()
      }, 500)
    },
    isModalOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.legislatorData) {
            this.$refs.legislatorData.isMapLoad = false;
            setTimeout(() => {
              this.$refs.legislatorData.isMapLoad = true;
              this.$refs.legislatorData.initMap && this.$refs.legislatorData.initMap();
            }, 100);
          }
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-application .v-dialog--fullscreen .v-toolbar__title {
    padding-left: 0 !important;
  }
  .assignment-modal__text-container {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .color-white {
    color: white !important;
  }
</style>
