<template>
  <v-flex md12 lg3 v-if="legislator && legislator.id">
    <!-- Image Section -->
    <div class="legislators_assigned-img-holder px-2 pt-4 pl-0" v-if="legislator.official_image">
      <img
        :src="legislator.official_image"
        :style="{ 'border-left': `4px solid ${partyColorList[legislator.party]}` }"
        :alt="legislator.nickname + ' ' + legislator.lastname"
        height="200"
      />
      <v-flex class="legislators_assigned-img-holder--icons">
        <vip-chip
          v-if="legislator.vip"
          :legislator="legislator"
          :show="'image_code'"
        />
      </v-flex>
      <v-icon
        v-if="legislator.lame_duck"
        color="#EECB23"
        class="legislators_icon--faded legislators_icon--large"
      >
        fa-solid fa-duck
      </v-icon>
    </div>

    <!-- Bio & Committees -->
    <div class="my-4" v-if="legislator.elected_at">
      <b>Occupation / Bio:</b>
      <span style="white-space: pre-line;" v-html="legislator.occupation"></span>
    </div>
    <div class="my-4" v-if="legislator.elected_at">
      <b>Elected:</b> {{ legislator.elected_at }}
    </div>
    <div class="my-4" v-if="legislator.legislator_committees && legislator.legislator_committees.length > 0">
      <b>Committees:</b>
      <v-tooltip
        v-for="(legislatorCommittee, index) in legislator.legislator_committees"
        :key="legislatorCommittee.id"
        right
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
            {{
              index + 1 < legislator.legislator_committees.length
                ? `${legislatorCommittee.committee.shortname}, `
                : legislatorCommittee.committee.shortname
            }}
          </span>
        </template>
        <span>{{ legislatorCommittee.committee.fullname }}</span>
      </v-tooltip>
    </div>
    <div class="my-4" v-if="legislator.leg_notes">
      <b>Public Notes:</b> {{ legislator.leg_notes }}
    </div>

    <!-- Private Notes -->
    <div class="my-4 d-flex">
      <strong>Private Notes:</strong>
      <v-spacer />
      <v-btn
        v-if="isEdit"
        class="mr-2"
        small
        outlined
        color="primary"
        @click="isEdit = false"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="isEdit"
        small
        color="primary"
        @click="saveLegislator"
      >
        Save
      </v-btn>
      <v-btn
        v-if="!isEdit && !isCoordinator"
        small
        color="primary"
        @click="isEdit = true"
      >
        Edit
      </v-btn>
    </div>
    <span class="pt-0" v-if="!isEdit">{{ legislator.vip_notes }}</span>
    <v-textarea
      class="pt-0"
      outlined
      dense
      v-else
      v-model="legislator.vip_notes"
    ></v-textarea>

    <!-- Map Section -->
    <div class="assignment-leg-map pt-4">
      <template v-if="isMapLoad">
        <map-openlayer
          :id="`map_${legislator.id}`"
          :legislator="legislator"
          :districtId="legislator.district_id"
          :client="selectedClient"
          :options="mapOptions"
          ref="mapLayer"
        />
      </template>
    </div>
  </v-flex>
</template>

<script>
import LegislatorService from "@/services/LegislatorService";
import vipChip from "@/components/legislators/vip-setting/vip-chip";
import { partyColorList } from "@/entities/party-color-list";
import mapOpenlayer from "@/components/common/map-ol";

export default {
  name: "LegislatorData",
  components: {
    "vip-chip": vipChip,
    "map-openlayer": mapOpenlayer,
  },
  mixins: [LegislatorService],
  props: {
    legislator: {
      type: Object,
      default: () => ({ id: null }),
    },
    selectedClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEdit: false,
      isMapLoad: true,
      mapOptions: {
        height: 300,
        width: "100%",
      },
      partyColorList: partyColorList(),
    };
  },
  mounted() {
    this.handleMapRender();
    window.addEventListener("resize", this.handleMapResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleMapResize);
  },
  watch: {
    legislator(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.reloadMap();
      }
    },
    isMapLoad(newVal) {
      if (newVal) {
        this.$nextTick(this.initMap);
      }
    },
  },
  methods: {
    handleMapRender() {
      this.$nextTick(() => {
        this.handleMapResize();
        this.initMap();
      });
    },
    handleMapResize() {
      const container = document.querySelector(".assignment-leg-map");
      if (container) {
        this.mapOptions.width = container.offsetWidth || "100%";
        this.mapOptions.height = container.offsetHeight || 300;
      }
    },
    reloadMap() {
      this.isMapLoad = false;
      setTimeout(() => {
        this.isMapLoad = true;
        this.handleMapRender();
      }, 300);
    },
    initMap() {
      if (this.$refs.mapLayer && typeof this.$refs.mapLayer.initMap === "function") {
        this.$refs.mapLayer.initMap();
      }
    },
    saveLegislator() {
      const payload = {
        id: this.legislator.id,
        leg_id: this.legislator.id,
        client_id: this.userClientId,
        vip_notes: this.legislator.vip_notes,
        leg_notes: this.legislator.leg_notes,
        leg_support: this.legislator.leg_support,
        leg_region: this.legislator.leg_region,
      };
      this.updateLegData(payload)
        .then(() => {
          this.isEdit = false;
          this.$emit("updateLegislators");
          this.onCallToastr("success", "Successfully saved.", "Success!");
        })
        .catch(() => {
          this.onCallToastr("error", "Something went wrong!", "Error!");
        });
    },
  },
};
</script>

<style scoped>
.assignment-leg-map {
  width: 100%;
  height: 300px; /* Ensure visibility */
  margin-bottom: 20px;
}
</style>