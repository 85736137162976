<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="client-options__container">
    <v-progress-linear
      style="z-index: 6"
      color="primary"
      indeterminate
      height="3"
      fixed
      top
      v-if="isBusy"
    ></v-progress-linear>
    <v-form v-if="client && client.id && !isBusy" ref="editClientOptionsForm" class="flex-1" @submit.prevent="onUpdateClient()">
      <v-layout wrap>
        <v-flex xs12 sm4 class="px-2">
          <v-card class="pt-10">
            <v-card-title class="d-flex flex-column justify-center">
              <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
              <v-avatar size="120" rounded class="bordered_avatar">
                <img :src="client.avatar_url || '/images/client-avatar.png'"/>
              </v-avatar>
              <span>{{client.association}}</span>
              <v-chip v-if="client.tariff" small label
                class="v-chip-light-bg primary primary--text">{{ client.tariff.name }}
                Plan
              </v-chip>
            </v-card-title>
            <v-card-text>
              <v-list class="d-flex">
                <v-list-item v-if="data && data.active_members" class="d-flex justify-end">
                  <v-avatar rounded class="primary--text v-avatar-light-bg primary">
                    <v-icon>fas fa-users</v-icon>
                  </v-avatar>
                  <div class="ml-2">
                    <h3>{{ data.active_members }}</h3>
                    <p class="mb-0">Advocates</p>
                  </div>
                </v-list-item>
                <v-list-item v-if="data && data.house && data.senate" class="d-flex justify-start">
                  <v-avatar rounded class="primary--text v-avatar-light-bg primary">
                    <v-icon>fas fa-user-tie</v-icon>
                  </v-avatar>
                  <div class="ml-2">
                    <h3>{{ +data.house + +data.senate }}</h3>
                    <p class="mb-0">Legislators</p>
                  </div>
                </v-list-item>
              </v-list>
              <h2>Details</h2>
              <v-divider class="my-2"></v-divider>
              <v-layout wrap>
                <v-flex>
                  <v-list class="d-flex flex-column">
                    <v-list-item dense class="px-0">
                      <span><b class="mr-2">Abbreviation:</b>{{ client.assoc_abbrev }}</span>
                    </v-list-item>
                    <v-list-item dense class="px-0">
                      <span><b class="mr-2">Status:</b>
                        <v-chip x-small label class="v-chip-light-bg success--text" color="success">
                          {{ client.active ? 'Active' : 'Not active' }}
                        </v-chip>
                      </span>
                    </v-list-item>
                    <v-list-item dense class="px-0 d-block">
                      <div v-if="client.tariff">
                        <span><b>Plan level:</b>{{ client.tariff.name }}</span>
                      </div>
                      <div v-else>
                        <span><b>Plan level:</b>None</span>
                      </div>
                      <span class="font-italic">Please contact Sales if you’d like to change your plan level.</span>
                    </v-list-item>
                    <v-list-item dense class="px-0">
                      <span><b>Official {{client.assoc_abbrev}} email:</b> {{client.mailgun_email}}</span>
                    </v-list-item>
                    <v-list-item dense class="px-0">
                      <span><b>Official {{client.assoc_abbrev}} text number:</b> {{client.twilio_number}}</span>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="$refs.editClientOptionsModal.isModalOpen = true" color="primary">Edit</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 class="px-2">
          <v-tabs
            v-model="tab"
            :vertical="$vuetify.breakpoint.xs"
            style="box-shadow: none !important; background-color: transparent!important;"
          >
            <v-tab>General</v-tab>
            <v-tab>Options</v-tab>
            <v-tab>Billing and subscription</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-5">
            <v-tab-item>
              <v-card>
                <v-card-title>General</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                  <v-layout wrap>
                    <v-flex xs12 md8 class="px-2">
                      <v-text-field label="Full Association Name*" outlined dense :rules="associationRules"
                        v-model="client.association"
                        @input="error = ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4 class="px-2">
                      <v-text-field label="Association Abbr*" outlined dense :rules="associationAbbrevRules"
                        v-model="client.assoc_abbrev"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md8 class="px-2">
                      <v-select label="Time zone" outlined dense :items="getTimeZonesList()"
                        v-model="client.timezone"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md4 class="px-2">
                      <v-select label="Max KPs" outlined dense :items="kpsList" :readonly="!isVIP"
                        v-model="client.uses_kps"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" color="primary">Save changes</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-title>Options</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                  <v-layout wrap>
                    <v-flex v-if="isSuperAdmin" xs12 md4 class="px-2">
                      <v-checkbox
                        color="primary"
                        label="Use regions?"
                        v-model="uses_regions"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 class="px-2">
                      <v-switch
                        color="primary"
                        v-model="client.send_mailgun_notifications"
                        :label="`Send email notifications to${uses_regions ? ' Coordinators and ' : ' '}selected Admins when Message received`"
                      >
                      </v-switch>
                    </v-flex>
                    <v-flex xs12 class="px-2" v-if="uses_regions">
                      <v-switch
                        color="primary"
                        v-model="client.uses_assignment_kp_c"
                        :label="`Allow Coordinators to search for, assign, and remove KPs`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2" v-if="uses_regions">
                      <v-switch
                        color="primary"
                        v-model="client.uses_approval_coordinator_m"
                        :label="`Queue all Coordinator messages for Admin approval?`"
                      >
                      </v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        v-model="client.uses_approval_kp_m"
                        :label="`Queue all KP messages for Admin approval?`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2" v-if="uses_regions">
                      <v-switch
                        color="primary"
                        label="Use Regional Coordinators?"
                        v-model="client.uses_coordinators"
                        @change="client.show_all = false"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Show All Task Rows for Coordinators"
                        v-model="client.show_all"
                        v-if="client.uses_coordinators"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Uses DOB?"
                        v-model="client.uses_dob"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Uses grad year?"
                        v-model="client.uses_grad_year"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Uses Political Leanings?"
                        v-model="client.uses_political_leaning"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Uses Hobbies/Interests?"
                        v-model="client.uses_interests"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Use 2nd work address?"
                        v-model="client.uses_2nd_work"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Use 3rd work address?"
                        v-model="client.uses_3rd_work"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Use 4th work address?"
                        v-model="client.uses_4th_work"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6 class="px-2">
                      <v-switch
                        color="primary"
                        label="Allow KPs to send donation requests?"
                        v-model="client.allow_kps_send_donation_requests"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 class="px-2 py-2">
                      <v-layout align-baseline>
                        <v-text-field
                          label="How to formally address a House Legislator in correspondence"
                          outlined
                          dense
                          v-model="client.formal_house"
                        ></v-text-field>
                        <v-tooltip bottom max-width="350">
                          <template v-slot:activator="{ on }">
                            <v-icon size="20" v-on="on">help</v-icon>
                          </template>
                          <span>example: Representative, Delegate, Assemb., Rep., Rep, Delegate, Del, etc.</span>
                        </v-tooltip>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="px-2">
                      <v-layout align-baseline>
                        <v-text-field
                          label="How to formally address a Senator in correspondence"
                          outlined
                          dense
                          v-model="client.formal_senate"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md12 class="px-2">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>Profile Update Notifications ({{client.profile_update_notifications ? "On" : "Off"}})</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-layout wrap>
                              <v-flex xs12 md6 class="px-2">
                                <v-switch
                                  color="primary"
                                  v-model="client.profile_update_notifications"
                                  label="Profile Update Notifications"
                                  @change="client.embed_personal_data = false"
                                ></v-switch>
                              </v-flex>
                              <v-flex xs12 md6 class="px-2" v-if="client.profile_update_notifications">
                                <v-layout align-center>
                                  <v-checkbox
                                    color="primary"
                                    v-model="client.embed_personal_data"
                                    label="Hide Personal Data"
                                  ></v-checkbox>
                                  <v-tooltip bottom max-width="450">
                                    <template v-slot:activator="{ on }">
                                      <v-icon size="20" v-on="on">help</v-icon>
                                    </template>
                                    <span>Check this box to hide personal data in the notification email. You will need to log into the app to see changes in Advocates > Profile Updates.</span>
                                  </v-tooltip>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="px-2" v-if="client.profile_update_notifications">
                                <v-text-field
                                  label="Notification Email Addresses"
                                  outlined
                                  dense
                                  hint="Comma-separated list allowed"
                                  v-model="client.notification_email"
                                  :rules="[emailRules]"
                                  @input="validateEmails"
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" color="primary">Save changes</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-title>Billing and subscription</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                  <div v-if="client.tariff"><b>Plan level:</b> {{client.tariff.name}}</div>
                  <div v-else><b>Plan level:</b> None</div>
                  <p class="font-italic">If you’d like to change plan level, please contact sales at
                    <a href="mailto:sales@kpdashboard.com">sales@kpdashboard.com</a>
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-form>
    <edit-client-options-modal
      v-if="client && client.id"
      ref="editClientOptionsModal"
      :client="client"
    ></edit-client-options-modal>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'
import SessionService from '@/services/SessionService'
import editClientOptionsModal from "@/components/global/edit-client-options-modal";
import {mapGetters} from "vuex";

export default {
  name: "ClientOptionsPage",
  mixins: [ClientService, CloudinaryService, StateService, SessionService],
  data () {
    return {
      committeeList: [],
      error: '',
      isBusy: false,
      tab: null,
      data: {},
      selectedSessionId: null,
      uses_regions: false,
      activeKpsPosition: null,
      kpsPositionList: [0, 1, 2, 3, 4, 5],
      emailRules: v => {
        if (!v) return true;
        const emails = v.split(',').map(email => email.trim());
        for (let i = 0; i < emails.length; i++) {
          if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(emails[i])) {
            return `Invalid email: ${emails[i]}`;
          }
        }
        return true;
      }
    }
  },
  components: {
    editClientOptionsModal
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    associationRules () {
      return [
        v => !!v || 'Association is required.',
      ]
    },
    associationAbbrevRules () {
      return [
        v => !!v || 'Association abbr is required.',
      ]
    },
    kpsList() {
      return this.kpsPositionList.map((kp) => ({
        text: kp.toString(),
        value: kp,
        disabled: kp < this.activeKpsPosition,
      }));
    },
  },
  methods: {
    init() {
      this.getClientData();
      this.getClientActiveKpsPostion();
    },
    onUpdateClient () {
      if (!this.$refs.editClientOptionsForm.validate()) return;
      this.isBusy = true;
      this.client.uses_regions = this.uses_regions;
      if (!this.validateEmails(this.client.notification_email)) {
        this.error = "Invalid email(s). Please check and try again.";
        this.isBusy = false;
        return;
      }
      this.updateClient(this.client)
        .then(() => {
          this.isBusy = false;
          this.onCallToastr('success', 'Client info has been updated.', 'Success!');
          const userData = _.cloneDeep(this.userData);
          if (this.isSuperAdmin) {
            this.$store.dispatch('app/setSuperAdminSelectedClient', this.client);
          } else {
            userData.client = this.client;
            this.$store.dispatch('auth/setUserData', userData);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          if (err && err.response && err.response.data && err.response.data.message) {
            this.onCallToastr('error', err.response.data.message, 'Failed!');
          } else {
            this.error = 'Something went wrong!';
          }
        });
    },
    async getClientData() {
      this.committeeList = (await this.getStateCommittees(this.client.id)).data;
      if (!this.client.house_committees) this.client.house_committees = [];
      if (!this.client.senate_committees) this.client.senate_committees = [];
      if (!this.client.joint_committees) this.client.joint_committees = [];
      const activeSession = (await this.getClientActiveSession(this.client.id)).data;
      this.selectedSessionId = activeSession ? activeSession.id : null;
      if (this.selectedSessionId) {
        this.data = (await this.getSessionDashboardData(this.selectedSessionId)).data;
      } else {
        this.data = {};
      }
      this.isBusy = false;
    },
    async getClientActiveKpsPostion(){
      if (this.client && this.client.id) {
        const data = (await this.getActiveKpsPostion(this.client.id)).data;
        this.activeKpsPosition = data || 0;
      }
    },
    validateEmails(value) {
      if (!value) return true;
      const emails = value.split(',').map(email => email.trim());
      for (let i = 0; i < emails.length; i++) {
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(emails[i])) {
          return false;
        }
      }
      return true;
    }
  },
  mounted() {
    if (!(this.client && this.client.id)) return;
    this.uses_regions = this.client.uses_regions;
    this.init();
  },
  watch: {
    'client': function () {
      this.getClientData();
    }
  }
}
</script>

<style lang="scss">
.client-options__container .v-tabs > .v-tabs-bar {
  background-color: transparent !important;
  border-bottom: thin solid rgba(94,86,105,.14);
}
</style>
