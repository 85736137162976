<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Advocacy Messages</h1>
        <advocacy-page-help-modal></advocacy-page-help-modal>
        <v-switch
          color="primary"
          hide-details
          label="Show archived"
          class="d-inline-block mt-0 ml-3 sms-preview-switch"
          v-model="archived"
          @change="refresh"
        >
        </v-switch>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="isMobile">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-btn color="primary" class="my-1" block small :loading="status === 'LOADING_PAGE'" @click="refresh">
                Refresh
                <v-icon dark>refresh</v-icon>
              </v-btn>
              <v-btn color="warning" class="my-1" :disabled="!client"  block small @click="onAMModalHandler">
                New Advocacy Message
                <v-icon dark class="ml-2">note_add</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn icon color="primary" :loading="status === 'LOADING_PAGE'" @click="refresh">
          <v-icon dark>refresh</v-icon>
        </v-btn>
        <v-btn color="warning" :disabled="!client" small @click="onAMModalHandler">
          New Advocacy Message
          <v-icon dark class="ml-2">note_add</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-data-table
      sort-by
      :loading="status === 'LOADING_PAGE'"
      class="elevation-1"
      :options.sync="pagination"
      :headers="advocacyMessageTableHeader"
      :items="advocacyMessageList"
    >
      <template v-slot:item.active="{ item }">
        <div>
          <v-icon color="success" v-if="isActive(item)">check_circle</v-icon>
          <v-icon color="error" v-else>cancel</v-icon>
        </div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div>{{ item.created_at | dateTimeFormatterWithoutUTC }}</div>
      </template>

      <template v-slot:item.deadline="{ item }">
        <div :class="getDeadlineClass(item)">{{ item.deadline | dateTimeFormatterWithoutUTC }}</div>
      </template>

      <template v-slot:item.message="{ item }">
        <div  class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a @click="$refs.fullMessageModal.toggle(item)" v-on="on">
                <v-icon color="primary" size="35" class="mx-1">drafts</v-icon>
              </a>
            </template>
            <span>Read full message</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.received="{ item }">
        <div  class="d-flex align-center justify-center schedule-circle">
          <v-progress-circular
            v-if="item.sent_at && item.active"
            class="cursor-pointer"
            :rotate="-90"
            :size="60"
            :width="5"
            :value="(item.received_message_count != null ? item.received_message_count : 0) * 100 / item.total_received_message_count"
            :color="getProgressColor((item.received_message_count != null ? item.received_message_count : 0) / item.total_received_message_count)"
            @click="$refs.SendStatsModal.toggle(item)"
          >
            {{ item.received_message_count != null ? item.received_message_count : 0 }} / {{ item.total_received_message_count }}
          </v-progress-circular>
        </div>
      </template>

      <template v-slot:item.stats="{ item }">
        <div  class="d-flex align-center justify-center schedule-circle">
          <v-progress-circular
            v-if="item.sent_at && item.active"
            class="cursor-pointer"
            :rotate="-90"
            :size="60"
            :width="5"
            :value="(item.sent_message_count != null ? item.sent_message_count : 0) * 100 / item.total_message_count"
            :color="getProgressColor((item.sent_message_count != null ? item.sent_message_count : 0) / item.total_message_count)"
            @click="$refs.statsModal.toggle(item)"
          >
            {{ item.sent_message_count != null ? item.sent_message_count : 0 }} / {{ item.total_message_count }}
          </v-progress-circular>
        </div>
      </template>

      <template v-slot:item.opened="{ item }">
        <div  class="d-flex align-center justify-center schedule-circle">
          <v-progress-circular
            v-if="item.sent_at && item.active"
            class="cursor-pointer"
            :rotate="-90"
            :size="60"
            :width="5"
            :value="(item.opened_message_count != null ? item.opened_message_count : 0) * 100 / item.total_message_count"
            :color="getProgressColor((item.opened_message_count != null ? item.opened_message_count : 0) / item.total_message_count)"
            @click="$refs.statsModal.toggle(item)"
          >
            {{ item.opened_message_count != null ? item.opened_message_count : 0 }} / {{ item.total_message_count }}
          </v-progress-circular>
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <div  class="d-flex align-center" v-if="!item.sent_at">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                @click="onAMModalHandler(item)"
                v-on="on"
                small
                class="mx-1"
              >
                Edit/Send
              </v-btn>
            </template>
            <span>Edit or Send</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                color="error"
                @click="onDelete(item)"
                v-on="on"
                small
                class="mx-1"
              >
                Delete
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-btn
            color="warning"
            @click="onArchive(item)"
            :loading="status === 'REMINDING_' + item.id"
            small
            class="mx-1"
          >
            {{ archived ? 'Unpack' : 'Archive' }}
          </v-btn>
        </div>
        <div class="d-flex align-center" v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="warning"
                @click="onRemind(item)"
                :loading="status === 'REMINDING_' + item.id"
                :disabled="!isActive(item)"
                v-on="on"
                small
                class="mx-1"
              >
                Remind All
              </v-btn>
            </template>
            <span>Remind all those who have yet to send</span>
          </v-tooltip>
          <v-tooltip bottom v-if="deadlineIsOver(item)">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                @click="$refs.deadlineModal.toggle(item)"
                v-on="on"
                small
                class="mx-1"
              >
                Edit
              </v-btn>
            </template>
            <span>Edit deadline or deactivate</span>
          </v-tooltip>
          <v-btn
            v-else
            color="primary"
            disabled
            small
            class="mx-1"
          >
            Edit
          </v-btn>
          <v-btn
            color="warning"
            @click="onArchive(item)"
            :loading="status === 'REMINDING_' + item.id"
            small
            class="mx-1"
          >
            {{ archived ? 'Unpack' : 'Archive' }}
          </v-btn>
        </div>
        <div class="d-flex align-center" >

        </div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No advocacy messages created yet.</p>
      </template>
    </v-data-table>

    <deadline-modal ref="deadlineModal" :refresh="refresh"></deadline-modal>
    <full-message-modal ref="fullMessageModal"></full-message-modal>
    <template v-if="isOpenAMModal">
      <message-modal
        ref="messageModal"
        :committee-list="committeeList"
        :selectedClient="client"
        :legislator-list="legislatorList"
        :refresh="refresh"
        @closeModal="onCloseAMModal"
      ></message-modal>
    </template>
    <stats-modal ref="statsModal" :refresh="refresh"></stats-modal>
    <send-stats-modal ref="SendStatsModal" :refresh="refresh"></send-stats-modal>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import ClientService from '@/services/ClientService'
import StateService from '@/services/StateService'

import advocacyPageHelpModal from '@/components/help-modals/advocacy-page-help-modal'
import deadlineModal from '@/components/advocacy/deadline-modal'
import fullMessageModal from '@/components/advocacy/full-message-modal'
import messageModal from '@/components/advocacy/message-modal'
import statsModal from '@/components/advocacy/stats-modal'
import SendStatsModal from '@/components/advocacy/send-stats-modal'
import {mapGetters} from "vuex";


export default {
  name: 'AdvocacyPage',
  mixins: [AdvocacyMessageService, ClientService, StateService],
  components: {
    'advocacy-page-help-modal': advocacyPageHelpModal,
    'deadline-modal': deadlineModal,
    'full-message-modal': fullMessageModal,
    'message-modal': messageModal,
    'stats-modal': statsModal,
    'send-stats-modal': SendStatsModal,
  },
  data () {
    return {
      status: '',
      committeeList: [],
      legislatorList: [],
      archived: false,
      stateList: [],
      advocacyMessageList: [],
      pagination: {
        itemsPerPage: 10
      },
      isOpenAMModal: false
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    advocacyMessageTableHeader () {
      return [
        { text: 'Active', value: 'active', align: 'center' },
        { text: 'Created At', value: 'created_at', align: 'center' },
        { text: 'Deadline', value: 'deadline', align: 'center' },
        { text: 'Message', value: 'message', align: 'center' },
        { text: 'User Received', value: 'received', align: 'center' },
        { text: 'User Sent', value: 'stats', align: 'center' },
        { text: 'Leg Opens', value: 'opened', align: 'center' },
        { text: '', value: 'action', align: 'center' },
      ]
    }
  },
  async mounted() {
    if (!(this.client && this.client.id)) return

    await this.init()
    await this.refresh()
  },
  methods: {
    async init() {
      const legislatorParams = {
        order: 'lastname'
      }
      this.committeeList = (await this.getStateCommittees(this.client.state_id, {
        only_parent_committees: true,
        with_subcommittees: true,
        client_id: this.client.id,
      })).data
      this.legislatorList = (await this.getClientLegislators(this.client.id, legislatorParams)).data
    },
    onArchive(advocacyMessage) {
      this.status = 'LOADING_PAGE'
      const payload = {
        id: advocacyMessage.id,
        archived: this.archived ? 0 : 1
      }
      this.archiveAdvocacyMessage(payload)
        .then(() => {
          this.onCallToastr('success', 'The message has been added to the archive.', 'Success!')
          this.refresh()
          this.status = ''
        })
        .catch((err) => {
          this.status = ''
          this.handleError(err)
        })
    },
    deadlineIsOver(advocacyMessage) {
      if (advocacyMessage.deadline > moment().format('YYYY-MM-DD hh:mm A')) return true
      return false
    },
    isActive (advocacyMessage) {
      if (!advocacyMessage.active) return false
      if (advocacyMessage.deadline < moment().format('YYYY-MM-DD H:mm:ss')) return false
      if (advocacyMessage.sent_message_count == advocacyMessage.total_message_count) return false
      return true
    },
    getDeadlineClass (advocacyMessage) {
      const now = moment().format('YYYY-MM-DD hh:mm A')
      const today = moment().format('YYYY-MM-DD')
      const deadline = advocacyMessage.deadline
      if (moment(deadline).format('YYYY-MM-DD') === today) return 'warning--text'
      return deadline <  now ? 'error--text' : 'success--text'
    },
    async refresh () {
      this.status = 'LOADING_PAGE'
      let params = { archived: this.archived ? 1 : 0 }
      this.advocacyMessageList = (await this.getClientAdvocacyMessages(this.client.id, params)).data

      this.status = ''
    },
    onRemind (advocacyMessage) {
      swal({
        title: 'Are you sure?',
        html: 'This action will resend the same notice to all members that have yet to send this Adovacy Message. ' +
          '<strong>This may take a while so please be patient while the system sends all of the messages.</strong>',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.appTheme.accent,
        cancelButtonColor: this.appTheme.error,
        confirmButtonText: 'Yes, Remind All Now',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.status = `REMINDING_${advocacyMessage.id}`
          try {
            this.sendAdvocacyMessage(advocacyMessage.id)
            this.onCallToastr('success', 'Remind message has been sent to assigned members.', 'Success!')
          } catch (err) {
            this.handleError(err)
          }
          this.status = ''
        }
      })
    },
    onDelete (advocacyMessage) {
      swal({
        title: 'Are you sure to delete this message?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.appTheme.accent,
        cancelButtonColor: this.appTheme.error,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteAdvocacyMessage(advocacyMessage.id)
            .then(() => {
              this.onCallToastr('success', 'Advocacy message has been removed.', 'Success!')
              this.refresh()
            })
            .catch((err) => {
              this.handleError(err)
            })
        }
      })
    },
    onAMModalHandler(item){
      this.isOpenAMModal = true;
      setTimeout(()=>{
        if(item) {
          this.$refs.messageModal.toggle(item)
        } else {
          this.$refs.messageModal.toggle();
        }
      })
    },
    onCloseAMModal(){
      this.isOpenAMModal = false;
    }
  },
  watch: {
    'client': function () {
      this.init()
      this.refresh()
    }
  }
}
</script>
