<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit district' : 'Create district' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <v-text-field
            v-model="form.district"
            :error-messages="errorMessages('district')"
            label="District"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.name"
            :error-messages="errorMessages('name')"
            label="District name"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.ocd_id"
            :error-messages="errorMessages('ocd_id')"
            label="Ocd id"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.ls_id"
            :error-messages="errorMessages('ls_id')"
            label="Legistorm id"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.kml"
            :error-messages="errorMessages('kml')"
            label="KML"
            :disabled="loading"
        >
        </v-text-field>
        <v-checkbox
          color="primary"
          v-model="form.is_federal"
          label="Is Federal?"
          :disabled="loading"
        ></v-checkbox>

        <v-checkbox
          color="primary"
          v-model="form.is_active"
          label="Is Active District?"
          :disabled="loading"
        ></v-checkbox>
        <p>Before deactivating a district, you must first abdicate any 
        active legislators in the district and also remove the district from any Regions! Also,
        run the Update Districts tool for this state if there are advocates using this district.
         </p>
        
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="saveAsNew" :loading="loading" :disabled="loading || disabled">Save as New</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading || disabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DistrictService from "@/services/DistrictService";

export default {
  name: "DistrictsFormModal",
  mixins: [DistrictService],
  props: ['selectedState'],
  data() {
    return {
      dialog: false,
      errors: {
        email: []
      },
      loading: false,
      form: {
        id: '',
        name: '',
        is_federal: false,
        is_active: true,
        district: '',
        state_id: '',
        ocd_id: '',
        ls_id: '',
        kml: '',
        country_id: ''
      }
    }
  },
  computed: {
    disabled() {
      return !this.form.name && !this.form.district && !this.form.ocd_id
    }
  },
  methods: {
    open(id) {
      if (id > 0) {
        this.getStateDistrict(id)
      }

      if (!this.form.state_id) {
        this.form.state_id = this.selectedState.id
      }

      this.errors = false
      this.dialog = true
    },
    close() {
      this.form = {
        id: '',
        name: '',
        is_federal: 0,
        is_active: 1,
        district: '',
        state_id: '',
        country_id: '',
        ocd_id: '',
        ls_id: '',
        kml: ''
      }

      this.$emit('update')
      this.dialog = false
    },
    save() {
      this.loading = true

      let data = {
        ...this.form,
        is_federal: this.form.is_federal ? 1 : 0,
        is_active: this.form.is_active ? 1 : 0,
        country_id: this.selectedState && this.selectedState.state_of, //District's country_id getting from state table `state_of`
      }

      this.saveStateDistrict(data).then(()=> {
        this.onCallToastr('success', 'Regions list has been updated.', 'Success!')
        this.close()
        this.loading = false
        this.errors = false
      }, err => {
        this.errors = err.response.data.errors
        this.loading = false
      })
    },
    saveAsNew() {
    this.loading = true

    // Clone the form data and remove the ID to treat it as a new entry
    let newData = {
      ...this.form,
      id: '', // Clear the ID for "Save as New"
      is_federal: this.form.is_federal ? 1 : 0,
      is_active: this.form.is_active ? 1 : 0,
      country_id: this.selectedState && this.selectedState.state_of, //District's country_id getting from state table `state_of`
    }

    // Call the save function, now creating a new entry
    this.saveStateDistrict(newData).then(() => {
      this.onCallToastr('success', 'New district has been created.', 'Success!')
      this.close()
      this.loading = false
      this.errors = false
    }, err => {
      this.errors = err.response.data.errors
      this.loading = false
    })
  },
    getStateDistrict(id) {
      this.loading = true
      this.getStateDistrictById(id).then(response => {
        this.form = response.data
        this.loading = false
      })
    },
    errorMessages(field) {
      return (this.errors && this.errors[field] && this.errors[field].length > 0) ? this.errors[field][0] : []
    },
  }
}
</script>