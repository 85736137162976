<template>
  <v-dialog width="900" v-model="isModalOpen" scrollable persistent>
    <v-form v-if="isModalOpen" ref="legislatorForm" class="flex-1" @submit.prevent="onSaveLegislator()">
      <v-card :loading="isBusy">
        <v-tabs
          :show-arrows="isMobile"
          v-model="tabs"
        >
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-square-user</v-icon>
            General
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-landmark</v-icon>
            Official
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-book-user</v-icon>
            Personal
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-globe</v-icon>
            Web
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-brands fa-twitter</v-icon>
            Social
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-calendar-alt</v-icon>
            Dates
          </v-tab>
        </v-tabs>
        <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>

        <v-card-text class="py-3">
          <v-tabs-items v-model="tabs" class="full-height-tab">
            <!-- General -->
            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex xs12 md7 class="d-flex align-center flex-wrap px-2">
                  <v-avatar
                    size="120"
                    class="bordered_avatar"
                  >
                    <img
                      v-if="legislator.official_image && legislator.official_image[8] === 'r'"
                      alt="Avatar"
                      :src="getThumbImageMedium(legislator.official_image)"
                    />
                    <img
                      v-if="legislator.official_image && legislator.official_image[8] !== 'r'"
                      alt="Avatar"
                      class="avatar_height-auto"
                      :src="legislator.official_image"
                    />
                    <img
                      v-if="!legislator.official_image"
                      alt="Avatar"
                      :src="'/images/member-avatar.jpg'"
                    />
                  </v-avatar>

                  <div class="upload-btn" :class="isMobile ? 'mt-2' : 'ml-3'">
                    <v-btn large color="primary" class="mr-3" @click="$refs.officialImageUploader.click()">Upload new
                      photo
                    </v-btn>
                    <p class="mt-4 mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                    <input
                      ref="officialImageUploader"
                      type="file"
                      accept="image/*"
                      class="d-none"
                      @change="onUploadOfficialImage()"
                    >
                  </div>
                </v-flex>
                <v-flex
                  v-if="legislator.vip"
                  xs12 md5
                  class="mb-0 ml-auto px-2" :class="{ 'mt-2': isMobile }"
                >
                  <v-textarea
                    rows="3"
                    outlined
                    label="VIP Notes*"
                    hint="Example: ‘Speaker of the House’, or ‘Senate Majority Leader"
                    v-model="legislator.vip_note"
                    :rules="vipRules"
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    :class="isMobile? 'mt-0' : ''"
                    color="primary"
                    label="Lame Duck"
                    v-model="legislator.lame_duck"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md6 class="px-2">
                  <v-switch
                    :class="isMobile? 'mt-0' : ''"
                    color="primary"
                    label="VIP"
                    v-model="legislator.vip"
                  ></v-switch>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex v-if="isSuperAdmin" sm3 class="px-2">
                  <v-checkbox
                    :class="isMobile? 'mt-0' : ''"
                    color="primary"
                    label="Is Federal?"
                    v-model="legislator.is_federal"
                    @change="onIsFederalChangeHandler"
                  ></v-checkbox>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Last Name*"
                    outlined
                    :rules="lastNameRules"
                    v-model="legislator.lastname"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Nick Name*"
                    outlined
                    :rules="nickNameRules"
                    v-model="legislator.nickname"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-select
                    label="Party"
                    outlined
                    :items="partyList"
                    dense
                    v-model="legislator.party"
                  ></v-select>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-select
                    label="District*"
                    outlined
                    item-text="district"
                    item-value="id"
                    :items="districtList"
                    dense
                    v-model="legislator.district_id"
                  ></v-select>
                </v-flex>
                <v-flex v-if="isSuperAdmin" sm6 class="px-2">
                  <v-select
                    label="State"
                    dense
                    item-text="state"
                    item-value="id"
                    :items="filteredStateList"
                    :rules="stateRules"
                    outlined
                    v-model="legislator.state_id"
                    @input="onSelectState"
                  >
                  </v-select>
                </v-flex>
                <v-flex v-if="isSuperAdmin" sm6 class="px-2">
                  <v-select
                    label="Country"
                    dense
                    item-text="state"
                    item-value="id"
                    :items="countryList"
                    :rules="countryRules"
                    outlined
                    v-model="countryId"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <!-- Official -->
            <v-tab-item class="py-4">
              <h3>Capitol Info</h3>
              <v-layout wrap>
                <v-flex sm12 class="px-2">
                  <v-text-field
                    class="mt-0"
                    placeholder="Capitol Address"
                    prepend-icon="fa-thin fa-building-columns"
                    outlined
                    persistent-hint
                    hint="Capitol Address"
                    v-model="legislator.official_address"
                    :dense="true"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Official Email"
                    prepend-icon="fa-thin fa-circle-envelope"
                    outlined
                    v-model="legislator.official_email"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Capitol Phone"
                    prepend-icon="fa-thin fa-phone-office"
                    outlined
                    v-mask="'(###) ###-####'"
                    :rules="phoneNumberRules"
                    v-model="legislator.official_phone"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="State Room"
                    prepend-icon="fa-thin fa-map-pin"
                    outlined
                    v-model="legislator.state_room"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Aide Name"
                    prepend-icon="fa-thin fa-user-hair"
                    outlined
                    v-model="legislator.aide_name"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <h3>District Info</h3>
                <v-flex sm12 class="px-2">
                  <v-text-field
                    class="mt-0"
                    placeholder="District Address"
                    prepend-icon="fa-thin fa-building"
                    v-model="legislator.local_address"
                    outlined
                    :dense="true"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="District Phone"
                    prepend-icon="fa-thin fa-phone-intercom"
                    outlined
                    v-mask="'(###) ###-####'"
                    :rules="phoneNumberRules"
                    v-model="legislator.local_phone"
                    dense
                  ></v-text-field>
                </v-flex>
                <!-- removed feb 19, 2022 by AP
                                    <v-flex sm6 class="px-2">
                                      <v-text-field
                                        label="Official Fax"
                                        prepend-icon="fa-thin fa-fax"
                                        outlined
                                        v-model="legislator.official_fax"
                                        dense
                                      ></v-text-field>
                                    </v-flex>
                -->

              </v-layout>
            </v-tab-item>

            <!-- Personal -->
            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex sm12 class="px-2">
                  <v-textarea
                    auto-grow
                    prepend-icon="fa-thin fa-id-card"
                    rows="3"
                    outlined
                    counter
                    :rules="occupationRules"
                    label="Occupation / Short Bio (500 ch max)"
                    v-model="legislator.occupation"
                    dense
                  ></v-textarea>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Personal Email"
                    prepend-icon="fa-thin fa-at"
                    outlined
                    v-model="legislator.personal_email"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Cell Phone"
                    prepend-icon="fa-thin fa-mobile-button"
                    outlined
                    v-mask="'(###) ###-####'"
                    :rules="phoneNumberRules"
                    v-model="legislator.cell_phone"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Home Phone"
                    prepend-icon="fa-thin fa-phone"
                    outlined
                    v-mask="'(###) ###-####'"
                    :rules="phoneNumberRules"
                    v-model="legislator.home_phone"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    label="Work Phone"
                    prepend-icon="fa-thin fa-phone-office"
                    outlined
                    v-mask="'(###) ###-####'"
                    :rules="phoneNumberRules"
                    v-model="legislator.work_phone"
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex col-12 class="px-2">
                  <h5 class="ml-1">You may enter as many as necessary</h5>
                  <v-autocomplete
                    label="Colleges or Universities Attended"
                    color="blue-grey lighten-2 mt-3"
                    :menu-props="{closeOnContentClick:true}"
                    :value="legislator.universities"
                    @change="setUniversity"
                    :search-input.sync="searchUniversities"
                    :items="universities"
                    item-text="name"
                    item-value="id"
                    :loading="isBusy"
                    hide-details
                    return-object
                    outlined
                    multiple
                    filled
                    dense
                  >
                    <template v-slot:selection="{item}"></template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <div class="mb-2 pt-1">
                    <div class="d-flex flex-wrap">
                      <div v-for="(item, index) of legislator.universities" class="mr-2">
                        <v-menu class="my-1"
                                v-model="displaySelected[getIndex(item.id, legislator.universities)]"
                                :key="index"
                                bottom
                                left
                                :close-on-content-click="false"
                                origin="top left"
                                content-class="role_card"
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                              class="my-1"
                              v-on="on"
                              close
                              outlined
                              color="primary"
                              :input-value="item.name"
                              @click:close="removeUniversityFromLegislator(index)"
                            >
                              {{ item.name }}
                            </v-chip>
                          </template>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </v-flex>


                <v-flex class="px-4" xs12 sm12 v-if="client.uses_interests">
                  <h4>Hobbies/Interests:</h4>
                  <div
                    v-for="interest in hobbiesInterests"
                    :key="interest.id"
                  >
                    <v-checkbox
                      v-if="interest.parent_id === null"
                      color="primary"
                      :label="interest.name"
                      class="mt-0"
                      :input-value="isEmptyInterest(interest)"
                      @change="editLegislatorInterests($event, interest)"
                    ></v-checkbox>
                    <div
                      class="ml-4"
                      v-for="childInterest in hobbiesInterests"
                      :key="childInterest.id"
                    >
                      <v-checkbox
                        v-if="interest.id === childInterest.parent_id
                            && isEmptyInterest(interest)
                            && childInterest.type !== 'favorite_team'
                            && childInterest.type !== 'note_hobby'"
                        color="primary"
                        :label="childInterest.name"
                        class="mt-0"
                        :input-value="isEmptyInterest(childInterest)"
                        @change="editLegislatorInterests($event, childInterest)"
                      ></v-checkbox>
                      <div
                        v-if="interest.id === childInterest.parent_id
                          && childInterest.type === 'watching_sports'
                          && isEmptyInterest(childInterest)
                          && isEmptyInterest(interest)"
                      >
                        <favorite-teams-autocomplete
                          :clientId="client.id"
                          :memberFavoriteTeams="legislatorFavoriteTeams"
                          @response="changeLegislatorFavoriteTeams"
                          @remove="removeMemberFavoriteTeam"
                        ></favorite-teams-autocomplete>
                      </div>
                      <div
                        v-if="childInterest.type === 'other'
                                  && interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && isEmptyInterest(childInterest)"
                      >
                        <v-textarea
                          v-model="otherHobbies[childInterest.id]"
                          label="Your Hobby"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15">
                        </v-textarea>
                      </div>
                    </div>
                    <div
                      v-if="interest.parent_id === null
                          && interest.type === 'other'
                          && isEmptyInterest(interest)"
                    >
                      <v-textarea
                        v-model="otherHobbies[interest.id]"
                        label="Your Hobby"
                        auto-grow
                        outlined
                        rows="1"
                        row-height="15">
                      </v-textarea>
                    </div>
                  </div>
                </v-flex>

              </v-layout>
            </v-tab-item>

            <!-- Social -->
            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-thin fa-globe" label="Official URL" outlined
                                v-model="legislator.website_url" dense
                                hint="The official website for this legislator"></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-thin fa-book-user" label="Biography URL" outlined
                                v-model="legislator.biography_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-thin fa-b" label="Ballotpedia URL" outlined
                                v-model="legislator.ballotpedia_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-thin fa-browser" label="Campaign URL" outlined
                                v-model="legislator.campaign_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-thin fa-square-pen" label="Custom Link" outlined
                                v-model="legislator.custom_link" dense></v-text-field>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <!-- Social Media -->
            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-brands fa-instagram" label="Instagram URL" outlined
                                v-model="legislator.instagram_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-brands fa-linkedin" label="LinkedIn URL" outlined
                                v-model="legislator.linkedin_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-brands fa-twitter" label="Twitter URL" outlined
                                v-model="legislator.twitter_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-brands fa-facebook" label="Facebook URL" outlined
                                v-model="legislator.facebook_url" dense></v-text-field>
                </v-flex>
                <v-flex sm12 class="px-2">
                  <v-text-field prepend-icon="fa-brands fa-youtube" label="YouTube URL" outlined
                                v-model="legislator.youtube_url" dense></v-text-field>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <!-- End Dates -->
            <v-tab-item class="py-4">
              <v-layout wrap>
                <v-flex sm6 class="px-2 py-2">
                  <v-date-picker2
                    dense
                    :disabled="!isSuperAdmin"
                    label="Sworn In (Admin Only)"
                    :rules="electedAtRules"
                    v-model="legislator.elected_at"
                    class="date-picker"
                  ></v-date-picker2>
                </v-flex>
                <v-flex sm6 class="px-2 py-2">
                  <v-date-picker2
                    dense
                    v-model="legislator.abdicated_at"
                    :disabled="!isSuperAdmin &&
                          ((legislator.member_legislators &&
                          legislator.member_legislators.length > 0) || (legislator.primary_kp &&
                          !!legislator.primary_kp.client_id))"
                    label="Abdicated (Admin Only)"
                    class="date-picker"
                  ></v-date-picker2>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>


        <v-card-actions>
          <v-spacer/>
          <v-btn outlined color="secondary" @click="isModalOpen = false" class="mt-2">Cancel</v-btn>
          <v-btn type="submit" color="primary" class="mr-3 mt-2">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import CloudinaryService from '@/services/CloudinaryService'
import CommitteeService from '@/services/CommitteeService'
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import FavoriteTeamsAutocomplete from "@/components/auth/favorite-teams-autocomplete";

import AddressInput from '@/components/common/address-input'
import VDatePicker2 from '@/components/common/date-picker'

import swal from 'sweetalert2'
import {mapGetters} from "vuex";
import InterestService from "@/services/InterestService";
import UniversityService from "@/services/UniversityService";

export default {
  props: ['stateList', 'countryList', 'committeeList', 'occupationList', 'refresh'],
  mixins: [CloudinaryService, CommitteeService, LegislatorService, StateService, InterestService, UniversityService],
  components: {
    AddressInput,
    VDatePicker2,
    "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
  },
  data() {
    return {
      tabs: null,
      isModalOpen: false,
      activeTabIndex: 0,
      districtList: [],
      displaySelected: {},
      hobbiesInterests: [],
      otherHobbies: {},
      legislatorFavoriteTeams: [],
      searchUniversities: '',
      universities: [],
      legislator: {},
      error: '',
      isBusy: false,
      initModalLoading: false,
      countryId: null,
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    stateRules() {
      return [
        v => !!v || 'State is required.',
      ]
    },
    countryRules() {
      return [
        v => !!v || 'Country is required.',
      ]
    },
    filteredStateList(){
      if(!this.countryId || !Array.isArray(this.stateList)){
        return [];
      }

      return this.stateList.filter(state => state.state_of === this.countryId)
    },
    vipRules() {
      return [
        v => !!v || 'VIP notes are required.',
      ]
    },
    lastNameRules() {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules() {
      return [
        v => !!v || 'Nickname is required.',
      ]
    },
    electedAtRules() {
      return [
        v => !!v || 'Sworn in date is required.',
      ]
    },
    occupationRules() {
      return [
        v => (!v || !!v && v.length <= 500) || 'Max 500 characters'
      ]
    },
    tabItemList() {
      return [
        {
          icon: 'account_box',
          title: 'General',
        },
        {
          icon: 'account_balance',
          title: 'Official',
        },
        {
          icon: 'home',
          title: 'Local',
        },
        {
          icon: 'public',
          title: 'Web Links',
        },
        {
          iconUrl: '/images/icon-twitter.png',
          title: 'Social Media',
        },
        {
          icon: 'date_range',
          title: 'Dates',
          hide: !this.isSuperAdmin
        },
      ]
    },
    partyList() {
      return ['None', 'R', 'D', 'I', 'L']
    },
    selectedState() {
      if (!this.legislator.state_id) return null
      return this.stateList.find(state => state.id === this.legislator.state_id)
    },
    legislatorCommitteeList() {
      if (this.isSuperAdmin) return []
      if (!this.legislator.district_id) return []
      const district = this.districtList.find(district => district.id === this.legislator.district_id)
      if (!district) return []
      const houseCommitteeList = this.committeeList.filter(committee => (this.userClient.house_committees || []).includes(committee.id))
      const senateCommitteeList = this.committeeList.filter(committee => (this.userClient.senate_committees || []).includes(committee.id))
      const jointCommitteeList = this.committeeList.filter(committee => (this.userClient.joint_committees || []).includes(committee.id))
      if (district.district[0] === 'H') {
        return [...houseCommitteeList, ...jointCommitteeList]
      }
      return [...senateCommitteeList, ...jointCommitteeList]
    },
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    onIsFederalChangeHandler(value) {
      this.onSelectState()
    },
    async toggle() {
      this.isModalOpen = true
      this.activeTabIndex = 0
      this.error = ''
      this.otherHobbies = []
      if(this.$refs.legislatorForm) this.$refs.legislatorForm.reset()
      this.legislator = {
        state_id: this.client.state_id,
        vip: false,
        lame_duck: false,
        interests: [],
        universities: [],
        committees: {}
      }
      this.selectCountryIdFromLegislatorStateId(this.legislator);
      await this.onSelectState()
      await this.getInterestsList()
      await this.getUniversity()

    },
    getUniversity() {
      const params = {
        'search': this.searchUniversities,
        'limit': 5,
      }
      this.getUniversities(params).then(response => {
        this.universities = response.data.data
      })
    },
    getInterestsList() {
      this.isBusy = true
      this.getInterests(this.client.id).then(response => {
        this.hobbiesInterests = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    isEmptyInterest(interest) {
      if (!this.legislator.interests) return false
      const legislatorInterest = this.legislator.interests.findIndex(item => item.id === interest.id)
      return legislatorInterest >= 0
    },
    editLegislatorInterests(event, value) {
      if (event) {
        this.legislator.interests.push(value)
      } else {
        this.removeInterest(value)
      }
    },
    removeInterest(value) {
      const index = this.legislator.interests.findIndex(object => object.id === value.id);
      if (index >= 0) {
        const childInterests = this.legislator.interests.filter(object => object.parent_id === value.id)
        childInterests.forEach(object => this.removeInterest(object))
        this.legislator.interests.splice(index, 1)
      }
      if (value.type === 'watching_sports') {
        this.legislatorFavoriteTeams = [];
      }
    },
    changeLegislatorFavoriteTeams(value) {
      this.legislatorFavoriteTeams = value
    },
    removeMemberFavoriteTeam(value) {
      const index = this.memberFavoriteTeams.findIndex(object => object.id === value.id);
      this.legislatorFavoriteTeams.splice(index, 1)
    },
    setUniversity(value) {
      this.legislator.universities = value
    },
    removeUniversityFromLegislator(value) {
      this.legislator.universities.splice(value, 1)
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    onSelectTab(tabIndex) {
      this.activeTabIndex = tabIndex
    },
    async onSelectState() {
      this.initModalLoading = true
      if (!this.legislator.state_id) return

      const query = {
        is_federal: this.legislator.is_federal ? 1 : 0
      } 

      this.districtList = (await this.getStateUnelectedDistricts(this.legislator.state_id, query)).data
      if (this.legislator.district) {
        this.districtList = [this.legislator.district, ...this.districtList]
      }

      this.initModalLoading = false
    },
    onUploadOfficialImage() {
      const file = this.$refs.officialImageUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.legislator.official_image = res.secure_url
          this.legislator = {...this.legislator}
        })
        .then(res => {
          this.onCallToastr('success', 'Legislator official image has been changed!', 'Success!')
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    onSaveLegislator() {
      if (!this.$refs.legislatorForm.validate()) {
        this.error = 'Please fill all required fields.'
        return
      }
      this.error = ''
      this.isBusy = true
      this.legislator.other_interests = []
      for (const [id, hobby] of Object.entries(this.otherHobbies)) {
        this.legislator.other_interests.push({
          "parent_id": id,
          "name": hobby
        })
      }
      this.legislator.universities = this.legislator.universities.map(university => university.id)
      this.legislator.interests = this.legislator.interests.filter(item => item.type !== 'favorite_team')
      this.legislator.interests = this.legislator.interests.concat(this.legislatorFavoriteTeams)
      const payload = _.cloneDeep(this.legislator)
      payload.client_id = this.client.id

      if (!payload.vip) delete payload.vip_note
      if (payload.party === 'None') {
        payload.party = ''
      }

      if (payload.abdicated_at) {
        swal({
          title: 'Are you sure? This will remove any assigned KPs as well as remove this legislator from all assigned Committees.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.saveLegislatorData(payload)
          } else {
            this.isBusy = false
          }
        })
      } else {
        this.saveLegislatorData(payload)
      }
    },
    saveLegislatorData(payload) {
      this.createLegislator(payload)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Legislator info has been updated.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch(() => {
          this.isBusy = false
          this.error = 'Something went wrong!'
        })
    },
    selectCountryIdFromLegislatorStateId(legislator) {
      if(!legislator.state_id) return;
      const state = this.stateList.find(state => state.id === legislator.state_id);
      if(state) {
        this.countryId = state.state_of;
      }
    }
  },
  watch: {
    searchUniversities: function () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getUniversity()
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  background-color: white;
}

.v-dialog--fullscreen .v-toolbar__title {
  padding-left: 0 !important;
}

.upload-btn {
  margin-top: 40px;
}

.v-tabs-items.full-height-tab .v-window-item {
  height: 50vh;
  overflow-y: auto;
}
</style>
