<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="addresses"
      :items-per-page="10"
      class=""
    >
      <template v-slot:item.address1="{ item }">
        {{ generateAddress(item) }}
      </template>
  </v-data-table>
  </div>
</template>

<script>
export default {
  name: "OtherAddressesTable",
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Address",
          value: "address1",
        },
        {
          text: "Phone",
          value: "phone",
        },
      ];
    },
  },
  data() {
    return {
      dummyData: [
        {
          title: "Title",
          address1: "Address",
          phone: "Phone",
        },
        {
          title: "Title",
          address1: "Address",
          phone: "Phone",
        },
      ],
    };
  },
  methods: {
    generateAddress(item) {
      const addressParts = [];
      const zipParts = [];
      if (item.address1) {
        addressParts.push(item.address1);
      }

      if (item.address2) {
        addressParts.push(item.address2);
      }

      if (item.city) {
        addressParts.push(item.city);
      }

      if (item.state && item.state.state) {
        addressParts.push(item.state.state);
      }

      if (item.zip) {
        zipParts.push(item.zip);
      }

      if(item.zip_ext){
        zipParts.push(item.zip_ext);
      }

      if(zipParts.length > 0){
        addressParts.push(zipParts.join('-'));
      }

      return addressParts.join(', ');
    }
  }
};
</script>
