<template>
  <v-layout wrap column>
    <div class="title mt-10">
      {{ subcommittee.fullname }} Subcommittee members:
      <span v-if="selectedLegislators && selectedLegislators.length"
        >({{ selectedLegislators.length }})</span
      >
    </div>
    <v-autocomplete
      v-model="selectedLegislators"
      :items="legislatorsWithDefaultMembership"
      outlined
      dense
      color="blue-grey lighten-2"
      label="Select legislators"
      item-text="fullname"
      multiple
      return-object
      :disabled="loading"
      :loading="loading"
      :search-input.sync="searchInputFull"
      @change="searchInputFull = ''"
      hide-details
      :menu-props="{ maxHeight: 'calc(100% - 320px)' }"
    >
      <template v-slot:selection="{ item }"> </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar class="avatar_image">
            <img :src="getThumbImage(data.item.official_image)" />
          </v-list-item-avatar>
          <v-icon v-if="data.item.lame_duck" color="#EECB23"
            >fa-solid fa-duck</v-icon
          >
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.fullname }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <div
      class="mb-2 pt-1"
      v-if="selectedLegislators && selectedLegislators.length"
    >
      <div class="d-flex flex-wrap">
        <div v-for="(item, index) of selectedLegislators" class="mr-2">
          <v-menu
            v-model="displaySelected[getIndex(item.id, selectedLegislators)]"
            :key="index"
            bottom
            left
            :close-on-content-click="false"
            origin="top left"
            content-class="role_card"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                :color="getSubLegislatorChipColor(item)"
                class="mt-2"
                :dark="getSubLegislatorChipColor(item) != 'default'"
                pill
                v-on="on"
                :style="colorsLegislatorsParties(item)"
              >
                <v-avatar left>
                  <v-img
                    class="avatar_image"
                    :src="getThumbImage(item.official_image)"
                  ></v-img>
                </v-avatar>
                <vip-chip
                  v-if="item.vip"
                  class="mr-2 mb-1"
                  :legislator="item"
                  :show="'chip_code'"
                >
                </vip-chip>
                {{ item.fullname }}
                <v-tooltip bottom v-if="item.lame_duck">
                  <template v-slot:activator="{ on }">
                    <a v-on="on">
                      <v-icon color="#EECB23" size="16" class="ml-1"
                        >fa-solid fa-duck</v-icon
                      >
                    </a>
                  </template>
                  <span>Lame Duck</span>
                </v-tooltip>
              </v-chip>
            </template>
            <v-card width="400" height="auto" class="d-flex">
              <v-flex class="legislators_list__card--img">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @click="
                        removeLegislator(
                          getIndex(item.id, selectedLegislators),
                          selectedLegislators,
                          item
                        )
                      "
                      class="legislators_remove_btn"
                      color="error"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove from {{ subcommittee.fullname }}</span>
                </v-tooltip>
                <v-img
                  class="avatar_image"
                  :src="getThumbImageMedium(item.official_image)"
                ></v-img>
              </v-flex>
              <v-flex>
                <v-flex class="d-flex justify-space-between">
                  <h2 class="mt-2">{{ item.fullname }}</h2>
                </v-flex>
                <v-flex>
                  <span>Membership:</span>
                  <v-radio-group
                    v-model="item.fullRole"
                    class="mt-2"
                    hide-details
                  >
                    <v-flex class="radio_group_role mb-3">
                      <v-radio
                        v-for="membership in memberships"
                        :key="membership.id"
                        color="primary"
                        :label="membership.name"
                        :value="membership"
                        class="mb-2"
                      ></v-radio>
                    </v-flex>
                  </v-radio-group>
                </v-flex>
              </v-flex>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import swal from "sweetalert2";
import _ from "lodash";
import VipChip from "@/components/legislators/vip-setting/vip-chip";

export default {
  name: "AssignLegislatorsInSubcommittees",
  components: {
    "vip-chip": VipChip,
  },
  props: {
    subcommittee: {
      type: Object,
      required: true,
    },
    legislators: {
      type: Array,
      required: false,
      default: () => [],
    },
    memberships: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLegislators: this.value || [],
      searchInputFull: "",
      displaySelected: {},
      loading: false,
    };
  },
  computed: {
    legislatorsWithDefaultMembership() {
      return _.cloneDeep(this.legislators).map(legislator => {
        const fallback = this.memberships.find(membership => membership.id === 4)
        if (fallback) {
          legislator.fullRole = fallback
        }
        return legislator
      })
    },
  },
  watch: {
    selectedLegislators: {
      handler(newVal) {
        this.$emit("onChangeLegislators", {
          subcommittee: this.subcommittee,
          legislators: newVal,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.setSelectedLegislators();
    //this.addFullRoleLegislator();
  },
  methods: {
    setSelectedLegislators() {
      this.selectedLegislators = _.cloneDeep(this.legislatorsWithDefaultMembership).filter((legislator) => {
        return (
          legislator.legislator_committees &&
          legislator.legislator_committees.some((committee) => {
            return committee.committee_id === this.subcommittee.id;
          })
        );
      }).map(legislator => {
        const legislatorCommittee = legislator.legislator_committees.find(legislatorCommittee => legislatorCommittee.committee_id === this.subcommittee.id)

        if(legislatorCommittee && legislatorCommittee.membership_states.length > 0){
          const membership = legislatorCommittee.membership_states[0].membership;

          legislator.fullRole = this.memberships.find(m => m.id === membership.id)
        }
        return legislator;
      })
    },
    getIndex(id, array) {
      let index = array.findIndex((item) => item.id === id);
      return index;
    },
    getSubLegislatorChipColor(item) {
      const legislator = this.selectedLegislators.find((l) => l.id == item.id);
      return legislator.fullRole && legislator.fullRole.color ? legislator.fullRole.color : "#ccc";
    },
    getSelectedLegislator(item) {
      return this.selectedLegislators.find(
        (legislator) => item.id == legislator.id
      );
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${item.fullname} from ${this.subcommittee.fullname}?`,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#9E9E9E",
        confirmButtonText: "&#10003 Remove",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          array.splice(index, 1);
        }
      });
    },
    formatLegislatorsList() {
      if (!this.subcommittee || !this.subcommittee.legislator_committees)
        return;

      this.selectedLegislators.forEach((legislator) => {
        this.subcommittee.legislator_committees.forEach(
          (legislatorCommittees) => {
            if (
              legislator.id == legislatorCommittees.legislator_id &&
              legislatorCommittees.membership_states
            ) {
              legislator.memberships =
                legislatorCommittees.membership_states.map(
                  (membershipState) => {
                    const membership =
                      membershipState && membershipState.membership
                        ? membershipState.membership
                        : {};
                    const pivot =
                      membershipState && membershipState.pivot
                        ? membershipState.pivot
                        : {};
                    return { ...membership, subcommittee: 0 };
                  }
                );
            }
          }
        );
      });
    },
    addFullRoleLegislator() {
      this.selectedLegislators.forEach(legislator => {
        const legislatorCommittee = legislator.legislator_committees.find(legislatorCommittee => legislatorCommittee.committee_id === this.subcommittee.id)

        if(legislatorCommittee && legislatorCommittee.membership_states.length > 0){
          const membership = legislatorCommittee.membership_states[0].membership;

          legislator.fullRole = this.memberships.find(m => m.id === membership.id)
        }
        return legislator;
      })

      // this.subcommittee.legislator_committees.forEach((legislatorCommittee) => {
      //   if (
      //     legislatorCommittee.membership_states &&
      //     legislatorCommittee.membership_states.length > 0
      //   ) {
      //     legislatorCommittee.membership_states.forEach((membershipState) => {
      //       if (legislatorCommittee.committee_id === this.subcommittee.id) {
      //         console.log("eachLC", {
      //           legislatorCommittee,
      //         });
      //         const legislator = this.selectedLegislators.find(
      //           (legislator) =>
      //             legislator.id === legislatorCommittee.legislator_id
      //         );
      //         if (legislator) {
      //           legislator.fullRole = membershipState.membership;
      //         }
      //         console.log("legislator", {
      //           legislator,
      //         });
      //       }
      //     });
      //   }
      // });
      // this.selectedLegislators.forEach(legislator => {
      //   if (!legislator.fullRole && legislator.memberships) {
      //     const found = legislator.memberships.find(m => !m.pivot)
      //     if (found && found.membership) {
      //       legislator.fullRole = found.membership
      //     }
      //   }
      // })

      // this.legislators.forEach(item => {
      //   item.memberships = []
      //   this.selectedLegislators.forEach(legislator => {
      //     if (item.id === legislator.id && legislator.fullRole) {
      //       item.memberships = [legislator.fullRole]
      //     }
      //   })
      // })
    },
  },
};
</script>
