<template>
    <!-- Start active clients popup  -->
    <div class="text-center">
            <v-dialog
              persistent
              v-model="isOpenDialog"
              width="525"
            >
              <v-card>
                <v-card-title class="d-flex flex-column align-start">
 Select your login...
 <v-card-subtitle style="text-align: left; word-break: normal; overflow-wrap: break-word;">
 You are an active Key Person for more than 1 active account.
 </v-card-subtitle>
</v-card-title>
                <v-card-text class="pt-5">
  <div :class="isDarkTheme ? 'card-dark' : 'card-light'">
    <v-layout row class="ma-auto">
      <v-flex
  class="register-page_person mr-0"
  col-sm-6 col-md-6 col-12
  v-for="(activeClient, index) in clients"
  :key="activeClient.id"
>
  <v-btn
    @click="onSelectActiveClient(activeClient)"
    class="client-card-button d-flex flex-column justify-center align-center"
    style="min-height: 120px; display: flex; flex-direction: column;"
  ><!-- adam could not get this to work, need to fix "activeClient && activeClient.avatar_url"
    <v-avatar size="70" style="border: 2px solid #3d4977;">
      <img :src="activeClient && activeClient.avatar_url || '/images/client-avatar.png'" />
    </v-avatar>
    -->
    <div class="mt-2 text-center">
      <span class="text-h4">{{ activeClient && activeClient.assoc_abbrev }}</span>
    </div>
  </v-btn>
</v-flex>
    </v-layout>
  </div>
</v-card-text>
  <v-divider></v-divider>
  <v-card-actions>
    <v-btn
      color="primary"
      text
      @click="onCloseClickHandler"
    >
      Back to Login
    </v-btn>
  </v-card-actions>
</v-card>
            </v-dialog>
          </div>
      <!-- End active clients popup  -->
  </template>
  
  <script>
    export default {
      data() {
        return {
          isOpenDialog: false,
          clients: [],
          selectedActiveClient: null,
        }
      },
      methods:{
        onCloseClickHandler(){
          this.isOpenDialog = false;
          this.$emit('onCloseClick');
        },
        async toggle(clients, isModalOpen = true) {
            this.isOpenDialog = isModalOpen;
            this.clients = clients;
        },
        onSelectActiveClient(client) {
           this.$emit('onSelectClient', client);
           this.isOpenDialog = false;
        },
      }
    }
  </script>
  <style>
.vertical-btn {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
}
</style>