<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-form ref="newClientForm" @submit.prevent="onAddClient()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Client</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-layout wrap>
            <v-flex xs12 class="mb-2 px-2">
              <div class="d-inline-block">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar size="80" class="cursor-pointer" @click="$refs.avatarUploader.click()" v-on="on">
                      <img :src="newClient.avatar_url || '/images/client-avatar.png'" />
                    </v-avatar>
                  </template>
                  <span>Click above to upload this client's association avatar.</span>
                </v-tooltip>
              </div>
              <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Active"
                v-model="newClient.active"
              ></v-checkbox>
            </v-flex>
            <v-flex xs8 class="px-2">
              <v-select
                v-if="isSuperAdmin"
                label="Plan"
                color="primary"
                item-text="name"
                item-value="id"
                :items="tariffList"
                :rules="tariffRules"
                v-model="newClient.tariff_id"
              ></v-select>
            </v-flex>
            <v-flex xs8 class="px-2">
              <v-text-field label="Association*" :rules="associationRules" v-model="newClient.association" @input="error = ''"></v-text-field>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-text-field label="Association Abbr*" :rules="associationAbbrevRules" v-model="newClient.assoc_abbrev"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Profession" v-model="newClient.profession"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Use KP Dashboard Emails in Messages?"
                v-model="newClient.is_forward_email_required"
                @change="newClient.reply_to_email = '';newClient.mailgun_email = '';newClient.forward_email = '';newClient.mailgun_domain = '';"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="!newClient.is_forward_email_required">
              <v-text-field label="Reply To Email*" v-model="newClient.reply_to_email"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="newClient.is_forward_email_required">
              <v-text-field label="Mailgun Email*" v-model="newClient.mailgun_email"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="newClient.is_forward_email_required">
              <v-text-field label="Forward Email" v-model="newClient.forward_email"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="newClient.is_forward_email_required">
              <v-text-field label="Mailgun Domain (no @ symbol!)" v-model="newClient.mailgun_domain"></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Forward reply texts to personal phone number?"
                v-model="newClient.is_forward_text_required"
                @change="newClient.forward_number = '';"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 md12 class="px-2">
              <v-select label="Time zone"
              :items="getTimeZonesList()"
              v-model="newClient.timezone"
              :rules="timezoneRules"
              ></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field label="Twilio SID" v-model="newClient.twilio_sid"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-text-field label="Twilio Number" v-mask="'(###) ###-####'" :rules="phoneNumberRules" v-model="newClient.twilio_number"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-text-field label="Forward Number*" v-mask="'(###) ###-####'" :rules="phoneNumberRules" v-model="newClient.forward_number" v-if="newClient.is_forward_text_required"></v-text-field>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-select label="Country*" item-text="state" item-value="id" :items="countryList" 
              :rules="countryRules" v-model="newClient.country_id" @change="onCountryChange"></v-select>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-switch
                  color="primary"
                  v-model="newClient.is_use_state"
                  :label="`State Client`"
                  @change="onStateClientChange"
              ></v-switch>
            </v-flex>
            <v-flex xs6 class="px-2" v-if="showState">
              <v-select label="State*" item-text="state" item-value="id" :items="onlyStatesList" 
              :rules="stateRules" v-model="newClient.state_id" @change="onSelectState"></v-select>
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-select label="How many KPs?" :items="kpsList" v-model="newClient.uses_kps"></v-select>
            </v-flex>
            <v-flex v-if="isSuperAdmin" xs4 class="px-2">
              <v-checkbox
                color="primary"
                :disabled="!(newClient.tariff_id > 1)"
                label="Uses regions?"
                v-model="newClient.uses_regions"
              ></v-checkbox>
            </v-flex>
            <v-switch
              color="primary"
              v-model="newClient.send_mailgun_notifications"
              :label="`Send email notifications to${newClient.uses_regions ? ' Coordinators and ' : ' '}selected Admins when Message received`"
            ></v-switch>
            <v-flex xs12 md6 class="px-2">
              <v-switch
                  color="primary"
                  v-model="newClient.uses_approval_coordinator_m"
                  :label="`Queue all Coordinator messages for Admin approval?`"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md6 class="px-2">
              <v-switch
                  color="primary"
                  v-model="newClient.uses_approval_kp_m"
                  :label="`Queue all KP messages for Admin approval?`"
              ></v-switch>
            </v-flex>
            <v-switch
                color="primary"
                v-model="newClient.uses_assignment_kp_c"
                :label="`Allow Coordinators to search for, assign, and remove KPs`"
            ></v-switch>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses coordinators?"
                v-model="newClient.uses_coordinators"
                @change="newClient.show_all = false"
              ></v-checkbox>
            </v-flex>
            <v-flex xs8 class="px-2">
              <v-checkbox
                color="primary"
                v-if="newClient.uses_coordinators"
                label="Show All Task Rows for Coordinators"
                v-model="newClient.show_all"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses DOB?"
                v-model="newClient.uses_dob"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses grad year?"
                v-model="newClient.uses_grad_year"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2">
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 2nd work?"
                v-model="newClient.uses_2nd_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 3rd work?"
                v-model="newClient.uses_3rd_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2">
              <v-checkbox
                color="primary"
                label="Uses 4th work?"
                v-model="newClient.uses_4th_work"
              ></v-checkbox>
            </v-flex>
            <v-flex xs class="px-2">
              <v-switch
                color="primary"
                v-model="newClient.profile_update_notifications"
                label="Profile Update Notifications"
                @change="newClient.embed_personal_data = false"
              ></v-switch>
            </v-flex>
            <v-flex xs class="px-2" v-if="newClient.profile_update_notifications">
              <div class="d-flex align-center">
                <v-checkbox
                  color="primary"
                  v-model="newClient.embed_personal_data"
                  label="Embed Personal Data"
                ></v-checkbox>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon size="20" v-on="on">help</v-icon>
                    </template>
                    <span>If you have security concerns about personal information in an email, then check this box to hide personal data in the notification email</span>
                  </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2" v-if="newClient.profile_update_notifications">
              <div class="d-flex align-center">
                <v-text-field label="Notification Email Address" v-model="newClient.notification_email"></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <div class="d-flex align-center">
                <v-text-field label="Formal House" v-model="newClient.formal_house"></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" v-on="on">help</v-icon>
                  </template>
                  <span>How you would like to address House legislators, placeholder = “example, Delegate/Representative/Del./Rep.</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <div class="d-flex align-center">
                <v-text-field label="Formal Senate" v-model="newClient.formal_senate"></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" v-on="on">help</v-icon>
                  </template>
                  <span>How you would like to address Senators, placeholder = “example, Senator/Sen.</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-alert type="info" :value="true" outlined class="mb-2">
                Please select the Committee(s) that are important to your cause.
                These will be used throughout your Dashboard - all others will be hidden, but available if you change your mind later.
              </v-alert>
              <v-autocomplete
                multiple
                chips
                label="House Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="houseCommitteeList"
                v-model="newClient.house_committees"
                v-if="selectedState && selectedState.house_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Senate Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="senateCommitteeList"
                v-model="newClient.senate_committees"
                v-if="selectedState && selectedState.sen_districts">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-autocomplete
                multiple
                chips
                label="Joint Committees To Track"
                item-text="fullname"
                item-value="id"
                :items="jointCommitteeList"
                v-model="newClient.joint_committees"
                v-if="selectedState && selectedState.has_joint_committees">
                <template v-slot:selection="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        dark
                        close
                        :input-value="props.selected"
                        :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                        class="chip--select-multi"
                        @click:close="props.parent.selectItem(props.item)"
                      >
                        {{ props.item.shortname }}
                      </v-chip>
                    </template>
                    <span>{{ props.item.fullname }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import StateService from '@/services/StateService'

export default {
  name: 'NewClientModal',
  props: ['stateList', 'refresh', 'tariffList'],
  mixins: [ClientService, CloudinaryService, StateService],
  data () {
    return {
      isModalOpen: false,
      committeeList: [],
      newClient: {},
      error: '',
      isBusy: false,
      onlyStatesList: {},
      countryList: {},
      showState: false,
    }
  },
  computed: {
    associationRules () {
      return [
        v => !!v || 'Association is required.',
      ]
    },
    tariffRules () {
      return [
        v => !!v || 'Tariff is required.',
      ]
    },
    timezoneRules () {
      return [
        v => !!v || 'Time Zone is required.',
      ]
    },
    associationAbbrevRules () {
      return [
        v => !!v || 'Association abbr is required.',
      ]
    },
    countryRules () {
      return [
        v => !!v || 'Country is required.',
      ]
    },
    stateRules () {
      return [
        v => !this.showState || !!v || 'State is required.',
      ]
    },
    kpsList () {
      return [0, 1, 2, 3, 4, 5]
    },
    houseCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 0)
    },
    senateCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 1)
    },
    jointCommitteeList () {
      return this.committeeList.filter(committee => committee.type === 2)
    },
    selectedState () {
      return this.stateList.find(state => 
        state.id === (this.showState ? this.newClient.state_id : this.newClient.country_id)
      );
    },
  },
  methods: {
    toggle () {
      this.isModalOpen = true
      this.error = ''
      this.countryList= this.stateList.filter(s => s.is_country == 1).sort((a, b) => a.id - b.id);
      if(this.$refs.newClientForm) {
        this.$refs.newClientForm.reset()
      }
      this.newClient = {
        house_committees: [],
        senate_committees: [],
        joint_committees: [],
      }
    },
    onUploadAvatar () {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.newClient.avatar_url = res.secure_url
          this.newClient = {...this.newClient}
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    async onSelectState () {
      if (!this.newClient.state_id) return
      this.committeeList = (await this.getStateCommittees(this.newClient.state_id)).data
      this.newClient.house_committees = []
      this.newClient.senate_committees = []
      this.newClient.joint_committees = []
      this.newClient = { ...this.newClient }
    },
    async onCountryChange(selectedCountry){
      this.onlyStatesList = this.stateList.filter(s => s.state_of == selectedCountry);
      if (!this.newClient.country_id) return
      this.committeeList = (await this.getStateCommittees(this.newClient.country_id)).data
      this.newClient.house_committees = []
      this.newClient.senate_committees = []
      this.newClient.joint_committees = []
      this.newClient = { ...this.newClient }
    },
    async onStateClientChange(status){
      if(status == false){
        this.newClient.state_id = null;
        if (!this.newClient.country_id) return
        this.committeeList = (await this.getStateCommittees(this.newClient.country_id)).data
        this.newClient.house_committees = []
        this.newClient.senate_committees = []
        this.newClient.joint_committees = []
        this.newClient = { ...this.newClient }
      }
      this.showState = status;
    },
    onAddClient () {
      if (!this.$refs.newClientForm.validate()) return
      this.isBusy = true
      if(this.showState != true){
        this.newClient.state_id = this.newClient.country_id;
      }
      this.createClient(this.newClient)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New client has been created.', 'Success!')
          this.refresh()
          this.isModalOpen = false
        })
        .catch((err) => {
          this.isBusy = false
          // this.error = 'Something went wrong!'
          this.handleError(err)
        })
    }
  }
}
</script>
