<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <transition-group
      tag="ul"
      class="legislators_list"
      name="fade-in"
      :css="false"
      v-on:before-enter="cardBeforeEnter"
      v-on:enter="cardEnter"
      v-on:leave="cardLeave"
      appear
    >
    <li v-for="(item, index) in filteredList" :key="item.id" :data-index="index + item.id" class="legislators_list__item">
      <v-card class="legislators_list__card" v-if="item.legislator">
          <v-app-bar
            dense
            elevation="1"
            max-height="48"
          >
            <v-app-bar-title>{{ item.legislator.nickname }} {{ item.legislator.lastname }} 
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" x-small outlined :disabled="item.legislator.abdicated_at !== null && !isSuperAdmin" color="primary" @click="edit(item.legislator)">
                    <v-icon small>manage_accounts</v-icon>
                  </v-btn>
                </template>
                <span>Manage {{ item.legislator.nickname }} {{ item.legislator.lastname }}</span>
              </v-tooltip>
            </v-app-bar-title>
               <v-spacer></v-spacer>
              <div class="">
            <div class="">
              <v-btn
                v-if="isLegSupport"
                color="primary"
                x-small
              >
                {{ item.district }} <span v-if="item.is_federal && !isSenateDistrict(item.district, item.state_abbrev)">({{ item.state_abbrev }})</span>
              </v-btn>
              <v-btn
                v-else
                color="primary"
                x-small
                @click="districtModal(item)"
              >
                {{ item.district }} <span v-if="item.is_federal && !isSenateDistrict(item.district, item.state_abbrev)">({{ item.state_abbrev }})</span>
              </v-btn>
            </div>
          </div>
      </v-app-bar>
        <div class="d-flex">
          <div class="legislators_list__card--img" style="padding-top: 1px;">
            <img
              v-if="item.legislator.official_image"
              :src="getThumbImageLarge(item.legislator.official_image)"
              :alt="item.legislator.nickname + ' ' + item.legislator.lastname"
              :style="{ 'border-left': `4px solid ${partyColorList[item.legislator.party]}` }"
              @click="detail(item)"
            >
            <img v-else src="/images/member-avatar.jpg" alt="No image" @click="detail(item)">
            <v-flex class="legislators_list__card--img--icons">
              <vip-chip
                v-if="item.legislator.vip"
                :legislator="item.legislator"
                :show="'image_code'"
              >
              </vip-chip>
            </v-flex>
            <v-icon v-if="item.legislator.lame_duck" class="legislators_icon--faded" @click="detail(item)" color="#EECB23">fa-solid fa-duck</v-icon>
            <div
              v-if="item.membership"
              class="legislators_list__card--label"
              :style="{ background: item.membership.color}"
            >
              <span>
                {{ item.committee.shortname }}
                 <br>{{ item.membership.name }} {{ item.committee.subcommittee >= 1 && client && client.state && client.state.has_subcommittees ? '(sub)' : '' }}
              </span>
            </div>
            <div v-if="item.legislator.abdicated_at" class="legislators_list__card--label is_archived">
              <span>Archived</span>
            </div>
          </div>

          <div class="legislators_list__card--content">
          
          <div class="legislator_card__title">
            <div class="legislator_card__title--name">
              <div
                  v-if="item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_support && !isSuperAdmin"
                  class="legislator_card__title--stars"
              >
                <v-icon
                  v-if="value <= (item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_support)"
                  size="18"
                  color="orange lighten-3"
                  v-for="value in [1, 2, 3, 4, 5]" :key="value"
                >
                  mdi-star
                </v-icon>
              </div>
              <div
                  v-if="!(item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_support) && !isSuperAdmin"
              >
                <span class="text--disabled text--caption">
                  <em>
                    no rating
                  </em>
                </span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center legislator_card__title--buttons legislator_card__title--buttons-top">
              <v-tooltip bottom v-if="!!(item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_notes) && !isSuperAdmin" max-width="250px">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" color="primary" @click="addData(item.legislator)">
                    <v-icon size="28">mdi-file</v-icon>
                  </v-btn>
                </template>
                <span>Public Note: {{ item.legislator.leg_data[0] && item.legislator.leg_data[0].leg_notes }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!!(item.legislator.leg_data[0] && item.legislator.leg_data[0].vip_notes) && isVIP" max-width="250px">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="addData(item.legislator)" v-if="!isSuperAdmin" class="ml-2">
                    <v-icon size="28" color="secondary">mdi-file-lock</v-icon>
                  </v-btn>
                </template>
                <span>Private Note: {{ item.legislator.leg_data[0] && item.legislator.leg_data[0].vip_notes }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="legislator_card__members">
            <div v-if="item.legislator.member_legislators && item.legislator.member_legislators.length">
              <div class="d-flex align-center flex-nowrap" v-for="(data, index) in item.legislator.member_legislators" :key="index">
                <span class="no-line-break align-center caption">
                  <strong>KP{{ data.kp_position }}: </strong>
                  <a @click="memberDetailModal(data.member)">{{ data.member.nickname }} {{ data.member.lastname }}</a>
                  <sup v-if="item.legislator.member_legislators[index].send_copy === 1">CC</sup>
                </span>
                <v-tooltip bottom v-if="data.member.kp_preference !== 'unknown'">
                  <template v-slot:activator="{ on }">
                    <v-avatar class="ml-1" v-on="on" :color="kpPreferenceColor(data.member)" size="10"></v-avatar>
                  </template>
                  <span>{{ kpPreferenceTitle(data.member) }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!!data.notes" max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-1" size="18" v-on="on">note</v-icon>
                  </template>
                  <span>KP Relationship Note: {{ data.notes }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.legislator.member_legislators[index].is_constituent" max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-1" color="success" size="16" v-on="on">fa-light fa-location-check</v-icon>
                  </template>
                  <span>Constituent</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.legislator.member_legislators[index].is_constituent" max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-1" color="error" size="16" v-on="on">fa-light fa-location-xmark</v-icon>
                  </template>
                  <span>Not a Constituent</span>
                </v-tooltip>

                <v-tooltip bottom v-for="(relationship, index) in data.relationships" :key="index" max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-avatar size="18" :color="relationship.color" class="ml-1" v-on="on">
                      <v-icon v-if="relationship.icon == 'phone'" size="14" color="white">{{ relationship.icon }}</v-icon>
                      <v-icon v-else-if="relationship.icon == 'fa-brands fa-twitter'" size="14" color="white">{{ relationship.icon }}</v-icon>
                      <v-icon v-else size="11" class="white--text d-flex align-text-center">{{
                          relationship.icon
                        }}</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{ relationship.name }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="legislators_list__card--actions elevation-3">
          <div class="legislator_card__title--buttons">
            <div class="legislators_list__card--committees">
<!--               
              <v-tooltip bottom v-for="legislatorCommittee in item.legislator.legislator_committees" :key="legislatorCommittee.id">
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    small
                    label
                    text-color="white"
                    :color="legislatorCommittee.committee.color ? legislatorCommittee.committee.color : getColorFromText(legislatorCommittee.committee.shortname)"
                    @click="openCommitteeMembersModal(legislatorCommittee, client)"
                  >
                    {{ legislatorCommittee.committee.shortname }}
                    <v-avatar
                      class="darken-4 ml-1"
                      :color="legislatorCommittee.committee.subcommittee_color ? legislatorCommittee.committee.subcommittee_color : getColorFromText(legislatorCommittee.committee.shortname)"
                      v-if="legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1) && client && client.state && client.state.has_subcommittees"
                    >
                      sub
                    </v-avatar>
                  </v-chip>
                </template>
                <span>{{ legislatorCommittee.committee.fullname }}</span>
              </v-tooltip>
 -->
              <!-- <grid-committee-chip-tooltip 
                v-for="legislatorCommittee in item.legislator.legislator_committees" 
                :key="legislatorCommittee.id"
                :legislatorCommittee="legislatorCommittee" 
                :client="client" 
                @click="openCommitteeMembersModal(legislatorCommittee, client)" 
              /> -->
              <grid-committee-combined-chip-tooltip 
                :legislator-committees="item.legislator.legislator_committees"
                :client="client"
                @click="openCommitteeMembersModal"
              />
            </div>
          </div>
          <v-spacer v-if="isDesktop || isDevice"></v-spacer>
          <div class="legislators_capabilities">
            <!--adam moved legislator edit button to card title 3.15.24 -->
            <v-btn-toggle
            color="primary"
            >
              <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn style="color:#9155fd;max-width:30px;" small v-on="on" :disabled="item.legislator.abdicated_at !== null" @click="openAssignmentModal(item.legislator)">
                KP
                  <v-icon small color="primary">edit</v-icon>
                </v-btn>
              </template>
              <span>KP Assignments</span>
            </v-tooltip>
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn small style="max-width:30px;" v-on="on" @click="$refs.donationModal.open(item.legislator)">
                  <v-icon small color="primary">monetization_on</v-icon>
                </v-btn>
              </template>
              <span>Manage Donations</span>
            </v-tooltip>
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn small style="max-width:30px;" v-on="on" @click="$refs.TimelineComponent.toggle(item.legislator)">
                  <v-icon small color="primary">fa-regular fa-list-timeline</v-icon>
                </v-btn>
              </template>
              <span>Open Timeline</span>
            </v-tooltip>
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn small style="max-width:30px;" v-on="on" @click="$refs.legConnectionsSmallModal.toggle(item.legislator)">
                  <v-icon small color="primary">fa-regular fa-chart-network</v-icon>
                </v-btn>
              </template>
              <span>Connections</span>
            </v-tooltip>
        </v-btn-toggle>
          </div>
        </div>
      </v-card>
      <v-card class="legislators_list__card" v-else>
          <v-app-bar
            dense
            elevation="1"
            max-height="48"
          >
            <v-app-bar-title>Vacant</v-app-bar-title>
               <v-spacer></v-spacer>
              <div class="">
            <div class="">
              <span>District: </span>
              <v-btn
                v-if="isLegSupport"
                color="primary"
                x-small
              >
                {{ item.district }}{{ item.is_federal && !isSenateDistrict(item.district, item.state_abbrev) ? '(' + item.state_abbrev + ')' : '' }}
              </v-btn>
              <v-btn
                v-else
                color="primary"
                x-small
                @click="districtModal(item)"
              >
                {{ item.district }}{{ item.is_federal && !isSenateDistrict(item.district, item.state_abbrev) ? '(' + item.state_abbrev + ')' : '' }}
              </v-btn>
            </div>
          </div>
      </v-app-bar>
        <div class="d-flex">
          <div class="legislators_list__card--img" style="padding-top: 1px;">
            <img src="/images/member-avatar.jpg" alt="No image">
            <v-flex class="legislators_list__card--img--icons">
            </v-flex>
          </div>

          <div class="legislators_list__card--content">
          
          <div class="legislator_card__title">
            <div class="legislator_card__title--name">
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center legislator_card__title--buttons legislator_card__title--buttons-top">
            </div>
          </div>
          <div class="legislator_card__members">
          </div>
        </div>
        </div>

        <div class="legislators_list__card--actions elevation-3">
          <div class="legislator_card__title--buttons">
            <div class="legislators_list__card--committees">
            </div>
          </div>
          <v-spacer v-if="isDesktop || isDevice"></v-spacer>
          <div class="legislators_capabilities" :style="{ width: isDesktop || isDevice ? '' : '80%'}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :disabled="true" :text="isDesktop || isDevice" small color="primary">
                  <v-icon size="20" class="mr-1">edit</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :disabled="true" :text="isDesktop || isDevice" small color="primary">
                  <v-icon size="20" class="mr-1">group_add</v-icon>
                </v-btn>
              </template>
              <span>Assignments</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :text="isDesktop || isDevice" :disabled="true" small color="primary" >
                  <v-icon size="20" class="mr-1">monetization_on</v-icon>
                </v-btn>
              </template>
              <span>Donations</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :text="isDesktop || isDevice" :disabled="true" small color="primary">
                  <v-icon size="18" class="mr-1">fa-thin fa-list-timeline</v-icon>
                </v-btn>
              </template>
              <span>Timeline</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :icon="isDesktop || isDevice" :text="isDesktop || isDevice" :disabled="true" small color="primary">
                  <v-icon size="18" class="mr-1">fa-regular fa-chart-network</v-icon>
                </v-btn>
              </template>
              <span>Connections</span>
            </v-tooltip>
          </div>
        </div>
      </v-card>
    </li>
  </transition-group>
    <timeline-component :clientId="client.id" ref="TimelineComponent"></timeline-component>
    <donation-modal :clientId="client.id" ref="donationModal"></donation-modal>
    <committee-members-modal ref="committeeMembersModal"></committee-members-modal>
    <leg-connections-small-modal :clientId="client.id" ref="legConnectionsSmallModal"></leg-connections-small-modal>
  </div>
</template>

<script>

import donationModal from './donation-modal'
import vipChip from '@/components/legislators/vip-setting/vip-chip'
import TimelineComponent from '@/components/legislators/timeline-modal'
import { partyColorList } from '@/entities/party-color-list'
import committeeMembersModal from "@/components/legislators/committee-members-modal.vue"
import legConnectionsSmallModal from "@/components/legislators/leg-connections-small-modal.vue"
import GridCommitteeChipTooltip from '@/components/legislators/grid-committee-chip-tooltip.vue'
import GridCommitteeCombinedChipTooltip from '@/components/legislators/grid-committee-combined-chip-tooltip.vue'
export default {
    props: {
      list: {
        default: function () {
          return {
            items: [],
            pagination: {
              last_page: 0
            }
          }
        },
        type: Object
      },
      listVacantDistricts: {
        default: function () {
          return []
        },
        type: Array
      },
      client: {
        default: function () {
          return {}
        },
        type: Object
      },
      membershipList: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    name: "LegislatorsGrid",
    data() {
      return {
        partyColorList: partyColorList(),
      }
    },
  computed: {
      filteredList() {
        // Here skip ids so that only Chair and Ranking members show the corner css flag
        // Id "1" is Chair
        // Id "2" is vice Chair
        // Id "6" is 1st vice chair 
        // Id "5" is 2nd vicechair
        // Id "3" is ranking 
        // Id "7" is vice ranking
        // Id "4" is member only
        const committeeMembersIds = [2, 4, 5, 6, 7];

        return this.list.items.map(item => {
          if(item.legislator && item.legislator.legislator_committees && item.legislator.legislator_committees.length > 0) {
            let membership = {}
            let committee = {}
            item.legislator.legislator_committees.forEach(legislatorCommittee => {
              legislatorCommittee.membership_states.forEach(membershipState => {
                if (!committeeMembersIds.includes(membershipState.committee_membership_id)) {
                  membership = membershipState.membership
                  committee = {...legislatorCommittee.committee, subcommittee: membershipState.pivot.subcommittee }
                }
              })
            })
            return {
              ...item,
              membership: membership,
              committee: committee
            }
          } else {
            return item
          }
        })
      }
    },
    methods: {
      isSenateDistrict(district, state){
        return district.toLowerCase().startsWith(state.toLowerCase())
      },
      cardBeforeEnter(el) {
        el.style.opacity = 0;
        el.style.transform = 'scale(90%)';
        el.style.height = 0;
      },
      cardEnter(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, height: '100%', scale: '100%' },
            { complete: done }
          );
        }, delay);
      },
      cardLeave(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 0, height: 0, scale: '90%' },
            { complete: done }
          );
        }, delay);
      },
      //modals
      edit (item) {
        this.$emit('edit', item)
      },
      addData (item) {
        this.$emit('add', item)
      },
      districtModal(item) {
        this.$emit('districtOpen', item)
      },
      detail(item) {
        this.$emit('detail', item)
      },
      memberDetailModal(item) {
        this.$emit('memberModalOpen', item)
      },
      openAssignmentModal(item) {
        this.$emit('assignment', item)
      },
      openCommitteeMembersModal(committee, client) {
        this.$refs.committeeMembersModal.toggle(committee, client)
      },
    },
    components: {
      donationModal,
      'leg-connections-small-modal': legConnectionsSmallModal,
      'committee-members-modal': committeeMembersModal,
      'vip-chip': vipChip,
      'timeline-component': TimelineComponent,
      'grid-committee-chip-tooltip': GridCommitteeChipTooltip,
      'grid-committee-combined-chip-tooltip': GridCommitteeCombinedChipTooltip
    }
  }
</script>