<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-chip
        v-on="on"
        small
        label
        text-color="white"
        :color="
          legislatorCommittee.committee.color
            ? legislatorCommittee.committee.color
            : getColorFromText(legislatorCommittee.committee.shortname)
        "
        @click="$emit('click', legislatorCommittee, client)"
      >
        {{ chipName }}
        <v-avatar
          class="darken-4 ml-1"
          :color="
            legislatorCommittee.committee.subcommittee_color
              ? legislatorCommittee.committee.subcommittee_color
              : getColorFromText(legislatorCommittee.committee.shortname)
          "
          v-if="
            !isSubcommittee &&
            legislatorCommittee.membership_states.some(
              (membershipState) => membershipState.pivot.subcommittee === 1
            ) &&
            client &&
            client.state &&
            client.state.has_subcommittees
          "
        >
          sub
        </v-avatar>
      </v-chip>
    </template>
    <span>{{ fullTooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "GridCommitteeChipTooltip",
  props: {
    legislatorCommittee: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubcommittee() {
      return (
        this.legislatorCommittee &&
        this.legislatorCommittee.committee &&
        this.legislatorCommittee.committee.parent_id !== null
      );
    },
    chipName() {
      if (!this.legislatorCommittee || !this.legislatorCommittee.committee) {
        return "";
      }

      if (this.isSubcommittee) {
        if (!this.legislatorCommittee.committee.parent) {
          return "";
        }

        return (
          this.legislatorCommittee.committee.parent.shortname +
          `(${this.legislatorCommittee.committee.shortname})`
        );
      }

      return this.legislatorCommittee.committee.shortname;
    },
    fullTooltipText() {
      if (!this.legislatorCommittee || !this.legislatorCommittee.committee) {
        return "";
      }

      if (this.isSubcommittee) {
        if (!this.legislatorCommittee.committee.parent) {
          return "";
        }

        return (
          this.legislatorCommittee.committee.parent.fullname +
          `(${this.legislatorCommittee.committee.shortname})`
        );
      }
      return this.legislatorCommittee.committee.fullname;
    },
  },
};
</script>
