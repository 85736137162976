<template>
  <div class="mt-3">
    <v-expansion-panels v-model="isFilterOpen">
      <v-expansion-panel>
        <v-expansion-panel-header>Filters (Optional)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-flex
              xs12
              sm12
              class="legislators_filter__additional--item_wrap px-3"
            >
              <div>
                <h4 class="mb-0 mt-2">Filters:</h4>
                <v-checkbox
                  color="primary"
                  label="Show Inactive"
                  class="mt-0"
                  hide-details
                  v-model="filter.showInactive"
                ></v-checkbox>
                <v-checkbox
                  color="primary"
                  label="No valid email or cell"
                  class="mt-0"
                  hide-details
                  v-model="filter.showInvalid"
                ></v-checkbox>
                <v-checkbox
                  color="primary"
                  label="Districts not set"
                  class="mt-0"
                  hide-details
                  v-model="filter.districtNotSet"
                ></v-checkbox>
                <v-checkbox
                  color="primary"
                  label="Active KP1"
                  class="mt-0"
                  hide-details
                  v-model="filter.activeKP1"
                ></v-checkbox>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm12
              class="legislators_filter__additional--item_wrap px-3"
            >
              <h4 class="mb-0 mt-2">Kp preference</h4>
              <div v-for="(item, index) in kpPreference" :key="index">
                <v-checkbox
                  color="primary"
                  hide-details
                  class="ma-0"
                  :label="item.title"
                  :input-value="filter.kpPreferenceResults.indexOf(item.value) >= 0"
                  @change="editKpPreferenceResults($event, item.value)"
                ></v-checkbox>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm12
              class="legislators_filter__additional--item_wrap pt-4 pb-4"
            >
            <v-expansion-panels>
              <!-- Regions -->
                <v-expansion-panel v-if="clientRegionsForFilter() && clientRegionsForFilter().length">
                  <v-expansion-panel-header>Filter with Regions</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group v-model="filter.region">
                      <v-radio
                        color="primary"
                        v-for="region in clientRegionsForFilter()"
                        :key="region.title"
                        :label="region.name"
                        :value="region.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
            <v-flex xs12 class="pb-4">
              <v-layout wrap>
                <h4 class="mb-0 mt-2">Legislators:</h4>
                <v-flex xs12 class="px-2">
                  <v-checkbox
                    class="mt-0"
                    color="primary"
                    label="Specific Legislator(s) Only"
                    hide-details
                    v-model="filter.isSpecificLegislatorsOnly"
                    @change="fillLegislator"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs12
                  class="px-2"
                  v-if="!filter.isSpecificLegislatorsOnly"
                >
                  <v-radio-group
                    row
                    v-model="filter.isDistricts"
                    @change="setFilterLegislators"
                  >
                    <v-radio label="House" value="0" color="primary"></v-radio>
                    <v-radio label="Senate" value="1" color="primary"></v-radio>
                    <v-radio label="Joint" value="2" color="primary"
                     v-if="client && client.state && client.state.has_joint_committees"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 class="px-2" v-if="!filter.isSpecificLegislatorsOnly && filter.isDistricts !== null">
                  <div class="d-flex flex-nowrap" v-for="committee in selectedCommitteeList" :key="committee.id">
                    <v-flex xs12 sm7>
                      <v-checkbox
                        color="primary"
                        @change="onSelectCommittee(committee)"
                        :input-value="filter.selectedCommitteeIdList.includes(committee.id)"
                        :label="committee.label"
                        :disabled="filter.selectedSubCommitteeIdList.includes(committee.id) || isBusy"
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm5
                            v-if="client && client.state && client.state.has_subcommittees">
                      <!-- <v-checkbox
                        color="primary"
                        :disabled="(!filter.selectedSubCommitteeIdList.includes(committee.id) && !filter.selectedCommitteeIdList.includes(committee.id)) || isBusy"
                        :input-value="filter.selectedSubCommitteeIdList.includes(committee.id)"
                        @change="onSelectCommittee(committee, true)"
                        label="Subcommittee only"
                        hide-details
                      ></v-checkbox> -->
                      <v-select
                            v-model="filterSubcommittees[committee.id]"
                            :items="committee.subcommittees"
                            item-text="fullname"
                            item-value="id"
                            label="Select Subcommittee"
                            persistent-hint
                            single-line
                            clearable
                            :disabled="(!filter.selectedCommitteeIdList.includes(committee.id) && !filterSubcommittees[committee.id]) || isBusy"
                            @change="onSelectSubcommitteeHandler($event, committee.id)"
                          ></v-select>
                    </v-flex>
                  </div>
                </v-flex>

                <v-flex xs12 class="mt-3 px-2">
                  <h3>{{ 'Send to members of these legislators' }}</h3>
                  <v-autocomplete
                    v-model="filter.legislators"
                    :items="sortLegislatorsList"
                    outlined
                    dense
                    class="mt-2"
                    color="blue-grey lighten-2"
                    label="Select legislators"
                    item-text="fullname"
                    item-value="id"
                    :label="'Select Legislator(s) (' + (filter.legislators || []).length + ')'"
                    multiple
                    return-object
                    :search-input.sync="searchInputFull"
                    hide-details
                    :menu-props="{maxHeight: 'calc(100% - 320px)'}"
                    @change="searchInputFull=''"
                  >
                    <template v-slot:selection="{ item }">
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar class="avatar_image">
                          <img
                            :src="data.item.official_image ? getThumbImage(data.item.official_image) : '/images/member-avatar.png'"
                            :alt="data.item.fullname">
                        </v-list-item-avatar>
                        <vip-chip
                          v-if="data.item.vip"
                          class="mr-2 mb-1"
                          :legislator="data.item"
                          :show="'chip_code'"
                        >
                        </vip-chip>
                        <v-list-item-content>
                          <v-list-item-title v-html="">
                            {{ `${data.item.fullname} (${data.item.district.district})` }}
                            <v-tooltip bottom v-if="data.item.lame_duck">
                              <template v-slot:activator="{ on }">
                                <a v-on="on">
                                  <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                                </a>
                              </template>
                              <span>Lame Duck</span>
                            </v-tooltip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-layout wrap :class="isMobile ? 'flex-column' : ''">
                    <v-flex xs12>
                      <h3>Include:</h3>
                      <div class="mb-2 pt-1" v-if="filter.legislators && filter.legislators.length">
                        <div class="d-flex flex-wrap">
                          <div v-for="item of filter.legislators" :key="item.id" class="mr-2">
                            <v-menu
                              v-model="displaySelected[getIndex(item.id, filter.legislators)]"
                              bottom
                              left
                              origin="top left"
                              content-class="role_card"
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  close
                                  class="mt-2"
                                  small
                                  pill
                                  v-on="on"
                                  :style=colorsLegislatorsParties(item,legislators)
                                  @click:close="removeLegislator(getIndex(item.id, filter.legislators), filter.legislators, item)"
                                >
                                  <v-avatar max-width="24" max-height="24" left>
                                    <v-img width="24" height="24" class="avatar_image"
                                           :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                  </v-avatar>
                                  <vip-chip
                                    v-if="item.vip"
                                    class="mr-2 mb-1"
                                    :legislator="item"
                                    :show="'chip_code'"
                                  >
                                  </vip-chip>
                                  {{ item.fullname }}
                                </v-chip>
                              </template>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 v-if="filter.exceptLegislators && filter.exceptLegislators.length">
                      <h3>Except:</h3>
                      <div class="mb-2 pt-1">
                        <div class="d-flex flex-wrap">
                          <div v-for="item of filter.exceptLegislators" :key="item.id" class="mr-2">
                            <v-menu
                              v-model="displayExceptSelected[getIndex(item.id, filter.exceptLegislators)]"
                              bottom
                              left
                              origin="top left"
                              content-class="role_card"
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  close
                                  class="mt-2"
                                  pill
                                  small
                                  v-on="on"
                                  :style=colorsLegislatorsParties(item,legislators)
                                  @click:close="removeExceptLegislator(getIndex(item.id, filter.exceptLegislators), filter.exceptLegislators)"
                                >
                                  <v-avatar max-width="24" max-height="24" left>
                                    <v-img width="24" height="24" class="avatar_image"
                                           :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                  </v-avatar>
                                  <vip-chip
                                    v-if="item.vip"
                                    class="mr-2 mb-1"
                                    :legislator="item"
                                    :show="'chip_code'"
                                  >
                                  </vip-chip>
                                  {{ item.fullname }}
                                </v-chip>
                              </template>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              v-if="filter.customFields && filter.customFields.length"
              v-for="(item, index) in filter.customFields"
              :key="index"
              xs12
              class="legislators_filter__additional--item_wrap px-3"
            >
              <div v-if="item.type == 'text'" class="mb-4">
                <h4 class="my-2">{{ item.name }}</h4>
                <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
              </div>
              <div v-if="item.type == 'checkbox' && item.metadata && item.metadata.length">
                <h4 class="my-2">{{ item.name }}</h4>
                <v-radio-group
                  v-for="(data, index) in item.metadata"
                  :key="index"
                  v-model="data.type"
                  :label="data.name"
                  hide-details
                  row
                >
                  <v-radio
                    color="primary"
                    v-for="(label, key) in checkboxLabels"
                    :key="key"
                    :label="label.name"
                    :value="label.value"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div v-if="item.type == 'toggle'">
                <h4 class="my-2">{{ item.name }}</h4>
                <v-checkbox
                  color="primary"
                  :label="item.name"
                  class="mt-0"
                  v-model="item.value"
                  hide-details
                ></v-checkbox>
              </div>
              <div v-if="item.type == 'dropdown' && item.metadata && item.metadata.length">
                <h4 class="my-2">{{ item.name }}</h4>
                <v-checkbox
                  color="primary"
                  v-for="(data, index) in item.metadata"
                  :key="index"
                  hide-details
                  :label="data.name"
                  class="mt-0"
                  v-model="data.checked"
                >
                </v-checkbox>
              </div>
            </v-flex>
          </v-layout>
          <div class="d-flex mt-2">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="clearFilter" :disabled="isBusy" small>Clear Filter</v-btn>
            <v-btn color="primary" text @click="refreshMembers" :disabled="isBusy" small>Apply Filter</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import VipChip from "@/components/legislators/vip-setting/vip-chip";
import swal from "sweetalert2";
import ClientService from "@/services/ClientService";
import CustomFieldsFilter from "@/mixins/custom-fields-filter";

export default {
  name: 'AdvocatesFilter',
  mixins: [CustomFieldsFilter, ClientService],
  components: {
    'vip-chip': VipChip,
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    legislators: {
      type: Array,
      default: () => {
        return []
      }
    },
    committeeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    filter: {
      type: Object,
      default: () => {
        return {
          activeKP1: false,
          commiteeTypeId: null,
          committees: [],
          customFields: [],
          districtNotSet: false,
          exceptLegislators: [],
          isDistricts: null,
          isSpecificLegislatorsOnly: true,
          isVerified: null,
          kpPreferenceResults: [],
          legislators: [],
          region: null,
          search: "",
          selectedCommitteeIdList: [],
          selectedSubCommitteeIdList: [],
          showInactive: false,
          showInvalid: false,
          subcommitee: false
        };
      }
    },
    clientRegions: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      filterSubcommittees: {},
      displaySelected: {},
      displayExceptSelected: [],
      isFilterOpen: false,
      isBusy: false,
      searchInputFull: '',
      checkboxLabels: [
        {value: 'default', name: 'All'},
        {value: 1, name: 'Yes'},
        {value: 0, name: 'No'},
      ],
    }
  },
  computed: {
    filterSubcommitteesIds(){
      const subcommitteesIds = []
      for (const key in this.filterSubcommittees) {
        if (Object.prototype.hasOwnProperty.call(this.filterSubcommittees, key)) {
          const id = this.filterSubcommittees[key];
          if(!id){
            continue;
          }
          subcommitteesIds.push(id)
        }
      }
      return subcommitteesIds
    },
    selectedCommitteeList() {
      let selectedCommitteeList = []
      if (this.filter.isDistricts === '0') {
        selectedCommitteeList = [...this.committeeList
          .filter(committee => committee.type === 0 && (this.client.house_committees || [])
            .includes(committee.id))
        ]
      }

      if (this.filter.isDistricts === '1') {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
            .filter(committee => committee.type === 1 && (this.client.senate_committees || [])
              .includes(committee.id))
        ]
      }

      if (this.filter.isDistricts === '2') {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
            .filter(committee => committee.type === 2 && (this.client.joint_committees || [])
              .includes(committee.id))
        ]
      }
      const committeeTypeList = ['H', 'S', 'J']

      selectedCommitteeList.forEach((committee) => {
        committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
        committee.isSubCommittee = false
      })

      return selectedCommitteeList
    },
    sortLegislatorsList() {
      const house = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      const senate = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      const joint = this.legislators.filter(legislator => !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'))
      return [
        ...this.checkAndSortArray(house, 'House'),
        ...this.checkAndSortArray(senate, 'Senate'),
        ...this.checkAndSortArray(joint, 'Joint'),
      ]
    },
  },
  methods: {
    onSelectSubcommitteeHandler(subcommitteeId, parentCommitteeId) {
      if(subcommitteeId){
        this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, parentCommitteeId)
      }
      this.onFilterLegislators()
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({divider: true})
        array.unshift({header: name})
      }
      return array
    },
    editKpPreferenceResults(event, value) {
      if (event) {
        this.filter.kpPreferenceResults.push(value)
      } else {
        const index = this.filter.kpPreferenceResults.indexOf(value)
        if (index >= 0) {
          this.filter.kpPreferenceResults.splice(index, 1)
        }
      }
    },
    fillLegislator() {
      if(!this.filter.isSpecificLegislatorsOnly) {
         this.filter.isDistricts = null;
        this.filter.legislators = this.legislators;
      } else {
        this.filter.legislators = [];
      }
    },
    setFilterLegislators() {
      // Reset selected committee and subcommitee 
      this.filter.selectedCommitteeIdList = [];
      this.filter.selectedSubCommitteeIdList = [];

      if (this.filter.isDistricts !== null && this.filter.isDistricts === '0') {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      } else if (this.filter.isDistricts !== null && this.filter.isDistricts === '1') {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      } else if (this.filter.isDistricts !== null && this.filter.isDistricts === '2') {
        this.filter.legislators = this.legislators.filter(legislator => !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'))
      }
    },
    onSelectCommittee(committee, sub = false) {
      if (sub) {
        if (this.filter.selectedSubCommitteeIdList.includes(committee.id)) {
          this.filter.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedSubCommitteeIdList, committee.id)
        } else {
          this.filter.selectedSubCommitteeIdList.push(committee.id)
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        }
      } else {
        if (this.filter.selectedCommitteeIdList.includes(committee.id)) {
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        } else {
          this.filter.selectedCommitteeIdList.push(committee.id)
        }
      }
      this.onFilterLegislators()
    },
    onSelectCommitteeIdList(arr, id) {
      return arr.filter(_committeeId => _committeeId !== id)
    },
    onFilterLegislators() {
      this.loading = true
      this.filter.legislators = this.legislators.filter((legislator) => {
        if (this.filter.selectedCommitteeIdList.length === 0 && this.filter.selectedSubCommitteeIdList.length === 0 && this.filterSubcommitteesIds.length === 0) {
          if (this.filter.isDistricts === "0") {
              return legislator.district.district[0] === 'H';
          } else if (this.filter.isDistricts === '1') {
              return legislator.district.district[0] === 'S';
          } else if (this.filter.isDistricts === '2') {
              return !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S');
          }
        }

        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

        const subCommitteeIds = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const subFilter = this.filter.selectedSubCommitteeIdList.find(sub => subCommitteeIds.includes(sub))
        if (subFilter) return true

        const committeeId = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const mainFilter = [
          ...this.filter.selectedCommitteeIdList,
          ...this.filterSubcommitteesIds
        ].find(committee => committeeId.includes((committee)))
        if (mainFilter) return true

        if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
      })
      this.loading = false
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${item.fullname}?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.filter.exceptLegislators.push(item)
          array.splice(index, 1)
        }
      })
    },
    removeExceptLegislator(index, array) {
      array.splice(index, 1)
    },
    clearFilter() {
      this.filter.showInactive = null
      this.filter.showInvalid = null
      this.filter.districtNotSet = null
      this.filter.activeKP1 = null
      this.filter.kpPreferenceResults = []
      this.filter.legislators = []
      this.filter.exceptLegislators = []
      this.filter.region = null
      this.loadCustomFields(this.client.id).then(() => {
        this.refreshMembers()
      })
    },
    refreshMembers() {
      this.$emit('refreshAdvocates', this.filter)
      this.isFilterOpen = false
    },
    clientRegionsForFilter() {
        return this.clientRegions && this.clientRegions.length ?
          [{ name: 'Show All', id: '' }, ...this.clientRegions] : []
      }
  }
}
</script>


