import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.committeesWhereHasNoSubcommittee),function(committee){return _c(VTooltip,{key:committee.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({attrs:{"small":"","label":"","text-color":"white","color":committee.color
            ? committee.color
            : _vm.getColorFromText(committee.shortname)},on:{"click":function($event){_vm.$emit('click', _vm.getLegislatorCommitteeByCommittee(committee), _vm.client)}}},on),[_vm._v(" "+_vm._s(committee.shortname)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(committee.fullname))])])}),_vm._l((_vm.committeesWhereHasSingleSubcommittee),function(committee){return _c(VMenu,{key:committee.id,attrs:{"open-on-hover":"","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g({attrs:{"small":"","label":"","text-color":"white","color":committee.color
            ? committee.color
            : _vm.getColorFromText(committee.shortname),"attrs":attrs},on:{"click":function($event){_vm.$emit('click', _vm.getLegislatorCommitteeByCommittee(committee), _vm.client)}}},on),[_vm._v(" "+_vm._s(committee.parent.shortname)+" ("+_vm._s(committee.shortname)+") ")])]}}],null,true)},[_c(VCard,{staticClass:"px-1"},[_vm._v(" "+_vm._s(committee.parent.fullname)+": "),_c('br'),_vm._v(" "+_vm._s(committee.fullname)+" ")])],1)}),_vm._l((_vm.committeesWhereHasMultipleSubcommittee),function(parentCommittee){return _c(VMenu,{key:parentCommittee.id * 2,attrs:{"open-on-hover":"","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g({attrs:{"small":"","label":"","text-color":"white","color":parentCommittee.color
            ? parentCommittee.color
            : _vm.getColorFromText(parentCommittee.shortname),"attrs":attrs}},on),[_vm._v(" "+_vm._s(parentCommittee.shortname)+" ("+_vm._s(parentCommittee.children.length)+" subs) ")])]}}],null,true)},[_c(VCard,{staticClass:"px-1"},[_vm._v(" "+_vm._s(parentCommittee.fullname)+": "),_c('br'),_vm._l((parentCommittee.children),function(subCommittee){return _c('div',{key:subCommittee.id},[_c(VChip,{attrs:{"small":"","label":"","text-color":"white","color":subCommittee.color
              ? subCommittee.color
              : _vm.getColorFromText(subCommittee.shortname)},on:{"click":function($event){_vm.$emit(
              'click',
              _vm.getLegislatorCommitteeByCommittee(subCommittee),
              _vm.client
            )}}},[_vm._v(" "+_vm._s(subCommittee.fullname)+" ")])],1)})],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }