<template>
  <v-layout wrap>
    <v-flex v-if="!isEditing && !isAdding">
      <div class="d-flex align-center mb-4">
        <h3>Staff</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addStaff">Add Staff</v-btn>
      </div>
      <staff-list
        :staffs="staffs"
        :pagination="pagination"
        :loading="loading"
        :editable="true"
        @edit-staff="editStaff"
        @update-pagination="handlePaginationUpdate"

      />
    </v-flex>

    <v-flex v-if="isEditing || isAdding">
      <div class="d-flex align-center mb-4" 
      style="position: sticky; top: 0; z-index: 1000; padding: 8px 16px;" :style="{ backgroundColor: $vuetify.theme.dark ? '#312d4b' : '#fff' }">
      <v-icon small @click="cancelEdit">arrow_back</v-icon>
      <h3 class="ml-2">{{ isAdding ? 'Add New Staff' : 'Edit Staff' }}</h3>
      <v-spacer></v-spacer>
      <v-btn outlined color="secondary" @click="cancelEdit">Cancel</v-btn>
      <v-btn color="primary" @click="doneEditing" class="ml-2">Save Staffer</v-btn>
    </div>

      <v-form ref="editForm" v-model="isFormValid">
        <v-layout wrap>
          <!-- Nickname Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Nickname" v-model="editData.firstname" :rules="[rules.required]" outlined dense />
          </v-flex>

          <!-- Lastname Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Lastname" v-model="editData.lastname" :rules="[rules.required]" outlined dense />
          </v-flex>

          <!-- Titles Multi-Select Dropdown with Add New Option -->
          <v-flex sm6 class="px-2">
            <v-select
              label="Titles"
              v-model="editData.roles"
              :items="localRoleList"
              item-text="role_name"
              outlined
              dense
              multiple
              return-object
              :menu-props="{ bottom: true, offsetY: true }"
            >
            <template v-slot:selection="{ item }">
              <v-chip
                v-if="item && item.role_name && item !== 'All'"
                :key="item.role_name"
                small
                close
                @click:close="removeRole(item)"
              >
                {{ item.role_name }}
              </v-chip>
            </template>
              <template v-slot:append-item>
                <v-list-item @click="showNewRoleInput = true" v-if="!showNewRoleInput">
                  <v-list-item-title class="text-primary">Add New Title</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showNewRoleInput">
                  <v-text-field label="Enter new title" dense v-model="newRole" @keyup.enter="addNewRole" />
                  <v-btn small text color="primary" @click="addNewRole">Add</v-btn>
                </v-list-item>
              </template>
            </v-select>
          </v-flex>

          <!-- Official Email Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Official Email" v-model="editData.email_official" :rules="[rules.email]" outlined dense />
          </v-flex>

          <!-- Personal Email Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Personal Email" v-model="editData.email_personal" :rules="[rules.email]" outlined dense />
          </v-flex>

          <!-- Office Phone Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Office Phone" v-model="editData.office_phone" outlined dense />
          </v-flex>

          <!-- Cell Field -->
          <v-flex sm6 class="px-2">
            <v-text-field label="Cell" v-model="editData.cell" outlined dense />
          </v-flex>

          <!-- Checkboxes for On Leave and Active Status -->
          <v-flex sm6 class="px-2">
            <v-row>
              <v-col cols="6">
                <v-checkbox v-model="editData.is_on_leave" label="On Leave" dense />
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="editData.active" label="Active" dense />
              </v-col>
            </v-row>
          </v-flex>

          <!-- Issues Multi-Select Dropdown with Add New Option -->
          <v-flex sm12 class="px-2">
            <v-select
              label="Issues"
              v-model="editData.issues"
              :items="issueList"
              item-text="issue_name"
              outlined
              dense
              multiple
              return-object
              :menu-props="{ bottom: true, offsetY: true }"
            >
            <template v-slot:selection="{ item }">
              <v-chip
                v-if="item && item.issue_name && item !== 'All'"
                :key="item.issue_name"
                small
                close
                @click:close="removeIssue(item)"
              >
                {{ item.issue_name }}
              </v-chip>
            </template>

              <template v-slot:append-item>
                <v-list-item @click="showNewIssueInput = true" v-if="!showNewIssueInput">
                  <v-list-item-title class="text-primary">Add New Issue</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showNewIssueInput">
                  <v-text-field label="Enter new issue" dense v-model="newIssue" @keyup.enter="addNewIssue" />
                  <v-btn small text color="primary" @click="addNewIssue">Add</v-btn>
                </v-list-item>
              </template>
            </v-select>
          </v-flex>

          <!-- Notes Field -->
          <v-flex sm12 class="px-2">
            <v-textarea label="Notes" v-model="editData.notes" outlined dense />
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import staffList from '@/components/legislators/staff-list';

export default {
  components: {
    'staff-list': staffList
  },
  props: {
    staffs: { type: Array, required: true },
    issueList: { type: Array, required: true },
    roleList: { type: Array, required: true },
    pagination: {
      type: Object,
      required: true,
      default: () => ({
        page: 1,
        itemsPerPage: 5, 
        totalItems: 0, 
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '', // Holds the user's search input
      isEditing: false,
      isAdding: false,
      isFormValid: false,
      showNewIssueInput: false,
      showNewRoleInput: false,
      newIssue: '',
      newRole: '',
      localRoleList: [...this.roleList],
      suppressPaginationUpdate: false,
      editData: {
        firstname: '',
        lastname: '',
        email_official: '',
        email_personal: '',
        office_phone: '',
        cell: '',
        is_on_leave: false,
        active: true,
        notes: '',
        roles: [],
        issues: [],
      },
      staffTableHeaders: [
        { text: '', value: 'actions', align: 'left', sortable: false },
        { text: 'Name', value: 'name' },
        //{ text: 'Titles', value: 'roles' }, removed titles to save space and put them under name (AP Dec1 2024)
        { text: 'Issues', value: 'issues' },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => !value || /.+@.+\..+/.test(value) || 'E-mail must be valid.',
      },
    };
  },
  watch: {
    "editData.roles"(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;


      if ((newVal.includes("All") && !oldVal.includes("All")) || (newVal.includes("All") && this.localRoleList.length > 1 && newVal.length === 1)) {
        this.editData.roles = [...this.localRoleList];
      }
      else if ((newVal.includes("All") && newVal.length < this.localRoleList.length) || (newVal.length < this.localRoleList.length - 1) || (newVal.length !== this.localRoleList.length - 1 && oldVal.includes("All") && !newVal.includes("All"))) {
        this.editData.roles = newVal.filter(role => role !== "All");
      } else if ((oldVal.length > 0 && newVal.length > 0) && newVal.length === this.localRoleList.length - 1 && !newVal.includes("All") && !oldVal.includes("All")) {
        this.editData.roles = ["All", ...newVal];
      } else if (newVal.length === this.localRoleList.length - 1 && oldVal.includes("All") && !newVal.includes("All")){
        this.editData.roles = [];
      }

    },

    "editData.issues"(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;

      if ((newVal.includes("All") && !oldVal.includes("All")) ||
          (newVal.includes("All") && this.issueList.length > 1 && newVal.length === 1)) {
        // Case 1: If "All" is selected or the only item selected, select all issues
        this.editData.issues = [...this.issueList];
      } 
      else if ((newVal.includes("All") && newVal.length < this.issueList.length) || 
              (newVal.length < this.issueList.length - 1) || 
              (newVal.length !== this.issueList.length - 1 && oldVal.includes("All") && !newVal.includes("All"))) {
        // Case 2: If "All" is selected but not all issues are selected, remove "All"
        this.editData.issues = newVal.filter(issue => issue !== "All");
      } 
      else if ((oldVal.length > 0 && newVal.length > 0) && 
              newVal.length === this.issueList.length - 1 &&
              !newVal.includes("All") && 
              !oldVal.includes("All")) {
        // Case 3: If all items except "All" are selected, add "All" to the selection
        this.editData.issues = ["All", ...newVal];
      } 
      else if (newVal.length === this.issueList.length - 1 && 
              oldVal.includes("All") && 
              !newVal.includes("All")) {
        // Case 4: If "All" is manually deselected, clear all issues
        this.editData.issues = [];
      }
    },

    isEditing(newVal) {
      this.$emit("modified-staff", newVal);
    },
    isAdding(newVal) {
      this.$emit("modified-staff", newVal);
    },
  },
  computed: {
    formattedStaffList() {
      return this.staffs.map((item) => ({
        ...item,
        issuesText: this.formatIssues(item.issues),
        showFullIssues: false, // Default to collapsed view
      }));
    },
    sortedStaffList() {
      const query = (this.searchQuery || '').toLowerCase(); // Handle null case safely
      const filteredList = this.formattedStaffList.filter((item) => {
        const name = `${item.firstname} ${item.lastname}`.toLowerCase();
        const issues = item.issuesText.toLowerCase();
        const roles = item.roles.map((role) => role.role_name).join(', ').toLowerCase();
        return (
          name.includes(query) ||
          issues.includes(query) ||
          roles.includes(query)
        );
      });

      // Sort with the specified hierarchy
      return filteredList.sort((a, b) => {
        // Check if "Chief of Staff" is in roles
        const isChiefA = a.roles.some((role) => role.role_name === 'Chief of Staff');
        const isChiefB = b.roles.some((role) => role.role_name === 'Chief of Staff');

        if (isChiefA && !isChiefB) return -1; // A comes first
        if (!isChiefA && isChiefB) return 1;  // B comes first

        // Check if "Health" or "Veterans Affairs" is in issues
        const hasPriorityIssueA = a.issues.some((issue) => 
          issue.issue_name === 'Health' || issue.issue_name === 'Veterans affairs'
        );
        const hasPriorityIssueB = b.issues.some((issue) => 
          issue.issue_name === 'Health' || issue.issue_name === 'Veterans affairs'
        );

        if (hasPriorityIssueA && !hasPriorityIssueB) return -1; // A comes first
        if (!hasPriorityIssueA && hasPriorityIssueB) return 1;  // B comes first

        // Sort remaining rows by issue content length
        const issueLengthA = (a.issuesText || '').length;
        const issueLengthB = (b.issuesText || '').length;

        return issueLengthB - issueLengthA; // longer issues content comes first
      });
    }
  },
  methods: {
    formatIssues(issues) {
      if (!issues || !Array.isArray(issues)) return '';
      return issues
        .map((issue) =>
          ['Health', 'Veterans affairs'].includes(issue.issue_name)
            ? `<strong>${issue.issue_name}</strong>` // Use <strong> for bold
            : issue.issue_name
        )
        .join(', ');
    },
    handlePaginationUpdate({ page, itemsPerPage }) {
      if (this.suppressPaginationUpdate) {
        this.suppressPaginationUpdate = false; // Reset the flag
        return; // Do not emit the event
      }

      if (
        page !== this.pagination.page ||
        itemsPerPage !== this.pagination.itemsPerPage
      ) {
        this.$emit('update-pagination', { page, itemsPerPage });
      } else if (page === 1) {
        // Force the API call when returning to the first page
        this.$emit('update-pagination', { page, itemsPerPage });
      }
    },
    formatTitles(roles) {
      return roles
        .filter(role => role.role_name !== "All")
        .map(role => role.role_name)
        .join(", ");
    },
    addStaff() {
      this.isAdding = true;
      this.isEditing = true;
      this.editData = {
        firstname: '',
        lastname: '',
        email_official: '',
        email_personal: '',
        office_phone: '',
        cell: '',
        is_on_leave: false,
        active: true,
        notes: '',
        roles: [],
        issues: [],
      };
    },
    editStaff(item) {
      this.isEditing = true;
      this.isAdding = false;
      this.editData = { ...item };
    },
    doneEditing() {
      if (this.$refs.editForm.validate()) {
        // Emit the updated data when editing is done
        if (this.isAdding) {
          this.$emit('add-staff', { ...this.editData });
        } else if (this.isEditing) {
          this.$emit('update-staff', { data: { ...this.editData } });
        }
        this.resetForm();
      }
    },
    cancelEdit() {
      this.resetForm();
    },
    resetForm() {
      this.suppressPaginationUpdate = true;
      this.isEditing = false;
      this.isAdding = false;
      this.editData = {
        firstname: '',
        lastname: '',
        email_official: '',
        email_personal: '',
        office_phone: '',
        cell: '',
        is_on_leave: false,
        active: true,
        notes: '',
        roles: [],
        issues: [],
      };
      this.showNewIssueInput = false;
      this.showNewRoleInput = false;
      this.newRole = '';
      this.isFormValid = false;
      if (this.$refs.editForm) this.$refs.editForm.resetValidation();
    },
    addNewIssue() {
      const trimmedIssue = this.newIssue.trim();
      if (!trimmedIssue) return;

      const newIssueItem = { id: 0, issue_name: trimmedIssue };

      // Add to issueList if it doesn't exist
      if (!this.issueList) {
        this.issueList = ["All"];
      }
      if (!this.issueList.some(issue => issue.issue_name === trimmedIssue)) {
        this.issueList.push(newIssueItem);
      }

      // Add to editData.issues if it doesn't exist
      if (this.editData && this.editData.issues && !this.editData.issues.some(issue => issue.issue_name === trimmedIssue)) {
        this.editData.issues.push(newIssueItem);
      } else {
        this.editData.issues.push(newIssueItem);
      }

      // Reset input and close new issue field
      this.newIssue = '';
      this.showNewIssueInput = false;
    },

    addNewRole() {
      const trimmedRole = this.newRole.trim();
      if (!trimmedRole) return;

      const newRoleItem = { id: 0, role_name: trimmedRole };
      
      // Add to localRoleList if it doesn't exist
      if (!this.localRoleList) {
        this.localRoleList = ["All"];
      }
      if (!this.localRoleList.some(role => role.role_name === trimmedRole)) {
        this.localRoleList.push(newRoleItem);
      }
      
      // Add to editData.roles if it doesn't exist
      if (!this.editData.roles.some(role => role.role_name === trimmedRole)) {
        this.editData.roles.push(newRoleItem);
      }

      // Reset input and close new title field
      this.newRole = '';
      this.showNewRoleInput = false;
    },

    removeIssue(item) {
      this.editData.issues = this.editData.issues.filter(issue => issue.issue_name !== item.issue_name);
    },
    removeRole(item) {
      this.editData.roles = this.editData.roles.filter(role => role.role_name !== item.role_name);
    }
  },
  watch: {
    isEditing(newVal) {
      this.$emit('editing-staff', newVal);
    }
  }
};
</script>

<style>
.text-smaller {
  font-size: 0.85em; /* Adjust the size relative to the parent element for roles/titles */
  color: gray;    
}
</style>

