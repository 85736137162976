<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg class="pa-0">
    <v-card>
      <v-card-text>
        <v-layout wrap>
          <v-flex class="d-flex">
            <h1>Advocacy Messages</h1>
          </v-flex>
          <v-flex class="d-flex">
            <div class="text-caption">
            Please open and send Advocacy Message(s) before deadline.
            </div>
          </v-flex>
        </v-layout>

        <v-data-table
          :loading="isLoading"
          class="elevation-1"
          :headers="assignedTaskTableHeader"
          :items="assignedTaskList"
          disable-pagination
          disable-sort
        >
          <template v-slot:item.created_at="{ item }">
            <div>{{ formatDate(item.created_at) }}</div>
          </template>

          <template v-slot:item.deadline="{ item }">
            <div :class="getDeadlineClass(item)">{{ item.deadline | dateTimeFormatterWithoutUTC }}</div>
          </template>

          <template v-slot:item.stats="{ item }">
            <div>
              <v-progress-circular
                :rotate="-90"
                :size="60"
                :width="5"
                :value="(item.sent_message_count != null ? item.sent_message_count : 0) * 100 / item.assigned_legislator_count"
                :color="getProgressColor((item.sent_message_count != null ? item.sent_message_count : 0) / item.assigned_legislator_count)"
                @click="$refs.replyStatsModal.toggle(item.id)"
              >
                {{ item.sent_message_count != null ? item.sent_message_count : 0 }} / {{ item.assigned_legislator_count }}
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="warning"
                    @click.stop.prevent="$refs.replyMessageModal.toggle(item)"
                    :disabled="(item.sent_message_count != null ? item.sent_message_count : 0) === item.assigned_legislator_count"
                    v-on="on"
                  >
                    {{ `Send ${item.assigned_legislator_count - item.sent_message_count} more` }}
                  </v-btn>
                </template>
                <span>Send</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No assigned Advocacy Messages found.</p>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <reply-message-modal
      ref="replyMessageModal"
      @refresh="refresh(true)"
      @success="sentAllMessages()"
    ></reply-message-modal>
    <reply-stats-modal ref="replyStatsModal"></reply-stats-modal>
  </v-container>
</template>

<script>
import MemberService from '@/services/MemberService'
import swal from 'sweetalert2'

import replyMessageModal from '@/components/advocacy/reply-message-modal'
import replyStatsModal from '@/components/advocacy/reply-stats-modal'

export default {
  name: 'AssignedTasksPage',
  mixins: [MemberService],
  components: {
    'reply-message-modal': replyMessageModal,
    'reply-stats-modal': replyStatsModal,
  },
  data () {
    return {
      isLoading: false,
      search: '',
      assignedTaskList: [],
      isSwal: true,
      pagination: {
        'sort-by': "['deadline']",
        'sort-desc': "[true]",
        itemsPerPage: 10,
      },
      inactiveMessage: false,
      messageWithPastDeadline: false,
    }
  },
  computed: {
    assignedTaskTableHeader () {
      const tableHeader = [
        { text: 'Date Assigned', value: 'created_at', align: 'left' },
        { text: 'Deadline', value: 'deadline', align: 'left' },
        { text: 'Messages Sent', value: 'stats', align: 'center' },
        { text: '', value: 'action', align: 'center' },
      ]
      return tableHeader
    },
    swalConfig() {
    if (this.inactiveMessage) {
      return {
        type: 'info',
        title: "No Advocacy Messages Found",
        text: "You will be notified if there are more Advocacy Messages to send in the future."
      };
    }

    if (this.messageWithPastDeadline) {
      return {
        type: 'info',
        title: "Thank you for your support,",
        text: "but this Advocacy Message request is now past due and no longer necessary. You will be notified if there are more Advocacy Messages to send in the future."
      };
    }

    return {
      type: 'success',
      title: "All done with advocacy messages!",
      text: ""
    };
  }
},
  mounted () {
    this.refresh(true)
  },
  methods: {
    getDeadlineClass (advocacyMessage) {
      const now = moment().format('YYYY-MM-DD hh:mm')
      const today = moment().format('YYYY-MM-DD')
      const deadline = advocacyMessage.deadline
      if (moment(deadline).format('YYYY-MM-DD') === today) return 'warning--text'
      return deadline <  now ? 'error--text' : 'success--text'
    },
    async refresh (needToggle) {
      this.isLoading = true
      this.getMemberAdvocacyMessages(this.userId)
        .then((response) => {

          this.assignedTaskList = response.data.messages
          this.inactiveMessage = response.data.inactiveMessage
          this.messageWithPastDeadline = response.data.messageWithPastDeadline

          if (!this.assignedTaskList.length && this.isSwal) {
            if (this.isKP) {
              this.swalHaveNotAM()
            } else {
              this.logout()
            }
          }
          if (needToggle) {
            const incompleteTaskList = this.assignedTaskList.filter(task => task.sent_message_count !== task.assigned_legislator_count)
            if (incompleteTaskList.length >= 1) {
              this.isSwal = false
              this.$refs.replyMessageModal.toggle(incompleteTaskList[0])
            }
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.handleError(err)
        })
    },
    swalHaveNotAM() {
  swal({
    type: this.swalConfig.type || 'info',
    title: this.swalConfig.title,
    text: this.swalConfig.text,
    showCancelButton: false,
    confirmButtonColor: '#1bc943',
    confirmButtonText: 'Ok',
    reverseButtons: true,
  }).then(() => {
    if (this.isMember) {
      this.logout()
    }
    this.$router.replace({ name: 'my-tasks-page' })
  })
},
    async sentAllMessages() {
      let assignedTaskList = (await this.getMemberAdvocacyMessages(this.userId)).data
      if (!assignedTaskList.messages.length) {
        swal({
          focusConfirm: false,
          type: 'success',
          title: 'Message Sent!',
          text: `YOU ROCK! Thank you for taking the time to connect with your legislator and help promote ${this.userClient.profession}!`,
          confirmButtonText: 'Close',
        }).then(() => {
          if (this.isMember) {
            this.logout()
          } else {
            this.inactiveMessage = false
            this.messageWithPastDeadline = false
            this.swalHaveNotAM()
          }
        })
      }
    }
  }
}
</script>
