<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-md style="max-width: 1600px">
    <v-layout row wrap>
      <v-flex xs12 sm6 md3>
        <v-select
          label="Session"
          hide-details
          outlined
          dense
          item-text="name"
          item-value="id"
          :items="sessionList"
          v-model="selectedSessionId"
          @input="refreshPage">
        </v-select>
      </v-flex>
    </v-layout>
    <v-layout v-if="progressCircle" class="align-center column pa-5">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
      {{ 'Loading your Home page...' }}
    </v-layout>
    <v-alert
      v-if="!progressCircle && !(activeSession && activeSession.id)"
      class="text-center"
      type="error"
      outlined
      dense
      text
    >
      No current session found so the most-recent Session was loaded.
      Click <a @click="$refs.editSessionModal.toggle(client, sessionList[sessionList.length - 1])">here</a> to edit last Session or <a @click="$refs.newSessionModal.toggle(client)">here</a> to start a new Session.
    </v-alert>

    <v-layout row wrap v-if="data && !progressCircle" class="pt-3 home-cards-container">
      <v-flex class="pa-0" :lg8="isVoteChart">
        <div class="fill-height d-flex flex-wrap">
          <v-flex xs12 sm6 lg6 v-if="data.assigned_legislators">
            <v-card
              class="fill-height d-flex flex-column"
            >
              <v-card-title>
                Assigned Legislators
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('legislators-page')">
                      <v-icon>fas fa-user-tie</v-icon>
                    </v-btn>
                  </template>
                  <span>Legislators</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center justify-space-between>
                  <v-flex
                    xs6
                    class="text-center progress-container"
                  >
                    <v-progress-circular
                      :rotate="-90"
                      :size="130"
                      :width="15"
                      :value="data.assigned_legislators"
                      :color="
                        data.assigned_legislators <= 25 ? 'error'
                        : data.assigned_legislators <= 74 ? 'warning'
                        : 'success'"
                    >
                      <h1>{{ data.assigned_legislators }}%</h1>
                    </v-progress-circular>
                  </v-flex>
                  <v-flex xs6 class="text-right text-no-wrap" style="vertical-align:bottom;">
                    <h4 class="mb-4"><u>Unassigned:</u></h4>
                    <v-btn class="mb-4" small outlined color="primary" @click="openUnassignedModal('house')">
                      House: {{ data.unassigned_legislators.house }}
                    </v-btn>
                    <br />
                    <v-btn small outlined color="primary" @click="openUnassignedModal('senate')">
                      Senate: {{ data.unassigned_legislators.senate }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6 lg6>
            <v-card
              class="fill-height d-flex flex-column"
            >
              <v-card-title>
                Active KP1s
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('kp-leaderboard-page')">
                      <v-icon>fas fa-medal</v-icon>
                    </v-btn>
                  </template>
                  <span>KP Leaderboard</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center>
                  <v-flex xs6 class="text-center progress-container">
                    <v-progress-circular
                      :rotate="-90"
                      :size="130"
                      :width="15"
                      :value="data.active_kps"
                      :color="
                        data.active_kps <= 25 ? 'error'
                        : data.active_kps <= 74 ? 'warning'
                        : 'success'"
                    >
                      <h1>{{ data.active_kps }}%</h1>
                    </v-progress-circular>
                  </v-flex>
                  <v-flex xs6 class="text-right" style="vertical-align:bottom;">
                    <v-chip label class="mb-4" small dark color="success">
                      Active: {{ data.active_kps_count }}
                    </v-chip>
                    <br />
                    <v-chip label small dark color="error" @click="openInactiveModal()">
                      Inactive: {{ data.inactive_kps_count }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6 lg6>
            <v-card
              class="fill-height d-flex flex-column"
            >
              <v-card-title>
                {{ client.assoc_abbrev }} Advocates
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('members-page')">
                      <v-icon>fas fa-users</v-icon>
                    </v-btn>
                  </template>
                  <span>Go to Advocate Page</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="d-flex flex-1  pb-5">
                <v-layout row align-center>
                  <v-flex xs6 class="text-center">
                    <p
                      class="text-no-wrap members-card-title"
                    >
                      {{ data.active_members }}
                    </p>
                    <span>Active</span>
                  </v-flex>

                  <v-flex xs6 class="text-right">
                    <v-chip class="mb-4" label small color="primary">
                      Unique KP1: {{ data.total_kps }}
                    </v-chip>
                    <br />
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on }">
                        <v-chip class="mb-4"  v-on="on" small label color="error">
                          Ineligible: {{ data.opted_out }}
                        </v-chip>
                      </template>
                      <span>Advocates who either quit, were removed, or expressed no interest in becoming a KP.</span>
                    </v-tooltip>
                    <br/>
                    <v-chip label small dark color="success" @click="$refs.SilencedModal.open()">
                      Silenced: {{ data.total_silence }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6 lg6>
            <v-card
              class="fill-height d-flex flex-column"
            >
              <v-card-title>
                Party Makeup
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('legislators-page')">
                      <v-icon>fas fa-user-tie</v-icon>
                    </v-btn>
                  </template>
                  <span>Go to Legislator Page</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center>
                  <v-flex xs6 class="text-center d-flex justify-center" >
                    <div class="doughnut-container">
                      <doughnut-chart :chart-data="chartData" :options="chartOptions"></doughnut-chart>
                    </div>
                  </v-flex>
                  <v-flex xs6 class="text-right" style="vertical-align:bottom;">
                    <v-chip label class="mb-4" color="info">
                      Total: {{ +data.house + +data.senate }}
                    </v-chip>
                    <br />
                    <v-chip label class="mb-4" small outlined color="info">
                      House: {{ data.house }}
                    </v-chip>
                    <br />
                    <v-chip label small outlined color="info">
                      Senate: {{ data.senate }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6 lg6>
            <v-card
              class="fill-height d-flex flex-column"
            >
              <v-card-title>
                Top KPs
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('kp-leaderboard-page')">
                      <v-icon>fas fa-medal</v-icon>
                    </v-btn>
                  </template>
                  <span>KP Leaderboard</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="d-flex flex-1 px-5 pb-5">
                <v-layout row align-center>
                  <v-flex
                    v-for="(item, index) in topKps" :key="index"
                    class="text-center full-width d-flex flex-nowrap align-center justify-center"
                  >
                    <v-flex xs4>
                      <a class="mr-3 no-line-break color-revert subtitle-2"
                         @click="$refs.MemberDetailModal.toggle(item)"
                      >{{ item.fullname }}</a>
                    </v-flex>
                    <v-flex sx5>
                      <h5>{{ Math.floor(item.percent) }}%</h5>
                      <v-progress-linear
                        :color="
                          item.percent <= 25 ? 'error'
                          : item.percent <= 74 ? 'warning'
                          : 'success'"
                        height="10"
                        :value="item.percent"
                        striped
                      ></v-progress-linear>
                    </v-flex>
                    <v-flex xs3>${{ item.amount_donations }}</v-flex>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
      <v-flex lg4 v-if="isVoteChart">
          <v-card
            class="fill-height d-flex flex-column"
          >
            <v-card-title>
              Votes
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('legislators-page')">
                    <v-icon>fas fa-user-tie</v-icon>
                  </v-btn>
                </template>
                <span>Legislators</span>
              </v-tooltip>
            </v-card-title>


            <v-card-text
              class="px-5 pb-5"
            >
              <v-flex class="d-flex justify-center pt-0">
                <v-btn-toggle
                  v-model="voteChange"
                  mandatory
                >
                  <v-btn
                    color="primary"
                    small
                    :value="true"
                    :disabled="!(data.pVote && data.pVote.length)"
                  >
                    p-Vote
                  </v-btn>
                  <v-btn
                    color="primary"
                    small
                    :value="false"
                    :disabled="!(data.fVote && data.fVote.length)"
                  >
                    F-Vote
                  </v-btn>
                </v-btn-toggle>
              </v-flex>
              <v-flex class="d-flex justify-center flex-wrap pa-0">
                <v-btn
                  color="primary mx-1 mt-1"
                  small
                  value="all"
                  :class="currentCommittee === 'all' ? 'active-button' : ''"
                  @click="filterLegislatorByCommittee('all')"
                >
                  All
                </v-btn>
                <div v-for="committee in committees"
                     :key="committee.id">
                  <v-btn
                    v-if="currentCommittee === committee.shortname || currentCommittee === 'all'"
                    color="primary mx-1 mt-1"
                    small
                    :class="currentCommittee === committee.shortname && !subCommittee ? 'active-button' : ''"
                    @click="filterLegislatorByCommittee(committee.shortname)"
                  >
                    {{ committee.shortname }}
                  </v-btn>
                  <v-btn
                    v-if="currentCommittee === committee.shortname && client && client.state && client.state.has_subcommittees"
                    :class="subCommittee ? 'active-button' : ''"
                    color="primary mx-1 mt-1"
                    small
                    @click="filterLegislatorByCommittee(committee.shortname, true)"
                  >
                    {{ committee.shortname + '(sub)' }}
                  </v-btn>
                </div>
              </v-flex>
              <v-flex class="text-center">
                <bar-chart v-if="voteChange" :chart-data="pVoteChartData" :options="voteChartOptions"></bar-chart>
                <bar-chart v-if="!voteChange && data.fVote && data.fVote.length" :chart-data="fVoteChartData" :options="voteChartOptions"></bar-chart>
              </v-flex>
            </v-card-text>
          </v-card>
      </v-flex>
    </v-layout>

    <v-layout row wrap v-if="!progressCircle" >
      <v-flex xs12 md12 v-if="regions && regions.length">
        <v-card
            class="fill-height"
        >
          <v-card-title>
              Region Progress
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('regions-page')">
                  <v-icon>fa-light fa-grid-dividers</v-icon>
                </v-btn>
              </template>
              <span>Regions/Districts</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>

            <v-data-table
                :loading="isLoading"
                class="elevation-1 pa-2 mt-4"
                :headers="regionsProgressHeader"
                :items="regions"
                sort-by="percent"
                :sort-desc="true"
                hide-default-footer
                disable-pagination
            >
              <template v-slot:item.memberInfo="{ item }">
                <div>
                   <v-avatar v-if="item.member && item.member.id"
                      :color="getColor(item.member && item.member.nickname)"
                      class="justify-center my-1"
                  >
                    <img :src="getThumbImageMedium(item.member && item.member.avatar_url)" v-if="item.member && item.member.avatar_url">
                    <span class="white--text headline" v-else>{{ item.member && item.member.nickname[0] }}</span>
                  </v-avatar>
                  <a v-if="item.member && item.member.id" class="ml-2 no-line-break color-revert subtitle-2"
                     @click="$refs.MemberDetailModal.toggle(item.member)"
                  >
                    {{item.member && item.member.fullname }}
                  </a>
                  <a v-else="" class="ml-2 no-line-break color-revert subtitle-2"> Unassigned </a>
                </div>
              </template>

              <template v-slot:item.name="{ item }">
                <p>{{ item.name }}</p>
              </template>

              <template v-slot:item.complete_tasks="{ item }">
                <p>{{ item.complete_tasks }}/{{ item.total_tasks }}</p>
              </template>

              <template v-slot:item.percent="{ item }">
                <v-progress-linear
                    :value="Math.ceil(item.percent)"
                    :color="
                        item.percent <= 25 ? 'error'
                        : item.percent <= 74 ? 'warning'
                        : 'success'"
                    height="25"
                >
                  <strong>{{ Math.ceil(item.percent) }}%</strong>
                </v-progress-linear>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row wrap v-if="data && !progressCircle">
      <v-flex xs12 md7 v-if="data.session_tasks">
        <v-card
          class="fill-height"
        >
          <v-card-title>
            {{ selectedSession ? selectedSession.name : '' }} Tasks
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('sessions-page')">
                  <v-icon>fas fa-clipboard-list</v-icon>
                </v-btn>
              </template>
              <span>Sessions/Tasks</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>

            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(item, index) in data.session_tasks"
                    :key="index"
                  >
                    <td>
                      <v-layout class="align-center">
                        <i v-if="item.task_type.data_type === 'Boolean'" class="fa-solid fa-square-check fa-2x mr-3" style="color: #9155fd" ></i>
                        <i v-if="item.task_type.data_type === 'Select'" class="fa-solid fa-list-radio fa-2x mr-3" style="color: #90CAF9" ></i>
                        <i v-if="item.task_type.data_type === 'String'" class="fa-solid fa-grid-horizontal fa-2x mr-3" style="color: #FFCC80" ></i>
                        <div>
                          <p :style="{fontWeight: 500, fontSize: $vuetify.breakpoint.smAndDown ? '18px' : '18px'}">{{
                              item.name }}</p>
                          <span style="font-size: 12px">{{ item.tooltip }}</span>
                        </div>
                      </v-layout>
                    </td>
                    <td class="text-center pa-2" style="width: 30%">
                      <h3>{{ Math.floor(item.percent) }}%</h3>
                      <v-progress-linear
                        :color="
                          item.percent <= 25 ? 'error'
                          : item.percent <= 74 ? 'warning'
                          : 'success'"
                        height="10"
                        :value="item.percent"
                        striped
                      ></v-progress-linear>
                    </td>
                    <td>
                      <v-chip
                        v-if="getDeadlineColor(item) == 'success'"
                        color="success"
                        label
                        dark
                        small
                        :style="{fontSize: $vuetify.breakpoint.smAndDown ? '11px' : ''}"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-if="item.deadline" v-on="on">
                              {{ formatDate(item.deadline, 'MM/DD/YY') }}
                            </span>
                            <span v-else v-on="on">
                              {{'no deadline' }}
                            </span>
                          </template>
                          <span>
                            Deadline: {{ formatDate(item.deadline, 'MM/DD/YY') }}
                          </span>
                        </v-tooltip>
                      </v-chip>
                      <v-chip
                        v-if="getDeadlineColor(item) == 'warning'"
                        color="warning"
                        label
                        dark
                        small
                        :style="{fontSize: $vuetify.breakpoint.smAndDown ? '11px' : ''}"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ formatDate(item.deadline, 'MM/DD/YY') }}
                            </span>
                          </template>
                          <span>
                            Deadline: {{ formatDate(item.deadline, 'MM/DD/YY') }}
                          </span>
                        </v-tooltip>
                      </v-chip>
                      <v-chip
                        v-if="getDeadlineColor(item) == 'error'"
                        color="error"
                        label
                        small
                        :style="{fontSize: $vuetify.breakpoint.smAndDown ? '11px' : ''}"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">Past Due</span>
                          </template>
                          <span>
                            Deadline: {{ formatDate(item.deadline, 'MM/DD/YY') }}
                          </span>
                        </v-tooltip>
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 md5 >
        <v-card
          class="fill-height"
          :loading="isLoading"
        >
          <v-card-title class="mb-4">
            Latest Tasks
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('kp-tasks-page')">
                  <v-icon>fas fa-tasks</v-icon>
                </v-btn>
              </template>
              <span>Session Dashboard</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-timeline
              class="latest-tasks-timeline"
              v-if="list && list.length !== 0"
              dense
            >
              <v-timeline-item
                v-for="(taskResults, date) in list"
                :key="date"
                large
              >
                <template v-slot:icon>
                  <v-avatar
                    v-if="!taskResults[0].member.avatar_url"
                    :style="kpPreferenceBorder(taskResults[0].member)"
                    :color="getColor(taskResults[0].member.nickname)"
                    class="justify-center"
                  >
                    <span class="white--text headline">{{ taskResults[0].member.nickname[0] }}</span>
                  </v-avatar>
                  <v-avatar v-else>
                    <img
                      v-if="taskResults[0].member.avatar_url"
                      :src="getThumbImageMedium(taskResults[0].member.avatar_url)"
                      :alt="taskResults[0].member.nickname + ' '+ taskResults[0].member.lastname"
                    />
                  </v-avatar>
                </template>
                <v-expansion-panels
                  :class="taskResults.length <= 1 ? 'pr-6' : ''"
                  :readonly="taskResults.length <= 1"
                  accordion
                  flat
                >
                  <v-expansion-panel
                    class="max-height-expansion-panel"
                  >
                    <v-expansion-panel-header
                      class="px-0 py-1"
                      :hide-actions="taskResults.length <= 1"
                      :class="taskResults.length <= 1 ? 'expansion-panel-header-latest-tasks' : ''"
                    >
                      <v-row class="align-center" justify="space-between">
                        <v-col
                          cols="6"
                        >
                          <a class="mr-1 no-line-break color-revert subtitle-2"
                             @click="$refs.MemberDetailModal.toggle(taskResults[0].member)"
                          >
                            {{ taskResults[0].kp_name }}
                          </a>
                          <div>
                            {{ taskResults[0].task_name }} for {{ getLegislatorSalutation(taskResults[0].legislator) }}
                          </div>
                        </v-col>
                        <v-col
                          class="text-right"
                          cols="6"
                        >
                          {{ date }}
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="max-height-expansion-panel-content pt-2"
                    >
                      <v-row
                        class="py-1"
                        v-for="(task,i) in taskResults"
                        :key="i"
                        v-if="i !== 0"
                      >
                        <div class="mr-4">
                          <v-avatar
                            v-if="!task.member.avatar_url"
                            :style="kpPreferenceBorder(task.member)"
                            :color="getColor(task.member.nickname)"
                            class="justify-center"
                          >
                            <span class="white--text headline">{{ task.member.nickname[0] }}</span>
                          </v-avatar>
                          <v-avatar v-else>
                            <img
                              v-if="task.member.avatar_url"
                              :src="getThumbImageMedium(task.member.avatar_url)"
                              :alt="task.member.nickname + ' '+ task.member.lastname"
                            />
                          </v-avatar>
                        </div>
                        <div>
                          <a class="mr-1 color-revert no-line-break subtitle-2" @click="$refs.MemberDetailModal.toggle(task.member)">
                            {{ task.kp_name }}
                          </a>
                          <div>
                            {{ task.task_name }} for {{ getLegislatorSalutation(task.legislator) }}
                          </div>
                        </div>
                      </v-row>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-flex>

    </v-layout>

    <v-layout row wrap v-if="data && !progressCircle">
      <v-flex xs12 md6 >
        <v-card
          class="fill-height"
        >
          <v-card-title>
            System Actions
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab tile outlined v-on="on" small dark color="primary" @click="goTo('system-actions-page')">
                  <v-icon>fas fa-file-alt</v-icon>
                </v-btn>
              </template>
              <span>System Actions</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <system-actions :clientId="client.id"></system-actions>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 md6 >
        <v-card
          class="fill-height"
        >
          <v-card-title class="mb-4">
            Legislator News
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab tile outlined v-on="on" small dark color="primary">
                  <v-icon>fas fa-newspaper</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-card-title>
          <v-text-field
            v-model="newsSearch"
            prepend-inner-icon="mdi-magnify"
            label="Search by Legislator Name"
            hide-details
            class="mx-2 mt-2 pa-0"
            clearable
          ></v-text-field>
          <v-data-table
            :loading="isLoading"
            class="elevation-1 pa-2 mt-4"
            :headers="legislatorNewsHeader"
            :items="news.items"
            :server-items-length="news.pagination.totalItems"
            :options.sync="news.pagination"
            @update:options="getLegislatorNewsList"
          >
            <template v-slot:item.legislators="{ item }">
              <div class="d-flex align-center flex-wrap" v-if="item.legislators.length <= 3">
                <div style="white-space: nowrap" v-for="(legislator, index) in item.legislators" :key="index">
                  {{ legislator.fullname }}
                  <v-tooltip bottom v-if="legislator.lame_duck">
                    <template v-slot:activator="{ on }">
                      <a v-on="on">
                        <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                      </a>
                    </template>
                    <span>Lame Duck</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex align-center flex-wrap" v-else>
                {{ item.legislators[0].fullname }}
                <v-tooltip bottom v-if="item.legislators[0].lame_duck">
                  <template v-slot:activator="{ on }">
                    <a v-on="on">
                      <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                    </a>
                  </template>
                  <span>Lame Duck</span>
                </v-tooltip>
                {{ item.legislators[1].fullname }}
                <v-tooltip bottom v-if="item.legislators[1].lame_duck">
                  <template v-slot:activator="{ on }">
                    <a v-on="on">
                      <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                    </a>
                  </template>
                  <span>Lame Duck</span>
                </v-tooltip>
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a v-on="on">
                        <v-chip
                          class="my-1"
                          label
                          outlined
                          small
                        >
                          more...
                        </v-chip>
                      </a>
                    </template>
                    <div v-for="(legislator, index) in item.legislators" :key="index">
                      <span v-if="index >=2 && index != (item.legislators.length - 1)">{{ legislator.fullname }}, </span>
                      <span v-if="index == (item.legislators.length - 1)">{{ legislator.fullname }}</span>
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </template>

            <template v-slot:item.title="{ item }">
              <a :href="item.link" target="_blank">{{ item.title }}</a>
            </template>

            <template v-slot:item.published_at="{ item }">
              <div>{{ formatDate(item.published_at) }}</div>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <unassigned-modal ref="UnassignedModal"></unassigned-modal>
    <inactive-modal ref="InactiveModal"></inactive-modal>
    <silenced-modal
      ref="SilencedModal"
      :clientId="client.id"
    ></silenced-modal>
    <member-detail-modal
      ref="MemberDetailModal"
      :selectedClient="client.id"
    ></member-detail-modal>
    <edit-session-modal
      ref="editSessionModal"
      :active-session="lastSession"
      :session-list="sessionList"
      :committee-list="committees"
      @refresh="refresh"
    ></edit-session-modal>
    <new-session-modal 
      ref="newSessionModal" 
      :active-session="activeSession && activeSession.id ? activeSession : null" 
      :session-list="sessionList" 
      :committee-list="committees" 
      :refresh="refresh"
      :legislators="sessionLegislatorsList">
    </new-session-modal>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import SessionService from '@/services/SessionService'
import MemberDetailModal from '@/components/members/member-detail-modal'

import DoughnutChart from '@/components/common/doughnut-chart'
import BarChart from '@/components/common/bar-chart'
import UnassignedModal from '@/components/home/unassigned-modal'
import InactiveModal from '@/components/home/inactive-modal'
import SilencedModal from '@/components/home/silenced-modal'
import SystemActions from '@/components/home/system-actions-component'

import carousel from 'vue-owl-carousel'
import CommitteeService from "@/services/CommitteeService";
import editSessionModal from "@/components/sessions/edit-session-modal";
import newSessionModal from '@/components/sessions/new-session-modal'
import {mapGetters} from "vuex";

export default {
  name: 'HomePage',
  mixins: [ClientService, SessionService, CommitteeService],
  components: {
    'edit-session-modal': editSessionModal,
    'new-session-modal': newSessionModal,
    'member-detail-modal': MemberDetailModal,
    'doughnut-chart': DoughnutChart,
    'bar-chart': BarChart,
    'unassigned-modal': UnassignedModal,
    'inactive-modal': InactiveModal,
    'silenced-modal': SilencedModal,
    'system-actions': SystemActions,
    carousel,
  },
  data () {
    return {
      isLoading: true,
      progressCircle: true,
      activeSession: {},
      sessionList: [],
      legislators: [],
      subCommittee: false,
      legislatorsByCommittee: [],
      voteLegislatorFullname: [],
      committees: [],
      regions: [],
      selectedSessionId: null,
      currentCommittee: 'all',
      newsSearch: '',
      voteChange: true,
      data: null,
      timer: null,
      fVote: {},
      pVote: {},
      topKps: [],
      filter: {
        search: '',
      },
      news: {
        pagination: {
          page: 1,
          itemsPerPage: 5,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: [],
        },
        items: []
      },
      list: [],
      sessionLegislatorsList: [],
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    lastSession() {
      return this.sessionList[this.sessionList.length - 1]
    },
    isVoteChart () {
      return (this.data.pVote && this.data.pVote.length) || (this.data.fVote && this.data.fVote.length)
    },
    selectedSession () {
      if (!this.selectedSessionId) return null
      return this.sessionList.find(session => session.id === this.selectedSessionId)
    },
    sessionTaskHistoryTableHeader () {
      return [
        { text: 'Task', value: 'name'},
        { text: 'Deadline', value: 'deadline'},
      ]
    },
    latestCompletedTaskHistoryTableHeader () {
      return [
        { text: 'KP', value: 'kp_name'},
        { text: 'Task', value: 'task_name'},
        { text: 'Completed', value: 'updated_at'},
      ]
    },
    legislatorNewsHeader() {
      return [
        { value: 'legislators', sortable: false, width: 150},
        { value: 'title', sortable: false},
        { value: 'published_at', sortable: false, width: 150},
      ]
    },
    regionsProgressHeader() {
      return [
        { text: 'Coordinator', value: 'memberInfo'},
        { text: 'Region', value: 'name'},
        { text: 'Completed Tasks', value: 'complete_tasks'},
        { text: 'Progress', value: 'percent'},
      ]
    },
    fVoteChartData() {
      const labels = ['Yea', 'Nay', 'Abstain']
      const data = [
        {
          label: 'Yea',
          fill: true,
          backgroundColor: '#70ad47',
          data: [this.fVote.Yea],
        },
        {
          label: 'Nay',
          fill: true,
          backgroundColor: '#a5a5a5',
          data: [0, this.fVote.Nay],
          hidden: false
        },
        {
          label: 'Abstain',
          fill: true,
          backgroundColor: '#ffc7ce',
          data: [0, 0, this.fVote.Abstain],
          hidden: false
        },
      ]
      return {
        labels: labels,
        datasets: data,
      }
    },
    pVoteChartData() {
      const labels = ['Supporting', 'Unknown/Other', 'Total Opposing']
      const data = [
        {
          label: 'Supporting',
          fill: true,
          backgroundColor: '#70ad47',
          data: [this.pVote.Supporting],
        },
        {
          label: 'Inclined to Support',
          fill: true,
          backgroundColor: '#c6efce',
          data: [this.pVote.IncSupport],
          hidden: false
        },
        {
          label: 'Neutral/Unknown',
          fill: true,
          backgroundColor: '#a5a5a5',
          data: [0, this.pVote.Unknown],
          hidden: false
        },
        {
          label: 'Inclined to Oppose',
          fill: true,
          backgroundColor: '#ffc7ce',
          data: [0, 0, this.pVote.IncOppose],
          hidden: false
        },
        {
          label: 'Opposing',
          fill: true,
          backgroundColor: '#ff5367',
          data: [0, 0, this.pVote.Opposing],
          hidden: false
        }
      ]
      return {
        labels: labels,
        datasets: data,
      }
    },
    voteChartOptions() {
      return {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          boxWidth: 50,
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              this.voteLegislatorFullname.forEach((obj) => {
                if( obj.name === label) {
                  label += ': ' + obj.legislators.join(', \n')
                }
              })
              return label;
            }
          }
        },
        borderWidth: 0,
        scales: {
          xAxes: [
            {
              stacked: true,
              offset: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }
    },
    chartData () {
      const labels = ['Rep', 'Dem']
      const data = [this.data.republicans, this.data.democrats]
      const backgroundColor = [`${this.appTheme.error}8f`, `${this.appTheme.accent}8f`]
      const borderColor = [`${this.appTheme.error}`, `${this.appTheme.accent}`]

      if (this.data.independents > 0) {
        labels.push('Independent')
        data.push(this.data.independents)
        backgroundColor.push(`${this.appTheme.warning}8f`)
        borderColor.push(`${this.appTheme.warning}`)
      }
      if (this.data.libertarians > 0) {
        labels.push('Libertarian')
        data.push(this.data.libertarians)
        backgroundColor.push(`${this.appTheme.warning}8f`)
        borderColor.push(`${this.appTheme.warning}`)
      }
      return {
        labels: labels,
        datasets: [{
          label: `Total Legislators for ${this.client.assoc_abbrev}`,
          data,
          backgroundColor,
          borderColor,
          borderWidth: 1,
        }]
      }
    },
    chartOptions () {
      return {
        responsive: true,
        cutoutPercentage: 78,
        legend: {
          display: false,
          position: 'top',
        },
        scales: {
          xAxes: [{
            display: false
          }]
        }
      }
    },
  },
  async mounted () {
    if (!(this.client && this.client.id)) return
    await this.refresh()
    await this.getLegislatorsList()
  },
  methods: {
    async refreshPage () {
      if (!this.selectedSessionId) return
      this.isLoading = true
      this.data = (await this.getSessionDashboardData(this.selectedSessionId)).data
      this.voteChange = !!(this.data.pVote && this.data.pVote.length)
      this.topKps = this.data['top_kps']
      await this.filterVote()
      await this.getLatestCompletedTasksList()
      this.isLoading = false
    },
    async refresh () {
      this.progressCircle = true
      if (!this.client || !this.client.id) return
      this.isLoading = true
      this.sessionList = (await this.getClientSessions(this.client && this.client.id)).data
      this.activeSession = (await this.getClientActiveSession(this.client && this.client.id)).data
      this.selectedSessionId = this.activeSession? this.activeSession.id : null;
          
          // this.sessionList && this.sessionList.length
          //     ? this.sessionList[this.sessionList.length - 1].id
          //     : null
      this.legislators = (await this.getClientLegislators(this.client && this.client.id)).data
      this.legislatorsByCommittee = this.legislators.map((legislator) => legislator.id)
      this.committees = (await this.getClientCommittees(this.client && this.client.id)).data
      if (this.selectedSessionId) {
        this.regions = (await this.getRegionsProgress(this.selectedSessionId)).data
      }
      await this.refreshPage()
      this.isLoading = false
      this.progressCircle = false
    },
    async getLegislatorsList() {
      let param = {
        order: 'lastname'
      }
      this.sessionLegislatorsList = await this.getClientLegislators(this.client && this.client.id, param).then((response) => {
        return response.data ? response.data : []
      })
    },
    getLegislatorSalutation(legislator) {
      if (legislator.district.district[0] === 'H') {
        return `${this.client.formal_house} ${legislator.fullname}`
      }
      if (legislator.district.district[0] === 'S') {
        return `${this.client.formal_senate} ${legislator.fullname}`
      }
      return legislator.fullname
    },
    getLegislatorNewsList() {
      this.isLoading = true
      let params = {
        page: this.news.pagination.page,
        limit: this.news.pagination.itemsPerPage,
        search: this.newsSearch,
      }
      return this.getLegislatorNew(this.client.id, params).then((response) => {
        this.news.items = response.data.data ? response.data.data : response.data
        this.news.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.news.pagination.current_page = response.data.current_page
        this.news.pagination.per_page = response.data.per_page
        this.news.pagination.last_page = response.data.last_page
        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },
    filterLegislatorByCommittee(committee, subCommittee = false) {
      this.subCommittee = subCommittee
      this.currentCommittee = committee
      if (committee === 'all') {
        this.legislatorsByCommittee = this.legislators.map((legislator) => legislator.id)
      } else {
        this.legislatorsByCommittee = this.legislators.filter((legislator) => {
          let isSave = false
          legislator.legislator_committees.forEach(legislatorCommittee => {
            if (legislatorCommittee.committee.shortname === committee) {
              if (subCommittee) {
                isSave =  legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1)
              } else {
                isSave = true
              }
            }
          })
          return isSave
        }).map((legislator) => legislator.id)
      }

      this.filterVote()
    },
    getLatestCompletedTasksList() {
      this.isLoading = true

      let params = {
        unlimit: true,
      }
      return this.getLatestCompletedTasks(this.selectedSessionId, params).then((response) => {
        this.list = response.data

        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },
    getDeadlineColor (item) {
      const remainingDays = moment.duration(moment(item.deadline).diff(moment())).asDays()
      if (remainingDays < 0) return 'error'
      if (remainingDays > 0 && remainingDays < 30) return 'warning'
      return 'success'
    },
    goTo (rout) {
      this.$router.push({ name: rout }).catch(err => {})
    },
    openUnassignedModal(type) {
      this.$refs.UnassignedModal.open(this.client.id, type)
    },
    openInactiveModal() {
      this.$refs.InactiveModal.open(this.selectedSessionId, this.client.id)
    },
    filterVote() {
      const pVote = this.data.pVote.filter(result => {
        return this.legislatorsByCommittee.includes(result.legislator_id)
      })

      const fVote = this.data.fVote.filter(result => {
        return this.legislatorsByCommittee.includes(result.legislator_id)
      })

      let supporting = pVote.filter((result) => result.result === 'Supporting')
      let incSupport = pVote.filter((result) => result.result === 'Inclined to Support')
      let unknown = pVote.filter((result) => result.result === 'Neutral/Unknown')
      let incOppose = pVote.filter((result) => result.result === 'Inclined to Oppose')
      let opposing = pVote.filter((result) => result.result === 'Opposing')
      let yea = fVote.filter((result) => result.result === 'Yea')
      let nay = fVote.filter((result) => result.result === 'Nay')
      let abstain = fVote.filter((result) => result.result === 'Abstain')

      let supportingLegId = _.clone(supporting).map(taskResult => taskResult.legislator_id)
      let incSupportLegId = _.clone(incSupport).map(taskResult => taskResult.legislator_id)
      let unknownLegId = _.clone(unknown).map(taskResult => taskResult.legislator_id)
      let incOpposeLegId = _.clone(incOppose).map(taskResult => taskResult.legislator_id)
      let opposingLegId = _.clone(opposing).map(taskResult => taskResult.legislator_id)
      let yeaLegId = _.clone(yea).map(taskResult => taskResult.legislator_id)
      let nayLegId = _.clone(nay).map(taskResult => taskResult.legislator_id)
      let abstainLegId = _.clone(abstain).map(taskResult => taskResult.legislator_id)

      this.voteLegislatorFullname = [
        {name: 'Supporting', legislators: this.legislators.filter((leg) => supportingLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Inclined to Support', legislators: this.legislators.filter((leg) => incSupportLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Neutral/Unknown', legislators: this.legislators.filter((leg) => unknownLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Inclined to Oppose', legislators: this.legislators.filter((leg) => incOpposeLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Opposing', legislators: this.legislators.filter((leg) => opposingLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Yea', legislators: this.legislators.filter((leg) => yeaLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Nay', legislators: this.legislators.filter((leg) => nayLegId.includes(leg.id)).map((leg) => leg.fullname)},
        {name: 'Abstain', legislators: this.legislators.filter((leg) => abstainLegId.includes(leg.id)).map((leg) => leg.fullname)},
      ]

      this.pVote = {
        Supporting: supporting.length,
        IncSupport: incSupport.length,
        Unknown: unknown.length,
        IncOppose: incOppose.length,
        Opposing: opposing.length,
      }

      this.fVote = {
        Yea: yea.length,
        Nay: nay.length,
        Abstain: abstain.length,
      }
    },
    update() {
      this.refresh()
    }
  },
  watch: {
    'filter.search'() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.list.pagination.page = 1
        this.getLatestCompletedTasksList()
      }, 500)
    },
    'newsSearch'() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getLegislatorNewsList()
      }, 500)
    },
    'client': function () {
      this.refresh()
    },
  }
}
</script>
