<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Find New Federal Legislators</h1>
        <v-spacer></v-spacer>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn color="primary" class="ml-2" small :loading="isLoading" :disabled="isLoading"  @click="findNewFederalLegislators">
          Find New Federal Legislators
        </v-btn>
        <!--v-btn color="primary" class="ml-2" small :loading="isLoading" :disabled="selected.length === 0" @click="importFederalLegislators">
          Import Selected Legislators
        </!--v-btn-->
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex class="d-flex align-center flex-wrap">
        <v-select
            label="Country"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="countryList"
            v-model="selectedCountryId"
            @input="changeCountry"
            v-if="isSuperAdmin"
        >
        </v-select>
        <v-select
            label="State"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="stateList"
            v-model="selectedStateId"
            @input="refresh"
            v-if="isSuperAdmin"
        >
        </v-select>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-data-table
      v-model="selected"
      :loading="isLoading"
      class="elevation-1"
      :options.sync="pagination"
      :headers="legislatorTableHeader"
      :items="legislatorList"
      item-key="id"
    >
    <template v-slot:item.active_legislator.nickname="{ item }">
        <div class="d-flex align-center mb-1" v-if="item.active_legislator">
          <div class="mr-1 no-line-break error--text">{{ item.active_legislator.nickname }} {{ item.active_legislator.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.active_legislator.official_image)"
              :alt="item.active_legislator.nickname + ' ' + item.active_legislator.lastname"
              v-if="item.active_legislator.official_image"
            />
          </div>
          <div class="ml-4"> <b>Elected:</b>  {{ item.active_legislator.elected_at }}</div>
        </div>
        <div class="d-flex align-center" v-if="item.imported_federal_legislator && (!item.active_legislator || item.active_legislator.ls_member_id != item.imported_federal_legislator.ls_member_id)">
          <div class="mr-1 no-line-break success--text">{{ item.imported_federal_legislator.nickname }} {{ item.imported_federal_legislator.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.imported_federal_legislator.official_image)"
              :alt="item.imported_federal_legislator.nickname + ' ' + item.imported_federal_legislator.lastname"
              v-if="item.imported_federal_legislator.official_image"
            />
          </div>
          <div class="ml-4"> <b>Elected:</b>  {{ item.imported_federal_legislator.elected_at }}</div>
        </div>
      </template>

      <template v-slot:item.state.state="{ item }">
          {{ item.state.state }}
      </template>
      <template v-slot:item.district="{ item }">
          {{ item.district ? item.district : "-" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-card-actions>
              <v-btn small color="error" @click="updateLegislatorAction(item.active_legislator.id, item.imported_federal_legislator.id)" v-if="item.active_legislator && item.imported_federal_legislator && item.active_legislator.ls_member_id == item.imported_federal_legislator.ls_member_id"  >Update</v-btn>
              <v-btn small color="info" @click="replaceLegislatorAction(item.active_legislator.id, item.imported_federal_legislator.id)" v-if="item.active_legislator && item.imported_federal_legislator && item.active_legislator.ls_member_id != item.imported_federal_legislator.ls_member_id">Replace</v-btn>
              <v-btn small color="success" @click="createLegislatorAction(item.imported_federal_legislator.id)" v-if="!item.active_legislator && item.imported_federal_legislator">Import</v-btn>
            </v-card-actions>
        </div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No federal legislator registered yet.</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import { partyColorList } from '@/entities/party-color-list'
import ApiSourcesService from "@/services/ApiSourcesService";

export default {
    name: 'LegislatorsPage',
    mixins: [ClientService, LegislatorService, StateService, ApiSourcesService],
    data() {
      return {
        isLoading: false,
        isImporting: false,
        isIgnoring: false,
        countryList: [],
        stateList: [],
        allStateList: [],
        selectedCountryId: null,
        selectedStateId: null,
        legislatorList: [],
        isUpdate: true,
        selected: [],
        pagination: {
          itemsPerPage: 10,
        },
        partyColorList: partyColorList(),
        apiSourceName: ''
      }
    },
    computed: {
      legislatorTableHeader() {
        return [
          {text: '', value: '', align: 'left', sortable: false},
          {text: 'Name', value: 'active_legislator.nickname', align: 'left'},
          {text: 'State', value: 'state.state', align: 'center'},
          {text: 'District', value: 'district', align: 'center'},
          // {text: 'Party', value: 'party', align: 'center'},
          {text: 'Actions', value: 'actions', sortable: false},
        ]
      },
    },
    async mounted() {
      this.isLoading = true
      if (!this.isSuperAdmin) {
        this.selectedStateId = this.userClient.state_id
      }
      this.stateList = []
      this.allStateList = (await this.getStates()).data
      this.countryList = this.allStateList.filter(state => state.is_country == 1)
      if (this.countryList.length > 0) {
          this.selectedCountryId = this.countryList[0].id;
          this.stateList = this.allStateList.filter(state => state.state_of == this.selectedCountryId)
        }
      this.refresh()
    },
    methods: {
      changeCountry(){
        this.selectedStateId = null
        this.stateList = this.allStateList.filter(state => state.state_of == this.selectedCountryId)
        this.refresh()
      },
      refresh() {
        this.isLoading = true
        this.isSelected = []
        this.selected = []
        this.legislatorList = []
        this.getImportedFederalLegislators({stateId: this.selectedStateId, countryId: this.selectedCountryId}).then(res => {
          this.legislatorList = res.data;
          this.isLoading = false
        })
      },
      findNewFederalLegislators() {
        this.isImporting = true
        swal({
          title: 'Are you sure?',
          html: `
          This action will fetch the federal legislators from the Legistorm API
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.fetchNewFederalLegislators()
            this.onCallToastr('success', "Find New Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      updateLegislatorAction(oldLegislatorId, newLegislatorId) {
        
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will Update selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            const payload = {
              oldLegislatorId: oldLegislatorId,
              newLegislatorId: newLegislatorId,
            }
            this.updateFederalLegislators(payload)
            this.onCallToastr('success', "Update Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      replaceLegislatorAction(oldLegislatorId, newLegislatorId) {
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will Replace selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            const payload = {
              oldLegislatorId: oldLegislatorId,
              newLegislatorId: newLegislatorId,
            }
            this.replaceFederalLegislators(payload)
            this.onCallToastr('success', "Replace Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },
      createLegislatorAction(item) {
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will create selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.createFederalLegislators(item)
            this.onCallToastr('success', "Create Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      importFederalLegislators() {
        this.isImporting = true
        let importedIds = this.selected.map(item => item.imported_federal_legislator.id);

        swal({
          title: 'Are you sure?',
          html: `
          This action will create selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.createMultipleFederalLegislators({importedIds: importedIds})
            this.onCallToastr('success', "Create Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },
    }
  }
</script>
