export default {
    setParentCommittees: (state, parentCommittees) => {
        state.parentCommittees = parentCommittees;
    },
    clearParentCommittees: (state) => {
        state.parentCommittees = [];
    },
    setParentCommitteeIsLoading: (state) => {
        state.parentCommitteeIsLoading = true;
    },
    setParentCommitteeIsNotLoading: (state) => {
        state.parentCommitteeIsLoading = false;
    }
}