<template>
  <v-dialog :max-width="isFederalClient?`700`:`500`" v-model="dialog" persistent scrollable>
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit region' : 'Create region' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
        <v-spacer></v-spacer>
        <v-icon class="ml-3" @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <v-text-field
            v-model="form.name"
            :error-messages="errorMessages('name')"
            label="Region name"
            :counter="25"
        >
        </v-text-field>
        <v-autocomplete
            label="Coordinator"
            clearable
            :items="members"
            v-model="form.member_id"
            :loading="isMembersLoading"
            item-text="nickNameAndLastName"
            item-value="id"
            :error-messages="errorMessages('member_id')"
            @change="dropEmailErrors()"
        >
        </v-autocomplete>
        <div v-if="form.member_id">
          <v-tooltip top v-if="!isUser">
            <template v-slot:activator="{ on }">
              <v-checkbox
                color="primary"
                hide-details
                class="mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox"
                v-model="form.is_send_invitation"
                v-on="on"
                @change="dropEmailErrors()"
              >
                <template v-slot:label>
                  Send Team member account invitation. If unchecked, you will have to do this manually in the Team area.
                </template>
              </v-checkbox>
            </template>
            <span >This will create the Team member account and send the invitation email.</span>
          </v-tooltip>
          <div class="mb-6" v-if="!!isUser">
            <v-icon
              small
              color="success"
              >mdi-check
              </v-icon>
            <span>This Coordinator already has an active Team account.</span>
          </div>
          <v-alert
            dense
            outlined
            type="error"
            v-if="errorMessages('email').length > 0"
          >
            {{ errorMessages('email') }}
          </v-alert>
        </div>
        <template v-if="!isFederalClient">
          <v-autocomplete
            v-model="selectedDistricts"
            :items="disabledDistricts"
            :item-text="item => item.region ? (item.district + ' - ' + item.region.name) : item.district"
            item-value="id"
            label="Districts"
            return-object
            multiple
            chips
            small-chips
            :disabled="disabled"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index == 0">
                <span>{{item.region ? (item.district + ' - ' + item.region.name) : item.district }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >(+{{ selectedDistricts.length - 1 }} districts)</span>
            </template>
          </v-autocomplete>
        </template>
        <template v-else="">
          <v-autocomplete
            v-model="selectedFederalStates"
            :items="federalStateList"
            :item-text="item => getStateName(item)"
            item-value="id"
            label="Select state(s)"
            return-object
            multiple
            :disabled="disabled"
          >
          </v-autocomplete>

          <div v-if="selectedFederalStates.length > 0" v-for="fedState in selectedFederalStates" :key="`fed_state_`+fedState.id" class="mb-2"> 
            <h4 class="mb-2 d-flex">
              {{ fedState.state }}
              <v-checkbox
                  color="primary"
                  label="Select/Unselect All"
                  hide-details
                  class="ml-3 pt-0 mt-0"
                  @change="(isChecked) => onChaneCheckedAllDistrict(isChecked, fedState)"
                ></v-checkbox>
            </h4>
            <v-layout wrap>
              <v-flex xs4 class="px-2" v-for="fedStateDistrict in fedState.districts" :key="`fed_state_district`+fedStateDistrict.id">
                <v-checkbox
                  color="primary"
                  :label="getDistrictName(fedStateDistrict)"
                  hide-details
                  class="mt-0 mb-2"
                  v-model="fedStateDistrict.isSelected"
                  :indeterminate="fedStateDistrict.disabled"
                  :disabled="fedStateDistrict.disabled"
                  @change="(isChecked) => onChaneSelectDistrict(isChecked, fedStateDistrict, fedState)"
                ></v-checkbox>
              </v-flex>                  
            </v-layout>
          </div>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading || isSaveNotAllowed">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import StateService from '@/services/StateService'

  export default {
    name: "RegionsFormModal",
    mixins: [ClientService, StateService],
    props: ['members', 'selectedClientId', 'isFederalClient', 'existRegionsWithDistricts', 'fedStateDistrict'],
    data() {
      return {
        dialog: false,
        errors: {
          email: []
        },
        loading: false,
        isMembersLoading: false,
        districtList: [],
        disabledItems: [],
        selectedDistricts: [],
        selectedFederalStates: [],
        selectedFederalDistricts: [],
        federalStateList: [],
        form: {
          id: '',
          name: '',
          member_id: '',
          districts: [],
          is_send_invitation: false,
        }
      }
    },
    computed: {
      isUser() {
        let member = this.members.find(member => member.id == this.form.member_id)
        if (member) {
          return member.user
        }
      },
      isSaveNotAllowed () {
        return this.form.name.length > 25
      },
      disabledDistricts() {
        const list = this.districtList.map(item => {
          return {
            ...item,
            disabled: item.region_id > 0 && !this.checkAvailableDistrict(item),
            region: this.checkRegionDistrict(item) ? this.checkRegionDistrict(item) : item.region,
          }
        });

        return list;
      },
      disabled() {
        return !this.form.name
      },
      clientId() {
        return this.selectedClientId ? this.selectedClientId : this.userClientId
      }
    },
    watch: {
      selectedFederalStates: function (newValue, oldValue) {

        if(newValue.length !== oldValue.length) {
          newValue.forEach(state => {
            state.districts.forEach(district => {
              if (!('isSelected' in district)) {
                  district.isSelected = false;
              }
            });
          });

          this.selectedFederalStates = newValue;
        }

      }
    },
    methods: {
      async open(id) {
        this.selectedDistricts = []
        this.disabledItems = []

        //Re-fetching federal client's states 
        //If has no states exist this instance variable
        if(this.isFederalClient) {
          this.federalStateList = this.getFormattedData(this.fedStateDistrict, this.existRegionsWithDistricts);
        }

        if (id > 0) {
          await this.getClientRegion(id)
        } else {
          if(!this.isFederalClient) {
            await this.getDistricts()
          }
        }

        this.errors = false
        this.dialog = true
      },
      onChaneSelectDistrict(isChecked, selectedFedDistrict, fedState ) {
        const fedDisttricts = fedState.districts;
        fedState.districts = fedDisttricts.map((item) => {
          if(item.id === selectedFedDistrict.id) { item.isSelected = isChecked; }
          return item;
        });
      },
      onChaneCheckedAllDistrict(isChecked, fedState ) {
        const fedDisttricts = fedState.districts;
        fedState.districts = fedDisttricts.map((item) => {
          if(!item.disabled) { item.isSelected = isChecked; }
          return item;
        });
      },
      checkAvailableDistrict(item) {
        let available
        for (let checked of this.disabledItems) {
          available = item.region_id === checked.pivot.region_id
        }
        return available
      },
      checkRegionDistrict(item) {
        let region
        for (let selected of this.selectedDistricts) {
          if (item.id === selected.id){
            region = { name: this.form.name }
          }
        }
        return region
      },
      async getDistricts() {
        // this.districtList = (await this.getClientDistricts(this.clientId)).data
        this.districtList = (await this.getClientStateDistricts(this.clientId)).data
      },
      async getRegionDistricts() {
        let regionId = this.form.id
        this.disabledItems = (await this.getClientRegionDistricts(this.clientId, regionId)).data.districts
        if(regionId) {
          for(let district of this.disabledItems) {
            this.selectedDistricts.push(district)
          }
        }
      },
      dropEmailErrors() {
        this.errors = { email: [] }
      },
      close() {
        this.form = {
          id: '',
          name: '',
          member_id: '',
          districts: ''
        }
        this.selectedFederalStates = [];
        this.$emit('update')
        this.dialog = false

      },
      save() {
        // this.loading = true
        let data = {}
        let districts = this.selectedDistricts;

        //If here is state client
        if(!this.isFederalClient) {
          this.form.districts = this.selectedDistricts
        } 
        
        if(this.isFederalClient) {
          districts = this.selectedFederalStates.flatMap(state =>
            state.districts.filter(district => district.isSelected === true)
          );
        }

        if(this.form.id) {
          data = {
            id: this.form.id,
            name: this.form.name,
            districts
          }
        } else {
          data = { name: this.form.name, districts}
        }

        this.saveClientRegions(this.clientId, this.form.member_id ? {...this.form, districts} : data).then(()=> {
          this.onCallToastr('success', 'Regions list has been updated.', 'Success!')
          this.close()
          this.loading = false
          this.errors = false
        }, err => {
          this.errors = err.response.data.errors
          this.loading = false
          if(this.errorMessages('email').length > 0) {
            this.onCallToastr('error', this.errorMessages('email'), 'Error!')
          }
        })
      },
      getClientRegion(id) {
        this.loading = true
        this.getClientRegionById(this.clientId, id).then(response => {
          this.form = response.data;
          this.form.is_send_invitation = false
          this.loading = false
          if(this.isFederalClient){
            // Set state and districts for edit region 
            const regionDistricts = response.data && response.data.districts || [];
            this.setSelectedStatesDistricts(regionDistricts);
          }

          this.getDistricts()
          this.getRegionDistricts()
        })
      },
      getStateName(stateItem) {
          let stateName = stateItem.state;
          if(stateItem && stateItem.regions && stateItem.regions.length){
            const stateRegions = stateItem.regions;
            let regionName = stateRegions.map(region => region.name).join(', ');
            stateName += ` (${regionName})`;
          }

          return stateName;
      },
      getDistrictName(districtItem) {
          let districtName = districtItem.district;
          const districtId = districtItem && districtItem.id;
          const hasDistrict = this.form && this.form.districts && this.form.districts.some(district => district.id === districtId);

          //Here skip current editting region name from district name
          if(districtItem && districtItem.region && !hasDistrict){
            districtName += ` (${districtItem.region.name})`;
          }

          return districtName;
      },
      errorMessages(field) {
        return (this.errors && this.errors[field] && this.errors[field].length > 0) ? this.errors[field][0] : []
      },
      //Here set editting region data
      setSelectedStatesDistricts(regionDistricts) {
        const uniqueStateIds = [...new Set(regionDistricts.map(district => district.state_id))];
        const uniqueDistrictIds = [...new Set(regionDistricts.map(district => district.id))];

        this.selectedFederalStates = this.federalStateList.filter(state => {
          if(uniqueStateIds.includes(state.id)){

              const stateDistricts = state && state.districts || [];
              stateDistricts.map((stateDistrict)=>{
                //When matched region's district 
                //then set enable select option
                //If matched then set isSelected
                if(uniqueDistrictIds.includes(stateDistrict.id)){
                  stateDistrict.isSelected = true;
                  stateDistrict.disabled = false;
                } else {
                  stateDistrict.isSelected = false;
                }

                return stateDistrict;
              })

              state.districts = stateDistricts;
              //Here set select option enabled when state is matched
              state.disabled = false;
            return state;
          } else return false;
        })

      },
      // Assuming `federalStateList` and `existRegionsWithDistricts` are defined
      getFormattedData(federalStateList, existRegionsWithDistricts) {
        // Create a map of regions for quick lookup by district ID
        const regionsMap = new Map();
        existRegionsWithDistricts.forEach(region => {
          region.districts.forEach(district => {
            regionsMap.set(district.id, {
              regionId: region.id,
              regionName: region.name
            });
          });
        });

        return federalStateList.map(state => {
            // Process districts for each state
            const districts = state.districts.map(district => {
                const regionInfo = regionsMap.get(district.id);
                return {
                    ...district,
                    region: regionInfo ? { id: regionInfo.regionId, name: regionInfo.regionName } : null,
                    disabled: regionInfo ? true : false
                };
            });
        
            // Create unique regions based on `id`
            const uniqueRegionsMap = new Map();
            districts.forEach(district => {
                if (district.region) {
                    uniqueRegionsMap.set(district.region.id, district.region);
                }
            });
            const regions = Array.from(uniqueRegionsMap.values());

            // Determine if the state is disabled based on all districts being assigned
            const stateDisabled = districts.every(d => d.disabled);

            return {
                id: state.id,
                state: state.state,
                districts: districts,
                regions: regions,
                disabled: stateDisabled
            };
        });
      }
    }
  }
</script>
