<template>
  <v-navigation-drawer
    fixed
    right
    touchless
    width="500"
    v-model="drawer"
    class="elevation-2"
  >
    <v-card flat>
      <v-card-title>
        <span class="title">
          Notifications
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
            size="22"
            width="2"
          ></v-progress-circular>
        </span>
        <v-spacer/>
        <v-icon @click="close">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <div>
        <div class="notifications_head d-flex justify-end" v-if="notificationsList && notificationsList.length">

          <v-btn x-small text @click="markAllAsRead">
            <v-icon size="16" class="mr-1">done_all</v-icon>
            <span>Mark all as read</span>
          </v-btn>
        </div>
        <ul class="notifications_list" v-if="notificationsList && notificationsList.length">
          <li
            v-for="(item, index) in notificationsList"
            :key="index"
            class="notifications_item"
            :class="setCardClass(item)"
          >
            <div v-if="item.data.type == 'ImportLegislators'
            || item.data.type == 'ImportMembers'
            || item.data.type == 'UpdateDistricts'
            || item.data.type ==='SendMail'
            || item.data.type ==='SendScheduleMessages'
            || item.data.type ==='ImportFederalLegislators'
            || item.data.type ==='CreateLegislatorsFromFederal'
            || item.data.type ==='SendAdvocacyMessage'"
            >
              <div class="notifications_item__head">
                <div v-if="item.data" class="notifications_item__indicator d-flex align-center">
                  <v-btn v-if="item.data.status == 'started'" icon x-small :loading="true" color="warning"></v-btn>
                  <v-icon v-if="item.data.status == 'finished'" size="18" color="success">check</v-icon>
                  <v-icon v-if="item.data.status == 'failed'" size="18" color="error">check</v-icon>
                </div>
                <a @click="openDetailsModal(item)">
                  <span>{{ getNotificationTitle(item) }}</span>
                </a>
                <div class="notifications_item__close" @click="markAsRead(item)" v-if="item.data.status !== 'started'">
                  <v-btn x-small icon>
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="notifications_item__body">
                <div>
                  <div class="notifications_item__body--item notifications_item__body--item_status">
                    <span>Status: </span>
                    <strong>{{ constants.status[item.data.status] }}</strong>
                  </div>
                  <div v-if="item.data.duration" class="notifications_item__body--item">
                    <span>Duration: </span>
                    <strong>{{ item.data.duration }}</strong>
                  </div>
                </div>
                <div>
                  <div class="notifications_item__body--item">
                    <span>Started at: </span>
                    <strong>{{ item.data.startedAt }}</strong>
                  </div>
                  <div v-if="item.data.finishedAt" class="notifications_item__body--item">
                    <span>Finished at: </span>
                    <strong>{{ item.data.finishedAt }}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.data.type == 'ContactError'" class="notifications_item-contact">
                <div class="notifications_item__head">
                  <div v-if="item.data" class="notifications_item__indicator d-flex align-center">
                    <v-icon size="18" color="error">info</v-icon>
                  </div>
                  <div v-if="item.data.payload.field == 'cell'">
                    <div>
                      <div class="notifications_item__body--item notifications_item__body--item_status">
                        <span class="contact_error">{{ item.data.payload.member_name }}`s cell phone number has been flagged as permanently failed.</span>
                      </div>
                    </div>
                  </div>
                  <div  v-if="item.data.payload.field == 'email'">
                    <div>
                      <div class="notifications_item__body--item notifications_item__body--item_status">
                        <span class="contact_error">{{ item.data.payload.member_name }}`s email has been flagged as permanently failed.</span>
                      </div>
                    </div>
                  </div>
                  <div class="notifications_item__close" @click="markAsRead(item)">
                    <v-btn x-small icon>
                      <v-icon>close</v-icon>
                    </v-btn>
                  </div>
                </div>
              <div class="notifications_item__body--item d-flex">
                <v-spacer></v-spacer>
                <strong class="contact_time">{{ item.updated_at }}</strong>
              </div>
              </div>
          </li>
        </ul>
        <div v-else class="pa-3">
          <v-alert
              outlined
              type="success"
              :value="true"
          >
            You're all caught up!
          </v-alert>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import {mapGetters} from "vuex"
  import swal from 'sweetalert2'
  import constants from '@/entities/notifications'
  import Echo from "laravel-echo";
  import token from "@/utils/storage/token";
  import url from "socket.io-client";

  export default {
    name: "AppNotifications",
    mixins: [],
    data() {
      return {
        $echo: {},
        timer: false,
        drawer: false,
        constants: constants,
        selectedNotification: {}
      }
    },
    beforeDestroy: function () {
      this.$echo.private(`notification`)
        .stopListening('NotificationEvent', (event) => {
          this.$store.dispatch('notifications/setLoading', true)
          this.$store.dispatch('notifications/setNotifications', event[0])
          this.$store.dispatch('notifications/setLoading', false)
        });
    },
    created() {
      const socketUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local:6001';
      this.$echo = new Echo({
        broadcaster: "socket.io",
        host: socketUrl,
        auth: {
          headers: {
            Authorization: 'Bearer ' + token.get(),
          },
        },
      })
    },
    mounted() {
      this.$echo.private(`notification`)
          .listen('NotificationEvent', (event) => {
            this.$store.dispatch('notifications/setLoading', true)
            this.$store.dispatch('notifications/setNotifications', event[0])
            this.$store.dispatch('notifications/setLoading', false)
            this.load()
          });
      this.$modals.register('notifications', this)
      this.load()
    },
    destroyed() {
      clearInterval(this.timer)
    },
    methods: {
      openAndLoad() {
        this.drawer = true
        this.load()
      },
      close() {
        this.drawer = false
      },
      toggle() {
        this.drawer = !this.drawer
      },
      async load() {
        this.execute(async () => {
          return await this.$api.notifications.list()
        })
      },
      async markAsRead(item) {
        this.execute(async () => {
          return await this.$api.notifications.mark(item.id)
        })
      },
      async markAllAsRead() {
        this.execute(async () => {
          return await this.$api.notifications.markAll()
        })
      },
      async execute(action) {
        this.$store.dispatch('notifications/setLoading', true)
        let notifications = await action()
        this.$store.dispatch('notifications/setNotifications', notifications)
        this.$store.dispatch('notifications/setLoading', false)
      },
      openDetailsModal(item) {
        swal({
          title: this.getNotificationTitle(item),
          type: 'info',
          html: this.getNotificationInfo(item),
          confirmButtonColor: '#3085d6',
        })
      },
      setCardClass(item) {
        if (!item.data) return ''
        if (item.data.status == 'started') return 'notifications_item__progress'
        if (item.data.status == 'finished') return 'notifications_item__success'
        if (item.data.status == 'failed') return 'notifications_item__error'
      },
      getNotificationTitle(item) {
        return this.constants[item.data.type].title[item.data.status]
      },
      getNotificationInfo(item) {
        return this.constants[item.data.type].info(item)
      }
    },
    computed: {
      ...mapGetters('notifications', ['notifications', 'loading']),
      notificationsList() {
        return this.notifications.filter(notification => {
          const scheduleMessages = notification.data.type == 'SendScheduleMessages'
          const scheduleMail = notification.data.type == 'SendMail'
          const scheduleTexts = notification.data.type == 'SendTexts'
          const AnalyzeMemberImport = notification.data.type == 'AnalyzeMemberImport'

          if (AnalyzeMemberImport) {
            return false
          }

          if(scheduleMessages || scheduleMail || scheduleTexts) {
            return !notification.data.payload.success
          }
          return notification
        })
      }
    },
  }
</script>
