export default {
    getFilteredParentCommittees: (state) => (filters) => {
        return state.parentCommittees.filter(committee => {
            if(Object.keys(filters).length === 0){
                return true;
            }

            return Object.keys(filters).every(key => {
                return committee[key] === filters[key];
            });
        });
    },
}