import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"525"},model:{value:(_vm.isOpenDialog),callback:function ($$v) {_vm.isOpenDialog=$$v},expression:"isOpenDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex flex-column align-start"},[_vm._v(" Select your login... "),_c(VCardSubtitle,{staticStyle:{"text-align":"left","word-break":"normal","overflow-wrap":"break-word"}},[_vm._v(" You are an active Key Person for more than 1 active account. ")])],1),_c(VCardText,{staticClass:"pt-5"},[_c('div',{class:_vm.isDarkTheme ? 'card-dark' : 'card-light'},[_c(VLayout,{staticClass:"ma-auto",attrs:{"row":""}},_vm._l((_vm.clients),function(activeClient,index){return _c(VFlex,{key:activeClient.id,staticClass:"register-page_person mr-0",attrs:{"col-sm-6":"","col-md-6":"","col-12":""}},[_c(VBtn,{staticClass:"client-card-button d-flex flex-column justify-center align-center",staticStyle:{"min-height":"120px","display":"flex","flex-direction":"column"},on:{"click":function($event){return _vm.onSelectActiveClient(activeClient)}}},[_c('div',{staticClass:"mt-2 text-center"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(activeClient && activeClient.assoc_abbrev))])])])],1)}),1)],1)]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.onCloseClickHandler}},[_vm._v(" Back to Login ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }