<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="800" v-model="isModalOpen">
    <v-form ref="newSessionForm" @submit.prevent="onAddSession()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Session</span>
          <v-spacer></v-spacer>
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
        <v-stepper v-model="step" vertical>
          <v-stepper-step step="1" :complete="step > 1">
            <div class="d-flex align-center">
              <span class="text-4xl">1</span>
              <div class="d-flex flex-column">
                <span>Basic Information</span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-form ref="step1" @submit.prevent="">
              <v-layout wrap>
                <v-flex xs12 class="px-2 mt-2">
                  <v-text-field label="Session Name*" outlined dense :rules="nameRules" v-model="newSession.name" @input="error = ''"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-2">
                  <v-textarea label="Internal Notes" outlined dense v-model="newSession.notes"></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn type="submit" color="primary" @click="next" :disabled="newSession.name && newSession.name.length === 0">Next</v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="2" :complete="step > 2">
            <div class="d-flex align-center">
              <span class="text-4xl">2</span>
              <div class="d-flex flex-column">
                <span>Duration</span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-form ref="step2" @submit.prevent="">
              <v-layout wrap>
                <v-flex xs12 sm4 class="px-2 mt-2">
                  Start Date: <br /> {{ startDateTime }}
                </v-flex>
                <v-flex xs12 sm8 class="px-2 mt-2 d-flex">
                  <v-flex xs12 sm6>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-date-picker2
                          :min="minDate"
                          v-on="on"
                          label="End Date"
                          dense="dense"
                          :rules="endAtRules"
                          v-model="newSession.end_at"
                          :isNormalDate="true"
                        ></v-date-picker2>
                      </template>
                      <span>A good time to end a session is a day after your Governor typically signs bills</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex xs12 sm6 class="ml-2">
                    <v-menu
                      ref="timeMenu"
                      v-model="timeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="sessionEndtime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="!newSession.end_at"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="getFormattedTime('hh:mm A')"
                          label="Select time"
                          prepend-icon="mdi-clock-outline"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!newSession.end_at"
                          hide-details
                          dense="dense"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="timeMenu"
                        v-model="sessionEndtime"
                        :min="endMinTime"
                        color="primary"
                        full-width
                        ampm-in-title
                        @click:minute="$refs.timeMenu.save(sessionEndtime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn color="secondary" outlined class="mr-2" @click="step = 1">
                  Previous
                </v-btn>
                <v-btn type="submit" color="primary" :disabled="!sessionStartDateTime || !newSession.end_at || !sessionEndtime" @click="next">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>


          <v-stepper-step step="3" :complete="step > 3">
            <div class="d-flex align-center">
              <span class="text-4xl">3</span>
              <div class="d-flex flex-column">
                <span>KP Instructions</span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-form ref="step3" @submit.prevent="">
              <v-layout wrap>
                <v-flex xs12 sm12 class="px-2 mt-2">
                  <editor
                    v-if="active"
                    :api-key="TINY_MCE_API_KEY"
                    :plugins="tinyPlugins"
                    :toolbar="tinyToolbars"
                    :init="tinyInit"
                    v-model="newSession['alert']"
                  />
                  <span class="text-counter" :class="alertLength > 550 ? 'error--text' : ''">
                    {{ alertLength }}/550
                  </span>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn color="secondary" outlined class="mr-2" @click="step = 2">
                  Previous
                </v-btn>
                <v-btn color="primary" :disabled="alertLength > 550" @click="next">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="4" :complete="step > 4">
            <div class="d-flex align-center">
              <span class="text-4xl">4</span>
              <div class="d-flex flex-column">
                <span>Committees</span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-form ref="step4" @submit.prevent="">
              <v-layout wrap>
                <v-flex xs12 sm6 class="px-2 mt-2">
                  <v-autocomplete
                    multiple
                    chips
                    label="House committees to track this session"
                    item-text="fullname"
                    item-value="id"
                    :items="houseCommitteeList"
                    v-model="newSession.house_committees"
                    v-if="client.state && client.state.house_districts">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                              v-on="on"
                              label
                              dark
                              close
                              :input-value="props.selected"
                              :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                              class="chip--select-multi"
                              @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 class="px-2 mt-2">
                  <v-autocomplete
                    multiple
                    chips
                    label="Senate committees to track this session"
                    item-text="fullname"
                    item-value="id"
                    :items="senateCommitteeList"
                    v-model="newSession.senate_committees"
                    v-if="client.state && client.state.sen_districts">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            label
                            dark
                            close
                            :input-value="props.selected"
                            :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                            class="chip--select-multi"
                            @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 class="px-2 mt-2">
                  <v-autocomplete
                    multiple
                    chips
                    label="Joint committees to track this session"
                    item-text="fullname"
                    item-value="id"
                    :items="jointCommitteeList"
                    v-model="newSession.joint_committees"
                    v-if="client.state && client.state.has_joint_committees">
                    <template v-slot:selection="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            label
                            dark
                            close
                            :input-value="props.selected"
                            :color="props.item.color ? props.item.color : getColorFromText(props.item.shortname)"
                            class="chip--select-multi"
                            @click:close="props.parent.selectItem(props.item)"
                          >
                            {{ props.item.shortname }}
                          </v-chip>
                        </template>
                        <span>{{ props.item.fullname }}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn color="secondary" outlined class="mr-2" @click="step = 3">
                  Previous
                </v-btn>
                <v-btn color="primary" @click="onAddSession" :loading="isBusy">
                  Add Session
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ClientService from "@/services/ClientService";
import SessionService from '@/services/SessionService'

import VDatePicker2 from '@/components/common/date-picker'
import TinyConfig from "@/mixins/tiny-mce-config";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";

export default {
  name: 'NewSessionModal',
  props: {
    activeSession: {
      type: Object,
      default: function () {
        return {}
      }
    },
    sessionList: {
      type: Array,
      default: function () {
        return []
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
    legislators: {
      type: Array,
      default: function () {
        return []
      }
    },
    refresh: {
      type: Function
    }
  },
  mixins: [SessionService, TinyConfig, ClientService],
  components: {
    VDatePicker2,
    'editor': Editor,
  },
  data () {
    return {
      step: 1,
      isModalOpen: false,
      client: {},
      active:false,
      newSession: {},
      error: '',
      isBusy: false,
      minDate: '',
      timeMenu: false,
      startDateTime: null,
      sessionStartDateTime: null,
      sessionEndtime: null,
    }
  },
  computed: {
    nameRules () {
      return [
        v => !!v || 'Name is required.',
      ]
    },
    startAtRules () {
      return [
        v => !!v || 'Start date is required.',
      ]
    },
    endAtRules () {
      if (!this.newSession.start_at) return []
      return [
        v => !!v || 'End date is required.',
        v => (!!v && `${v} ${this.sessionEndtime}` > this.newSession.start_at) || 'End date should be after the start date.'
      ]
    },
    alertLength() {
      return this.newSession && this.newSession.alert ? this.newSession.alert.length : 0
    },
    houseCommitteeList () {
      const houseCommitteeList =  this.committeeList.filter(committee => committee.type === 0)
      if (this.newSession.house_committees.length > 0) {
        this.newSession.house_committees.forEach(houseCommittee => {
          const houseCommitteeIndex = houseCommitteeList.findIndex((item) => item.id === houseCommittee);
          if(houseCommitteeIndex !== -1){
            const selectedHouseCommittee = houseCommitteeList[houseCommitteeIndex];
            if (selectedHouseCommittee.subcommittees && selectedHouseCommittee.subcommittees.length > 0) {
              // Add additional items after the selected item
              const additionalItemsNotInList = selectedHouseCommittee.subcommittees.filter(
                (item) => !houseCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                houseCommitteeList.splice(houseCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }
      return houseCommitteeList
    },
    senateCommitteeList () {
      const senateCommitteeList = this.committeeList.filter(committee => committee.type === 1)
      if(this.newSession.senate_committees.length > 0){
        this.newSession.senate_committees.forEach(senateCommittee => {
          const senateCommitteeIndex = senateCommitteeList.findIndex((item) => item.id === senateCommittee);
          if(senateCommitteeIndex !== -1){
            const selectedSenateCommittee = senateCommitteeList[senateCommitteeIndex];
            if (selectedSenateCommittee.subcommittees && selectedSenateCommittee.subcommittees.length > 0) {
              const additionalItemsNotInList = selectedSenateCommittee.subcommittees.filter(
                (item) => !senateCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                senateCommitteeList.splice(senateCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }
      return senateCommitteeList
    },
    jointCommitteeList () {
      const jointCommitteeList = this.committeeList.filter(committee => committee.type === 2)
      if(this.newSession.joint_committees.length > 0){
        this.newSession.joint_committees.forEach(jointCommittee => {
          const jointCommitteeIndex = jointCommitteeList.findIndex((item) => item.id === jointCommittee);
          if(jointCommitteeIndex !== -1){
            const selectedJointCommittee = jointCommitteeList[jointCommitteeIndex];
            if (selectedJointCommittee.subcommittees && selectedJointCommittee.subcommittees.length > 0) {
              const additionalItemsNotInList = selectedJointCommittee.subcommittees.filter(
                (item) => !jointCommitteeList.find((existing) => existing.id === item.id)
              ).map(item => {
                return {
                  ...item,
                  fullname: `- ${item.fullname}`,
                }
              });
              if (additionalItemsNotInList.length > 0) {
                jointCommitteeList.splice(jointCommitteeIndex + 1, 0, ...additionalItemsNotInList);
              }
            }
          }
        })
      }
      return jointCommitteeList
    },
    endMinTime() {
      const timezone = this.getTimezone(this.client);
      //If selected end_at is large from current date
      // Then timepicker should not be validation
      const startDate = moment(this.sessionStartDateTime).format('YYYY-MM-DD');
      const endDate = moment(this.newSession.end_at).format('YYYY-MM-DD');
      if(startDate === endDate){
        return moment().tz(timezone).add(10, 'minutes').format('HH:mm:ss');
      }
    },
    // getFormattedTime() {
    //   const timezone = this.client ? this.client.timezone : moment.tz.guess();
    //   return this.sessionEndtime ? moment(this.sessionEndtime, "hh:mm:ss").format('HH:mm:ss') : moment().tz(timezone).format('HH:mm:ss')
    // },
  }, 
  methods: {
    toggle (client) {
      this.isModalOpen = true
      this.step = 1
      this.error = ''
      this.client = client
      let alert = 'These are your legislator(s) for which you are the primary KP. ' +
      'Please learn about them, complete assigned tasks by deadlines, ' +
      'record any donations, and keep us updated with Notes. Thanks!'
      this.newSession = {
        alert: alert,
        client_id: client.id,
        house_committees: [],
        senate_committees: [],
        joint_committees: [],
        ignored_legislators: [],
      }

      this.$nextTick(() => {
        this.active = true

        if (this.client && this.client.id ) {
          this.setStartDatetime()
        }
      })

    },
    setStartDatetime(){
      const timezone = this.getTimezone(this.client);
      //By default session start datetime is current date
      // according to client timezone
      this.sessionStartDateTime = this.formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.startDateTime = this.formatDate(new Date(), 'MMM D, YYYY hh:mm A');
      this.minDate = this.formatDate(new Date(), 'YYYY-MM-DD');
      this.newSession = {
        ...this.newSession,
        end_at: moment().tz(timezone).add(1, 'day').format('YYYY-MM-DD')
      }
    },
    getFormattedTime(format) {
      const timezone = this.getTimezone(this.client);
      if(this.sessionEndtime) {
        return moment(this.sessionEndtime, "hh:mm:ss").format(format? format:'HH:mm:ss') 
       } else {
        const endTime = moment().tz(timezone).endOf('day');
        this.sessionEndtime = endTime.format('HH:mm:ss');
        return endTime.format(format? format:'HH:mm:ss');
       }
    },
    validate () {
      let isValid = true
      if (this.activeSession && this.newSession.end_at > moment().format('YYYY-MM-DD')) {
        this.error = 'Can\'t create more active sessions.'
        isValid = false
        return isValid
      }

      let hasOverlap = false;
      const startAt = this.sessionStartDateTime;
      const endAt = `${this.newSession.end_at} ${this.getFormattedTime()}`;
      this.sessionList.forEach(session => {
        const sessionStart = new Date(session.start_at);
        const sessionEnd = new Date(session.end_at);
        const rangeStart = new Date(startAt);
        const rangeEnd = new Date(endAt);

        // Check if session overlaps with the range
        if (sessionStart < rangeEnd && sessionEnd > rangeStart) {
            hasOverlap = true;
        }
      });

      //If has overlap any session then showing alert
      if (hasOverlap){
        this.error = 'Session period can\'t be overlapped.';
        isValid = false;
      }

      return isValid
    },
    onAddSession () {
      if (!this.validate()) return
      this.isBusy = true

      const newSessionData = { 
          ...this.newSession,
          start_at: this.sessionStartDateTime,
          end_at: `${this.newSession.end_at} ${this.getFormattedTime()}`
        };

      this.createSession(newSessionData)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New session has been created.', 'Success!')
          this.refresh();
          this.step = 1;
          this.isModalOpen = false;
          this.sessionStartDateTime = null;
          this.sessionEndtime = null;
        })
        .catch(() => {
          this.isBusy = false
          this.error = 'Something went wrong!'
        })
    },
    next() {
      let stepName = `step${this.step}`
      if (!this.$refs[stepName].validate()) return
      this.step = this.step + 1
    }
  },
  watch: {
    'client': function () {
      if (this.client && this.client.id ) {
          this.setStartDatetime()
      }
    }
  }
}
</script>
