<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-lg>
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>{{ client ? `${client.assoc_abbrev} ` : '' }}Advocates</h1>
            <members-page-help-modal></members-page-help-modal>
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="isMobile">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <export-selected-legislators
                    :legislators="exportSelectedLegislatorsProps"
                    v-if="isLegislatorsSelected"
                    >
                  </export-selected-legislators>
                  <v-btn color="primary" class="my-1" block small :loading="isLoading" @click="refresh">
                    Refresh
                    <v-icon dark class="ml-2">refresh</v-icon>
                  </v-btn>
                  <export-members
                      :params="exportProps"
                      v-if="isSuperAdmin || isVIP"
                      :disabled="!!list.items.length"
                      :client="client"
                  ></export-members>
                  <v-btn color="primary" class="my-1" block small @click="onUpdateDistricts" :loading="isUpdating"
                         v-if="(isSuperAdmin && client.id) || isVIP">
                    Update Districts
                    <v-icon dark class="ml-2">update</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="client && client.id && !isImporting"
                    color="primary"
                    class="my-1"
                    block
                    small
                    @click="$router.push({path: 'import-members'})"
                  >
                    Import
                    <v-icon dark>import_export</v-icon>
                  </v-btn>
                  <v-btn block color="warning" class="my-1" small @click="$refs.newMemberModal.toggle()">
                    New Advocate
                    <v-icon dark class="ml-2">person_add</v-icon>
                  </v-btn>
                  <v-tooltip bottom v-if="!isSuperAdmin && !(userClient && userClient.tariff && userClient.tariff.messages_members)">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          class="mt-1 disabled-button"
                          small
                          block
                          v-on="on"
                      >
                        Message Current List
                        <v-icon lass="disabled-button-icon ml-2">mail_outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Upgrade for this functionality</span>
                  </v-tooltip>
                  <v-btn v-else block color="warning" class="my-1" small @click="$refs.confirmationDialog.open()">
                    Message Current List
                    <v-icon dark class="ml-2">mail_outline</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>
          <v-spacer v-if="isDesktop || isDevice"></v-spacer>
          <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
            <export-selected-legislators
                :legislators="exportSelectedLegislatorsProps"
                v-if="isLegislatorsSelected"
            >
            </export-selected-legislators>
            <v-btn icon color="primary" :loading="isLoading" @click="refresh">
              <v-icon dark>refresh</v-icon>
            </v-btn>
            <v-btn class="mx-2" color="warning" small @click="$refs.newMemberModal.toggle()">
              Add Advocate
              <v-icon dark class="ml-2">person_add</v-icon>
            </v-btn>

            <v-menu
              bottom
              transition="slide-x-transition"
              left
            >
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
              </template>

              <v-list>
                <export-members-list
                  v-if="isSuperAdmin || isVIP"
                  :params="exportProps"
                  :disabled="list.items.length"
                  :client="client"
                ></export-members-list>
                <v-list-item
                  v-if="isSuperAdmin && client && client.id"
                  color="primary"
                  class="mb-1"
                  small
                  block
                  @click="onUpdateDistricts"
                  :loading="isUpdating"
                >
                  <v-list-item-title>Update Districts</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="client && client.id && !isImporting"
                  class="mb-1"
                  color="primary"
                  block
                  small
                  @click="$router.push({path: 'import-members'})"
                >
                  <v-list-item-title>Import</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isSuperAdmin || isVIP"
                  color="warning"
                  class="mb-1"
                  block
                  small
                  @click="$refs.confirmationDialog.open()"
                >
                  <v-list-item-title>Mass Messaging</v-list-item-title>
                </v-list-item>
                <v-tooltip bottom v-if="!(isSuperAdmin || isVIP) && !(userClient && userClient.tariff && userClient.tariff.messages_members)">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      class="disabled-button"
                      block
                      small
                      v-on="on"
                    >
                      <v-list-item-title>Mass Messaging</v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>Upgrade for this functionality</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>

        <v-layout wrap class="pb-3">
          <v-flex class="d-flex align-center flex-wrap">
            <v-text-field
              prepend-inner-icon="search"
              label="Search Advocates"
              clearable
              class="search-input search-input_members"
              v-model="filter.search"
              persistent-hint
              :hint="`${active_members_count} Active`"
            >
            </v-text-field>
            <div class="district_lookup_filters_list pt-3" v-if="checkSelectedFilters && checkSelectedFilters.length">
              <div
                v-for="(filter, index) in checkSelectedFilters"
                :key="index"
                class="district_lookup_filters_list_item"
              >
                <v-chip
                  v-if="filter.type == 'checkbox'"
                  v-for="(data, key) in filter.value"
                  :key="key"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
                >
                  {{ data }}
                </v-chip>
                <v-chip
                  v-if="filter.type == 'static' "
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, filter.name)"
                >
                  {{ getFieldText(filter.name) }}
                </v-chip>
                <v-chip
                  v-if="filter.type == 'text'"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter)"
                >
                  <span v-if="filter.type == 'text'">{{ filter.value }}</span>
                  <span v-else>{{ filter.name }}</span>
                </v-chip>
                <v-chip
                  v-if="filter.type == 'dropdown'"
                  v-for="(data, j) in filter.value"
                  :key="j"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
                >
                  {{ data }}
                </v-chip>
              </div>
            </div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex  class="d-flex align-end justify-end">
            <div v-if="isSuperAdmin" class="mr-4"><i>{{  updateDistrictDate }}</i></div>
            <div class="legislators_filter__additional--filter" :class="{ 'w-100': isMobile }">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                left
                offset-y
                :close-on-click="false"
                min-width="600"
                max-width="300"
              >
                <template v-slot:activator="{ on }">
                  <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                    <span>Filters</span>
                    <v-icon>filter_list</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="members_filter-overflow">
                    <v-layout wrap>
                      <v-flex xs6 sm6 class="legislators_filter__additional--item_wrap">
                        <h4>Filters:</h4>
                        <v-checkbox
                          color="primary"
                          label="Show Inactive"
                          class="mt-0"
                          v-model="filter.showInactive"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          color="primary"
                          label="No valid email or cell"
                          class="mt-0"
                          v-model="filter.showInvalid"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          color="primary"
                          label="Districts not set"
                          class="mt-0"
                          v-model="filter.districtNotSet"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          color="primary"
                          label="Active KP1"
                          class="mt-0"
                          v-model="filter.activeKP1"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          color="primary"
                          label="Is Verified"
                          class="mt-0"
                          v-model="filter.isVerified"
                          hide-details
                        ></v-checkbox>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <div class="mb-4">
                          <h4>KP Preference</h4>
                        </div>
                        <div v-for="(item, index) in kpPreference" :key="index">
                          <v-checkbox
                            color="primary"
                            hide-details
                            class="ma-0"
                            :label="item.title"
                            :value="filter.kpPreferenceResults.indexOf(item.value) >= 0"
                            @change="editKpPreferenceResults($event, item.value)"
                          ></v-checkbox>
                        </div>
                      </v-flex>

                      <v-flex xs12 class="pb-4">
                        <v-layout wrap>
                            <h4>Legislators:</h4>
                          <v-flex xs12 class="px-2">
                            <v-checkbox
                              color="primary"
                              label="All Legislators"
                              class="mt-0"
                              hide-details
                              v-model="filter.isSpecificLegislatorsOnly"
                              @change="fillLegislator"
                            ></v-checkbox>
                          </v-flex>
                          <v-flex
                            xs12
                            class="px-2"
                            v-if="!filter.isSpecificLegislatorsOnly"
                          >
                            <v-radio-group
                              row
                              v-model="filter.isDistricts"
                              @change="setFilterLegislators"
                            >
                              <v-radio color="primary" label="House" value="0"></v-radio>
                              <v-radio color="primary" label="Senate" value="1"></v-radio>
                              <v-radio color="primary" label="Joint" value="2" v-if="client && client.state && client.state.has_joint_committees"></v-radio>
                            </v-radio-group>
                          </v-flex>
                          <v-flex xs12 class="px-2" v-if="!filter.isSpecificLegislatorsOnly && filter.isDistricts !== null">
                            <div class="d-flex flex-nowrap" v-for="committee in selectedCommitteeList" :key="committee.id">
                              <v-flex xs12 sm7>
                                <v-checkbox
                                  color="primary"
                                  @change="onSelectCommittee(committee)"
                                  :input-value="filter.selectedCommitteeIdList.includes(committee.id)"
                                  :label="committee.label"
                                  :disabled="filter.selectedSubCommitteeIdList.includes(committee.id) || isLoading"
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm5 v-if="client && client.state && client.state.has_subcommittees">
                                <v-checkbox
                                   color="primary"
                                  :disabled="(!filter.selectedSubCommitteeIdList.includes(committee.id) && !filter.selectedCommitteeIdList.includes(committee.id)) || isLoading"
                                  :value="filter.selectedSubCommitteeIdList.includes(committee.id)"
                                  @change="onSelectCommittee(committee, true)"
                                  label="Subcommittee only"
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                            </div>
                          </v-flex>

                          <v-flex xs12 class="mt-3 px-2">
                            <h3>{{'Send to constituents of these Legislators'}}</h3>
                            <v-autocomplete
                              v-model="filter.legislators"
                              :items="sortLegislatorsList"
                              filled
                              color="blue-grey lighten-2"
                              label="Select legislators"
                              item-text="fullname"
                              item-value="id"
                              :label="'Select Legislator(s) (' + (filter.legislators || []).length + ')'"
                              multiple
                              return-object
                              :search-input.sync="searchInputFull"
                              hide-details
                              :menu-props="{maxHeight: '65vh'}"
                              @change="searchInputFull=''"
                            >
                              <template v-slot:selection="{ item }">
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-avatar class="avatar_image">
                                    <img
                                      :src="data.item.official_image ? getThumbImage(data.item.official_image) : '/images/member-avatar.png'"
                                      :alt="data.item.fullname">
                                  </v-list-item-avatar>
                                  <vip-chip
                                    v-if="data.item.vip"
                                    class="mr-2 mb-1"
                                    :legislator="data.item"
                                    :show="'chip_code'"
                                  >
                                  </vip-chip>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ `${data.item.fullname} (${data.item && data.item.district && data.item.district.district})` }}
                                      <v-tooltip bottom v-if="data.item.lame_duck">
                                        <template v-slot:activator="{ on }">
                                          <a v-on="on">
                                            <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                                          </a>
                                        </template>
                                        <span>Lame Duck</span>
                                      </v-tooltip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                            <v-layout :class="isMobile ? 'flex-column' : ''">
                              <v-flex
                                xs12
                                :md9="filter.exceptLegislators && filter.exceptLegislators.length"
                              >
                                <h3>Include:</h3>
                                <div class="mb-2 pt-1" v-if="filter.legislators && filter.legislators.length">
                                  <div class="d-flex flex-wrap">
                                    <div v-for="item of filter.legislators" :key="item.id" class="mr-2">
                                      <v-menu
                                        v-model="displaySelected[getIndex(item.id, filter.legislators)]"
                                        bottom
                                        left
                                        origin="top left"
                                        content-class="role_card"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            close
                                            class="mt-2"
                                            pill
                                            v-on="on"
                                            :style=colorsLegislatorsParties(item,legislators)
                                            @click:close="removeLegislator(getIndex(item.id, filter.legislators), filter.legislators, item)"
                                          >
                                            <v-avatar left>
                                              <v-img class="avatar_image"
                                                     :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                            </v-avatar>
                                            <vip-chip
                                              v-if="item.vip"
                                              class="mr-2 mb-1"
                                              :legislator="item"
                                              :show="'chip_code'"
                                            >
                                            </vip-chip>
                                            {{ item.fullname }}
                                          </v-chip>
                                        </template>
                                      </v-menu>
                                    </div>
                                  </div>
                                </div>
                              </v-flex>
                              <v-flex xs12 md3 v-if="filter.exceptLegislators && filter.exceptLegislators.length">
                                <h3>Except:</h3>
                                <div class="mb-2 pt-1">
                                  <div class="d-flex flex-wrap">
                                    <div v-for="item of filter.exceptLegislators" :key="item.id" class="mr-2">
                                      <v-menu
                                        v-model="displayExceptSelected[getIndex(item.id, filter.exceptLegislators)]"
                                        bottom
                                        left
                                        origin="top left"
                                        content-class="role_card"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            close
                                            class="mt-2"
                                            pill
                                            v-on="on"
                                            :style=colorsLegislatorsParties(item,legislators)
                                            @click:close="removeExceptLegislator(getIndex(item.id, filter.exceptLegislators), filter.exceptLegislators)"
                                          >
                                            <v-avatar left>
                                              <v-img class="avatar_image"
                                                     :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                            </v-avatar>
                                            <vip-chip
                                              v-if="item.vip"
                                              class="mr-2 mb-1"
                                              :legislator="item"
                                              :show="'chip_code'"
                                            >
                                            </vip-chip>
                                            {{ item.fullname }}
                                          </v-chip>
                                        </template>
                                      </v-menu>
                                    </div>
                                  </div>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout
                          v-if="filter.customFields && filter.customFields.length"
                          class="legislators_filter__additional--item_wrap"
                          v-for="(item, index) in filter.customFields"
                          :key="index"
                        >
                          <div v-if="item.type == 'text' && item.member_page" class="mb-4">
                            <h4>{{ item.name }}</h4>
                            <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                          </div>
                          <div
                            v-if="item.type == 'checkbox' && item.metadata && item.metadata.length && item.member_page">
                            <h4>{{ item.name }}</h4>
                            <v-radio-group
                              v-for="(data, j) in item.metadata"
                              :key="j"
                              row
                              v-model="data.type"
                              :label="data.name"
                            >
                              <v-radio
                                color="primary"
                                v-for="(label, key) in checkboxLabels"
                                :key="key"
                                :label="label.name"
                                :value="label.value"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div v-if="item.type == 'toggle' && item.member_page">
                            <h4>{{ item.name }}</h4>
                            <v-checkbox :label="item.name" class="mt-0" v-model="item.value"></v-checkbox>
                          </div>
                          <div
                            v-if="item.type == 'dropdown' && item.metadata && item.metadata.length && item.member_page">
                            <h4 class="my-2">{{ item.name }}</h4>
                            <v-checkbox
                              color="primary"
                              v-for="(data, j) in item.metadata"
                              :key="j"
                              :label="data.name"
                              class="mt-0"
                              v-model="data.checked"
                            >
                            </v-checkbox>
                          </div>
                        </v-layout>
                      </v-flex>
                      <v-expansion-panels>
                      <!-- Regions -->
                        <v-expansion-panel v-if="clientRegionsForFilter && clientRegionsForFilter.length">
                          <v-expansion-panel-header>Filter with Regions</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-radio-group v-model="filter.region">
                              <v-radio
                                color="primary"
                                v-for="region in clientRegionsForFilter"
                                :key="region.title"
                                :label="region.name"
                                :value="region.id"
                              ></v-radio>
                            </v-radio-group>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                    <v-btn color="primary" text @click="doFilter">Apply Filter</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </v-flex>
        </v-layout>

        <v-data-table
          class="elevation-1"
          :options.sync="list.pagination"
          :server-items-length="list.pagination.totalItems"
          :footer-props="tableFooter"
          :headers="memberTableHeader"
          :items="list.items"
          @pagination="getPaginateList"
          :disable-sort="isMobile"
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon class="mx-0" small @click="onEditMember(item)">
              <v-icon size="18" color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="onDeleteMember(item.id)" v-if="false">
              <v-icon size="18" color="error">delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.nickname="{ item }">
            <div class="d-flex align-center">
              <a style="white-space: nowrap;" class="mr-1 no-line-break" @click="$refs.memberDetailModal.toggle(item)">
                {{ item.nickname }} {{ item.lastname }}
              </a>
              <v-tooltip bottom v-if="item.isVerified">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"  color="success" size="16" class="mx-1">fa-badge-check</v-icon>
                </template>
                <span>Last Verified: {{ formatDate(item.last_login_at) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.updated_profile_at">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"  color="success" size="16" class="mx-1">fa-light fa-user-check</v-icon>
                </template>
                <span v-if="item.updated_profile_at">Last Updated: {{ formatDate(item.updated_profile_at) }}</span>
              </v-tooltip>
              <sup v-if="item.is_kp1 && item.activeKP1">KP1</sup>
              <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ kpPreferenceTitle(item) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.political_leaning_id !== null">
                <template v-slot:activator="{ on }">
                  <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
                </template>
                <span>{{ politicalLearningsTitle(item) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!!item.cell">
                <template v-slot:activator="{ on }">
                  <a :href="'tel:' + item.cell" v-on="on">
                    <v-icon v-if="item.bad_cell" color="error" size="16" class="mx-1">
                      no_cell
                    </v-icon>
                    <v-icon v-else color="primary" size="16" class="mx-1">
                      phone_iphone
                    </v-icon>
                  </a>
                </template>
                <span>{{ item.cell }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!!item.email">
                <template v-slot:activator="{ on }">
                  <a :href="'mailto:' + item.email" v-on="on">
                    <v-icon :color="isBad(item.email) ? 'error' : 'primary'" size="16" class="mx-1">email</v-icon>
                  </a>
                </template>
                <span>{{ item.email }}</span>
              </v-tooltip>
              <!-- This inactive tag when advocate is inactive. Other we didn't show active tag -->
              <v-chip  v-if="!item.active" class="ma-2 custom-inactive-badge" x-small>INACTIVE</v-chip>
            </div>
            
              <!-- New student chip for advocates with member_type "student" -->
              <v-chip
                v-if="isMemberStudent(item)"
                outlined
                color="warning"
                x-small
              >
                student
              </v-chip>
          </template>
<!-- removed by Adam 12.2.24
          <template v-slot:item.isEmailCellValid="{ item }">
            <v-icon color="success" v-if="item.isEmailCellValid">check_circle</v-icon>
            <v-icon color="error" v-else>cancel</v-icon>
          </template>

          <template v-slot:item.eligible="{ item }">
            <v-icon color="success" v-if="item.eligible">check_circle</v-icon>
            <v-icon color="error" v-else>cancel</v-icon>
          </template>
-->
<!-- removed by Adam 8.5.22
          <template v-if="isCoordinatorRequired" v-slot:item.coordinator="{ item }">
            {{ item.coordinator | integerFormatter('', 2, '0') }}
          </template>
-->
          <template v-slot:item.notes="{ item }">
            <v-tooltip bottom v-if="!!item.notes" max-width="450">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on">note</v-icon>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
          </template>
<!-- removed by Adam 12.2.24          
          <template v-slot:item.isDistrictSet="{ item }">
            <v-icon color="success" v-if="item.isDistrictSet">check_circle</v-icon>
            <v-icon color="error" v-else>cancel</v-icon>
          </template>
-->
          <template v-slot:item.home_house_district="{ item }">
            <v-btn
              v-if="item.home_house_district[0] !== null"
              v-for="dist in item.home_house_district"
              :key="dist"
              :text="!isDistrictMatch(item, dist)"
              :color="isDistrictMatch(item, dist) ? 'success' : ''"
              small
              @click="openDistrictModal(dist)"
              @mouseenter="startHoverTimer(dist)"
              @mouseleave="cancelHoverTimer"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                </template>
                <span v-if="legislatorName">{{ legislatorName }}</span>
                <span v-else>
                  <v-progress-circular indeterminate :size="20"></v-progress-circular>
                </span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-if="isHouseDistrictsRequired" v-slot:item.home_house_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <v-btn
                  v-if="item.home_con_districts_with_state[0] !== null"
                  v-for="dist in item.home_con_districts_with_state"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                        {{ dist.district }} ({{ dist.state && dist.state.state_abbrev }})
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.home_address && (item.home_con_districts_with_state[0] === null || typeof item.home_con_districts_with_state[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-btn
                  v-if="item.home_house_district[0] !== null"
                  v-for="dist in item.home_house_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.home_address && (item.home_house_district[0] === null || typeof item.home_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-slot:item.home_sen_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <template
                  v-if="Array.isArray(item.federal_home_sen_districts) && item.federal_home_sen_districts[0] !== null"
                >
                  <v-btn
                    v-for="dist in item.federal_home_sen_districts"
                    :key="dist.id"
                    :text="!isDistrictMatch(item, dist.district)"
                    :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                    small
                    @click="openDistrictModal(dist.district, dist.id)"
                    @mouseenter="startHoverTimer(dist.district, dist.id)"
                    @mouseleave="cancelHoverTimer"
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                        {{ dist.district }}
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                  </v-btn>
                </template>
                <v-tooltip bottom v-if="item.home_address && (item.federal_home_sen_districts[0] === null || typeof item.federal_home_sen_districts[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <template
                  v-if="item.home_sen_district[0] !== null"
                >
                <v-btn
                  v-for="dist in item.home_sen_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
              </template>
              <v-tooltip bottom v-if="item.home_address && (item.home_sen_district[0] === null || typeof item.home_sen_district[0] == 'undefined')">
                <template v-slot:activator="{ on }">
                  <v-icon color="error" v-on="on">cancel</v-icon>
                </template>
                <span>Address outside {{ client.state.state }}</span>
              </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="isHouseDistrictsRequired" v-slot:item.work_house_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <v-btn
                  v-if="item.work_con_districts_with_state[0] !== null"
                  v-for="dist in item.work_con_districts_with_state"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                      {{ dist.district }} ({{ dist.state && dist.state.state_abbrev }})
                    </span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work_address && (item.work_con_districts_with_state[0] === null || typeof item.work_con_districts_with_state[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-btn
                  v-if="item.work_house_district[0] !== null"
                  v-for="dist in item.work_house_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work_address && (item.work_house_district[0] === null || typeof item.work_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-slot:item.work_sen_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <template
                  v-if="item.federal_work_sen_districts[0] !== null"
                >
                  <v-btn
                    v-for="dist in item.federal_work_sen_districts"
                    :key="dist.id"
                    :text="!isDistrictMatch(item, dist.district)"
                    :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                    small
                    @click="openDistrictModal(dist.district, dist.id)"
                    @mouseenter="startHoverTimer(dist.district, dist.id)"
                    @mouseleave="cancelHoverTimer"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                          {{ dist.district }}
                        </span>
                      </template>
                      <span v-if="legislatorName">{{ legislatorName }}</span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20"></v-progress-circular>
                      </span>
                    </v-tooltip>
                  </v-btn>
                </template>

                <v-tooltip bottom v-if="item.work_address && (item.federal_work_sen_districts[0] === null || typeof item.federal_work_sen_districts[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <template 
                  v-if="item.work_sen_district[0] !== null"
                >        
                  <v-btn
                    v-for="dist in item.work_sen_district"
                    :key="dist"
                    :text="!isDistrictMatch(item, dist)"
                    :color="isDistrictMatch(item, dist) ? 'success' : ''"
                    small
                    @click="openDistrictModal(dist)"
                    @mouseenter="startHoverTimer(dist)"
                    @mouseleave="cancelHoverTimer"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                      </template>
                      <span v-if="legislatorName">{{ legislatorName }}</span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20"></v-progress-circular>
                      </span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work_address && (item.work_sen_district[0] === null || typeof item.work_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="isHouseDistrictsRequired && is2ndWorkDistrictRequired" v-slot:item.work2_house_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <v-btn
                  v-if="item.work2_con_districts_with_state[0] !== null"
                  v-for="dist in item.work2_con_districts_with_state"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">
                      {{ dist.district }} ({{ dist.state && dist.state.state_abbrev }})
                    </span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work2_address && (item.work2_con_districts_with_state[0] === null || typeof item.work2_con_districts_with_state[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <v-btn
                  v-if="item.work2_house_district[0] !== null"
                  v-for="dist in item.work2_house_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work2_address && (item.work2_house_district[0] === null || typeof item.work2_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="is2ndWorkDistrictRequired" v-slot:item.work2_sen_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <template
                  v-if="item.federal_work2_sen_districts[0] !== null"
                >
                <v-btn
                  v-for="dist in item.federal_work2_sen_districts"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">
                        {{ dist.district }}
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                </template>

                <v-tooltip bottom v-if="item.work2_address && (item.federal_work2_sen_districts[0] === null || typeof item.federal_work2_sen_districts[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <template 
                  v-if="item.work2_sen_district[0] !== null"
                >
                <v-btn
                  v-for="dist in item.work2_sen_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work2_address && (item.work2_sen_district[0] === null || typeof item.work2_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="isHouseDistrictsRequired && is3rdWorkDistrictRequired" v-slot:item.work3_house_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <v-btn
                  v-if="item.work3_con_districts_with_state[0] !== null"
                  v-for="dist in item.work3_con_districts_with_state"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                      {{ dist.district }} ({{ dist.state && dist.state.state_abbrev }})
                    </span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work3_address && (item.work3_con_districts_with_state[0] === null || typeof item.work3_con_districts_with_state[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <v-btn
                  v-if="item.work3_house_district[0] !== null"
                  v-for="dist in item.work3_house_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work3_address && (item.work3_house_district[0] === null || typeof item.work3_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="is3rdWorkDistrictRequired" v-slot:item.work3_sen_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <template
                  v-if="item.federal_work3_sen_districts[0] !== null"
                >
                <v-btn
                  v-for="dist in item.federal_work3_sen_districts"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                        {{ dist.district }}
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work3_address && (item.federal_work3_sen_districts[0] === null || typeof item.federal_work3_sen_districts[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <template 
                  v-if="item.work3_sen_district[0] !== null"
                >
                  <v-btn
                    v-for="dist in item.work3_sen_district"
                    :key="dist"
                    :text="!isDistrictMatch(item, dist)"
                    :color="isDistrictMatch(item, dist) ? 'success' : ''"
                    small
                    @click="openDistrictModal(dist)"
                    @mouseenter="startHoverTimer(dist)"
                    @mouseleave="cancelHoverTimer"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                      </template>
                      <span v-if="legislatorName">{{ legislatorName }}</span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20"></v-progress-circular>
                      </span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work3_address && (item.work3_sen_district[0] === null || typeof item.work3_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="isHouseDistrictsRequired && is4thWorkDistrictRequired" v-slot:item.work4_house_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <v-btn
                  v-if="item.work4_con_districts_with_state[0] !== null"
                  v-for="dist in item.work4_con_districts_with_state"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                        {{ dist.district }} ({{ dist.state && dist.state.state_abbrev }})
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work4_address && (item.work4_con_districts_with_state[0] === null || typeof item.work4_con_districts_with_state[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-btn
                v-if="item.work4_house_district[0] !== null"
                v-for="dist in item.work4_house_district"
                :key="dist"
                :text="!isDistrictMatch(item, dist)"
                :color="isDistrictMatch(item, dist) ? 'success' : ''"
                small
                @click="openDistrictModal(dist)"
                @mouseenter="startHoverTimer(dist)"
                @mouseleave="cancelHoverTimer"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                  </template>
                  <span v-if="legislatorName">{{ legislatorName }}</span>
                  <span v-else>
                    <v-progress-circular indeterminate :size="20"></v-progress-circular>
                  </span>
                </v-tooltip>
                </v-btn>
                <v-tooltip bottom v-if="item.work4_address && (item.work4_house_district[0] === null || typeof item.work4_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>

          <template v-if="is4thWorkDistrictRequired" v-slot:item.work4_sen_district="{ item }">
            <div class="d-flex flex-column">
              <template v-if="isFederalClient">
                <template
                  v-if="item.federal_work4_sen_districts[0] !== null"
                >
                <v-btn
                  v-for="dist in item.federal_work4_sen_districts"
                  :key="dist.id"
                  :text="!isDistrictMatch(item, dist.district)"
                  :color="isDistrictMatch(item, dist.district) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist.district, dist.id)"
                  @mouseenter="startHoverTimer(dist.district, dist.id)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist.district) ? '' : 'clickable-cell'">
                        {{ dist.district }}
                      </span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work4_address && (item.federal_work4_sen_districts[0] === null || typeof item.federal_work4_sen_districts[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <template
                  v-if="item.work4_sen_district[0] !== null"
                >
                <v-btn
                  v-for="dist in item.work4_sen_district"
                  :key="dist"
                  :text="!isDistrictMatch(item, dist)"
                  :color="isDistrictMatch(item, dist) ? 'success' : ''"
                  small
                  @click="openDistrictModal(dist)"
                  @mouseenter="startHoverTimer(dist)"
                  @mouseleave="cancelHoverTimer"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" :class="isDistrictMatch(item, dist) ? '' : 'clickable-cell'">{{ dist }}</span>
                    </template>
                    <span v-if="legislatorName">{{ legislatorName }}</span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20"></v-progress-circular>
                    </span>
                  </v-tooltip>
                </v-btn>
                </template>
                <v-tooltip bottom v-if="item.work4_address && (item.work4_sen_district[0] === null || typeof item.work4_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ client.state.state }}</span>
                </v-tooltip>
              </template>
            </div>
          </template>
          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No Advocates registered yet.</p>
          </template>
        </v-data-table>

    <member-file-template-modal
      ref="memberFileTemplateModal"
      :client="client"
    ></member-file-template-modal>
    <edit-member-modal
      v-if="client"
      ref="editMemberModal"
      :selected-client="client"
      :client-list="clientList"
      :refresh="refresh"
    ></edit-member-modal>
    <member-detail-modal
      ref="memberDetailModal"
      :selected-client="client"
      @openDistrictModal="openDistrictModal"
    ></member-detail-modal>
    <new-member-modal
      v-if="client"
      ref="newMemberModal"
      :client-list="clientList"
      :selected-client="client"
      :refresh="refresh"
    ></new-member-modal>
    <confirmation-dialog
      ref="confirmationDialog"
      :legislators="legislators"
      :committeeList="committeeList"
      :parentFilter="filter"
      :clientRegions="clientRegions"
    ></confirmation-dialog>

    <district-modal v-if="districtModalEnabled"></district-modal>

  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import DistrictService from '@/services/DistrictService'
import StateService from '@/services/StateService'

import EditMemberModal from '@/components/members/edit-member-modal'
import MemberDetailModal from '@/components/members/member-detail-modal'
import MemberFileTemplateModal from '@/components/members/member-file-template-modal'
import MembersPageHelpModal from '@/components/help-modals/members-page-help-modal'
import NewMemberModal from '@/components/members/new-member-modal.vue'
import DistrictModal from '@/components/common/district-modal'
import ExportMembers from '@/components/common/export-button'
import ExportMembersList from '@/components/common/export-list'
import ConfirmationDialog from '@/components/members/confirmation-dialog'

import CustomFieldsFilter from '@/mixins/custom-fields-filter'
import _ from "lodash";
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import {mapGetters} from "vuex";
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
import ExportSelectedLegislators from "@/components/legislators/export-selected-legislators-button.vue";

export default {
    name: 'MembersPage',
    mixins: [ClientService, MemberService, DistrictService, CustomFieldsFilter, StateService, politicalLeaningsBorder],
    components: {
      'export-selected-legislators': ExportSelectedLegislators,
      'district-modal': DistrictModal,
      'edit-member-modal': EditMemberModal,
      'member-detail-modal': MemberDetailModal,
      'member-file-template-modal': MemberFileTemplateModal,
      'members-page-help-modal': MembersPageHelpModal,
      'new-member-modal': NewMemberModal,
      'export-members': ExportMembers,
      'export-members-list': ExportMembersList,
      'confirmation-dialog': ConfirmationDialog,
      'vip-chip': VipChip,
    },
    data() {
      return {
        menu: false,
        isLoading: false,
        isUpdating: false,
        isImporting: false,
        search: '',
        clientList: [],
        committeeList: [],
        filteredCommittees: [],
        politicalLearnings: [],
        selected_committees: [],
        committeeTypeList: [
          {id: 0, title: 'House'},
          {id: 1, title: 'Senate'},
        ],
        selectedStateId: null,
        checkSelectedFilters: [],
        searchInputFull: '',
        displayExceptSelected: [],
        displaySelected: {},
        legislators: [],
        exportProps: {},
        exportSelectedLegislatorsProps: [],
        filter: {
          search: '',
          isDistricts: null,
          selectedSubCommitteeIdList: [],
          selectedCommitteeIdList: [],
          isSpecificLegislatorsOnly: true,
          showInactive: null,
          showInvalid: null,
          districtNotSet: null,
          activeKP1: null,
          isVerified: null,
          kpPreferenceResults: [],
          exceptLegislators: [],
          legislators: [],
          customFields: [],
          committees: [],
          commiteeTypeId: null,
          subcommitee: false,
          region: null,
        },
        list: {
          pagination: {
            page: 1,
            itemsPerPage: 25,
            totalItems: 0,
            current_page: 0,
            per_page: 0,
            last_page: 0,
            sortBy: ['lastname'],
            sortDesc: ['asc']
          },
          items: [],
        },
        tableFooter: {
          'items-per-page-options': [25, 50, 100]
        },
        active_members_count: 0,
        checkboxLabels: [
          { value: 'default', name: 'All' },
          { value: 1, name: 'Yes' },
          { value: 0, name: 'No' },
        ],
        clear: true,
        clientRegions: [],
        hoverTimer: null,
        legislatorName: null
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      isFederalClient(){
        return this.client && this.client.is_use_state === 0;
      },
      updateDistrictDate() {
        return this.client.districts_updated_at
            ? `Districts updated ${this.getFormattedDate(this.client.districts_updated_at, 'MMMM DD, YYYY')}`
            : 'Districts updated: never'
      },
      sortLegislatorsList() {
        if(this.isFederalClient) {
          const federalLegislators = this.legislators.filter(legislator => legislator.is_federal === 1)
          return this.checkAndSortArray(federalLegislators, 'Federal')
        }
        const house = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
        const senate = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
        return [
          ...this.checkAndSortArray(house, 'House'),
          ...this.checkAndSortArray(senate, 'Senate')
        ]
      },
      selectedCommitteeList() {
        let selectedCommitteeList = []

        if (this.filter.isDistricts === '0') {
          selectedCommitteeList = [...this.committeeList
            .filter(committee => committee.type === 0 && (this.client.house_committees || [])
              .includes(committee.id))
          ]
        }

        if (this.filter.isDistricts === '1') {
          selectedCommitteeList = [
            ...selectedCommitteeList,
            ...this.committeeList
              .filter(committee => committee.type === 1 && (this.client.senate_committees || [])
                .includes(committee.id))
          ]
        }

        if (this.filter.isDistricts === '2') {
          selectedCommitteeList = [
            ...selectedCommitteeList,
            ...this.committeeList
              .filter(committee => committee.type === 2 && (this.client.joint_committees || [])
                .includes(committee.id))
          ]
        }

        const committeeTypeList = ['H', 'S', 'J']

        selectedCommitteeList.forEach((committee) => {
          committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
          committee.isSubCommittee = false
        })

        return selectedCommitteeList
      },
      isCoordinatorRequired() {
        return this.client && this.client.uses_coordinators
      },
      isHouseDistrictsRequired() {
        return this.client && this.client.state && this.client.state.house_districts
      },
      is2ndWorkDistrictRequired() {
        return this.client && this.client.uses_2nd_work
      },
      is3rdWorkDistrictRequired() {
        return this.client && this.client.uses_3rd_work
      },
      is4thWorkDistrictRequired() {
        return this.client && this.client.uses_4th_work
      },
      isLegislatorsSelected() {
        return this.filter.isSpecificLegislatorsOnly && this.filter.legislators.length
      },
      memberTableHeader() {
        let tableHeader = [
          {text: '', value: 'action', align: 'center', sortable: false},
          {text: 'Advocate', value: 'nickname', align: 'left'},
          //{text: 'Valid Email/Cell?', value: 'isEmailCellValid', align: 'center', sortable: false},
          //{text: 'KP Eligible?', value: 'eligible', align: 'center'},
          //{text: 'Coordinator?', value: 'coordinator', align: 'center'},
          {text: 'Notes', value: 'notes', align: 'center'},
          //{text: 'Districts Set?', value: 'isDistrictSet', align: 'center', sortable: false},
          {text: 'HHD', value: 'home_house_district', align: 'center'},
          {text: 'HSD', value: 'home_sen_district', align: 'center'},
          {text: 'WHD', value: 'work_house_district', align: 'center'},
          {text: 'WSD', value: 'work_sen_district', align: 'center'},
          {text: 'W2HD', value: 'work2_house_district', align: 'center'},
          {text: 'W2SD', value: 'work2_sen_district', align: 'center'},
          {text: 'W3HD', value: 'work3_house_district', align: 'center'},
          {text: 'W3SD', value: 'work3_sen_district', align: 'center'},
          {text: 'W4HD', value: 'work4_house_district', align: 'center'},
          {text: 'W4SD', value: 'work4_sen_district', align: 'center'},
        ]
        let hiddenColumns = []
        if (!this.isCoordinatorRequired) {
          hiddenColumns.push('Coordinator?')
        }
        if (!this.isHouseDistrictsRequired) {
          hiddenColumns.push('HHD', 'WHD', 'W2HD', 'W3HD', 'W4HD')
        }
        if (!this.is2ndWorkDistrictRequired) {
          hiddenColumns.push('W2HD', 'W2SD')
        }
        if (!this.is3rdWorkDistrictRequired) {
          hiddenColumns.push('W3HD', 'W3SD')
        }
        if (!this.is4thWorkDistrictRequired) {
          hiddenColumns.push('W4HD', 'W4SD')
        }
        tableHeader = tableHeader.filter(header => !hiddenColumns.includes(header.text))
        return tableHeader
      },
      clientRegionsForFilter() {
        return this.clientRegions && this.clientRegions.length ?
          [{ name: 'Show All', id: '' }, ...this.clientRegions] : []
      }
    },
    async mounted() {
      this.clientList = (await this.getClients()).data
      this.politicalLearnings = (await this.getPoliticalLeanings()).data
      await this.refresh()
      this.getClientRegionsList()
      this.clear = false
    },
    methods: {
      isMemberStudent(memberItem) {
        let isStudent = false;
        const customFields = memberItem && memberItem.custom_fields || [];

        if (customFields.length) {
          const memberTypeField = customFields.find(field => field.name === "Member Type");

          if (memberTypeField && memberTypeField.pivot && memberTypeField.pivot.metadata) {
            try {
              const metadata = JSON.parse(memberTypeField.pivot.metadata);
              if (metadata.name === "STUDENT") {
                isStudent = true;
              }
            } catch (error) {
              console.error("Error parsing metadata:", error);
            }
          }
        }

        return isStudent;
      },
      setFilterLegislators() {
        // Reset selected committee and subcommitee 
        this.filter.selectedCommitteeIdList = [];
        this.filter.selectedSubCommitteeIdList = [];

        if (this.filter.isDistricts !== null && this.filter.isDistricts === '0') {
          this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
        } else if (this.filter.isDistricts !== null && this.filter.isDistricts === '1') {
          this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
        } else if (this.filter.isDistricts !== null && this.filter.isDistricts === '2') {
          this.filter.legislators = this.legislators.filter(legislator => !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'))
        }
      },
      async refresh() {
        this.isLoading = true
        if (!(this.client && this.client.id)) return

        this.getCommitteesList().then(() => {
          this.filteredCommittees = this.setCommitteesList()
        })
        let param = {
          order: 'lastname',
        }
        this.legislators  = this.client && this.client.id ? (await this.getClientLegislators(this.client.id, param)).data : []
        
        const clientId = this.client && this.client.id || '';
        this.loadCustomFields(clientId, this.clear)
        await this.getList()
        this.isLoading = false
      },
      getPaginateList() {
        if (!this.client.id) return
        this.getList()
      },
      onSelectCommittee(committee, sub = false) {
        if (sub) {
          if (this.filter.selectedSubCommitteeIdList.includes(committee.id)) {
            this.filter.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedSubCommitteeIdList, committee.id)
          } else {
            this.filter.selectedSubCommitteeIdList.push(committee.id)
            this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
          }
        } else {
          if (this.filter.selectedCommitteeIdList.includes(committee.id)) {
            this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
          } else {
            this.filter.selectedCommitteeIdList.push(committee.id)
          }
        }
        this.onFilterLegislators()
      },
      onFilterLegislators() {
        this.loading = true
        this.filter.legislators = this.legislators.filter((legislator) => {
          if (this.filter.selectedCommitteeIdList.length === 0 && this.filter.selectedSubCommitteeIdList.length === 0) {
            if (this.filter.isDistricts === '0') {
              return legislator && legislator.district && legislator.district.district[0] === 'H'
            } else if (this.filter.isDistricts === '1') {
              return legislator && legislator.district && legislator.district.district[0] === 'S'
            } else if (this.filter.isDistricts === '2') {
              return !(legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S');
            }
          }

          if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

          const subCommitteeIds = legislator.legislator_committees
            .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
            .map(legislatorCommittee => legislatorCommittee.committee_id)


          const subFilter = this.filter.selectedSubCommitteeIdList.find(sub => subCommitteeIds.includes(sub))
          if (subFilter) return true

          const committeeId = legislator.legislator_committees
            .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
            .map(legislatorCommittee => legislatorCommittee.committee_id)

          const mainFilter = this.filter.selectedCommitteeIdList.find(committee => committeeId.includes((committee)))
          if (mainFilter) return true

          if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
        })
        this.loading = false
      },
      onSelectCommitteeIdList(arr, id) {
        return arr.filter(_committeeId => _committeeId !== id)
      },
      checkAndSortArray(array, name) {
        if (array.length) {
          array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
          array.push({divider: true})
          array.unshift({header: name})
        }
        return array
      },
      fillLegislator() {
        if(!this.filter.isSpecificLegislatorsOnly) {
         this.filter.isDistricts = null;
          this.filter.legislators = this.legislators;
        } else {
          this.filter.legislators = [];
        }
      },
      getIndex(id, array) {
        return array.findIndex(item => item.id === id)
      },
      removeExceptLegislator(index, array) {
        array.splice(index, 1)
      },
      removeLegislator(index, array, item) {
        swal({
          title: `Remove ${item.fullname}?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#9E9E9E',
          confirmButtonText: '&#10003 Remove',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.filter.exceptLegislators.push(item)
            array.splice(index, 1)
          }
        })
      },
      onUpdateDistricts() {
        swal({
          title: 'Are you sure?',
          html: `
          This action will overwrite all of the districts for all of your advocates that have a valid address.
          For blank addresses, or invalid addresses for which you have already manually set districts, no changes will be made.
          This is useful after an initial import, or if your state has recently redistricted.<br />
          <b>It cannot be undone.</b>
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isUpdating = true
            this.updateClientDistricts(this.client.id)
              .then(() => {
                this.isUpdating = false
                swal({
                  title: 'Updating Districts in progress',
                  type: 'info',
                  html: `You've started update of districts. Task will run in the background`,
                  confirmButtonColor: '#3085d6',
                })
                this.$modals.get('notifications').openAndLoad()
              })
              .catch((err) => {
                this.isUpdating = false
                this.handleError(err)
              })
          }
        })
      },
      onDeleteMember(memberId) {
        swal({
          title: 'Delete this Advocate?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.deleteMember(memberId)
              .then((res) => {
                this.onCallToastr('success', 'Advocate has been deleted.', 'Success!')
                this.refresh()
              })
              .catch((err) => {
                this.onCallToastr('error', 'Something went wrong.', 'Error!')
              })
          }
        })
      },
      onEditMember(member) {
        this.$refs.editMemberModal.toggle(member)
      },
      isDistrictMatch(member, district) {
        return member.matched_districts.some(item => item === district)
      },
      getList() {
        this.isLoading = true
        let params = {
          page: this.list.pagination.page,
          limit: this.list.pagination.itemsPerPage,
          search: this.filter.search,
          is_verified:  this.filter.isVerified ? this.filter.isVerified : null,
          sort: this.list.pagination.sortBy[0],
          direction: this.list.pagination.sortDesc[0] ? 'asc' : 'desc',
          showInactive: this.filter.showInactive ? this.filter.showInactive : null,
          showInvalid: this.filter.showInvalid ? this.filter.showInvalid : null,
          districtNotSet: this.filter.districtNotSet ? this.filter.districtNotSet : null,
          activeKP1: this.filter.activeKP1 ? this.filter.activeKP1 : null,
          legislators: _.cloneDeep(this.filter.legislators).map(legislator => legislator.id),
          reduceLegislators: _.cloneDeep(this.filter.exceptLegislators).map(legislator => legislator.id),
          custom_fields: this.collectCustomFieldsFilter(),
          kp_preference: this.filter.kpPreferenceResults,
          region: this.filter.region,
        }
        this.exportProps = _.cloneDeep(params)
        this.exportSelectedLegislatorsProps =  params.legislators
        delete this.exportProps["page", "sort", "direction"]

        const clientId = this.client && this.client.id || '';
        return this.getClientMembersWithPagination(clientId, params).then((response) => {
          this.list.items = response.data.data ? response.data.data : response.data
          this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
          this.list.pagination.current_page = response.data.current_page
          this.list.pagination.per_page = response.data.per_page
          this.list.pagination.last_page = response.data.last_page
          this.active_members_count = response.data.active_members_count

          this.checkSelectedFilters = this.getCheckSelectedFilters()
          this.selected_committees = this.filter.committees
          this.isLoading = false
        }, () => {
          this.isLoading = false
        })
      },
      doFilter() {
        this.list.pagination.page = 1
        this.menu = false
        this.refresh()
      },
      clearFilter() {
        this.filter.isSpecificLegislatorsOnly = true
        this.filter.showInactive = null
        this.filter.showInvalid = null
        this.filter.districtNotSet = null
        this.filter.activeKP1 = null
        this.filter.isVerified = null
        this.filter.region = null
        this.filter.legislators = []
        this.filter.exceptLegislators = []
        this.filter.kpPreferenceResults = []
				this.loadCustomFields(this.client.id, true).then(() => {
					this.doFilter()
        })
      },
      editKpPreferenceResults(event, value) {
        if (event) {
          this.filter.kpPreferenceResults.push(value)
        } else {
          const index = this.filter.kpPreferenceResults.indexOf(value)
          if (index >= 0) {
            this.filter.kpPreferenceResults.splice(index,1)
          }
        }
      },
      getCheckSelectedFilters () {
        let selectedFilters = []

        this.filter.customFields.forEach( item => {
          if (item.type == 'text' || item.type == 'toggle')
            if (item.value)
              selectedFilters.push({ id: item.id, name: item.name, type: item.type, value: item.value })
          if (item.type == 'checkbox' || item.type == 'dropdown') {
            let value = item.metadata.filter( meta => meta.checked ).map( meta => meta.name)
            if (value && value.length)
              selectedFilters.push({ id: item.id, type: item.type, name: item.name, value: value })
          }
        })

        if (this.filter.showInactive) selectedFilters.push({ type: "static", name: "showInactive" })
        if (this.filter.showInvalid) selectedFilters.push({ type: "static", name: "showInvalid" })
        if (this.filter.districtNotSet) selectedFilters.push({ type: "static", name: "districtNotSet" })
        if (this.filter.activeKP1) selectedFilters.push({ type: "static", name: "activeKP1" })
        if (this.filter.isVerified) selectedFilters.push({ type: "static", name: "isVerified" })
        if (this.filter.region) selectedFilters.push({ type: "static", name: "region" })

        return selectedFilters
      },
      removeFilterItem (item, key = null) {
        if (item.type == 'static') {
          this.filter[key] = false
        }

        this.filter.customFields.forEach( field => {
          if (field.id == item.id) {
            if (item.type == 'checkbox' || item.type == 'dropdown') {
              field.metadata.forEach( data => {
                if (data.name == key) data.checked = false
              })
            } else if (item.type == 'text') {
              field.value = ''
            } else {
              field.value = false
            }
          }
        })

        this.doFilter()
      },
      getFieldText(name) {
        if(name == 'showInactive') return 'Show Inactive'
        if(name == 'showInvalid') return 'No valid email or cell'
        if(name == 'districtNotSet') return 'Districts not set'
        if(name == 'activeKP1') return 'Active KP1'
        if(name == 'isVerified') return 'Is Verified'
        if(name == 'region') return 'By Region'
      },
      update() {
        this.refresh()
      },
      getCommitteesList() {
        this.selectedStateId = this.client && this.client.state && this.client.state.id
        return this.getStateCommittees(this.selectedStateId, {
          only_parent_committees: true,
          with_subcommittees: true,
        }).then(response => {
          this.committeeList = response.data
        })
      },
      setCommitteesList() {
        this.filteredCommittees = []

        let houseCommittees = this.client && this.client.state && this.client.state.house_districts ? this.client.house_committees : []
        let senateCommittees = this.client && this.client.state && this.client.state.sen_districts ? this.client.senate_committees : []
        let jointCommittees = this.client && this.client.joint_committees || []

        let committeesList = [...houseCommittees, ...senateCommittees, ...jointCommittees]
            .filter(committeeId => this.committeeList.some(committee => committee.id === committeeId))
            .map((committeeId) => this.committeeList.find(committee => committee.id === committeeId))

        if(committeesList) {
          return committeesList
        }

        return []
      },
      getRandomInt() {
        return Math.floor(Math.random() * Math.floor(100))
      },
      getClientRegionsList() {
        if (this.client && this.client.uses_regions) {
          return this.getClientRegions(this.client.id).then(response => {
            if (response.data && response.data.length) {
              this.clientRegions = response.data
            }
          })
        }
      },
      startHoverTimer(district, districtId = null) {
          this.hoverTimer = setTimeout(() => {
            this.performAction(district, districtId);
          }, 500); // .5 seconds
        },
        cancelHoverTimer() {
          this.legislatorName = null;
          clearTimeout(this.hoverTimer);
        },
        performAction(district, districtId = null) {
          const payload = {
            district_name: district,
            district_id: districtId,
          }
          this.legislatorName = null;
          this.getDistrictLegislator(this.client.id, payload)
            .then((response) => {
              if(response.data && Object.keys(response.data).length !== 0){
                this.legislatorName = response.data.fullname
              }
              else{
                this.onCallToastr('error', 'Not Found', 'Error!')
              }
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong, please try again later', 'Error!')
            })
        },
    },
    watch: {
      'client': function () {
        this.refresh()
        this.getClientRegionsList()
      },
      'filter.search'() {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=> {
          this.list.items = []
          this.list.pagination.page = 1
          this.refresh()
        }, 500)
      }
    },
  }
</script>
