<template>
  <v-dialog persistent max-width="400" v-model="isModalOpen">
    <v-card class="legislators_unassigned-content">
      <v-card-title>
        <span class="title">Unassigned {{ title }}</span>
        <v-spacer/>
        <v-icon class="ml-3" @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="isLoading"
        type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-list v-else two-line class="py-0">
        <template v-for="(item, index) in list.items">
          <v-list-item :key="index" @click="$refs.legislatorDetailModal.toggle(item)">
            <v-list-item-avatar>
              <v-img :src="item.official_image"></v-img>
              <v-icon v-if="item.lame_duck" color="#EECB23" class="legislators_icon--faded">fa-solid fa-duck</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <span>{{ item.fullname }}</span>
                <v-tooltip bottom v-if="item.lame_duck">
                  <template v-slot:activator="{ on }">
                    <a v-on="on">
                      <v-icon color="#EECB23" size="16" class="mx-2">fa-solid fa-duck</v-icon>
                    </a>
                  </template>
                  <span>Lame Duck</span>
                </v-tooltip>
              </v-list-item-title>

              <v-list-item-subtitle v-html="item.official_email"></v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              class="ml-2"
              :color="colorDistrictButton(item)"
              @click.native.stop="openDistrictModal(item.district.district)"
              small
              outlined
            >
              {{ item.district.district }}
              <span v-if="isFederalClient && item.district && item.district.state && item.district.state.state_abbrev">
                ({{ item.district.state.state_abbrev }})
              </span>
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
      <v-card-text>
        <v-pagination
          :disabled="isLoading"
          v-model="list.pagination.page"
          :length="list.pagination.last_page"
          :total-visible="list.pagination.last_page >= 5 ? 5 : list.pagination.last_page"
          @input="updatePaginate(legislatorType, selectedClient)"
        ></v-pagination>
      </v-card-text>
    </v-card>
    <district-modal v-if="districtModalEnabled"></district-modal>

    <legislator-detail-modal ref="legislatorDetailModal" :refresh="update"></legislator-detail-modal>

  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'
  import DistrictService from "@/services/DistrictService"
  import districtModal from '@/components/common/district-modal'

  export default {
    mixins: [ClientService, DistrictService],
    components: {
      'legislator-detail-modal': legislatorDetailModal,
      'district-modal': districtModal,
    },
    data() {
      return {
        isModalOpen: false,
        isLoading: false,
        selectedClient: null,
        legislatorType: '',
        title: '',
        taskList: [],
        kpList: [],
        kpTasks: [],
        activeLegislator: null,
        activePanel: null,
        activePanelHeader: null,
        list: {
          pagination: {
            page: 1,
            itemsPerPage: 5,
            totalItems: 0,
            current_page: 0,
            per_page: 0,
            last_page: 0,
          },
          items: [],
        },
      }
    },
    computed: {
      selectedClientObject() {
        if(this.isSuperAdmin) {
          return this.AppState.superAdminSelectedClient
        }

        return this.userClient
      },
      isFederalClient(){
        return this.selectedClientObject && this.selectedClientObject.is_use_state === 0;
      }
    },
    methods: {
      async refresh() {
        this.activeLegislator = null
        this.activePanel = null
        this.activePanelHeader = null
        this.isLoading = true
        this.formChanged = false
        this.kpList = []
        try {
          const res = (await this.getMyKPs(this.userClient.id)).data
          this.taskList = res.tasks
          this.kpList = res.myKPList
          this.coordinator = res.coordinator

          this.legislatorsWithoutKp1 = res.legislatorsWithoutKp.map(legislator => {
            legislator.salutation = ''
            if (legislator.district.district[0] === 'H') {
              legislator.salutation = this.userClient.formal_house + ' ' + legislator.fullname
            } else if (legislator.district.district[0] === 'S') {
              legislator.salutation = this.userClient.formal_senate + ' ' + legislator.fullname
            }
            return legislator
          })

          this.kpTasks = res.tasks.filter((task) => {
            return task.hide_from_kp === 0 && (task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select')
          })

          this.currencyTasks = res.tasks.filter((task) => {
            return task.hide_from_kp === 0 && (task.task_type.data_type === 'Currency')
          })

          this.kpList.map(members => this.mapMembers(members))
        } catch (error) {
          this.loading = false
        }
        this.isLoading = false
      },
      open(clientId, type) {
        this.title = type
        this.list.items = []
        this.isModalOpen = true
        this.selectedClient = clientId
        this.legislatorType = type

        this.updatePaginate(type, clientId)
        this.refresh()

      },
      updatePaginate(legType, legId) {
        this.isLoading = true

        let params = new Object();

        params.page = this.list.pagination.page;
        params.limit = this.list.pagination.itemsPerPage;
        params[`${legType}`] = true;
        params.unassigned = true;

        return this.getClientLegislatorsWithPagination(legId, params).then((response) => {
          this.list.items = response.data.data ? response.data.data : response.data
          this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
          this.list.pagination.current_page = response.data.current_page
          this.list.pagination.per_page = response.data.per_page
          this.list.pagination.last_page = response.data.last_page

          this.isLoading = false
        }, () => {
          this.isLoading = false
        })
      },
      update() {
        this.isModalOpen = false
        this.$parent.update()
      },
      mapMembers(member) {
        member.member_legislator.map(memberLegislator => this.mapMemberLegislators(memberLegislator))
        return member
      },
      mapMemberLegislators(memberLegislator) {
        memberLegislator.legislator.salutation = ''
        if (memberLegislator.legislator.district.district[0] === 'H') {
          memberLegislator.legislator.salutation = this.userClient.formal_house + ' ' + memberLegislator.legislator.fullname
        } else if (memberLegislator.legislator.district.district[0] === 'S') {
          memberLegislator.legislator.salutation = this.userClient.formal_senate + ' ' + memberLegislator.legislator.fullname
        }

        memberLegislator.legislator.tasks = this.taskList.map(task => {
          task = {...task}
          task.result = memberLegislator.task_results.filter(result => {
            return result.task_id == task.id
          })[0]
          return task
        })
        return memberLegislator
      },
      colorDistrictButton(legislator) {
        return legislator.district.district[0] === 'H' ? 'primary' : 'error'
      },
    }
  }
</script>
