import axios from 'axios'

export default {
  methods: {
    login (payload) {
      return axios.post('/login', payload)
    },
    getActiveClientsForLogin(payload){
      return axios.post('/login/active-clients', payload)
    },
    loginAsMember (payload) {
      return axios.post('/login/member', payload)
    },
    register (payload) {
      return axios.post('/register', payload)
    },
    remember (payload) {
      return axios.post('/password/forgot', payload)
    },
    reset (payload) {
      return axios.post('/password/reset', payload)
    },
    sendKPLoginLink(payload) {
      return axios.post('/login/member/link', payload)
    },
    sendOTPKPLogin(payload) {
      return axios.post('/send/otp', payload)
    },
    sendVerifyOTPCode(payload) {
      return axios.post('/send/verify-otp-code', payload)
    },
    sendKPProfileUpdateLink(payload) {
      return axios.post('/login/member/profile-link', payload)
    },
    memberCheck(payload) {
      return axios.post('/login/member-check', payload)
    },
    getMemberActiveClients(params) {
      return axios.get('/login/member/get-active-clients', { params })
    },
    userCheck(payload) {
      return axios.post('/register/user-check', payload)
    },
  }
}
