import {mapGetters} from "vuex";


export default {
  data() {
    return {
      committeeList: []
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    selectedClient() {
      if (this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.userClient
    },
  },
  methods: {
    async filterCommitteeList() {

      const committeeList = (await this.getStateCommittees(this.selectedClient.state_id, {
        only_parent_committees: true,
        with_subcommittees: true,
        client_id: this.selectedClient.id,
      })).data
      this.committeeList = []
      if (this.selectedClient && this.selectedClient.state && this.selectedClient.state.house_districts) {
        this.committeeList = [
          ...(this.selectedClient.house_committees || [])
        ]
      }
      if (this.selectedClient && this.selectedClient.state && this.selectedClient.state.sen_districts) {
        this.committeeList = [
          ...this.committeeList,
          ...(this.selectedClient.senate_committees || [])
        ]
      }
      this.committeeList = [
        ...this.committeeList,
        ...(this.selectedClient.joint_committees || []),
      ]
      this.committeeList = this.committeeList
        .map(committeeId => committeeList.find(committee => committee.id === committeeId))
        .filter(committeeId => !!committeeId)
    }
  }
}
