<template>
  <div class="toolbar">
    <v-app-bar
      app
      fixed
      :dark="isDarkTheme"
      transparent
      color="transparent"
      height="58px"
      elevate-on-scroll
      :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <v-app-bar-nav-icon v-if="!isProfileUpdate && (isDevice || isMobile)" text-color="white"
      class="ml-0" @click="toggleSidebar"></v-app-bar-nav-icon>
      <app-logo v-if="!isProfileUpdate" :isSidebarExpanded="isSidebarExpanded" :class="isMobile ? '' : 'logo'"></app-logo>
      <v-select
          v-if="isSuperAdmin"
          label="Client"
          class="client-selector ml-2"
          hide-details
          :item-text="item => `${item.assoc_abbrev} - ${item.association}`"
          item-value="id"
          :items="clientList"
          v-model="selectedClientId"
      >
        <template slot="item" slot-scope="data">
          <v-list-item-content>
            <v-list-item-title>{{ data.item.assoc_abbrev }} - {{ data.item.association }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
      <v-spacer v-if="!isProfileUpdate"></v-spacer>
      <div v-if="$route.name !== 'profile-update-page' && (isSuperAdmin || isVIP)">
        <search-component-mobile ref="searchComponentMobile"></search-component-mobile>
        <v-icon v-if="isMobile" @click="openSearchComponentMobile">mdi-magnify</v-icon>
        <search-component v-else></search-component>
      </div>
      <v-spacer v-if="!isProfileUpdate"></v-spacer>

      <template v-if="$vuetify.breakpoint.smAndUp && !isProfileUpdate">
        <v-tooltip v-if="isDarkTheme" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-3"
              v-on="on"
              color="primary"
              small
              icon
              @click="onThemeChange(false)"
            >
              <v-icon icon>mdi-weather-sunny</v-icon>
            </v-btn>
          </template>
          <span>Change to Light Mode</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-3"
              v-on="on"
              color="primary"
              small
              icon
              @click="onThemeChange(true)"
            >
              <v-icon icon color="secondary">mdi-weather-night</v-icon>
            </v-btn>
          </template>
          <span>Change to Dark Mode</span>
        </v-tooltip>
      </template>
      <template v-if="$vuetify.breakpoint.smAndUp && !isProfileUpdate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div class="c-menu--popover-attach" v-on="on">
              <v-menu role="dialog" 
                  v-model="helpMenu" 
                  content-class="c-menu--popover" 
                  :close-on-content-click="false" 
                  :eager="true"
                  :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="mr-3"
                    small 
                    v-bind="attrs" 
                    v-on="on"
                    >
                      <v-icon :color="isDarkTheme ? 'primary' : 'secondary'">help</v-icon>
                      <v-icon v-if="!helpMenu" :color="isDarkTheme ? 'primary' : 'secondary'">mdi-chevron-up</v-icon>
                      <v-icon v-else :color="isDarkTheme ? 'primary' : 'secondary'">mdi-chevron-down</v-icon>
                </div>
                </template>
                <v-card tabindex="-1" class="c-card--popover">
                  <v-card-title id="popoverLabel-1" class="c-card-title--popover">
                    Help
                    <v-spacer></v-spacer>
                    <v-btn @click="helpMenu = false" small icon class="c-card-title-close--popover">
                      <v-icon small>mdi-close</v-icon>
                      <span class="d-sr-only">Close</span>
                    </v-btn>
                  </v-card-title>
                <help-menu-items></help-menu-items>
                </v-card>
              </v-menu>
            </div>
          </template>
          <span>Help</span>
        </v-tooltip>
      </template>
      <v-badge  color="red" overlap right top class="mr-4" v-model="notificationsList.length"
                v-if="isSuperAdmin || isVIP">
        <template v-slot:badge>
          <span>{{ notificationsList.length > 9 ? '9+' : notificationsList.length }}</span>
            </template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :max-width="isMobile ? '10px': '28px'"
              v-on="on"
              icon
              small
              :class="isMobile ? 'mr-2' : ''"
              @click="toggleNotifications"
            >
              <v-icon :color="isDarkTheme ? 'primary' : 'secondary'">notifications</v-icon>
            </v-btn>
          </template>
          <span>Notifications</span>
        </v-tooltip>
      </v-badge>
      <div v-if="userClient && isProfileUpdate" class="d-flex align-center full-width justify-center">
        <h3>{{ userClient.association }}</h3>
        <v-avatar
          class="mx-2"
          size="43"
        >
          <img :src="userClient.avatar_url || '/images/client-avatar.png'" />
        </v-avatar>
      </div>
      <v-menu
        v-if="menuItems.item.show && menuItems.item.items && $route.name !== 'profile-update-page'"
        transition="slide-x-transition"
        :nudge-width="200"
        bottom
        nudge-bottom="50"
        :content-class="'app-sidebar_popup'"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }" >
              <v-btn fab small dark color="primary" v-on="on" class="mx-2">
                <span  style="font-size:18px;">{{ userNameAbbr }}</span>
              </v-btn>
            </template>
            <span>My Menu</span>
          </v-tooltip>
          </div>
        </template>
        <v-card>
          <v-list class="pa-0">
            <v-list-item v-show="$vuetify.breakpoint.xs">
              <v-list-item-action>
                <v-switch @click="onThemeChange(isDarkTheme ? false : true)"></v-switch>
              </v-list-item-action>
              <v-list-item-title>{{isDarkTheme? 'Change to Light Mode' : 'Change to Dark Mode' }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :ripple="false"
              @click="onClickSubMenuItem(subMenuItem)"
              v-for="subMenuItem in menuItems.item.items"
              v-if="subMenuItem.show"
              :key="subMenuItem.title"
              :class="{'active': subMenuItem.link === $route.name }"
            >
              <v-list-item-action>
                <v-icon v-text="subMenuItem.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <app-notifications v-if="!isKP"></app-notifications>
    <kp-profile-edit-modal ref="kpProfileEditModal"></kp-profile-edit-modal>
  </div>
</template>

<script>
  import {mapGetters} from "vuex"
  import sidebar from '@/components/global/sidebar'
  import notifications from '@/components/global/notifications'
  import swal from "sweetalert2";
  import theme from '@/utils/storage/theme'
  import logo from '@/components/common/app-logo'
  import HelpMenuItems from '@/components/global/help-menu-items'
  import SearchComponent from '@/components/global/search-component'
  import SearchComponentMobile from '@/components/global/search-component-mobile'
  import KpProfileEditModal from '@/components/members/kp-profile-edit-modal'
  import ClientService from "@/services/ClientService";

  export default {
    mixins: [ClientService],
    data() {
      return {
        timerInterval: null,
        sessionTimerId: null,
        isSidebarExpanded: true,
        clientList: [],
        helpMenu: false
      }
    },
    components: {
      'app-sidebar': sidebar,
      'app-notifications': notifications,
      'app-logo': logo,
      'search-component': SearchComponent,
      'search-component-mobile': SearchComponentMobile,
      'kp-profile-edit-modal': KpProfileEditModal,
      'help-menu-items': HelpMenuItems,
    },
    computed: {
      ...mapGetters('notifications', ['notifications']),
      ...mapGetters('app', ['superAdminSelectedClient']),
      selectedClientId: {
        get() {
          let client = this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
          return client && client.id ? client.id : null
        },
        set(newValue) {
          if (this.isSuperAdmin) {
            let client = this.clientList.find(client => client.id === newValue)
            this.$store.dispatch('app/setSuperAdminSelectedClient', client)
          }
        }
      },
      isUnseenMessages() {
        return this.$store.state.app.unseenMessageCount > 0
      },
      userNameAbbr() {
        let namesArray = this.userName ? this.userName.split(' ') : []
        return namesArray[0] && namesArray[1] ? namesArray[0][0].toUpperCase() + namesArray[1][0].toUpperCase() : namesArray[0] ? namesArray[0][0].toUpperCase() : ''
      },
      notificationsList() {
        return this.notifications.filter(notification => {
          const scheduleMessages = notification.data.type == 'SendScheduleMessages'
          const scheduleMail = notification.data.type == 'SendMail'
          const scheduleTexts = notification.data.type == 'SendTexts'
          const AnalyzeMemberImport = notification.data.type == 'AnalyzeMemberImport'

          if (AnalyzeMemberImport) {
            return false
          }

          if(scheduleMessages || scheduleMail || scheduleTexts) {
            return !notification.data.payload.success
          }
          return notification
        })
      },
      menuItems() {
        return {
          item: {
            icon: '',
            title: 'Profile',
            show: true,
            items: [
              {
                icon: 'fact_check',
                title: 'My Legislators',
                link: 'my-tasks-page',
                show: (this.isVIP || this.isCoordinator) && this.userIsKP1,
              },
              {
                icon: 'groups',
                title: 'My KPs',
                link: 'my-kps-page',
                show: (this.isVIP && this.userData.coordinator) || this.isCoordinator
              },
              {
                icon: 'perm_identity',
                title: 'Profile',
                link: '',
                show: this.isSuperAdmin || this.isVIP || this.isCoordinator ||  this.userData.role === 'KP' && this.userData.member_id ? true : false
              },
              {
                icon: 'perm_identity',
                title: 'Edit profile',
                link: '',
                show: this.userData.role === 'KP'
              },
              {
                icon: 'exit_to_app',
                title: 'Logout',
                link: '',
                show: true
              },
            ]
          }
        }
      },
      isProfileUpdate(){
        return localStorage.getItem('onlyProfileUpdate')
      },
    },
    methods: {
      onThemeChange(isDark) {
        this.$vuetify.theme.dark = isDark
        theme.set(isDark)
      },
      toggleNotifications() {
        this.$modals.get('notifications').openAndLoad()
      },
      onClickSubMenuItem (subItem) {
        if(subItem.title === 'Logout') {
          localStorage.removeItem('onlyProfileUpdate')
          this.onLogout()
        }
        if(subItem.title === 'Profile') {
          return this.$store.dispatch('app/setProfileSidebarOpen', true)
        }
        if(subItem.title === 'Edit profile') {
          this.$refs.kpProfileEditModal.isModalOpen = true
        }

        this.$router.push({ name: subItem.link }).catch(err => {})
      },
      changeSessionWatcher() {
        clearInterval(this.sessionTimerId)
        this.sessionTimerId = setInterval(this.checkSession, 60 * 1000)
        this.$store.dispatch('auth/refreshSession', moment())
      },
      checkSession () {
        let past = moment().diff(this.refreshedAt, 'minutes')
        if (past == 115) {
          swal({
            html: '<h2>Your session will end in <strong></strong>, would you like to continue working?</h2>',
            type: 'question',
            timer: 5 * 60 * 1000,
            allowOutsideClick: false, // Prevent closing when clicking outside
            onBeforeOpen: () => {
              this.timerInterval = setInterval(() => {
                const seconds = swal.getTimerLeft() / 1000
                if(swal.getContent() && swal.getContent().querySelector('strong')) {
                  swal.getContent().querySelector('strong').textContent = `${parseInt(seconds / 60)} mins ${parseInt(seconds % 60)} seconds`
                }
              }, 1000)
            },
            onClose: () => {
              clearInterval(this.timerInterval)
            },
            showCancelButton: true,
            confirmButtonColor: '#4CAF50',
            cancelButtonColor: '#FF5252',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Log Out',
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.$store.dispatch('auth/refreshSession', moment())
            } else {
              this.onLogout()
            }
          })
        }
        if (past >= 120) {
          this.onLogout()
        }
      },
      onLogout () {
        document.removeEventListener('click', this.changeSessionWatcher)
        clearInterval(this.sessionTimerId)
        clearInterval(this.unseenMessagesTimerId)
        this.sessionTimerId = null
        this.$store.dispatch('auth/refreshSession', null)

        this.logout()
      },
      toggleSidebar() {
        this.$store.dispatch('app/setMenuSidebarOpen', !this.$store.state.app.isMenuSidebarOpen)
      },
      setTheme() {
       const isDarkTheme = theme.get()
        if(isDarkTheme) {
          this.$vuetify.theme.dark = true
        }
      },
      openSearchComponentMobile() {
        this.$refs.searchComponentMobile.open()
      },
      getClientsList() {
        this.getClients().then(response => {
          this.$store.dispatch('app/setClientList', response.data)
          this.setClientList()
        })
      },
      setClientList() {
        this.clientList = this.$store.state.app.clientList
      }

    },
    created() {
      if(this.isSuperAdmin) {
        this.getClientsList()
      }
      this.setTheme()
    },
    mounted () {
      // this.isSidebarExpanded = sidebar.get()
      this.$store.dispatch('auth/refreshSession', moment())
      this.checkSession()
      this.changeSessionWatcher()

      if (this.isKP && !this.userData.avatar_url && !this.isProfileUpdate) {
        this.$refs.kpProfileEditModal.isModalOpen = true
      }

      document.addEventListener('click', this.changeSessionWatcher)
    },
    watch: {
      "$store.state.app.clientList": {
        handler: function (val) {
          if(val) {
            this.setClientList()
          }
        },
        immediate: true
      },
    }
  }
</script>

<style>
.logo {
  margin-right: 61px;
}
</style>
