import axios from 'axios'

export default {
  methods: {
    getStates () {
      return axios.get('/states')
    },
    createState (payload) {
      return axios.post('/states', payload)
    },
    updateState (stateId, payload) {
      return axios.put(`/states/${stateId}`, payload)
    },
    deleteState (stateId) {
      return axios.delete(`/states/${stateId}`)
    },
    getStateCommittees (stateId, params = {}) {
      return axios.get(`/states/${stateId}/committees`, {params})
    },
    getStateUnelectedDistricts (stateId, params = {}) {
      return axios.get(`/states/${stateId}/unelected-districts`, {params})
    },
    getNewStateLegislators (stateId, params = {}) {
      return axios.get(`/states/${stateId}/new-legislators`, {params: params})
    },
    getLegislatorsUrls (stateId, params = {}) {
      return axios.get(`/states/${stateId}/legislator-urls`, {params})
    },
    storeLegislatorsUrls (stateId, payload) {
      return axios.post(`/states/${stateId}/legislator-urls`, payload)
    },
    deleteLegislatorsUrls (stateId, legislatorUrlId) {
      return axios.delete(`/states/${stateId}/legislator-urls/${legislatorUrlId}`)
    },
    getFederalStates(clientId, params = {}) {
      return axios.get(`/clients/${clientId}/federal-states`, {params})
    },
  }
}
