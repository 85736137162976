<template>
  <div>
    <v-tooltip
      v-for="committee in committeesWhereHasNoSubcommittee"
      :key="committee.id"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          small
          label
          text-color="white"
          :color="
            committee.color
              ? committee.color
              : getColorFromText(committee.shortname)
          "
          @click="
            $emit('click', getLegislatorCommitteeByCommittee(committee), client)
          "
        >
          {{ committee.shortname }}
        </v-chip>
      </template>
      <span>{{ committee.fullname }}</span>
    </v-tooltip>

    <v-menu
      v-for="committee in committeesWhereHasSingleSubcommittee"
      :key="committee.id"
      open-on-hover
      bottom
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-on="on"
          small
          label
          text-color="white"
          :color="
            committee.color
              ? committee.color
              : getColorFromText(committee.shortname)
          "
          :attrs="attrs"
          @click="
            $emit('click', getLegislatorCommitteeByCommittee(committee), client)
          "
        >
          {{ committee.parent.shortname }} ({{ committee.shortname }})
        </v-chip>
      </template>
      <v-card class="px-1">
        {{ committee.parent.fullname }}:
        <br />
        {{ committee.fullname }}
      </v-card>
    </v-menu>

    <!-- <div v-for="parentCommittee in committeesWhereHasMultipleSubcommittee" :key="parentCommittee.id">
      {{ parentCommittee.shortname }} ({{ parentCommittee.children.length }} subs)

    </div> -->

    <v-menu
      v-for="parentCommittee in committeesWhereHasMultipleSubcommittee"
      :key="parentCommittee.id * 2"
      open-on-hover
      bottom
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-on="on"
          small
          label
          text-color="white"
          :color="
            parentCommittee.color
              ? parentCommittee.color
              : getColorFromText(parentCommittee.shortname)
          "
          :attrs="attrs"
        >
          {{ parentCommittee.shortname }} ({{
            parentCommittee.children.length
          }}
          subs)
        </v-chip>
      </template>
      <v-card class="px-1">
        {{ parentCommittee.fullname }}:
        <br />
        <div
          v-for="subCommittee in parentCommittee.children"
          :key="subCommittee.id"
        >
          <v-chip
            small
            label
            text-color="white"
            :color="
              subCommittee.color
                ? subCommittee.color
                : getColorFromText(subCommittee.shortname)
            "
            @click="
              $emit(
                'click',
                getLegislatorCommitteeByCommittee(subCommittee),
                client
              )
            "
          >
            {{ subCommittee.fullname }}
          </v-chip>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "GridCommitteeCombinedChipTooltip",
  props: {
    legislatorCommittees: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    committeesWhereHasNoSubcommittee() {
      return this.legislatorCommittees
        .filter((legislatorCommittee) => {
          return legislatorCommittee.committee.parent === null;
        })
        .map((legislatorCommittee) => legislatorCommittee.committee);
    },
    legislatorCommitteesWhereHasSubcommittees() {
      return this.legislatorCommittees.filter((legislatorCommittee) => {
        return legislatorCommittee.committee.parent !== null;
      });
    },
    groupByParentCommitteeId() {
      return _.groupBy(
        this.legislatorCommitteesWhereHasSubcommittees,
        "committee.parent.id"
      );
    },
    committeesWhereHasSingleSubcommittee() {
      const legislatorCommittees = [];
      for (const key in this.groupByParentCommitteeId) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.groupByParentCommitteeId,
            key
          )
        ) {
          const element = this.groupByParentCommitteeId[key];
          if (Array.isArray(element) && element.length === 1) {
            legislatorCommittees.push(element[0]);
          }
        }
      }
      return legislatorCommittees.map(
        (legislatorCommittee) => legislatorCommittee.committee
      );
    },
    committeesWhereHasMultipleSubcommittee() {
      const parentCommitteesWithMultipleSubcommittees = [];

      for (const key in this.groupByParentCommitteeId) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.groupByParentCommitteeId,
            key
          )
        ) {
          const element = this.groupByParentCommitteeId[key];
          if (Array.isArray(element) && element.length > 1) {
            element.forEach((legislatorCommittee) => {
              const parentCommittee = legislatorCommittee.committee.parent;
              const parentCommitteeInArray =
                parentCommitteesWithMultipleSubcommittees.find(
                  (parentCommittee) => parentCommittee.id === parentCommittee.id
                );
              if (parentCommitteeInArray) {
                parentCommitteeInArray.children.push(
                  legislatorCommittee.committee
                );
              } else {
                parentCommitteesWithMultipleSubcommittees.push({
                  ...parentCommittee,
                  children: [legislatorCommittee.committee],
                });
              }
            });
          }
        }
      }
      return parentCommitteesWithMultipleSubcommittees;
    },
  },
  methods: {
    getLegislatorCommitteeByCommittee(committee) {
      return this.legislatorCommittees.find(
        (legislatorCommittee) =>
          legislatorCommittee.committee.id === committee.id
      );
    },
  },
};
</script>
