export default [
  {
    title: 'Text Field',
    value: 'text'
  },
  {
    title: 'Yes/No Toggle',
    value: 'toggle'
  },
  {
    title: 'Single-Select Dropdown',
    value: 'dropdown'
  },
  {
    title: 'Multi-Select Dropdown',
    value: 'checkbox'
  }
]
